import React from "react";
import {message} from "antd";
import {DhDetailDataType} from "../common/DhDetailType";

class DhHospitalAddEditCommon extends React.Component<{}, any & DhDetailDataType> {

    protected dhPatientBookingModelResult: any = null;

    closeModal = () => {

    }
    getLat = (values: any) => {
        const {addressSelect, data} = this.state;
        const {locationAddr} = values;
        //说明修改地址了
        if (addressSelect && addressSelect.length > 0) {
            let lat = 0,
                lng = 0;
            const address: any = addressSelect.find((item: {
                value: string | ''
            }) => {
                return item.value === locationAddr;
            });
            if (address && address.location) {
                lat = address.location.lat;
                lng = address.location.lng;
            } else {
                message.error('您输入的地址不正确，请通过下拉列表选择输入');
                return false;
            }
            return {
                lng: lng,
                lat: lat,
            };
            //没有修改地址
        } else if (data.lng !== 0) {
            return {
                lng: data.lng,
                lat: data.lat,
            };
            //异常
        } else {
            message.error("无法获取定位地址");
            return false;
        }
    };
}


export default DhHospitalAddEditCommon