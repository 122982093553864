import CryptoJS from 'crypto-js';

const key = "32OCpPu0";

export default function encryptData(data) {
    const cry = CryptoJS.AES.encrypt(data, key).toString();
    return encodeURIComponent(cry)
}

export function decryptData(encryptedData) {
    if (!encryptedData) {
        return '';
    }
    encryptedData = decodeURIComponent(encryptedData)
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    return bytes.toString(CryptoJS.enc.Utf8);
}
