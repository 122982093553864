import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Col, Dropdown, Input, Menu, message, Modal, Row, Select, Space, Table,} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import createModal from '../common/createModal';
// import ProjectForm from './ProjectForm';
import {Project} from './types';
import styles from './project.module.css';
import App from '../../App';
import Statistics from '../gongyiActivity/Statistics';
import ReviewDetail from '../gongyiActivity/ReviewDetail';
import {Link} from 'react-router-dom';

// import { useHistory } from 'react-router';
import {renderStatus, renderStatusOp,} from '../gongyiActivity/GongyiActivityList';
import Auth from '../common/Auth';
import ReasonDetail from '../gongyiActivity/ReasonDetail';
import {DownOutlined} from '@ant-design/icons';
import ReasonForm from '../gongyiActivity/ReasonForm';
import {showReason} from '../gongyiActivity/GongyiActivityDetail';
// import { createHashHistory } from 'history';
// const history = createHashHistory();

type ProjectState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: Project[];
    status: string;
    reviewStatus: number;
    name: string;
    sKey: number;
    reasons: any[];
    reason: any;
    project: Project | null;
    loading: boolean;
};

// const FormModal = createModal(ProjectForm);
const ReviewModal = createModal(ReviewDetail);
const ReasonDetailModal = createModal(ReasonDetail);
const ReasonFormModal = createModal(ReasonForm);

const {confirm} = Modal;

class ProjectList extends Component<{ review: boolean }, ProjectState> {
    private modal: any = null;
    private detailModal: any = null;
    private reviewModal: any = null;
    private reasonModal: any = null;
    private formModal: any = null;

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            page: 0,
            pageSize: 10,
            reasons: [],
            reason: null,
            totalElements: 0,
            totalPages: 0,
            content: [],
            status: '',
            name: '',
            sKey: 1,
            reviewStatus: -1,
            project: null,
            loading: true,
        };
    }

    showReasonModal = (reason: any) => {
        this.setState(
            {
                reason,
            },
            this.reasonModal.show
        );
    };

    loadReasons = (ids: number[]) => {
        Fetch.get(`${API.reason.list}?ids=${ids.join(',')}&category=2`).then(
            (data) => {
                this.setState({
                    reasons: data,
                });
            }
        );
    };

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, name, reviewStatus} = this.state;
        let url = `${API.project.list}?page=${page}&size=${pageSize}&name=${name}&reviewStatus=3`;
        if (this.props.review) {
            url = `${API.project.list}?page=${page}&size=${pageSize}&name=${name}&reviewStatus=${reviewStatus}`;
        }
        Fetch.get(url).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                sKey: this.state.sKey + 1,
                loading: false,
            });
            const ids = data.content.map((item: any) => item.id);
            this.loadReasons(ids);
        });
    };

    create = () => {
        window.location.href = '/#/project/edit';
        // history.push('/account');
        // this.context.router.push({
        //   pathname: '/project/edit',
        // });
    };

    edit = (record: any) => {
        window.location.href = '/#/project/edit?id=' + record.id;
        // const coverIds = record.covers.map((item: any) => {
        //   return {
        //     id: item.id,
        //     uid: item.id,
        //     status: 'done',
        //     name: item.fileName,
        //     url: API.domain + item.url,
        //   };
        // });
        // const project = {
        //   ...record,
        //   coverIds,
        // };
        // this.setState(
        //   {
        //     project,
        //   },
        //   this.modal.show
        // );
    };

    show = (record: any) => {
        this.setState(
            {
                project: record,
            },
            this.detailModal.show
        );
    };

    del = (id: number) => {
        const result = window.confirm('是否确定删除？');
        if (result) {
            Fetch.del(`${API.project.zsgc}/${id}`).then(() => {
                message.success('删除成功！');
                window.setTimeout(function () {
                    window.location.reload();
                }, 200);
            });
        }
    };

    changeStatus = (id: number, status: string, reviewStatus: string) => {
        let newStatus = '';
        if (status === 'DRAFT') {
            newStatus = 'PUBLISHED';
        } else if (status === 'DISABLED') {
            newStatus = 'PUBLISHED';
            Fetch.put(`${API.project.online}`, {id: id, status: newStatus}).then(
                () => {
                    message.success('更新成功！');
                    this.loadData();
                }
            );
            return;
        } else if (reviewStatus === 'ENABLE') {
            newStatus = 'DISABLED';
        }
        Fetch.put(`${API.project.status}`, {id: id, status: newStatus}).then(
            () => {
                message.success('更新成功！');
                this.loadData();
            }
        );
    };

    reset = () => {
        this.setState(
            {
                name: '',
                reviewStatus: -1,
                page: 0,
            },
            this.loadData
        );
    };

    showReview = (record: any) => {
        this.setState(
            {
                project: record,
            },
            this.reviewModal.show
        );
    };

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    showDownModal = (record: any) => {
        this.setState(
            {
                project: record,
            },
            this.formModal.show
        );
    };

    revoke = (id: any) => {
        confirm({
            title: '是否撤销提交?',
            content:
                '注意撤销提交后，该资讯将不会继续审批流程，只有初审状态才能撤销提交，撤销提交后该资讯将被打回草稿箱，请谨慎操作！',
            onOk: () => {
                Fetch.put(`${API.project.status}`, {id: id, status: 'DRAFT'}).then(
                    () => {
                        message.success('撤销成功！');
                        this.loadData();
                    }
                );
            },
            onCancel() {

            },
        });
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        let columns: ColumnsType<Project> = [
            {
                title: '公益资讯标题',
                dataIndex: 'title',
                align: `center`,
            },
            {
                title: '阅读量',
                dataIndex: 'clicks',
                align: `center`,
                width: 80,
            },
            {
                title: '发起人',
                dataIndex: 'publisher',
                align: `center`,
            },
            {
                title: '所属组织',
                dataIndex: 'institution',
                align: `center`,
            },
            {
                title: '发布时间',
                dataIndex: 'publishDate',
                align: `center`,
                render: (text) => {
                    return text ? text : ' - ';
                },
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                render: (text, record) => {
                    return renderStatus(record.status, record.reviewStatus);
                },
            },
        ];

        const {review} = this.props;
        const auth = new Auth();
        if (review) {
            columns = columns.concat([
                {
                    title: '操作',
                    align: `center`,
                    render: (text, record: any) => {
                        const operationText = renderStatusOp(
                            record.status,
                            record.reviewStatus,
                            record.userId,
                            record.offlineRole
                        );
                        const items = [];
                        if (record.status === 'DRAFT') {
                            items.push(
                                <Menu.Item onClick={() => this.edit(record)}>编辑</Menu.Item>
                            );
                            items.push(
                                <Menu.Item onClick={() => this.del(record.id)}>删除</Menu.Item>
                            );
                        }
                        if (operationText) {
                            items.push(
                                <Menu.Item
                                    onClick={() =>
                                        this.changeStatus(
                                            record.id,
                                            record.status,
                                            record.reviewStatus
                                        )
                                    }
                                >
                                    {operationText}
                                </Menu.Item>
                            );
                        }
                        if (
                            record.reviewStatus === 'PROVINCE_REJECTED' ||
                            record.reviewStatus === 'FINAL_REJECTED'
                        ) {
                            items.push(
                                <Menu.Item onClick={() => this.showReview(record)}>
                                    驳回原因
                                </Menu.Item>
                            );
                        }
                        if (auth.isYueling() && record.reviewStatus === 'PROVINCE') {
                            items.push(
                                <Menu.Item onClick={() => this.revoke(record.id)}>
                                    撤销提交
                                </Menu.Item>
                            );
                        }

                        return (
                            <Space>
                                <Button>
                                    <Link to={`/project/detail/${record.id}`}>查看</Link>
                                </Button>
                                {items.length > 0 && (
                                    <Dropdown overlay={<Menu>{items}</Menu>}>
                                        <Button>
                                            更多操作 <DownOutlined/>
                                        </Button>
                                    </Dropdown>
                                )}
                            </Space>
                        );
                    },
                },
            ]);
        } else {
            columns = columns.concat([
                {
                    title: '操作',
                    align: `center`,
                    render: (text, record: any) => {
                        const operationText = renderStatusOp(
                            record.status,
                            record.reviewStatus,
                            record.userId,
                            record.offlineRole
                        );
                        const reason = this.state.reasons.find(
                            (item: any) => item.activityId === record.id
                        );
                        const items = [];
                        if (auth.isAdmin()) {
                            items.push(
                                <Menu.Item onClick={() => this.edit(record)}>编辑</Menu.Item>
                            );
                        }
                        if (operationText) {
                            if (operationText === '申请下架') {
                                items.push(
                                    <Menu.Item onClick={() => this.showDownModal(record)}>
                                        {operationText}
                                    </Menu.Item>
                                );
                            } else {
                                items.push(
                                    <Menu.Item
                                        onClick={() =>
                                            this.changeStatus(
                                                record.id,
                                                record.status,
                                                record.reviewStatus
                                            )
                                        }
                                    >
                                        {operationText}
                                    </Menu.Item>
                                );
                            }
                        }
                        if (showReason(reason, record.status, record.reviewStatus)) {
                            items.push(
                                <Menu.Item onClick={() => this.showReasonModal(reason)}>
                                    申请理由
                                </Menu.Item>
                            );
                        }
                        return (
                            <Space>
                                <Button>
                                    <Link to={`/project/detail/${record.id}`}>查看</Link>
                                </Button>
                                {items.length > 0 && (
                                    <Dropdown overlay={<Menu>{items}</Menu>}>
                                        <Button>
                                            更多操作 <DownOutlined/>
                                        </Button>
                                    </Dropdown>
                                )}
                            </Space>
                        );
                    },
                },
            ]);
        }
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>公益资讯</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    {!review && <Statistics key={this.state.sKey} category="project"/>}
                    <div className="mgTop15">
                        <Row align="middle">
                            <Col span={12}>
                                <Space>
                                    <div>{review ? '审核列表' : '资讯列表'}</div>
                                    <Button
                                        onClick={this.create}
                                        className={styles.addBtn}
                                        type="primary"
                                    >
                                        新建资讯
                                    </Button>
                                </Space>
                            </Col>
                            <Col span={12} style={{float: 'right'}}>
                                <Space
                                    style={{float: 'right'}}
                                    onKeyDown={this.handleKeyDown}
                                >
                                    {review && (
                                        <Select
                                            value={this.state.reviewStatus}
                                            onChange={(e) =>
                                                this.changeCondition({page: 0, reviewStatus: e})
                                            }
                                        >
                                            <Select.Option value={-1}>全部</Select.Option>
                                            <Select.Option value={0}>初审中</Select.Option>
                                            <Select.Option value={1}>终审中</Select.Option>
                                        </Select>
                                    )}

                                    <Input
                                        placeholder="请输入名称"
                                        value={this.state.name}
                                        onChange={(e) =>
                                            this.changeCondition({
                                                page: 0,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                    <Button onClick={this.loadData}>搜索</Button>
                                    <Button onClick={this.reset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                    <Table
                        loading={this.state.loading}
                        bordered
                        rowKey={(record) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
                {/* <FormModal
          title={this.state.project === null ? '添加资讯' : '编辑资讯'}
          reload={this.loadData}
          project={this.state.project}
          ref={(c) => (this.modal = c)}
        /> */}

                <ReasonDetailModal
                    reason={this.state.reason}
                    title="查看申请理由"
                    width={600}
                    ref={(c) => (this.reasonModal = c)}
                />
                <ReasonFormModal
                    title="下架理由"
                    width={600}
                    category="PROJECT"
                    //@ts-ignore
                    activityId={this.state.project ? this.state.project.id : ''}
                    ref={(c) => (this.formModal = c)}
                />
                {review && (
                    <ReviewModal
                        title={'驳回原因'}
                        category="PROJECT"
                        activity={this.state.project}
                        ref={(c) => (this.reviewModal = c)}
                    />
                )}
            </>
        );
    }
}

export default function ProjectIndex() {
    return (
        <App selectedKeys={['project']} openKeys="sub1">
            <ProjectList review={false}/>
        </App>
    );
}

export function ProjectReviewIndex() {
    return (
        <App selectedKeys={['project']} openKeys="sub1">
            <ProjectList review={true}/>
        </App>
    );
}
