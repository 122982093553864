import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Button, Input, Space, Table} from "antd";
import Fetch from '../common/FetchIt';
import API from '../common/api';


class DonationActivityTable extends Component {
    state = {
        page: 0,
        pageSize: 1000,
        totalElements: 0,
        totalPages: 0,
        content: [],
        name: '',
    };

    selectedRows = [];

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition, load = false) => {
        this.setState({
            ...condition,
        }, () => {
            if (load) {
                this.loadData();
            }
        });
    };

    loadData = () => {
        const {page, pageSize, name} = this.state;
        let url = `${API.donationActivity.list}?page=${page}&size=${pageSize}&name=${name}&status=1`;
        Fetch.get(url).then(data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        });
    };

    reset = () => {
        this.setState({
            name: '',
            page: 0,
        }, this.loadData)
    }

    submit = () => {
        this.props.hide();
        this.props.onSelect(this.selectedRows);
    }

    render() {
        const {content} = this.state;
        const rowSelection = {
            type: this.props.selectionType,
            defaultSelectedRowKeys: this.props.selectedRows,
            onChange: (selectedRowKeys, selectedRows) => {
                this.selectedRows = selectedRows;
            }
        };

        const columns = [
            {
                title: "募捐活动ID",
                dataIndex: "id",
                align: `center`,
            },
            {
                title: "捐款活动名称",
                dataIndex: "title",
                align: `center`
            }];

        return (
            <>
                <div className="site-layout-background">
                    <div>
                        <Space>
                            <Input
                                placeholder="请输入名称"
                                value={this.state.name}
                                onChange={e => this.changeCondition({page: 0, name: e.target.value})}
                            />
                            <Button onClick={this.loadData} type="primary">搜索</Button>
                            <Button onClick={this.reset}>重置</Button>
                        </Space>
                    </div>
                    <Table
                        bordered
                        style={{margin: '10px 0'}}
                        rowKey={record => {
                            return record.id;
                        }}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={content}
                        pagination={false}
                    />
                    <div style={{textAlign: 'right'}}>
                        <Space>
                            <Button onClick={() => this.props.hide()}>取消</Button>
                            <Button type="primary" onClick={this.submit}>确定</Button>
                        </Space>
                    </div>
                </div>
            </>
        );
    }
}

export default DonationActivityTable;
