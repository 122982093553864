import React, {Component} from 'react';
import {Breadcrumb, Button, Checkbox, Form, FormInstance, Input, InputNumber, message, Modal,} from 'antd';
import {Link} from 'react-router-dom';
import App from '../../App';
import styles from './operationalActivity.module.css';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import FormItem from 'antd/lib/form/FormItem';

const {confirm} = Modal;

class DemoDetailClass extends Component {
    state = {
        id: common.getQuery('id'),
        data: {}
    };
    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        if (this.state.id) {
            this.getData();
        }
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.operationalActivity.operationalActivitiesGetDetail}?id=${id}`).then((data) => {
            //@ts-ignore
            this.formRef.current.setFieldsValue({
                ...data,
            });
        });
    };

    submit = (values: any) => {
        if (values.id) {
            // 更改
            this.update(values);
        } else {
            // 增加
            delete values.id;
            this.create(values);
        }
    };

    update = (values: any) => {
        Fetch.putJSON(API.operationalActivity.operationalActivitiesUpdate, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    create = (values: any) => {
        Fetch.postJSON(API.operationalActivity.operationalActivitiesCreate, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    cancel() {
        window.location.href = '/#/operationalActivity/list';
    };

    render() {
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/operationalActivity/list">推广活动</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {common.getQuery('id') ? '修改活动' : '新建活动'}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <div className={styles.container}>
                        <Form
                            onFinish={this.submit}
                            ref={this.formRef}
                        >
                            <FormItem hidden noStyle name="id">
                                <Input/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="name"
                                label="名称"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="名称"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="buttonName"
                                label="按钮文案"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="按钮文案"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="bootstrapPage"
                                label="跳转链接"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="跳转链接"/>
                            </FormItem>
                            <FormItem
                                name="oldUsersEnter"
                                label="老用户提示"
                                valuePropName="checked"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                            >
                                <Checkbox>提示</Checkbox>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="givePoints"
                                label="赠送积分"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                            >
                                <InputNumber min={1} placeholder="赠送积分"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="giveWorkPoints"
                                label="赠送工分"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                            >
                                <InputNumber min={1} placeholder="赠送工分"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="giveDesc"
                                label="发放描述"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                            >
                                <Input placeholder="发放描述"/>
                            </FormItem>
                            <FormItem wrapperCol={{span: 12, offset: 2}} className="center">
                                <Button
                                    size="large"
                                    className={styles.mybtn}
                                    style={{marginRight: '15px'}}
                                    onClick={this.cancel}
                                >
                                    取 消
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className={styles.mybtn}
                                >
                                    提 交
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}

export default function OperationalActivityDetail() {
    return (
        <App selectedKeys={['operationalActivity']}>
            <DemoDetailClass></DemoDetailClass>
        </App>
    );
}
