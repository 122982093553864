import React, {useEffect, useRef} from 'react';
import {Button, Card, Form, FormInstance, Input, message, Space} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import FormItem from 'antd/lib/form/FormItem';
import styles from "../../message/message.module.css";

interface ModalType {
    teethName: string,
    teethPrice: number,
    deleted: number,
    hospitalId: number,
    id: number,
    hide: any,
    isAdd: boolean
}

const HospitalTeethFeeModel: React.FC<ModalType> = (props) => {
    const formRef = useRef<FormInstance>(null);

    useEffect(
        function () {

            loadData();
        },
        [props.id]
    );

    const loadData = () => {
        let newVar = 0
        if (!props.isAdd) {
            newVar = props.id ? props.id : 0;
        }
        Fetch.get(`${API.dhHospitalTeethFee.details}?id=${newVar}`).then((data) => {
            if (data) {
                if (formRef.current) {
                    formRef.current.setFieldsValue({
                        ...data,
                    });
                }
            } else {
                if (formRef.current) {
                    formRef.current.setFieldsValue({
                        teethName: '',
                        teethPrice: ''
                    })
                }
            }
        });
    };

    const submit = (values: any) => {
        if (!values.hospitalId) {
            values.hospitalId = props.hospitalId;
        }
        create(values);
    };

    const create = (values: any) => {
        if (!values.id) {
            Fetch.postJSON(API.dhHospitalTeethFee.create, values).then(() => {
                message.success('提交成功');
                props.hide();
            });
        } else {
            Fetch.putJSON(API.dhHospitalTeethFee.update, values).then(() => {
                message.success('修改成功');
                props.hide();
            });
        }

    };

    const cancel = () => {
        props.hide();
    };
    return <>
        <>
            <div
                className="site-layout-background"
                style={{minHeight: 280}}
            >
                <div>
                    <Form
                        onFinish={submit}
                        ref={formRef}
                    >
                        <Card title="牙齿管理" size="small">
                            <Space direction="vertical" style={{display: 'flex'}}>

                                <FormItem className={styles.mylabel} name="id" hidden>
                                    <Input/>
                                </FormItem>

                                <FormItem className={styles.mylabel} name="teethName" label="牙齿名称"
                                          labelCol={{span: 4}}
                                          wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                    <Input

                                        placeholder="牙齿名称"
                                    />
                                </FormItem>

                                <FormItem className={styles.mylabel} name="teethPrice" label="牙齿价格"
                                          labelCol={{span: 4}}
                                          wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                    <Input

                                        placeholder="牙齿价格"
                                    />
                                </FormItem>

                                <Space align="center" style={{display: "flex", justifyContent: "right"}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size="large"
                                        style={{marginRight: '15px'}}
                                        onClick={cancel}
                                    >
                                        取 消
                                    </Button>
                                </Space>
                            </Space>
                        </Card>
                    </Form>
                </div>
            </div>
        </>
    </>
}

export default HospitalTeethFeeModel;
