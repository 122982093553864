import React, {useEffect, useState} from 'react';
import {Button, List, Typography} from "antd";
import {PlusCircleTwoTone} from '@ant-design/icons';
import createModal from "../common/createModal";
import DonationActivityTable from "../donationActivity/DonationActivityTable";
import Fetch from "../common/FetchIt";
import API from "../common/api";

const DonationActivityTableModal = createModal(DonationActivityTable);

export function LinkTable(props) {
    const [data, setData] = useState([]);
    useEffect(function () {
        if (props.value) {
            Fetch.get(`${API.donationActivity.listById}?ids=${props.value}`).then(data => {
                setData(data);
            });
        }
    }, [props.value]);

    let modal = null;
    const showAdd = () => {
        modal.show();
    }

    const del = (id) => {
        const newData = data.filter(item => item.id !== id);
        setData(newData);
        const items = newData.map(item => item.id);
        props.onChange(items.join(','));
    }

    const renderHeader = function () {
        if (props.selectionType === 'radio' && data && data.length > 0) {
            return false;
        }
        return <PlusCircleTwoTone onClick={showAdd} style={{fontSize: '20px'}}/>;
    }

    const onSelect = function (value) {
        setData(value);
        const ids = value.map(item => item.id);
        props.onChange(ids.join(','));
    }

    let selectedIds = [];
    if (data && data.length > 0) {
        selectedIds = data.map(item => item.id);
    }
    return (
        <>
            <List
                header={renderHeader()}
                bordered
                dataSource={data}
                renderItem={item => (
                    <List.Item
                        actions={[<Button onClick={() => del(item.id)}>删除</Button>]}
                    >
                        <Typography.Text>{item.title}</Typography.Text>
                    </List.Item>
                )}
            />
            <DonationActivityTableModal
                width={600}
                onSelect={onSelect}
                selectedRows={selectedIds}
                selectionType={props.selectionType}
                title="选择募捐活动"
                ref={dom => modal = dom}
            />
        </>
    )
}
