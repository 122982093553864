const common = {
    getAccessToken() {
        function getQueryString(name) {
            const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
            const r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        }

        let accessToken = sessionStorage.getItem('token');
        if (!accessToken) {
            accessToken = getQueryString('access_token');
        }
        return accessToken;
    },
    stringifyImages(images) {
        let newImages = [];
        if (images && images.length > 0) {
            newImages = images.map((image) => {
                const {url, name} = image;
                return {url, name};
            });
        }
        return JSON.stringify(newImages);
    },
    parseImages(images) {
        const newImages = [];
        newImages.forEach((image, index) => {
            image.uid = index;
            image.status = 'done';
        });
        return newImages;
    },
    getQuery(name) {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        if (window.location.hash.indexOf('?') !== -1) {
            let r = window.location.hash.split('?')[1].match(reg); //获取url中"?"符后的字符串并正则匹配
            let context = '';
            if (r != null) context = r[2];
            r = null;
            return context == null || context === '' || context === 'undefined'
                ? ''
                : context;
        } else {
            return null;
        }
    },
    downloadFile(fileName, filePath, callback) {
        const fileUrl = filePath; // 替换为实际的文件下载链接
        const filename = fileName; // 替换为新的文件名
        fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                if (filename) {
                    a.download = filename;
                }

                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                callback && callback(true);
            })
            .catch((error) => {
                console.error('文件下载失败', error);
                callback && callback(false);
            });
    },
    downloadFile2(fileName, filePath) {
        return new Promise((resolve, reject) => {
            const fileUrl = filePath; // 替换为实际的文件下载链接
            const filename = fileName; // 替换为新的文件名
            fetch(fileUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    if (filename) {
                        a.download = filename;
                    }

                    document.body.appendChild(a);
                    a.click();

                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                    resolve(true)
                })
                .catch((error) => {
                    console.error('文件下载失败', error);
                    reject(true);
                });
        })
    }
};

export {common as default};
