import React, {RefObject, useEffect, useState} from 'react';
import {Form, FormInstance, Input, Select, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';

const Option = Select.Option;

type ZoneSelectProps = {
    form: RefObject<FormInstance>;
    setAreaStr: any;
    loadData: {
        provinceId: number | 0,
        cityId: number | 0
    } | undefined
};

type Zone = {
    id: number;
    name: string;
};

export default function ZoneSelect(props: ZoneSelectProps) {

    const [init, setInit] = useState(0);
    const [provinceName, setProvinceName] = useState<string>('');
    const [cityName, setCityName] = useState<string>('');
    const [districtName, setDistrictName] = useState<string>('');
    const [provinces, setProvinces] = useState<Zone[]>([]);
    const [cities, setCities] = useState<Zone[]>([]);
    const [districts, setDistrict] = useState<Zone[]>([]);
    const [areaChange, setAreaChange] = useState<boolean>(false);

    useEffect(() => {
        getZone(0, 0).then(r => {
            if (props.loadData?.cityId) {
                const {provinceId, cityId} = props.loadData
                if (provinceId !== 0) {
                    getZone(provinceId, 1).then(r => {
                        if (cityId !== 0) {
                            getZone(cityId, 2).then(r => {
                                console.log("加载完成")
                            });
                        }
                    });
                }
            }
        });
    }, [props.loadData?.cityId]);

    useEffect(() => {
        if (areaChange) {

            const areaStr = getProvinceName().concat(getCityName()).concat(districtName);
            props.form.current?.setFieldsValue({
                areaStr: areaStr,
            });
            if (districtName) {
                props.setAreaStr(areaStr);
            } else {
                props.setAreaStr('');
            }
            setAreaChange(false)
        }
    }, [areaChange]);

    const getProvinceName = () => {

        if (provinceName) {
            return provinceName;
        }
        //provinceName 为空， provinces 不为空
        if (!provinceName && provinces) {
            const form = props.form.current?.getFieldValue("provinceId");
            const foundObject = provinces.find((item: { id: any; }) => item.id === form);
            return foundObject ? foundObject.name : ''
        } else {
            return '';
        }
    }

    const getCityName = () => {

        if (cityName) {
            return cityName;
        }
        //provinceName 为空， provinces 不为空
        if (!cityName && cities) {
            const form = props.form.current?.getFieldValue("cityId");
            if (API.ignoreList.indexOf(form) >= 0) {
                return '';
            } else {
                const foundObject = cities.find((item: { id: any; }) => item.id === form);
                return foundObject ? foundObject.name : ''
            }
        } else {
            return '';
        }
    }


    const getZone = async (val: number, level: number) => {
        const data: Zone[] = await Fetch.get(`${API.zone.find_zone_by_pid}?parentId=${val}&r=0`);
        if (level === 0) {
            setProvinces(data)
            setCities([]);
            setDistrict([]);
        } else if (level === 1) {
            setCities(data);
            setDistrict([]);
        } else if (level === 2) {
            setDistrict(data);
        }
    }

    // if (props.form.current) {
    //     const provinceId = props.form.current.getFieldValue('provinceId');
    //     if (provinceId != pId) {
    //         setPId(provinceId);
    //     }
    // }

    const selectChange = (val: number, level: number, e: any) => {
        getZone(val, level)
        if (level == 1) {
            setProvinceName(e.children)
            setCityName('')
            setDistrictName('')
            props.form.current?.setFieldsValue({
                cityId: undefined,
                districtId: undefined
            });
        } else if (level == 2) {
            if (API.ignoreList.indexOf(val) >= 0) {
                setCityName('')
            } else {
                setCityName(e.children)
            }
            setDistrictName('')
            props.form.current?.setFieldsValue({
                districtId: undefined,
            });
        } else if (level == 3) {
            setDistrictName(e.children)
        }
        setAreaChange(true);
    }

    return (
        <>
            <Space style={{width: '100%'}}>
                <FormItem
                    // label="居住地址"
                    name="provinceId"
                    // labelCol={{span: 4}}
                    // wrapperCol={{span: 15}}
                    // rules={[{required: true, message: '必填'}]}
                    // style={{minWidth: '150px'}}
                >
                    <Select
                        style={{minWidth: '100px'}}
                        placeholder="请选择"
                        onChange={(value: number, e) => selectChange(value, 1, e)}
                    >
                        {provinces.map((item) => {
                            return (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            );
                        })}
                    </Select>
                </FormItem>
                <FormItem
                    name="cityId"
                    // rules={[{required: true, message: '必填'}]}

                >
                    <Select
                        style={{minWidth: '100px'}}
                        placeholder="请选择"
                        onChange={(value: number, e) => selectChange(value, 2, e)}>
                        {cities.map((item) => {
                            return (
                                <Option key={item.id} value={item.id} name={item.name}>
                                    {item.name}
                                </Option>
                            );
                        })}
                    </Select>
                </FormItem>
                <FormItem

                    name="districtId"
                    // rules={[{required: true, message: '必填'}]}

                >
                    <Select
                        style={{minWidth: '100px'}}
                        placeholder="请选择"
                        onChange={(value: number, e) => selectChange(value, 3, e)}>
                        {districts.map((item) => {
                            return (
                                <Option key={item.id} value={item.id} name={item.name}>
                                    {item.name}
                                </Option>
                            );
                        })}
                    </Select>
                </FormItem>
            </Space>
            <Form.Item
                name="areaStr"
                // rules={[{required: true, message: '必填'}]}
                hidden={true}
            >
                <Input/>
            </Form.Item>
        </>
    );
}
