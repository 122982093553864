import {Card, Descriptions, Space} from 'antd';
import React from 'react';
import styles from "../add/ServiceUser.module.css";
import {DetailClass} from "../util/ServiceUserDetailTypes"
import {Link} from 'react-router-dom';
import ViewFileListClass from "../../common/modal/ViewFileList";
import encryptData from "../../common/crypto";

type  VoucherInfoType = {
    data: DetailClass['data'] | null,
}
const VoucherInfo: React.FC<VoucherInfoType> = (props) => {

    const {data} = props;
    return (
        <>
            {data && data.fee != null && data.fee.voucher && data.fee.voucher.length > 0 ? (
                <Card size="small" className={styles.mycard}
                      title={`报销凭证`}>
                    <Space size={[8, 16]} wrap align="start">
                        <Card style={{width: '100%'}}>
                            <Descriptions>
                                <Descriptions.Item label="补贴款发放时间">
                                    {data.user.releaseTime}
                                </Descriptions.Item>
                            </Descriptions>
                            <Card style={{width: '25%'}}>
                                <ViewFileListClass
                                    title="报销凭证"
                                    fileList={data.fee.voucher}
                                />
                            </Card>
                        </Card>
                    </Space>
                </Card>
            ) : (
                <>
                    {data && data.user.releaseTime && (
                        <Card size="small" className={styles.mycard}
                              title={`补贴款发放`}>
                            <Descriptions>
                                <Descriptions.Item label="补贴款发放时间">
                                    <Space>
                                        <div>
                                            {data.user.releaseTime}
                                        </div>
                                        <Link
                                            to={`/serviceUserBill/detail?billId=${encryptData(data.user.billId + "")}`}>
                                            关联账单
                                        </Link>
                                    </Space>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    )}
                </>
            )}
        </>
    );
};

export default VoucherInfo;
