import React, {Component} from 'react';
import {Breadcrumb, Button, Input, message, Select, Space, Spin, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
// import createModal from '../common/createModal';
// import VolunteerForm from './VolunteerForm';
import {Volunteer} from './types';
import 'dayjs/locale/zh-cn';
// import locale from 'antd/lib/date-picker/locale/zh_CN';
import App from '../../App';
import styles from './list.module.css';
import * as XLSX from 'xlsx';

const Option = Select.Option;
// const { RangePicker } = DatePicker;

type VolunteerState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: Volunteer[];
    username: string;
    date: [];
    volunteer: Volunteer | null;
    loading: boolean;
    volunteerType: number | '';
    importLoading: boolean,
    btnQ: {}
};

// const FormModal = createModal(VolunteerForm);

class VolunteerList extends Component<{}, VolunteerState> {
    state: any = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        username: '',
        date: [],
        volunteer: null,
        loading: true,
        loginAddress: '',
        volunteerType: '',
        importLoading: false,
        btnQ: {
            ROLE_IMPORT_VOLUNTEERS: 0,
        },
    };
    private modal: any = null;

    componentDidMount() {
        let btnQ = window.sessionStorage.getItem('buttons');
        let btn = {};
        if (typeof btnQ === 'string') {
            btn = JSON.parse(btnQ);

            this.setState({
                btnQ: btn['buttons'],
            });
        }
        this.loadData();
    }

    // 移除组织
    removeFn(row: any) {
        // /admin/volunteer_apply/admin_remove/id
        Fetch.put(`${API.volunteer.del}/${row.id}`).then(() => {
            this.loadData();
        });
    }

    // volunteerType;
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };
    // 获取表格数据;
    loadData = () => {
        const {page, pageSize, username, volunteerType} = this.state;
        // &loginAddress=${loginAddress}
        Fetch.get(
            `${API.volunteer.list}?page=${page}&size=${pageSize}&userName=${username}&volunteerType=${volunteerType}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    create = () => {
        this.setState(
            {
                volunteer: null,
            },
            this.modal.show
        );
    };

    // edit = (record: Volunteer) => {
    //   this.setState(
    //     {
    //       volunteer: record,
    //     },
    //     this.modal.show
    //   );
    // };

    // del = (id: number) => {
    //   Fetch.del(`${API.volunteer.zsgc}/${id}`).then(() => {
    //     this.loadData();
    //   });
    // };
    // 重制
    reset = () => {
        this.setState(
            {
                username: '',
                volunteerType: '',
                page: 0,
            },
            this.loadData
        );
    };

    typeChange = (e: any) => {
        this.setState({
            volunteerType: e,
        });
    };
    changeInpt = (val: string) => {
        this.setState({
            username: val,
        });
    };
    goDetail = (row: any) => {
        window.location.href = `/#/volunteerDetail/1/${row.volunteerId}/${row.userId}`;
    };

    onImportExcel = (file: any) => {

        this.setState({
            importLoading: true,
        });
        // 获取上传的文件对象
        const {files} = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                // @ts-ignore
                const {result} = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, {type: 'binary'});
                let data: any = []; // 存储获取到的数据
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
                Fetch.postJSON(
                    `${API.volunteer.do_import_volunteer}`, data
                ).then((data) => {
                    window.open(API.domain + data) //这里是打开新窗口
                    setTimeout(() => {
                        message.success('处理完成');
                        this.setState({
                            importLoading: false,
                        });
                        window.location.reload()
                    }, 200);
                });
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                this.setState({
                    importLoading: false,
                });
                return;
            } finally {

            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }


    render() {
        const {page, pageSize, totalElements, content, volunteerType, btnQ} =
            this.state;
        const columns: ColumnsType<Volunteer> = [
            {
                title: '用户名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                align: `center`,
                render: (text, record) => {
                    return record.phone ? record.phone : record.wxPhone;
                },
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render: (text, record) => {
                    return text == 1 ? '男' : '女';
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
            },
            {
                title: '志愿者类型',
                dataIndex: 'volunteerType',
                align: `center`,
                render: (text, record) => {
                    return text == 1 ? '乐龄志愿者' : '敬老志愿者';
                },
            },
            // {
            //   title: '所在地',
            //   dataIndex: 'loginAddress',
            //   align: `center`,
            // },
            {
                title: '注册时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                render: (text) => {
                    // text === 2 ? '已注销' :
                    return '正常';
                },
            },
            {
                title: '操作',
                align: `center`,
                render: (text, row: any) => {
                    return (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    this.goDetail(row);
                                }}
                            >
                                详情
                            </Button>
                            <Button
                                type="link"
                                onClick={() => {
                                    this.removeFn(row);
                                }}
                            >
                                移除组织
                            </Button>
                        </>
                    );
                },
            },
        ];
        return (
            <>
                <Spin spinning={this.state.importLoading}>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>志愿者管理</Breadcrumb.Item>
                        <Breadcrumb.Item>志愿者列表</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space>
                            志愿者类型：
                            <Select
                                placeholder="请选择分类"
                                defaultValue={volunteerType}
                                onChange={this.typeChange}
                            >
                                <Option value="">全部志愿者</Option>
                                <Option value={1}>乐龄志愿者</Option>
                                <Option value={2}>敬老志愿者</Option>
                            </Select>
                            用户名:
                            <Input
                                placeholder="请输入用户名"
                                value={this.state.username}
                                onChange={(e) => this.changeInpt(e.target.value)}
                            />
                            <Button onClick={this.loadData} type="primary">
                                搜索
                            </Button>
                            <Button onClick={this.reset}>重置</Button>
                            {btnQ.ROLE_IMPORT_VOLUNTEERS ? (
                                <Button className={styles['upload-wrap']}>
                                    <input className={styles['file-uploader']} type='file' accept='.xlsx, .xls'
                                           onChange={this.onImportExcel}/>
                                    <span className={styles['upload-text']}>上传文件</span>
                                </Button>
                            ) : ('')}
                        </Space>
                        <Table
                            loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey={(record) => {
                                return record.id;
                            }}
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.changeCondition(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    },
                                    true
                                )
                            }
                        />
                    </div>
                    {/* <FormModal
          title={this.state.volunteer === null ? '创建志愿者' : '编辑志愿者'}
          reload={this.loadData}
          volunteer={this.state.volunteer}
          ref={(c) => (this.modal = c)}
        /> */}
                </Spin>
            </>
        );
    }
}

export default function VolunteerIndex() {
    return (
        <App selectedKeys={['volunteer']} openKeys="vol">
            <VolunteerList/>
        </App>
    );
}
