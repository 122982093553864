import {useEffect, useRef, useState} from 'react';
import {Table, Tabs} from 'antd';
import {useParams} from 'react-router-dom';
import './active.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';

const {TabPane} = Tabs;
export default function Donation() {
    const {userid} = useParams<{ userid: string }>();
    const [page, setPage] = useState<number>(0);
    const [datas, setData] = useState<any>({
        content: [],
        totalElements: 0,
        totalPages: 0,
        loading: true,
    });

    const [page2, setPage2] = useState<number>(0);
    const ref = useRef({page, page2});
    const [togetherData, setTogetherData] = useState<any>({
        content: [],
        totalElements: 0,
        totalPages: 0,
        loading: true,
    });
    useEffect(() => {
        getData();
    }, [page]);
    useEffect(() => {
        getTogetherData();
    }, [page2]);
    // 获取个人捐款
    const getData = () => {
        Fetch.get(
            `${API.volunteer.DonationUser}/${userid}?page=${page}&size=6`
        ).then((data) => {
            setData({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };
    // 获取一起捐
    const getTogetherData = () => {
        Fetch.get(
            `${API.volunteer.DonationTogether}/${userid}?page=${page2}&size=6`
        ).then((data) => {
            setTogetherData({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };
    const changeCondition2 = (condition: any, load = false) => {
        setPage2(condition.page);
    };
    const changeCondition = (condition: any, load = false) => {
        setPage(condition.page);
    };
    const onChange = (key: string) => {

    };

    const columns = [
        {
            title: '项目名称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '发起方',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '捐款金额(元)',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '捐款时间',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
    ];
    const columns2 = [
        {
            title: '项目名称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '发起方',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '劝募金额(元)',
            dataIndex: 'togetherSumAmount',
            key: 'togetherSumAmount',
        },
        {
            title: '劝捐人数',
            dataIndex: 'togetherSumPeople',
            key: 'togetherSumPeople',
        },
    ];
    return (
        <div className="active">
            <Tabs defaultActiveKey="1" onChange={onChange}>
                <TabPane tab="个人捐款" key="1">
                    <Table
                        dataSource={datas.content}
                        bordered
                        rowKey={(record, index) => {
                            return index + '';
                        }}
                        columns={columns}
                        pagination={{
                            current: page + 1,
                            pageSize: 6,
                            total: datas.totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            changeCondition(
                                {
                                    page: pagination.current - 1,
                                },
                                true
                            )
                        }
                    />
                </TabPane>
                <TabPane tab="劝捐" key="2">
                    <Table
                        columns={columns2}
                        dataSource={togetherData.content}
                        bordered
                        rowKey={(record) => {
                            return record.id;
                        }}
                        pagination={{
                            current: page2 + 1,
                            pageSize: 20,
                            total: togetherData.totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            changeCondition2(
                                {
                                    page: pagination.current - 1,
                                },
                                true
                            )
                        }
                    />
                </TabPane>
            </Tabs>
        </div>
    );
}
