import React from 'react';
import {Table} from 'antd';
import styles from "../hospitalauditv2/ServiceUserV2.module.css";
import {ColumnsType} from 'antd/es/table';
import {QuotaDetail} from "../util/ServiceUserDetailTypes";
import {EyeDiseasesCompleteObj, EyeDiseasesObj} from "../util/EyeDiseasesUtil";

type Record = {
    quota: QuotaDetail[]
}
type User = {};
const AierServiceUserIllnessLog: React.FC<Record> = (props) => {

    const columns: ColumnsType<User> = [
        {
            title: '眼病',
            dataIndex: 'quotaCode',
            align: `center`,
            render(value: any) {
                return <span>{EyeDiseasesObj[value]}</span>;
            },
        },
        {
            title: '是否完成',
            dataIndex: 'complete',
            align: `center`,
            render(value: any) {
                return <span style={{color: value === 1 ? "#1677ff" : "black"}}>{EyeDiseasesCompleteObj[value]}</span>;
            },
        }
    ];
    return (
        <>
            <Table
                className={styles.popover}
                rowKey="name"
                dataSource={props.quota}
                columns={columns}
                bordered
                loading={false}
                pagination={{position: [], pageSize: 100}}
                scroll={{y: 430}}
            />
        </>
    );
};

export default AierServiceUserIllnessLog;
