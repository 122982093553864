import React from "react";

export default function ReasonDetail(props: any) {
    const reason = props.reason;

    if (!reason) {
        return null;
    }
    return (
        <p>
            {
                reason.text
            }
        </p>
    )
}
