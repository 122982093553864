import React, {Component} from 'react';
import {AutoComplete, Button, Card, Form, FormInstance, Input, message, Select} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import Api from '../common/api';
import styles from './institution.module.css';
import ZoneSelect from '../institution/ZoneSelect';

const FormItem = Form.Item;

class RegisteredForm extends Component {
    formRef = React.createRef<FormInstance>();
    cityRef = React.createRef();
    state = {
        provinceId: '',
        cityId: '',
        treeNodes: null,
        industries: [],
        institutionCategory: '',
        options: [],
        optionsData: [],
        locationChange: false,
        region: '', //城市名称
    };

    componentDidMount() {
        this.loadIndustries();
    }

    loadIndustries = () => {
        Fetch.get(`${API.dict.list}?type=industry`).then((industries) => {
            this.setState({
                industries,
            });
        });
    };
    setCitystr = (val: any) => {
        this.setState({
            region: val,
        });
    };
    // 搜索地址
    onSearch = (searchText: any) => {
        if (!this.state.region && this.state.region === '') {
            message.error('请先选择省、市');
            // @ts-ignore
            this.formRef.current.setFieldsValue({
                address: '',
            });
            return;
        }

        let text = this.state.region.trim();
        const rep = Api.zoneRep[text];
        text = rep ? rep : text;
        if (text && text.length > 0) {
            Fetch.get(
                `${API.location2}?keyword=${searchText}&region=${
                    text
                }`
            ).then((data) => {
                const result = JSON.parse(data);
                if (result.status === 0) {
                    const options = result.data.map((item: any) => {
                        return {
                            label: item.title + ' ' + item.address,
                            value: item.title + ' ' + item.address,
                            location: item.location,
                        };
                    });
                    this.setState({
                        locationChange: true,
                        optionsData: options,
                    });
                }
            });
        }
    };
    submit = (values: any) => {
        this.filter(values);
        let obj = this.getLat(values);
        if (obj) {
            let params: any = {
                ...values,
                ...obj,
            };
            Fetch.postJSON(`${API.institution.do_register}`, params).then(() => {
                message.success('提交成功');
                window.location.href = '/#/register/success';
            });
        } else {
            return;
        }
    };
    filter = (values: any) => {
        values.address = {
            provinceId: values.provinceId,
            cityId: values.cityId,
            detail: values.address,
        };
        values.legalPersonIdcardUrl = this.getUrl(values.legalPersonIdcardUrl)
        values.businessLicenseUrl = this.getUrl(values.businessLicenseUrl)
        values.bankAccountUrl = this.getUrl(values.bankAccountUrl)
        values.financeUsername = values.username;
        values.financePhone = values.phone;
        values.type = "1";
    };

    getUrl(files: any) {

        if (files) {
            if (files.length == 1) {
                return files[0].url
            } else if (files.length == 2) {
                const items = [];
                for (let i = 0; i < 2; i++) {
                    let url = files[i].url;
                    items.push(url);
                }
                return items.join(',');
            }
        } else {
            return "";
        }
    }

    getLat = (values: any) => {
        const {optionsData, locationChange} = this.state;
        let lat = 0,
            lng = 0;
        const address: any = optionsData.find((item: any) => {
            return item.value === values.address.detail;
        });
        if (address) {
            lat = address.location.lat;
            lng = address.location.lng;
        } else if (!values.id || locationChange) {
            message.error('您输入的地址不正确，请通过下拉列表选择输入');
            return false;
        }
        return {
            lng: lng,
            lat: lat,
        };
    };

    back = () => {
        window.location.href = '/';
    }

    render() {
        return (
            <>
                <Card title="注册"
                      styles={{header: {backgroundColor: 'white', color: 'black'}, body: {border: 'none'}}}
                      bordered={false}
                      className={styles.mycard}
                      extra={
                          <Button onClick={() => this.back()}>返 回</Button>
                      }
                >
                    <div className={styles.container}>
                        <Form
                            onFinish={this.submit}
                            ref={this.formRef}
                        >
                            <FormItem
                                className={styles.mylabel}
                                name="invitationCode"
                                label="邀请码"
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="请输入邀请码"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="name"
                                label="组织名称"
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="请输入组织名称"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="provinceId"
                                label={<span className={styles.star}>所属省份</span>}
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                            >
                                <ZoneSelect
                                    setCitystr={this.setCitystr}
                                    form={this.formRef}/>
                            </FormItem>
                            <FormItem
                                name="address"
                                label="组织地址"
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <AutoComplete
                                    options={this.state.optionsData}
                                    onSearch={this.onSearch}
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="type"
                                label="组织类型"
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                                hidden
                                initialValue={"1"}
                            >
                                <Select mode="multiple" placeholder="请选择">
                                    <Select.Option value="1">乐龄志愿服务组织</Select.Option>
                                    <Select.Option value="2">敬老志愿服务组织</Select.Option>
                                </Select>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="industryCode"
                                label="所属行业"
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Select placeholder="请选择">
                                    {this.state.industries.map((industry: any) => (
                                        <Select.Option key={industry.id} value={industry.code}>
                                            {industry.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="username"
                                label="负责人"
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                                rules={[
                                    {required: true, message: '必填'},
                                    () => ({
                                        validator(_, value) {
                                            let val = value.replace(/^\s+|\s+$/gm, '');
                                            if (val !== '') {
                                                return Promise.resolve();
                                            }
                                            if (value) {
                                                return Promise.reject(new Error('负责人姓名不能为空'));
                                            } else {
                                                return Promise.reject(new Error(' '));
                                            }
                                        },
                                    }),
                                ]}

                                // x.replace(/^\s+|\s+$/gm,'');
                            >
                                <Input placeholder="请输入负责人"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="phone"
                                label="负责人联系电话"
                                labelCol={{span: 7}}
                                wrapperCol={{span: 17}}
                                rules={[
                                    {required: true, message: '必填'},
                                    () => ({
                                        validator(_, value) {
                                            let reg = /^[1][0-9]{10}$/;
                                            if (reg.test(value)) {
                                                return Promise.resolve();
                                            }
                                            if (value) {
                                                return Promise.reject(new Error('请输入正确的手机号'));
                                            } else {
                                                return Promise.reject(new Error(' '));
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    type="number"
                                    placeholder="负责人联系电话"

                                />
                            </FormItem>
                            {/*<FormItem*/}
                            {/*    className={styles.mylabel}*/}
                            {/*    name="financeUsername"*/}
                            {/*    label="财务负责人"*/}
                            {/*    labelCol={{span: 7}}*/}
                            {/*    wrapperCol={{span: 17}}*/}
                            {/*    hidden*/}
                            {/*    rules={[{required: true, message: '必填'}]}*/}
                            {/*>*/}
                            {/*    <Input*/}
                            {/*        // type="number"*/}
                            {/*        placeholder="请输入财务负责人"*/}
                            {/*        */}
                            {/*    />*/}
                            {/*</FormItem>*/}
                            {/*<FormItem*/}
                            {/*    className={styles.mylabel}*/}
                            {/*    name="financePhone"*/}
                            {/*    label="财务负责人联系电话"*/}
                            {/*    labelCol={{span: 7}}*/}
                            {/*    wrapperCol={{span: 17}}*/}
                            {/*    hidden*/}
                            {/*    rules={[{required: true, message: '必填'}]}*/}
                            {/*>*/}
                            {/*    <Input*/}
                            {/*        placeholder="请输入财务负责人联系电话"*/}
                            {/*        */}
                            {/*    />*/}
                            {/*</FormItem>*/}

                            {/*<FormItem*/}
                            {/*    className={styles.mylabel}*/}
                            {/*    name="legalPersonIdcardUrl"*/}
                            {/*    valuePropName="fileList"*/}
                            {/*    label="法人身份证复印件"*/}
                            {/*    labelCol={{span: 7}}*/}
                            {/*    wrapperCol={{span: 17}}*/}
                            {/*    rules={[{required: true, message: '必填'}]}*/}
                            {/*    hidden*/}
                            {/*>*/}
                            {/*    <FileUploader*/}
                            {/*        multiple*/}
                            {/*        maxCount={2}*/}
                            {/*        listType="picture-card"*/}
                            {/*        checkSize={false}*/}
                            {/*        desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 最多正反2张"*/}
                            {/*    />*/}
                            {/*</FormItem>*/}
                            {/*<FormItem*/}
                            {/*    className={styles.mylabel}*/}
                            {/*    name="businessLicenseUrl"*/}
                            {/*    valuePropName="fileList"*/}
                            {/*    label="营业执照扫描件盖公章"*/}
                            {/*    labelCol={{span: 7}}*/}
                            {/*    wrapperCol={{span: 17}}*/}
                            {/*    hidden*/}
                            {/*    // rules={[{ required: true, message: '必填' }]}*/}
                            {/*>*/}
                            {/*    <FileUploader*/}
                            {/*        listType="picture-card"*/}
                            {/*        checkSize={false}*/}
                            {/*        maxCount={1}*/}
                            {/*        desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; "*/}
                            {/*    />*/}
                            {/*</FormItem>*/}
                            {/*<FormItem*/}
                            {/*    className={styles.mylabel}*/}
                            {/*    name="bankAccountUrl"*/}
                            {/*    valuePropName="fileList"*/}
                            {/*    label="银行开户许可证扫描件（加盖公章）"*/}
                            {/*    labelCol={{span: 7}}*/}
                            {/*    wrapperCol={{span: 17}}*/}
                            {/*    hidden*/}
                            {/*    // rules={[{ required: true, message: '必填' }]}*/}
                            {/*>*/}
                            {/*    <FileUploader*/}
                            {/*        listType="picture-card"*/}
                            {/*        maxCount={1}*/}
                            {/*        checkSize={false}*/}
                            {/*        desc="支持扩展名：.jpg、.png; 图片大小不能超过2M;"*/}
                            {/*    />*/}
                            {/*</FormItem>*/}
                            {/*<FormItem*/}
                            {/*    className={styles.mylabel}*/}
                            {/*    name="description"*/}
                            {/*    label="组织简介"*/}
                            {/*    labelCol={{span: 7}}*/}
                            {/*    wrapperCol={{span: 17}}*/}
                            {/*    hidden*/}
                            {/*    rules={[{max: 2000, message: '最多2000个字符', type: 'string'}]}*/}
                            {/*>*/}
                            {/*    <Input.TextArea rows={5}/>*/}
                            {/*</FormItem>*/}
                            <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                                <Button type="primary" htmlType="submit" className={styles.mybtn}>
                                    提交
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </Card>
            </>
        );
    }
}

export default RegisteredForm;
