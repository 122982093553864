import React, {RefObject} from 'react';
import EditAccount from "../../editPatient/EditAccount";
import {FormInstance, Space} from "antd";
import PatientInfo from "../../patientDetail/PatientInfo";
import DhWarVeteranInfo from "../../patientDetail/DhWarVeteranInfo";
import {DhDetailDataType} from "../DhDetailType";
import {useLocation} from "react-router-dom";
import PATH from "../../../common/pagePath";
import EditBasicInfo from "../../editPatient/EditBasicInfo";
import EditWarVeteran from "../../editPatient/EditWarVeteran";
import {DictObjectType} from "../DhDictData";
import {HospitalEditConfig} from "./HospitalEditConfig";


export type DetailAndEdit = EditBaseType & {

    data: DhDetailDataType['data'],
    setAddressSelect?: any,
    taskId?: number | 0
}

export type EditBaseType = DictObjectType & {
    form: RefObject<FormInstance>;
}


// type EditType = EditBaseType & {
//
//     fileEdit: boolean,
//     initDate: DhDetailDataType["data"]
// }

type DetailType = {
    data: DhDetailDataType['data']
}

type EditAccountType = EditBaseType & {

    fileEdit: boolean,
    initDate: DhDetailDataType["data"]
    taskId?: number | undefined
}

const ComEditAccount: React.FC<EditAccountType> = ({
                                                       form,
                                                       dictInfo,
                                                       fileEdit,
                                                       initDate,
                                                       taskId
                                                   }) => {

    return <EditAccount key={"EditAccount"} form={form} dictInfo={dictInfo}
                        fileEdit={fileEdit} initDate={initDate} taskDetailId={taskId} required={false}/>
}

type EditEditBasicType = EditBaseType & {
    taskId?: number | 0,
    setAddressSelect?: any,
    isEdit: boolean,
    data: { cityId: number, provinceId: number, lng: number | null, lat: number | null },
    areaStr: string | ''
}
const ComEditBasicInfo: React.FC<EditEditBasicType> = ({
                                                           form,
                                                           taskId,
                                                           setAddressSelect,
                                                           isEdit,
                                                           data,
                                                           areaStr,
                                                           dictInfo
                                                       }) => {

    return <EditBasicInfo key={"EditBasicInfo"} form={form}
                          taskId={taskId}
                          setAddressSelect={setAddressSelect}
                          isEdit={isEdit}
                          data={data}
                          areaStr={areaStr}
                          dictInfo={dictInfo}
    />
}

type EditWarVeteranType = EditBaseType & {
    warVeteran?: DhDetailDataType["data"]['warVeteran']
}
const ComEditWarVeteran: React.FC<EditWarVeteranType> = ({
                                                             form,
                                                             dictInfo,
                                                             warVeteran
                                                         }) => {

    return <EditWarVeteran key={"EditWarVeteran"} form={form} dictInfo={dictInfo} warVeteran={warVeteran}/>
}

// const ComEditFreeClinicsReport: React.FC<EditBaseType> = ({
//                                                               form,
//                                                               dictInfo
//                                                           }) => {
//
//     return <EditFreeClinicsReport form={form} dictInfo={dictInfo}/>
// }

// const ComEditFreeClinicsReport: React.FC<EditBaseType> = ({
//                                                               form,
//                                                               dictInfo
//                                                           }) => {
//
//     return <EditServiceUserFee form={form} userDetail={} dictInfo={dictInfo}/>
// }


//----
const ComPatientInfo: React.FC<DetailType> = ({data}) => {

    return <PatientInfo key={"PatientInfo"} userDetail={data?.userDetail} addressDetail={data?.addressDetail}
                        warVeteran={data.warVeteran}/>
}

const ComWarVeteran: React.FC<DetailType> = ({data}) => {

    return <DhWarVeteranInfo key={"DhWarVeteranInfo"} warVeteran={data?.warVeteran}/>
}

// const ComDiagnosisReport: React.FC<DetailType> = ({data}) => {
//
//     return <DiagnosisReportInfo key={"DiagnosisReportInfo"} report={data?.report}/>
// }

// const ComDhAccountInfo: React.FC<DetailType> = ({data}) => {
//
//     return <DhAccountInfo key={"DhAccountInfo"} account={data?.account}/>
// }

export const ComDhDetailOrEdit: React.FC<DetailAndEdit> = ({
                                                               form,
                                                               dictInfo,
                                                               data,
                                                               setAddressSelect,
                                                               taskId
                                                           }) => {
    const location = useLocation();
    const router = location.pathname;
    if (PATH.dhHospitalPatient.edit === router) {
        return <HospitalEditConfig key={"HospitalEdit"} form={form} dictInfo={dictInfo}
                                   data={data}/>;
    } else if (PATH.dhPatientApplication.edit === router) {
        return <AdminEdit key={"AdminEdit"} form={form} dictInfo={dictInfo}
                          data={data} setAddressSelect={setAddressSelect}
        />;
    } else if (PATH.dhPatientApplication.add === router) {
        return <AdminAdd key={"AdminEdit"} form={form} dictInfo={dictInfo}
                         data={data} setAddressSelect={setAddressSelect}
                         taskId={taskId}
        />;
    } else {
        return null;
    }
};

/**
 * 医院修改
 *
 *     101: '待分配',
 *     102: '待审核',
 *     103: '审核退回',
 *     104: '待签到',
 *     105: '已签到',
 *     106: '不需要进行手术',
 *     107: '义诊已完成',
 * @param form
 * @param dictInfo
 * @param data
 * @see dhStatusObj
 * @constructor
 */


/**
 * 管理台修改
 *
 *     101: '待分配',
 *     102: '待审核',
 *     103: '审核退回',
 *     104: '待签到',
 *     105: '已签到',
 *     106: '不需要进行手术',
 *     107: '义诊已完成',
 * @param form
 * @param dictInfo
 * @param data
 * @param setAddressSelect
 * @see dhStatusObj
 * @constructor
 */
const AdminEdit: React.FC<DetailAndEdit> = ({
                                                form,
                                                dictInfo,
                                                data,
                                                setAddressSelect
                                            }) => {

    if (!data) {
        return <div></div>
    }
    const componentsToRender = [];
    const dhStatus = data.userDetail?.dhStatus;
    let cityData: EditEditBasicType['data'] = {cityId: 0, provinceId: 0, lng: null, lat: null}
    let areaStr = '';
    if (data.addressDetail && data.userDetail) {
        cityData = {
            cityId: data.addressDetail.cityId,
            provinceId: data.addressDetail.provinceId,
            lng: data.addressDetail.lng,
            lat: data.addressDetail.lat
        }
        areaStr = data.addressDetail.areaStr;
    }

    // 101, 102, 103, 104, 105 107
    if (dhStatus === 102 || dhStatus === 101 || dhStatus == 103) {

        componentsToRender.push(<ComEditBasicInfo key={"ComEditBasicInfo"}
                                                  form={form}
                                                  taskId={0}
                                                  setAddressSelect={setAddressSelect}
                                                  isEdit={true}
                                                  data={cityData}
                                                  areaStr={areaStr}
                                                  dictInfo={dictInfo}/>)
        componentsToRender.push(<ComEditWarVeteran key={"ComEditWarVeteran"}
                                                   form={form}
                                                   dictInfo={dictInfo}
                                                   warVeteran={data.warVeteran}/>)
        componentsToRender.push(<ComEditAccount key={"ComEditAccount"}
                                                form={form}
                                                dictInfo={dictInfo}
                                                initDate={data}
                                                fileEdit={false}

        />)
    } else if (dhStatus === 104 || dhStatus === 105) {
        componentsToRender.push(<ComPatientInfo key={"ComPatientInfo"}
                                                data={data}/>)
        componentsToRender.push(<ComWarVeteran key={"ComWarVeteran"}
                                               data={data}/>)
        componentsToRender.push(<ComEditAccount key={"ComEditAccount"}
                                                form={form}
                                                dictInfo={dictInfo}
                                                initDate={data}
                                                fileEdit={false}/>)
    }
    return <Space direction="vertical" size="middle" style={{display: 'flex'}}>{componentsToRender}</Space>;
}


const AdminAdd: React.FC<DetailAndEdit> = ({
                                               form,
                                               dictInfo,
                                               data,
                                               setAddressSelect,
                                               taskId
                                           }) => {


    const componentsToRender = [];
    const dhStatus = data.userDetail?.dhStatus;
    let areaStr = data.addressDetail?.areaStr;
    // 101, 102, 103, 104, 105
    if (dhStatus === undefined || dhStatus === 0 || dhStatus === 101) {
        areaStr = areaStr === undefined ? '' : areaStr;
        componentsToRender.push(<ComEditBasicInfo key={"ComEditBasicInfo"}
                                                  form={form}
                                                  taskId={taskId}
                                                  setAddressSelect={setAddressSelect}
                                                  isEdit={false}
                                                  data={{cityId: 0, provinceId: 0, lng: 0, lat: 0}}
                                                  areaStr={areaStr}
                                                  dictInfo={dictInfo}/>)
        componentsToRender.push(<ComEditWarVeteran key={"ComEditWarVeteran"}
                                                   form={form}
                                                   dictInfo={dictInfo}/>)
        componentsToRender.push(<ComEditAccount key={"ComEditAccount"}
                                                form={form}
                                                dictInfo={dictInfo}
                                                initDate={data}
                                                fileEdit={false}
                                                taskId={taskId}/>)
    }
    return <Space direction="vertical" size="middle" style={{display: 'flex'}}>{componentsToRender}</Space>;
}