import {Descriptions} from 'antd';
import {useState} from 'react';

export default function Educational(props: any) {
    const {datas, dictData} = props;
    const [lineStyle, setlineStyle] = useState<{}>({
        width: '200px',
        color: '#8C8C8C',
        textAlign: 'right',
    });

    return (
        <div>
            <Descriptions bordered column={{xs: 1, md: 1, sm: 1}}>
                <Descriptions.Item label="最高学位" labelStyle={lineStyle}>
                    {dictData[`academicDegree${datas.academicDegreeCode}`]
                        ? dictData[`academicDegree${datas.academicDegreeCode}`]
                        : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="学位授予单位" labelStyle={lineStyle}>
                    {datas.academicDegreeGrantOrg ? datas.academicDegreeGrantOrg : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="最高学历" labelStyle={lineStyle}>
                    {dictData[`education${datas.educationCode}`]
                        ? dictData[`education${datas.educationCode}`]
                        : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="学历授予单位" labelStyle={lineStyle}>
                    {datas.educationGrantOrg ? datas.educationGrantOrg : '--'}
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
}
