import {Button, Card, DatePicker, Descriptions, Form, FormInstance, message, Space} from "antd";
import FormItem from 'antd/lib/form/FormItem';
import React, {useEffect, useState} from "react";
import API from "../../../common/api";
import Fetch from "../../../common/FetchIt";
import BillInfo from "../hospitalbilldetail/BillInfo";
import BillAccountInfo from "../hospitalbilldetail/BillAccountInfo";
import {HospitalBillDetail} from "../hospitalbilldetail/types/HospitalBillType";
import {billDetailDefault} from "../hospitalbilldetail/types/HospitalDetailCommon";
import styles from "../../add/ServiceUser.module.css";
import FileUploader from "../../../common/uploader";
import {UploadOutlined} from '@ant-design/icons';
import type {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';

type ReviewType = {
    hide: any,
    reload: any,
    id: number,
}

export default function ReviewModal(props: ReviewType) {

    const formRef = React.createRef<FormInstance>();
    const [billData, setBillData] = useState<HospitalBillDetail['data']>(billDetailDefault.data);
    const ok = () => {
        props.hide();
        props.reload();
    }

    useEffect(
        function () {
            let url = `${API.service_user_bill.get_bill_detail}?billId=${props.id}`;
            Fetch.get(url).then((data) => {
                setBillData(data)
            });
        },
        [props.id]
    );
    const submit = (values: any) => {
        buildFile(values)
        values.releaseTime = values.releaseTime.format('YYYY-MM-DD');
        values.billId = props.id;
        Fetch.postJSON(API.service_user_bill.update_reimbursement_complete, values).then(() => {
            message.success('提交成功');
            ok();
        });
    }

    const buildFile = (values: any) => {

        const files: any[] = [];
        addFiles(files, values.voucher, 2);
        values.fileList = files;
        delete values.voucher;
    }

    const addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current < dayjs().startOf('month') || current >= dayjs().endOf('month'))
    };
    return <>
        <div>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <BillInfo data={billData}/>
                <BillAccountInfo data={billData}/>

                <Form
                    onFinish={submit}
                    ref={formRef}
                >
                    <Card size="small" className={styles.mycard}
                          title={`上传凭证`}>
                        <Descriptions>
                            <Descriptions.Item>
                                <FormItem
                                    className={styles.mylabel}
                                    name="releaseTime"
                                    label="补贴款发放时间"
                                    // labelCol={{span: }}
                                    wrapperCol={{span: 17}}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        style={{width: '100%'}}/>
                                </FormItem>
                            </Descriptions.Item>
                            <Descriptions.Item>
                                <FormItem
                                    className={styles.mylabel}
                                    name="voucher"
                                    valuePropName="fileList"
                                    label="上传凭证"
                                    // labelCol={{span: 0}}
                                    wrapperCol={{span: 17}}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <FileUploader
                                        multiple
                                        listType="picture"
                                        className="upload-list-inline"
                                        accept='*'
                                        checkSize={false}
                                        noChekckSizeMal={true}
                                        icon={<UploadOutlined/>}
                                    />
                                </FormItem>
                            </Descriptions.Item>
                        </Descriptions>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                保存
                            </Button>
                            <Button
                                style={{marginRight: '15px'}}
                                onClick={ok}
                            >
                                取 消
                            </Button>
                        </Space>
                    </Card>
                </Form>
            </Space>
        </div>
    </>;
}
