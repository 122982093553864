import React, {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Card, Descriptions, Image, message, Modal, Space} from 'antd';

import styles from '../institution.module.css';
import {useParams} from 'react-router-dom';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import App from '../../../App';
import createModal from '../../common/createModal';
import InstitutionPoster from '../InstitutionPoster';
import html2canvas from 'html2canvas';

const {confirm} = Modal;
const CertModal = createModal(InstitutionPoster);
export const renderType = function (text, concise = false) {
    if (text === '1') {
        return '乐龄志愿服务组织';
    } else if (text === '2') {
        return '敬老志愿服务组织';
    } else {
        return '乐龄志愿服务组织/敬老志愿服务组织';
    }
};


function InstitutionAuditDetail() {
    const [institution, setInstitution] = useState();
    const params = useParams();

    const loadData = () => {
        Fetch.get(`${API.institution.zsgc}/${params.id}`).then((data) => {
            setInstitution(data);
        });
    };
    useEffect(
        function () {
            loadData();
        },
        [params.id]
    );
    let modal = null;
    let certModal = null;
    let posterRef = null;

    if (!institution) {
        return null;
    }
    const renderIdImg = (url) => {
        if (!url) {
            return ' - ';
        }
        const imgs = url.split(',');
        return (
            <div>
                {imgs.map((img, index) => {
                    return (
                        <Image
                            width={100}
                            src={API.domain + img}
                        />
                    );
                })}
            </div>
        );
    };

    const onOk = () => {
        html2canvas(posterRef, {
            backgroundColor: null,
        }).then(function (canvas) {
            const link = document.createElement('a');
            link.download = 'certification.png';
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    const updateStatusRefuse = () => {

        let id = params.id;
        confirm({
            title: `是否确认审核退回？`,
            onOk() {
                Fetch.postJSON(API.institution.update_status_refuse, {institutionId: id}).then(() => {
                    message.success('审核成功');
                    window.location.href = '/#/institution/reviewed_list';
                });
            },
        });
    };

    const updateStatusApproved = () => {

        let id = params.id;
        confirm({
            title: `是否确认审核通过？`,
            onOk() {
                Fetch.postJSON(API.institution.update_status_approved, {institutionId: id}).then(() => {
                    message.success('审核成功');
                    window.location.href = '/#/institution/reviewed_list';
                });
            },
        });
    };

    const goBack = () => {
        window.location.href = '/#/institution/reviewed_list';
    }

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>
                    <a href="/#/institution">组织审核</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>审核详情</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: '100%'}}
            >
                <Card
                    title={`组织名称: ${institution.name}`}
                >
                    <Descriptions column={4}>
                        <Descriptions.Item label="组织编码">
                            {institution.code}
                        </Descriptions.Item>
                        <Descriptions.Item label="组织海报">
                            {' '}
                            <a href="javascript:void(0)" onClick={() => certModal.show()}>
                                查看
                            </a>{' '}
                        </Descriptions.Item>
                        <Descriptions.Item label="状态">
                            {institution.isActive === 1 ? '已激活' : '未激活'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card title="组织信息" className={styles.mycard}>
                    <Descriptions>
                        <Descriptions.Item label="组织名称">
                            {institution.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属省市">
                            {institution.province}
                            {institution.city}
                        </Descriptions.Item>
                        <Descriptions.Item label="地址">
                            {institution.address.detail}
                        </Descriptions.Item>
                        <Descriptions.Item label="组织类型">
                            {renderType(institution.type)}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属行业">
                            {institution.industryName}
                        </Descriptions.Item>
                        <Descriptions.Item label="负责人">
                            {institution.username}
                        </Descriptions.Item>
                        <Descriptions.Item label="负责人联系电话">
                            {institution.phone}
                        </Descriptions.Item>
                        <Descriptions.Item label="财务负责人">
                            {institution.financeUsername}
                        </Descriptions.Item>
                        <Descriptions.Item label="财务负责人电话">
                            {institution.financePhone}
                        </Descriptions.Item>
                        <Descriptions.Item label="发起活动数">
                            {institution.gongyiActivityNum}
                        </Descriptions.Item>
                        <Descriptions.Item span="3" label="发起募捐数">
                            {institution.donationActivityNum}
                        </Descriptions.Item>
                        <Descriptions.Item label="法人身份证复印件">
                            {renderIdImg(institution.legalPersonIdcardUrl)}
                        </Descriptions.Item>
                        <Descriptions.Item span="2" label="营业执照扫描件盖公章">
                            {renderIdImg(institution.businessLicenseUrl)}
                        </Descriptions.Item>
                        <Descriptions.Item label="银行开户许可证扫描件（加盖公章）">
                            {renderIdImg(institution.bankAccountUrl)}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Button
                    // type="primary"
                    htmlType="button"
                    size="large"
                    className={styles.backBtn}
                    onClick={goBack}
                >
                    返回
                </Button>

                <Space className={styles.auditButton}>
                    <Button
                        type="primary"
                        htmlType="button"
                        size="large"
                        onClick={updateStatusApproved}
                    >
                        通过
                    </Button>
                    <Button
                        type="primary"
                        htmlType="button"
                        danger
                        size="large"
                        onClick={updateStatusRefuse}
                    >
                        拒绝
                    </Button>
                </Space>
                <CertModal
                    title={'组织海报'}
                    width={808}
                    institution={institution}
                    showFooter
                    onOk={onOk}
                    okText="下载海报"
                    setPosterRef={(element) => (posterRef = element)}
                    ref={(c) => (certModal = c)}
                />
            </div>
        </>
    );
}

export default function InstitutionDetailIndex() {
    return (
        <App selectedKeys={['InstitutionAuditList']} openKeys="InstitutionMenu">
            <InstitutionAuditDetail/>
        </App>
    );
}
