import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import {Breadcrumb, Button, DatePicker, Input, message, Radio, Select, Space, Table} from "antd";
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import createModal from '../common/createModal';
import RefuseForm from './RefuseForm';
import {Apply} from './types';
import {Link} from "react-router-dom";
import App from "../../App";
import ResetPasswordForm from "../user/ResetPasswordForm";

const {RangePicker} = DatePicker;

type ApplyState = {
    page: number,
    pageSize: number,
    totalElements: number,
    totalPages: number,
    content: Apply[],
    name: string,
    type: string,
    status: string,
    date: [],
    tab: string,
    apply: Apply | null,
};

const RefuseFormModal = createModal(RefuseForm);
const ResetFormModal = createModal(ResetPasswordForm);

class ApplyList extends Component<{}, ApplyState> {
    state: any = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        name: '',
        status: '1,2',
        tab: '1,2',
        date: [],
        apply: null,
    };
    private modal: any = null;
    private resetModal: any = null;

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState({
            ...condition,
        }, () => {
            if (load) {
                this.loadData();
            }
        });
    };

    loadData = () => {
        const {page, pageSize, status, name} = this.state;
        let startDate = '';
        let endDate = '';
        if (this.state.date != null && this.state.date.length == 2) {
            startDate = this.state.date[0].format('YYYY-MM-DD');
            endDate = this.state.date[1].format('YYYY-MM-DD');
        }
        Fetch.get(`${API.apply.list}?page=${page}&size=${pageSize}&status=${status}&keyword=${name}&startDate=${startDate}&endDate=${endDate}`).then(data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            })
        });
    };

    create = () => {
        this.setState({
            apply: null,
        }, this.modal.show);
    };

    showReason = (record: Apply) => {
        this.setState({
            apply: {
                ...record,
                readonly: true
            },
        }, this.modal.show);
    };

    resetPassword = (record: Apply) => {
        this.setState({
            apply: {
                ...record,
                readonly: true
            },
        }, this.resetModal.show);
    };

    del = (id: number) => {
        Fetch.del(`${API.apply.zsgc}/${id}`).then(() => {
            this.loadData();
        });
    };

    reset = () => {
        this.setState({
            name: '',
            status: '1,2',
            tab: '1,2',
            date: [],
            page: 0,
        }, this.loadData)
    }

    handleStatusChange = (event: any) => {
        const e = event.target.value;
        this.setState({
            tab: e,
            status: e
        }, this.loadData)
    }

    changeStatus = (id: any, status: any) => {
        Fetch.put(`${API.apply.status}`, {id, status}).then(() => {
            message.success('操作成功！');
            this.loadData();
        });
    }

    refuse = (record: any) => {
        this.setState({
            apply: {
                ...record,
                readonly: false
            }
        }, this.modal.show);
    }

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    }

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<Apply> = [
            {
                title: "组织名称",
                dataIndex: ['organization', 'name'],
                align: `center`,
            },
            {
                title: "上级组织",
                dataIndex: "institution",
                align: `center`,
            },
            {
                title: "所在区域",
                dataIndex: ['organization', "address", "detail"],
                align: `center`,
            },
            {
                title: "负责人",
                dataIndex: ['organization', "username"],
                align: `center`,
            },
            {
                title: "状态",
                dataIndex: "status",
                width: 75,
                render: (text, record) => {
                    switch (text) {
                        case 'UNCHECKED':
                            return '待审核';
                        case 'ENABLE':
                            return '启用';
                        case 'DISABLE':
                            return '禁用';
                        case 'REJECTED':
                            return '已驳回';
                        default:
                            return '';
                    }
                },
                align: `center`,
            },
            {
                title: "申请人",
                dataIndex: "username",
                align: `center`,
            },
            {
                title: this.state.tab == 0 ? '申请时间' : (this.state.tab == 3 ? '驳回时间' : '审核时间'),
                dataIndex: "validateDate",
                align: `center`,
                render: (text, record: any) => {
                    if (this.state.tab == 0) {
                        return record.createdDate;
                    } else {
                        return record.validateDate;
                    }
                },
            },
            {
                title: "操作",
                align: `center`,
                width: 210,
                render: (text, record: any) => {
                    const ops = [];
                    ops.push(<Link to={`/yueling/${record.id}`}>查看</Link>);
                    if (this.state.tab === '1,2') {
                        if (record.status === 'ENABLE') {
                            ops.push(<Button onClick={() => this.changeStatus(record.id, 'DISABLE')}>禁用</Button>);
                        } else if (record.status === 'DISABLE') {
                            ops.push(<Button onClick={() => this.changeStatus(record.id, 'ENABLE')}>启用</Button>);
                        }
                        ops.push(<Button onClick={() => this.resetPassword(record)}>重置</Button>);
                    } else if (this.state.tab === '0') {
                        ops.push(<Button onClick={() => this.changeStatus(record.id, 'ENABLE')}>通过</Button>);
                        ops.push(<Button onClick={() => this.refuse(record)}>驳回</Button>);
                    } else {
                        ops.push(<Button onClick={() => this.showReason(record)}>驳回原因</Button>);
                    }
                    return (
                        <Space>
                            {
                                ops
                            }
                        </Space>
                    )
                }
            }];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>乐龄志愿服务组织管理</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 720}}>
                    <Space size="small" onKeyDown={this.handleKeyDown}>
                        组织名称：
                        <Input
                            placeholder="请输入组织名称"
                            value={this.state.name}
                            onChange={e => this.changeCondition({page: 0, name: e.target.value})}
                        />
                        状态：
                        <Select
                            placeholder="状态"
                            value={(this.state.status == 0 || this.state.status == 3) ? '1,2' : this.state.status}
                            onChange={e => this.changeCondition({page: 0, status: e})}
                        >
                            <Select.Option value="1,2">全部</Select.Option>
                            <Select.Option value="1">启用</Select.Option>
                            <Select.Option value="2">禁用</Select.Option>
                        </Select>
                        审核时间:
                        <RangePicker
                            locale={locale}
                            value={this.state.date}
                            placeholder={['审核开始时间', '审核结束时间']}
                            onChange={e => this.changeCondition({date: e, page: 0})}
                        />
                        <Button onClick={this.loadData}>搜索</Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>

                    <div className="mgTop15">
                        <Radio.Group onChange={this.handleStatusChange} value={this.state.tab}>
                            <Radio.Button value="1,2">已通过</Radio.Button>
                            <Radio.Button value="0">待审核</Radio.Button>
                            <Radio.Button value="3">已驳回</Radio.Button>
                        </Radio.Group>
                    </div>
                    <Table
                        bordered
                        rowKey={record => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) => this.changeCondition({
                            page: pagination.current - 1,
                            pageSize: pagination.pageSize,
                        }, true)}
                    />
                </div>
                <RefuseFormModal
                    title={'驳回原因'}
                    reload={this.loadData}
                    apply={this.state.apply}
                    ref={c => this.modal = c}
                />
                <ResetFormModal
                    title={'重置密码'}
                    width={600}
                    id={this.state.apply ? this.state.apply.userId : ''}
                    ref={c => this.resetModal = c}
                />
            </>
        );
    }
}

export default function YuelingIndex() {
    return (
        <App selectedKeys={['yueling']}>
            <ApplyList/>
        </App>
    )
}
