import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Checkbox, Col, FormInstance, message, Row, Space} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import FormItem from 'antd/lib/form/FormItem';

interface ModalType {
    unitDatas: string[],
    hide: any
}

type CheckboxType = { value: string, label: string }


const MergeUnitGroupClass: React.FC<ModalType> = (props) => {

    const [units, setUnits] = useState<CheckboxType[]>([{value: '', label: ''}]);
    const [selectData, setSelectData] = useState<String[]>([]);
    const formRef = useRef<FormInstance>(null);


    useEffect(
        function () {
            let units: CheckboxType[] = [];
            props.unitDatas.forEach((item, index) => {
                units.push({value: item, label: item})
            })
            setUnits(units);
        },
        []
    );

    const submit = (values: any) => {

        create(values);
    };

    const create = (values: any) => {
        Fetch.postJSON(API.aier_hospital_role.update_role, values).then(() => {
            message.success('提交成功');
            cancel();
        });
    }

    const cancel = () => {

        props.hide();
    }

    const changeCheckbox = ()=>{

    }



    return (
        <>
            <div
                className="site-layout-background"
                style={{minHeight: 720}}
            >
                <div>
                    <Card title="数据权限" size="small">
                        <Space direction="vertical">
                            <FormItem
                                name="viewIds"
                                label="单位"
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Row gutter={[16, 16]}>
                                    {units.map(item =>
                                        <Col span={4}>
                                            <Checkbox value={item.value}>{item.label}</Checkbox>
                                        </Col>
                                    )}
                                </Row>
                            </FormItem>
                            <Space>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                >
                                    保存
                                </Button>
                                <Button
                                    size="large"
                                    style={{marginRight: '15px'}}
                                    onClick={cancel}
                                >
                                    取 消
                                </Button>
                            </Space>
                        </Space>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default MergeUnitGroupClass;
