export type TroupeArtistDetailType = {
    id: Number | null,
    importLoading: boolean,
    data: {
        name: string | null,
        age: number | null,
        gender: string | number | 0
        birthday: string | null,
        idNumber: string | null,
        phone: string | null,
        address: string | null,
        signUrl: {
            url: string
        } | null,
        origin: string | null,
        ethnic: string | null,
        politics: string | null,
        specialtyStr: string | null,
        groupName: string | null,
        groupDuties: string | null,
        originalUnit: string | null,
        originalDuties: string | null,
        signDate: string | null,
        certificateDate: string | null,
        rongTitle: string | null,
        identity: string | null,
        files: any[],
        certificate: { url: string } | null
    }
}

export function getInitDetail() {
    return {
        id: null,
        importLoading: true,
        data: {
            name: null,
            age: null,
            gender: 0,
            birthday: null,
            idNumber: null,
            phone: null,
            address: null,
            signUrl: null,
            origin: null,
            ethnic: null,
            politics: null,
            specialtyStr: null,
            groupName: null,
            groupDuties: null,
            originalUnit: null,
            originalDuties: null,
            signDate: null,
            certificateDate: null,
            rongTitle: null,
            identity: null,
            files: [],
            certificate: null
        }
    }
}

export type BoneRoleType = {

    //添加艺术团
    ROLE_219001?: number | undefined,
    //修改艺术团
    ROLE_219002?: number | undefined,
    //创建证书
    ROLE_217001?: number | undefined,
    //查看详情()
    ROLE_217002?: number | undefined,
    //查看详情()
    ROLE_218003?: number | undefined,
    //查看详情()
    ROLE_220001?: number | undefined,

    ROLE_220002?: number | undefined,

    ROLE_219003?: number | undefined
}