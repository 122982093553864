import React, {Component} from 'react';
import {Breadcrumb, Button, Checkbox, Form, FormInstance, Input, message,} from 'antd';
import {Link} from 'react-router-dom';
import './limitsEdit.css';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import App from '../../App';


const FormItem = Form.Item;

class LimitsEdit1 extends Component {
    formRef = React.createRef<FormInstance>();
    state = {
        data: {list: [], name: ''},
        restList: {},
        id: 0,
        listVal: new Array(),
        flag: common.getQuery('see'),
        treeVos: [],
        menuType: {}
    };
    menuType = 0;
    tempchekId = 0;
    tempnochekId = 0;
    tempParentId = 0;

    componentDidMount() {
        const id = common.getQuery('id');
        if (id) {
            this.setState({id}, () => {
                this.getca(id);
            });
        } else {
            // 权限菜单
            this.getca('0');
        }
    }
    // tempParentCheckId = 0;

    //获取权限列表
    getca = (id: string) => {
        // limitsSelect;
        let url = API.user.limitsSelect;
        if (id !== '0') {
            url = `${API.user.limitsSelect}?roleId=${id}`;
        }
        Fetch.get(url).then((res) => {
            this.setState({
                treeVos: res.treeVos,
            });
            if (id !== '0') {
                this.getData(res);
                this.setState({
                    menuType: this.initMenuType()
                })
            }
            // restList;
            let restList = {};
            res.treeVos.map((item: any) => {
                restList['parent' + item.id] = this.setrestData(item.child, [item.id]);

                if (item.child) {
                    item.child.map((items: any) => {
                        restList['Lowparent' + items.id] = this.setrestData(
                            items.child,
                            []
                        );
                    });
                }
            });

            this.setState({restList});
        });
    };

    getData = (res: any) => {

        let {treeVos} = this.state;
        let arr = new Array();
        res.selectMenusIds.map((item: number) => {
            treeVos.map((itemp: any) => {
                itemp.child.map((items: any) => {
                    if (items.id == item) {
                        arr.push(itemp.id);
                    }
                });
            });
        });
        arr = arr.concat(res.selectMenusIds);
        let newArr1 = new Set(arr);
        res.selectMenusIds = Array.from(newArr1);
        this.setState({
            ...res,
            menuIds: res.selectMenusIds,
            listVal: res.selectMenusIds
        });
        //@ts-ignore
        this.formRef.current.setFieldsValue({
            ...res,
            menuIds: res.selectMenusIds,
        });

        // Fetch.get(`${API.user.findUpdateDetail}/${this.state.id}`).then(
        //     (res: any) => {
        //         let {menuIds} = res;
        //         let {treeVos} = this.state;
        //         let arr = new Array();
        //         menuIds.map((item: number) => {
        //             treeVos.map((itemp: any) => {
        //                 itemp.child.map((items: any) => {
        //                     if (items.id == item) {
        //                         arr.push(itemp.id);
        //                     }
        //                 });
        //             });
        //         });
        //         arr = arr.concat(menuIds);
        //         let newArr1 = new Set(arr);
        //         menuIds = Array.from(newArr1);
        //         this.setState({
        //             ...res,
        //             menuIds,
        //             listVal: menuIds
        //         });
        //         //@ts-ignore
        //         this.formRef.current.setFieldsValue({
        //             ...res,
        //             menuIds,
        //         });
        //     }
        // );
    };

    setrestData(data: any, arr: any) {
        for (let i = 0; i < data.length; i++) {
            arr.push(data[i]['id']);
            if (data[i].child) {
                this.setrestData(data[i].child, arr);
            }
        }
        return arr;
    }


    // 总盒子点击事件
    onChange = (e: any) => {
        let arr = [...e];
        // 选中
        if (this.tempchekId != 0) {
            arr = arr.concat(this.doSelected(this.tempchekId, "add"));
        } else {
            const doSelected1 = this.doSelected(this.tempnochekId, "sub");
            const removeList = this.checkLevel(e, doSelected1)
            removeList.map((item1: any) => {
                let indexOf = doSelected1.indexOf(item1);
                if (indexOf != -1) {
                    doSelected1.splice(indexOf, 1);
                }
            });
            doSelected1.map((item1: any) => {
                let indexOf = arr.indexOf(item1);
                if (indexOf != -1) {
                    arr.splice(indexOf, 1);
                }
            });
            // arr = result;
        }

        // 页面点击事件
        // 权限点击事件
        let newArr1 = new Set(arr);
        let newArr = Array.from(newArr1);
        this.setState({listVal: newArr});
        //@ts-ignore
        this.formRef.current.setFieldsValue({
            menuIds: newArr,
        });
        this.menuType = 0;
        this.tempchekId = 0;
        this.tempnochekId = 0;
        this.tempParentId = 0;
    };

    doSelected(id: any, type: any) {
        let treeVos = this.state.treeVos;
        const listVal = this.state.listVal;
        let child: any[] = [];
        let initPid = 0;
        let endFlag = false;
        let parent: any[] = [];
        let menuType = this.state.menuType;

        findParent(treeVos, id);
        try {
            searchTreeVos(treeVos, initPid, id, false);
        } catch (selectMenus) {
            child = selectMenus
        }
        return child.concat(parent)

        function searchTreeVos(treeList: any, pId: any, searchId: any, endFlag: boolean) {

            if (endFlag) {
                throw child;
            }
            for (const element of treeList) {
                const item = element;
                let id = item.id;
                endFlag = id == searchId;
                if (endFlag) {
                    addChild(item.child)
                }
                searchTreeVos(item.child, item.id, searchId, endFlag);
            }
        }

        function addChild(treeList: any) {
            for (let i = 0; i < treeList.length; i++) {
                const item = treeList[i];
                let id = item.id;
                child.push(id)
                if (item.child.length > 0) {
                    addChild(item.child);
                }
            }
        }

        function findParent(treeList: any, menuId: any) {

            if (menuId == null) {
                return
            }
            for (let i = 0; i < treeList.length; i++) {
                const item = treeList[i];
                //判断当前菜单ID 是否与要查找的menuId相同
                if (item.id === menuId) {
                    //当为减少时不添加原始menuId
                    if (item.id !== id && type == "sub") {
                        if (menuType[id] !== 2) {
                            parent.push(item.id);
                        }
                    } else {
                        parent.push(item.id);
                    }
                    findParent(treeVos, item.parentId);
                    break;
                } else {
                    if (item.child.length > 0) {
                        findParent(item.child, menuId);
                    }
                }
            }
        }
    }

    checkLevel = (e: any, parent: any) => {

        let treeVos = this.state.treeVos;
        const listVal = this.state.listVal;
        let noCheckId = this.tempnochekId; //当前取消
        let childList = [];
        let flag = false;
        let removeFlag = false;
        let removeList = [];
        if (parent.length > 0) {
            let pre: any = null;
            for (let i = 0; i < parent.length; i++) {
                if (flag && !removeFlag) {
                    try {
                        //获取层级数据
                        findChildClickSize(treeVos, parent[i])
                    } catch (childList) {
                        removeFlag = findChildSelectSize(childList, pre);
                    }
                }
                if (removeFlag) {
                    removeList.push(parent[i]);
                }
                if (noCheckId == parent[i]) {
                    flag = true;
                }
                pre = parent[i];
            }
        }
        return removeList;

        function findChildClickSize(checkList: any, checkMenuId: any) {

            for (let i = 0; i < checkList.length; i++) {
                const item = checkList[i];
                let id = item.id;
                if (id == checkMenuId) {
                    throw item.child;
                } else {
                    findChildClickSize(item.child, checkMenuId)
                }
            }
        }

        function findChildSelectSize(checkChild: any, pre: any) {

            let index = 0
            for (let i = 0; i < checkChild.length; i++) {
                const item = checkChild[i];
                if (item.id == pre) {
                    continue;
                }
                if (listVal.indexOf(item.id) != -1) {
                    index++
                }
            }
            return index > 0
        }
    }

    initMenuType() {
        let treeVos = this.state.treeVos;
        let menuType = {};
        addChild(treeVos);
        return menuType;

        function addChild(treeList: any) {
            for (let i = 0; i < treeList.length; i++) {
                const item = treeList[i];
                let id = item.id;
                menuType[item.id] = item.menuType
                if (item.child.length > 0) {
                    addChild(item.child);
                }
            }
        }
    }

    childChange = (e: any) => {
        let id = e.target.value;
        this.menuType = e.target['data-type'];
        this.tempParentId = e.target['data-parentid'];
        if (e.target.checked) {
            this.tempchekId = id;
        } else {
            this.tempnochekId = id;
        }
    };
    listNode = (data: any) => {
        return (
            <div className="limitsbox">
                {data.length ? this.listChildNode(data) : ''}
            </div>
        );
    };
    listChildNode = (data: any) => {
        return (
            <div>
                {
                    //@ts-ignore
                    data.map((item: any) => {
                        return (
                            <div key={'id' + item.id}>
                                <div
                                    className={'limitsitem' + item.menuType}
                                    key={'id' + item.id}
                                >
                                    <Checkbox
                                        key={item.id}
                                        value={item.id}
                                        onChange={this.childChange}
                                        data-type={item.menuType}
                                        data-parentid={item.parentId ? item.parentId : 0}
                                    >
                                        {item.name}
                                    </Checkbox>
                                </div>
                                {item.child && item.child.length
                                    ? this.listChildNode(item.child)
                                    : null}
                            </div>
                        );
                    })
                }
                {/* {data.child && data.child.length
          ? this.listChildNode(data.child)
          : null} */}
            </div>
        );
    };
    submit = (values: any) => {

        if (this.state.id) {
            // 更改
            this.update(values);
        } else {
            // 增加
            delete values.id;
            this.create(values);
        }
    };

    create = (values: any) => {
        Fetch.postJSON(API.user.saveOrganizationRole, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };
    cancle = () => {
        window.history.go(-1);
    };
    update = (values: any) => {
        let data = {
            ...values,
            roleId: this.state.id,
        };
        delete data.id;

        Fetch.postJSON(API.user.UpdateOrganizationRole, data).then(() => {
            message.success('保存成功');
            window.history.go(-1);
        });
    };

    changeUrl(url: string) {
        let urls = '';
        if (url.indexOf(API.domain) !== -1) {
            urls = url.replace(API.domain, '');
        } else {
            urls = url;
        }
        return urls;
    }

    render() {
        const {data, treeVos, listVal, flag} = this.state;
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/userlimits">权限管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {common.getQuery('see')
                            ? '详情'
                            : common.getQuery('id')
                                ? '修改角色'
                                : '新建角色'}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background limits"
                    style={{padding: 24, minHeight: 720}}
                >
                    <div className="container">
                        <Form
                            initialValues={data}
                            onFinish={this.submit}
                            ref={this.formRef}
                        >
                            <FormItem hidden noStyle name="id">
                                <Input/>
                            </FormItem>
                            {!!flag}
                            <FormItem
                                className="mylabel"
                                name="name"
                                label="角色名称"
                                labelCol={{span: 2}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input
                                    disabled={!!flag}
                                    className="myinput"
                                    placeholder="角色名称"
                                    maxLength={20}
                                />
                            </FormItem>

                            <div>
                                <div className="detailTitle">功能权限</div>
                                <Form.Item name="menuIds">
                                    <Checkbox.Group
                                        disabled={!!flag}
                                        style={{width: '100%'}}
                                        onChange={this.onChange}
                                        value={listVal}
                                    >
                                        {this.listNode(treeVos)}
                                    </Checkbox.Group>
                                </Form.Item>
                            </div>
                            {!flag ? (
                                <FormItem
                                    wrapperCol={{span: 12, offset: 2}}
                                    className="center"
                                >
                                    <Button
                                        size="large"
                                        className="mybtn"
                                        style={{marginRight: '15px'}}
                                        onClick={this.cancle}
                                    >
                                        取 消
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className="mybtn"
                                    >
                                        提 交
                                    </Button>
                                </FormItem>
                            ) : (
                                ''
                            )}
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}

export default function LimitsEdit() {
    return (
        <App selectedKeys={['limits']}>
            <LimitsEdit1/>
        </App>
    );
}
