import {Card, Descriptions} from 'antd';
import React, {useEffect, useState} from 'react';
import {DhDetailDataType} from "../common/DhDetailType";
import {FormTitle, getTitle} from "../common/IdentityData";

type DhWarVeteranType = {

    warVeteran: DhDetailDataType["data"]["warVeteran"]
}

const DhWarVeteranInfo: React.FC<DhWarVeteranType> = (props) => {

    const [titleObj, setTitleObj] = useState<FormTitle>();
    const [warVeteranShowStr, setWarVeteranShowStr] = useState<string>();

    useEffect(() => {
        // setTitleObj(getTitle(props.warVeteran?.serviceIdentity));
        if (props.warVeteran?.warVeteran === "1") {
            setWarVeteranShowStr(titleObj?.warVeteranShow)
        } else if (props.warVeteran?.warVeteran === "2") {
            setWarVeteranShowStr("家属")
        } else {
            setWarVeteranShowStr("未知")
        }
    }, [titleObj])

    useEffect(() => {
        setTitleObj(getTitle(props.warVeteran?.serviceIdentity));
    }, [props])

    return (
        <>
            {(props.warVeteran && props.warVeteran.serviceIdentityStr) && (
                <Card size="small"
                      title={titleObj?.cardTitle}
                >
                    <Descriptions>
                        <Descriptions.Item label={titleObj?.cardTitle}>
                            {props.warVeteran.serviceIdentityStr}
                        </Descriptions.Item>
                        {props.warVeteran.serviceIdentity !== '4' && (
                            <Descriptions.Item label={titleObj?.warVeteran}>
                                {warVeteranShowStr}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                    {(props.warVeteran.serviceIdentity !== '4'
                        && props.warVeteran.warVeteran === "2") && (
                        <>
                            <Descriptions>
                                <Descriptions.Item label={titleObj?.warVeteranName}>
                                    {props.warVeteran.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={titleObj?.warVeteranIdNumber}>
                                    {props.warVeteran.idNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label={titleObj?.warVeteranPhone}>
                                    {props.warVeteran.phone}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label={titleObj?.warVeteranRelations}>
                                    {props.warVeteran.veteranRelationsStr}
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    )}
                </Card>
            )
            }
        </>
    );
};

export default DhWarVeteranInfo;
