import React from 'react';
import {Button, Card, Form, FormInstance, message, Modal, Space, Spin} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {decryptData} from "../../common/crypto";
import common from "../../common/common";
import DhHospitalAddEditCommon from "./DhHospitalAddEditCommon";
import {defaultDhDetailData, DhDetailDataType, EditType} from "../common/DhDetailType";
import {ComDhDetailOrEdit} from "../common/edit/DhEditAndDetailPageConfig";
import PATH from "../../common/pagePath";
import {buildData} from "../common/edit/DhSubmitDataConfig";
import {defDictData, getDictData} from "../common/DhDictData";
import {buildBackFillData, dhShowFile} from "../common/edit/DhBackfillDataConfig";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

const {confirm} = Modal;

class EditDhPatientHospitalClass extends DhHospitalAddEditCommon {

    formRef = React.createRef<FormInstance>();
    state: any & DhDetailDataType = {
        id: decryptData(common.getQuery('id')),
        hospitalSelect: [],
        addressSelect: [],
        areaStr: '',
        saveResult: {
            id: 0,
            dhStatus: 0
        },
        data: defaultDhDetailData.data,
        dictInfo: defDictData,
        paymentMethodId: '',
        isWarVeteran: '',
        tip: '加载中',
        importLoading: true,
        loadingData: true
    };

    componentDidMount() {
        this.getData();
    };

    getData() {
        const {id} = this.state;
        let url = `${API.dentistry.dh_service_user_booking.get_service_user_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            let formValue: EditType = {};
            dhShowFile(data).then(() => {
                buildBackFillData(formValue, data, PATH.dhHospitalPatient.edit)
                getDictData().then((dictData) => {
                    this.setState({
                        data: data,
                        paymentMethodId: data.account.paymentMethod,
                        importLoading: false,
                        loadingData: false,
                        dictInfo: dictData
                    }, () => {
                        this.formRef.current?.setFieldsValue({
                            ...formValue
                        });
                    })
                })
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    submit = (values: any) => {
        this.setState({
            importLoading: true
        }, () => {
            let that = this;
            confirm({
                title: `是否提交数据`,
                onOk() {
                    const {id, data} = that.state;
                    const point = that.getLat(values);
                    const target = buildData(values, point, data.userDetail.dhStatus, PATH.dhHospitalPatient.edit);
                    target.id = id;
                    that.update(target);
                },
                onCancel() {
                    that.setState({
                        importLoading: false
                    })
                }
            });
        })
    }

    update = (values: any) => {

        Fetch.postJSON(API.dentistry.service_user.do_update_application, values).then((data) => {
            this.setState({
                saveResult: data
            })
            message.success('修改成功');
            window.history.go(-1);
        });
    };


    render() {
        const {
            data,
            dictInfo,
        } = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Form
                        onFinish={this.submit}
                        ref={this.formRef}
                    >
                        <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                {!this.state.loadingData && (
                                    <ComDhDetailOrEdit data={data} form={this.formRef} dictInfo={dictInfo}
                                    />
                                )}

                                <Card>
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                        >
                                            保存
                                        </Button>
                                        <Button
                                            size='large'
                                            onClick={this.cancel}
                                        >
                                            取消
                                        </Button>
                                    </Space>
                                </Card>
                            </Space>
                        </Spin>
                    </Form>
                </div>

            </div>
        );
    }
}

export default function EditDhPatientHospital() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <EditDhPatientHospitalClass/>
        </App>
    );
}
