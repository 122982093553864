import {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, message, Modal, Table} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/es/table';

import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import createModal from '../../common/createModal';

import App from '../../../App';
import {AccountType} from './types';
import FormContent from './form';

const confirm = Modal.confirm;

const FormModal = createModal(FormContent);
export default class Atltemplate extends Component {
    state = {
        page: 0,
        pageSize: 8,
        totalElements: 0,
        totalPages: 0,
        content: [],
        account: null,
        title: '新建模版',
        loading: true,
        categoryList: [],
    };
    private modal: any = null;

    componentDidMount() {
        this.getData();
        this.getSelect();
    }

    // 获取数据
    getData = () => {
        const {page, pageSize} = this.state;
        Fetch.get(`${API.atl.template.list}?page=${page}&size=${pageSize}`).then(
            (data) => {
                this.setState({
                    content: data.content,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages,
                    loading: false,
                });
            }
        );
    };
    getSelect = () => {
        Fetch.get(API.atl.category.select).then((data) => {
            this.setState({
                categoryList: data,
            });
        });
    };
    // 编辑
    edit = (id: number) => {
        window.location.href = `/#/templateDetail/${id}/0`;
    };
    // 详情
    goDetail = (id: number) => {
        window.location.href = `/#/templateDetail/${id}/1`;
    };
    // 删除
    del = (row: any) => {
        let that = this;
        confirm({
            title: `您是否确认要删除模版： “${row.name}”`,
            onOk() {
                Fetch.put(API.atl.template.deleted, {id: row.id}).then(() => {
                    message.success('删除成功');
                    that.getData();
                });
            },
        });
    };
    //  禁用
    close = (id: number, type: number) => {
        Fetch.put(API.atl.template.active, {id: id}).then(() => {
            if (type) {
                message.success('启用成功');
            } else {
                message.success('禁用成功');
            }
            this.getData();
        });
    };
    creat = () => {
        window.location.href = '/#/templateDetail/0/0';
    };
    changeInput = (val: String, type: any) => {
        this.setState({
            [type]: val,
        });
    };
    // 下一页
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.getData();
                }
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content, title} = this.state;
        const columns: ColumnsType<AccountType> = [
            {
                title: '模版名称',
                dataIndex: 'name',
                align: `center`,
                width: 150,
            },
            {
                title: '类型',
                dataIndex: 'type',
                align: `center`,
                render: (text, record) => {
                    if (text === 1) {
                        return '活动';
                    } else if (text === 2) {
                        return '募捐';
                    } else {
                        return '';
                    }
                },
            },
            {
                title: '所属类别',
                dataIndex: 'categoryName',
                align: `center`,
            },
            {
                title: '所属项目',
                dataIndex: 'projectName',
                align: `center`,
            },
            {
                title: '积分',
                dataIndex: 'credits',
                align: `center`,
                render: (text, record) => {
                    return text ? text : '-';
                },
            },
            {
                title: '工分',
                dataIndex: 'workPoints',
                align: `center`,
                render: (text, record) => {
                    return text ? text : '-';
                },
            },
            {
                title: '服务时长',
                dataIndex: 'duration',
                align: `center`,
                render: (text, record) => {
                    if (text) {
                        return text;
                    } else {
                        return '-';
                    }
                },
            },

            {
                title: '创建日期',
                dataIndex: 'createDate',
                align: `center`,
                width: 150,
            },

            {
                title: '状态',
                dataIndex: 'active',
                render: (text, record) => {
                    if (text === true) {
                        return '已启用';
                    } else if (text === false) {
                        return '已禁用';
                    } else {
                        return '';
                    }
                },
                align: `center`,
            },

            {
                title: '操作',
                align: `center`,
                fixed: 'right',
                key: 'operation',
                width: 210,
                render: (text, record: any) => {
                    return (
                        <div>
                            <Button
                                size="small"
                                type="link"
                                onClick={() => this.goDetail(record.id)}
                            >
                                详情
                            </Button>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => this.edit(record.id)}
                            >
                                编辑
                            </Button>

                            {record.active ? (
                                <Button
                                    size="small"
                                    type="text"
                                    danger
                                    onClick={() => this.close(record.id, 0)}
                                >
                                    禁用
                                </Button>
                            ) : (
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() => this.close(record.id, 1)}
                                >
                                    启用
                                </Button>
                            )}
                            <Button
                                type="text"
                                danger
                                size="small"
                                onClick={() => this.del(record)}
                            >
                                删除
                            </Button>
                        </div>
                    );
                },
            },
        ];
        return (
            <App selectedKeys={['atltemplate']} openKeys="atl">
                <>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>公益活动库 / 模版</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <div
                            style={{
                                float: 'right',
                                marginLeft: 'auto',
                                marginBottom: '15px',
                            }}
                        >
                            <Button
                                onClick={this.creat}
                                type="primary"
                                icon={<PlusOutlined/>}
                            >
                                新建
                            </Button>
                        </div>

                        <Table
                            loading={this.state.loading}
                            scroll={{x: 1300}}
                            className="mgTop15"
                            bordered
                            rowKey={(record) => {
                                return record.id;
                            }}
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.changeCondition(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    },
                                    true
                                )
                            }
                        />
                    </div>
                    <FormModal
                        width={500}
                        title={title}
                        reload={this.getData}
                        account={this.state.account}
                        categoryList={this.state.categoryList}
                        flag={title}
                        ref={(c) => (this.modal = c)}
                    ></FormModal>
                </>
            </App>
        );
    }
}
