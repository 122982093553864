export const statusArrV2 = {

    1: '等待审核',
    202: '审核通过，等待医院检查',
    218: '待定',
    203: '审核不通过',
    204: '检查结束，符合手术条件',
    205: '检查结束，不符合手术条件', //红
    206: '等待医院申请报销',
    207: '报销申请已提交',
    208: '报销申请已撤回',
    209: '报销审核通过',
    210: '报销审核不通过',
    211: '报销完成，请上传术后信息',
    212: '已完成',
    213: '财务报销中',
    214: '报销失败',
    215: '等待医院申请报销',
    216: '报销审核通过',
    217: '对账中'
}
export const statusColorArrV2 = {
    1: 'black',
    202: 'black',
    203: 'black',
    204: 'black',
    205: 'black', //红
    206: 'black',
    207: 'black',
    208: 'black',
    209: 'black',
    210: 'black',
    211: 'black',
    212: 'black',
    213: 'black'
}
export const statusSelectArrV2 = [
    {
        value: 1,
        label: '等待审核',
    },
    {
        value: 202,
        label: '审核通过，等待医院检查',
    },
    {
        value: 203,
        label: '审核不通过',
    },
    {
        value: 218,
        label: '待定',
    },
    {
        value: 204,
        label: '检查结束，符合手术条件',
    },
    {
        value: 205,
        label: '检查结束，不符合手术条件',
    },
    {
        value: 206,
        label: '等待医院申请报销',
    },
    {
        value: 207,
        label: '报销申请已提交',
    },
    {
        value: 208,
        label: '报销申请已撤回',
    },
    {
        value: 209,
        label: '报销审核通过',
    },
    {
        value: 210,
        label: '报销审核不通过',
    },
    {
        value: 211,
        label: '报销完成，请上传术后信息',
    },
    {
        value: 212,
        label: '已完成',
    },
    {
        value: 213,
        label: '财务报销中',
    },
    {
        value: 214,
        label: '报销失败',
    },
    {
        value: 217,
        label: '对账中',
    }]
