import {useEffect, useState} from 'react';
import {Card, Image, Space} from 'antd';
import {useParams} from 'react-router-dom';
import './active.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';

export default function Donation() {
    const {userid} = useParams<{ userid: string }>();
    const [datas, setDatas] = useState<any>({
        data: {}
    });
    useEffect(() => {
        getData();
    }, datas);
    const getData = () => {
        Fetch.get(
            `${API.volunteer.volunteer_file_card}/${userid}`
        ).then((data) => {
            setDatas({
                data: data
            });
        });
    };

    // @ts-ignore
    // @ts-ignore
    return (
        <div className="active">
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <Card title="志愿者证书" size="small">
                    {
                        datas.data.certificate ?
                            <Image
                                width={300}
                                src={API.domain + datas.data.certificate}
                            />
                            :
                            <p>没有证书</p>
                    }

                </Card>
                <Card title="志愿者名片" size="small">
                    {
                        datas.data.volunteerCardUrl ?
                            <Image
                                width={300}
                                src={API.domain + datas.data.volunteerCardUrl}
                            />
                            :
                            <p>没有生成志愿者名片</p>
                    }
                    {
                        datas.data.volunteerCardUrl ?
                            <Image
                                width={300}
                                src={API.domain + datas.data.volunteerCardBackUrl}
                            />
                            : <p></p>
                    }
                </Card>
            </Space>
        </div>
    );
}
