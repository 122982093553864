import React, {useEffect, useState} from 'react';
import {Card, Col, Input, Radio, Row, Select} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import {getIdNumberRole, PHONE_NUMBER} from "../../common/VerifyRegex";
import {EditBaseType} from "../common/edit/DhEditAndDetailPageConfig";
import {FormTitle, getTitle} from "../common/IdentityData";
import {showFormArray} from "../common/DhDictData";
import {DhDetailDataType} from "../common/DhDetailType";

type EditWarVeteranType = EditBaseType & {
    warVeteran?: DhDetailDataType["data"]['warVeteran']
};


export default function EditWarVeteran(props: EditWarVeteranType) {

    const [isWarVeteran, setIsWarVeteran] = useState<string>('1');
    const [titleObj, setTitleObj] = useState<FormTitle>();
    const [showForm, setShowForm] = useState<boolean>(true);
    const [idNumberChange, setIdNumberChange] = useState<boolean>(false);


    useEffect(() => {
        let serviceIdentity = props.form.current?.getFieldValue("serviceIdentity");
        setTitleObj(getTitle(serviceIdentity));
    }, [])

    useEffect(() => {
        if (props.warVeteran) {
            setIsWarVeteran(props.warVeteran.warVeteran)
            setShowForm(showFormArray.includes(props.warVeteran.serviceIdentity))
            props.form.current?.setFieldsValue({
                warVeteran: props.warVeteran.warVeteran,
            });
        } else {
            props.form.current?.setFieldsValue({
                warVeteran: isWarVeteran,
            });
        }
    }, [props.warVeteran])

    const warVeteranChange = (e: any) => {

        setIsWarVeteran(e.target.value)
    };

    const identityChange = (val: any) => {

        setTitleObj(getTitle(val.target.value));
        setShowForm(showFormArray.includes(val.target.value))
    }

    const handleIdNumberFocus = (e: any) => {

        const fieldName = e.target.id;
        if (!props.warVeteran) {
            return
        }
        const value = e.target.value;
        if (value.includes('*')) {
            // 清空带有星号的内容
            props.form.current?.setFieldsValue({
                [fieldName]: undefined,
            });
        }
    }

    const handleIdNumberBlur = (e: any) => {

        const fieldName = e.target.id;
        if (!props.warVeteran) {
            return
        }
        const value = e.target.value;
        if (!value) {
            //重置修改状态
            setIdNumberChange(false)
            // 如果失去焦点时为空，则回填原来的数据
            props.form.current?.setFieldsValue({
                [fieldName]: props.warVeteran.idNumber,
            })
        }
    }

    const warVeteranIdNumberChange = (e: any) => {
        setIdNumberChange(true)
    }


    return (
        <>
            <Card size="small"
                  title={titleObj?.cardTitle}
            >
                <Row gutter={40}>
                    <Col span={8}>
                        <FormItem
                            name="serviceIdentity"
                            label="选择身份"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 40}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Radio.Group onChange={identityChange}>
                                {props.dictInfo.serviceIdentity.map(item => (
                                    <>
                                        <Radio key={item.code} value={item.code}>
                                            {item.name}
                                        </Radio>
                                    </>
                                ))}
                            </Radio.Group>
                        </FormItem>
                    </Col>
                </Row>
                {showForm === true && (
                    <>
                        <Row gutter={40}>
                            <Col span={8}>
                                <FormItem
                                    className={styles.mylabel}
                                    name="warVeteran"
                                    label={titleObj?.warVeteran}
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 12}}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Radio.Group onChange={warVeteranChange}
                                                 defaultValue={isWarVeteran}>
                                        {props.dictInfo.war_veteran.map(item => (
                                            <>
                                                <Radio key={item.code} value={item.code}>
                                                    {item.name}
                                                </Radio>
                                            </>
                                        ))}
                                    </Radio.Group>
                                </FormItem>
                            </Col>
                        </Row>
                        {isWarVeteran === '2' && (
                            <>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="warVeteranName"
                                            label={titleObj?.warVeteranName}
                                            labelCol={{span: 6}}
                                            wrapperCol={{span: 12}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input
                                                placeholder={titleObj?.warVeteranName}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="warVeteranIdNumber"
                                            label={titleObj?.warVeteranIdNumber}
                                            labelCol={{span: 6}}
                                            wrapperCol={{span: 12}}
                                            rules={getIdNumberRole(idNumberChange)}
                                        >
                                            <Input
                                                onFocus={handleIdNumberFocus}
                                                onBlur={handleIdNumberBlur}
                                                onChange={warVeteranIdNumberChange}
                                                placeholder={titleObj?.warVeteranIdNumber}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="warVeteranPhone"
                                            label={titleObj?.warVeteranPhone}
                                            labelCol={{span: 6}}
                                            wrapperCol={{span: 12}}
                                            rules={[{required: true, message: '必填'}, PHONE_NUMBER]}
                                        >
                                            <Input placeholder={titleObj?.warVeteranPhone}/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={40}>
                                    <Col span={8}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="warVeteranRelations"
                                            label={titleObj?.warVeteranRelations}
                                            labelCol={{span: 6}}
                                            wrapperCol={{span: 12}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                options={props.dictInfo.veteran_relations}
                                                placeholder={titleObj?.warVeteranRelations}
                                            >
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                )}
            </Card>
        </>
    );
}
