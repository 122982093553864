export const QuesStatus = {
    1: '初始',
    2: '上架',
    3: '下架',
}
export const QuesType = {
    1: '单选题',
    2: '多选题',
    3: '判断题'
}

export const UnitLogType = {
    1: '合并',
    2: '转个人',
}

export const UndoType = {
    1: '可撤销',
    2: '已撤销',
}

export const GroupType = {
    1: '个人组',
    2: '单位组',
}

export const QuesTypeSelect = [
    {
        value: 1,
        label: '单选题',
    },
    {
        value: 2,
        label: '多选题',
    },
    {
        value: 3,
        label: '判断题',
    }];

export const SubSource = {
    'WECHAT_APPLETS': '微信小程序',
    'ALIPAY': '支付宝小程序',
    'KS': '快手小程序',
    'BYTE_APPLETS': '抖音小程序'
}

export const SubSourceArr =
    [{value: 'WECHAT_APPLETS', label: '微信小程序'}, {value: 'ALIPAY', label: '支付宝小程序'}, {
        value: 'KS',
        label: '快手小程序'
    }, {value: 'BYTE_APPLETS', label: '抖音小程序'}]



export const Options = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
