import {Breadcrumb} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import BREADCRUMB_INFO_CONFIG, {BreadcrumbType} from "./BreadcrumbConfig";
import {getBreadcrumb} from "./BreadcrumbUtil";

type  Bread = any;

type pageInf = {
    url: string | '',
    name: string | '',
    root: boolean | '',
    realUrl: string | ''
}

const BreadcrumbInfo: React.FC<Bread> = (props) => {

        const location = useLocation();

        const [breadcrumbArr, setBreadcrumbArr] = useState<string[]>([]);
        useEffect(
            function () {
                const sessionBreadcrumb = getBreadcrumb();
                const newBreadcrumb: any[] = [];
                const realUrl = location.pathname + location.search;
                const router = location.pathname;
                const pageConfig: BreadcrumbType | undefined = BREADCRUMB_INFO_CONFIG.get(router);
                if (pageConfig) {
                    const pageInfo: pageInf = {
                        url: pageConfig.url,
                        name: pageConfig.name,
                        root: pageConfig.root,
                        realUrl: realUrl
                    }
                    if (pageConfig.root) {
                        newBreadcrumb.push(pageInfo);
                    } else {
                        let isAdd = false;
                        sessionBreadcrumb.forEach((value: any) => {
                            if (!isAdd) {
                                isAdd = value.realUrl === pageInfo.realUrl;
                                newBreadcrumb.push(value);
                            }
                        });
                        if (!isAdd) {
                            newBreadcrumb.push(pageInfo);
                        }
                    }
                    flushData(newBreadcrumb)
                }
            },
            [location]
        );

        const flushData = (breadcrumb: any[]) => {

            const key = "Breadcrumb";
            sessionStorage.setItem(key, JSON.stringify(breadcrumb));
            setBreadcrumbArr(breadcrumb);
        }
        return (
            <>
                <Breadcrumb key={"Breadcrumb"} style={{margin: '16px 0'}}>
                    {breadcrumbArr.map((item: any, index) => (
                        <>
                            <Breadcrumb.Item key={index}>
                                {index !== breadcrumbArr.length - 1 ? (
                                    <Link to={item.realUrl}>
                                        {item.name}
                                    </Link>
                                ) : (
                                    <>{item.name}</>
                                )}
                            </Breadcrumb.Item>
                        </>
                    ))}
                </Breadcrumb>
            </>
        );
    }
;

export default BreadcrumbInfo;
