import {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Card, Descriptions, Space, Steps, Tabs, Tooltip, Typography,} from 'antd';

import styles from './gongyiActivity.module.css';
import App from '../../App';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {useParams} from 'react-router-dom';
import createModal from '../common/createModal';
import ReviewForm from './ReviewForm';
import ReasonForm from './ReasonForm';
import ReasonDetail from './ReasonDetail';
import ReviewItem from './ReviewItem';
import {gongyiActivityStatus} from "./GongyiActivityStatus";
import dayjs from "dayjs";

const {Step} = Steps;

const ReviewFormModal = createModal(ReviewForm);
const ReasonFormModal = createModal(ReasonForm);
const ReasonDetailModal = createModal(ReasonDetail);

export function showReason(reason, status, reviewStatus) {
    return reason && status === 'PUBLISHED' && reviewStatus === 'ENABLE';
}

export function getMenu(
    id,
    reviewStatus,
    operation,
    down,
    reason,
    status,
    up,
    category,
    offlineRole
) {
    let user = {roleId: -1};
    if (sessionStorage.user) {
        user = JSON.parse(sessionStorage.user);
    }

    let formModal = null;
    const showDownModal = () => {
        formModal.show();
    };

    let reasonModal = null;
    const showReasonModal = () => {
        reasonModal.show();
    };

    let text = '',
        reasonText = '';
    let fun = down;
    let buttons = sessionStorage.getItem('buttons');
    let obj = JSON.parse(buttons);
    if (obj.buttons.ROLE_REVIEW_STATUS === 1) {
        if (reviewStatus === 'FINAL') {
            text = '审核';
            fun = operation;
        }
    }
    return (
        <Space>
            {showReason(reason, status, reviewStatus) && (
                <Button type="primary" onClick={showReasonModal}>
                    {reasonText}
                </Button>
            )}
            {text && (
                <Button type="primary" onClick={fun}>
                    {text}
                </Button>
            )}
            <ReasonDetailModal
                reason={reason}
                title="查看申请理由"
                width={600}
                ref={(c) => (reasonModal = c)}
            />
            <Button
                onClick={() => {
                    window.history.go(-1);
                }}
            >
                返回
            </Button>
        </Space>
    );
}

// }

export const getCurrentStep = (reviewStatus) => {
    if (reviewStatus === 'PROVINCE' || reviewStatus === 'PROVINCE_REJECTED') {
        return 0;
    } else if (reviewStatus === 'FINAL' || reviewStatus === 'FINAL_REJECTED') {
        return 1;
    } else if (reviewStatus === 'ENABLE') {
        return 2;
    }
};

export const isFinal = (reviewStatus) => {
    if (reviewStatus === 'PROVINCE') {
        return 0;
    } else if (reviewStatus === 'FINAL') {
        return 1;
    }
    return 0;
};

export function renderReviewStatus(status) {
    if (status === 'PROVINCE') {
        return '初审中';
    } else if (status === 'FINAL') {
        return '终审中';
    } else if (status === 'ENABLE') {
        return '已发布';
    } else if (status === 'PROVINCE_REJECTED') {
        return '初审驳回';
    } else if (status === 'FINAL_REJECTED') {
        return '终审驳回';
    }
    return '-';
}

function GongyiActivityDetail(props) {
    const [gongyiActivity, setGongyiActivity] = useState();
    const [qrcode, setQrcode] = useState();

    const params = useParams();

    const loadData = () => {
        Fetch.get(`${API.gongyiActivity.zsgc}?id=${params.id}`).then((data) => {
            setGongyiActivity(data);
        });
    };

    const generatQrcode = () => {
        // const path = encodeURIComponent('/pages/signIn/scan/index');
        // Fetch.get(`${API.gongyiActivity.qrcode}?id=${params.id}&path=${path}`).then(
        //     (data) => {
        //         setQrcode(data);
        //     }
        // );
    };

    const [reason, setReason] = useState();
    const loadReason = () => {
        Fetch.get(`${API.reason.zsgc}?activityId=${params.id}&category=0`).then(
            (data) => {
                setReason(data);
            }
        );
    };

    const [review, setReview] = useState();
    const loadReview = () => {
        Fetch.get(`${API.review.zsgc}?activityId=${params.id}&category=0`).then(
            (data) => {
                setReview(data);
            }
        );
    };

    useEffect(function () {
        loadData();
        generatQrcode();
        loadReason();
        loadReview();
    }, []);

    function renderStatus() {
        if (!gongyiActivity) {
            return '';
        }
        const startDate = dayjs(gongyiActivity.startDate);
        const endDate = dayjs(gongyiActivity.endDate);
        const now = Date.now();
        if (startDate.isBefore(now)) {
            if (endDate.isBefore(now)) {
                return '活动已结束';
            }
            return '活动进行中';
        }
        return '活动未开始';
    }

    function renderStatus2() {
        if (!gongyiActivity) {
            return '';
        }
        const status = gongyiActivity.status;
        if (status === 'DRAFT') {
            return '草稿';
        } else if (status === 'PUBLISHED') {
            return '已发布';
        } else if (status === 'DISABLED') {
            return '已下架';
        }
    }

    let modal = null;

    if (!gongyiActivity) {
        return null;
    }

    const down = () => {
        const result = window.confirm('是否确定下架？');
        if (result) {
            Fetch.put(
                `${API.gongyiActivity.status}?id=${params.id}&status=DISABLED`
            ).then(() => {
                loadData();
            });
        }
    };
    const up = () => {
        Fetch.put(
            `${API.gongyiActivity.online}?id=${params.id}&status=PUBLISHED`
        ).then(() => {
            loadData();
        });
    };
    const isReviewing = () => {
        return (
            gongyiActivity.reviewStatus === 'PROVINCE' ||
            gongyiActivity.reviewStatus === 'FINAL' ||
            gongyiActivity.reviewStatus === 'PROVINCE_REJECTED' ||
            gongyiActivity.reviewStatus === 'FINAL_REJECTED'
        );
    };
    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>
                    <a href="/#/gongyiActivity">公益活动</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {isReviewing() ? '审核详情' : '活动详情'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <Card
                    title={`活动名称: ${gongyiActivity.title}`}
                    extra={
                        <Space>
                            {gongyiActivity.signRule === 2 && (
                                <Tooltip
                                    placement="bottom"
                                    title={<img width="160" src={qrcode}/>}
                                >
                                    <Button>活动二维码</Button>
                                </Tooltip>
                            )}
                            {getMenu(
                                params.id,
                                gongyiActivity.reviewStatus,
                                () => {
                                    modal.show();
                                },
                                down,
                                reason,
                                gongyiActivity.status,
                                up,
                                'GONGYI',
                                gongyiActivity.offlineRole
                            )}
                        </Space>
                    }
                >
                    {isReviewing() ? (
                        <Descriptions>
                            <Descriptions.Item label="操作人">
                                {gongyiActivity.publisher}
                            </Descriptions.Item>
                            <Descriptions.Item label="提交时间">
                                {gongyiActivity.submitDate ? gongyiActivity.submitDate : ' - '}
                            </Descriptions.Item>
                            <Descriptions.Item label="审核状态">
                                {renderReviewStatus(gongyiActivity.reviewStatus)}
                            </Descriptions.Item>
                        </Descriptions>
                    ) : (
                        <Descriptions>
                            <Descriptions.Item label="操作人">
                                {gongyiActivity.publisher}
                            </Descriptions.Item>
                            <Descriptions.Item label="上级组织">
                                {gongyiActivity.institution}
                            </Descriptions.Item>
                            <Descriptions.Item label="审核状态">
                                {renderReviewStatus(gongyiActivity.reviewStatus)}
                            </Descriptions.Item>
                            <Descriptions.Item label="发布时间">
                                {gongyiActivity.publishDate
                                    ? gongyiActivity.publishDate
                                    : ' - '}
                            </Descriptions.Item>
                            <Descriptions.Item label="初审机构">
                                {gongyiActivity.institution}
                            </Descriptions.Item>
                            <Descriptions.Item label="终审机构">国家老基会</Descriptions.Item>
                            <Descriptions.Item label="上架状态">
                                {renderStatus2()}
                            </Descriptions.Item>
                            <Descriptions.Item label="活动状态">
                                {/*{renderStatus()}*/}
                                {gongyiActivityStatus(gongyiActivity.activityStatus)}
                            </Descriptions.Item>
                            <Descriptions.Item label="提交时间">
                                {gongyiActivity.submitDate ? gongyiActivity.submitDate : ' - '}
                            </Descriptions.Item>
                            <Descriptions.Item label="招募人数">
                                {gongyiActivity.peopleNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="报名人数">
                                {gongyiActivity.activityUserNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="签到人次">
                                {gongyiActivity.signUserNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="活动编码">
                                {gongyiActivity.code}
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                </Card>
                {isReviewing() && (
                    <Card title="审核流程" style={{marginTop: '10px'}}>
                        <Steps
                            progressDot
                            current={getCurrentStep(gongyiActivity.reviewStatus)}
                        >
                            <Step
                                title={
                                    //@ts-ignore
                                    <ReviewItem
                                        category="GONGYI"
                                        activity={gongyiActivity}
                                        title="省级初审"
                                        review={review}
                                        current={0}
                                        institution={gongyiActivity.institution}
                                        submitDate={gongyiActivity.submitDate}
                                    />
                                }
                            />
                            <Step
                                title={
                                    //@ts-ignore
                                    <ReviewItem
                                        category="GONGYI"
                                        activity={gongyiActivity}
                                        title="老基会终审"
                                        review={review}
                                        current={1}
                                        institution="国家老基会"
                                        submitDate={gongyiActivity.submitDate}
                                    />
                                }
                            />
                            <Step title="发布成功"/>
                        </Steps>
                    </Card>
                )}
                <Card title="活动信息" style={{marginTop: '10px'}}>
                    {!isReviewing() ? (
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="基本信息" key="1">
                                <BasicInfo gongyiActivity={gongyiActivity}/>
                            </Tabs.TabPane>
                            {/* <Tabs.TabPane tab="报名列表" key="2">
                <ActivityUserList activityId={params.id} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="签到列表" key="3">
                <SignLogList activityId={params.id} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="活动记录" key="4">
                <ActivityProgressList activityId={Number(params.id)} />
              </Tabs.TabPane> */}
                        </Tabs>
                    ) : (
                        <BasicInfo gongyiActivity={gongyiActivity}/>
                    )}
                </Card>
                <ReviewFormModal
                    title="审核"
                    width={600}
                    ref={(c) => (modal = c)}
                    isFinal={isFinal(gongyiActivity.reviewStatus)}
                    reload={loadData}
                    activityId={params.id}
                    institution={gongyiActivity.institution}
                    firstReviewDate={gongyiActivity.validateDate}
                    category="GONGYI"
                />
            </div>
        </>
    );
}

function BasicInfo(props) {
    const {gongyiActivity} = props;

    let donationList = ' - ';
    if (gongyiActivity.relatedDonationList) {
        const arr = gongyiActivity.relatedDonationList.map((item) => item.title);
        donationList = arr.join('，');
    }
    return (
        <div className={styles.mycard}>
            <Descriptions>
                <Descriptions.Item span={3} label="封面图">
                    {gongyiActivity.covers && gongyiActivity.covers.length === 0 && (
                        <span>暂无</span>
                    )}
                    {gongyiActivity.covers &&
                        gongyiActivity.covers.map((file) => {
                            return (
                                <div key={file.id} className={styles.mgRight10}>
                                    <img src={API.domain + file.url} width={100}/>
                                </div>
                            );
                        })}
                </Descriptions.Item>
                <Descriptions.Item label="活动地址">
                    <Typography.Text ellipsis={true} style={{width: '220px'}}>
                        {gongyiActivity.address}
                    </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="所属类别">
                    {gongyiActivity.template.categoryName}
                </Descriptions.Item>
                <Descriptions.Item label="所属类型">
                    {gongyiActivity.template.projectName}
                </Descriptions.Item>
                <Descriptions.Item label="报名开始时间">
                    {gongyiActivity.registerStartDate}
                </Descriptions.Item>
                <Descriptions.Item label="报名结束时间">
                    {gongyiActivity.registerEndDate}
                </Descriptions.Item>
                <Descriptions.Item label="活动开始时间">
                    {gongyiActivity.startDate}
                </Descriptions.Item>
                <Descriptions.Item label="活动结束时间">
                    {gongyiActivity.endDate}
                </Descriptions.Item>
                <Descriptions.Item label="签到开始时间">
                    {gongyiActivity.signStartDate || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="签到结束时间">
                    {gongyiActivity.signEndDate || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="签到规则">
                    {gongyiActivity.needSign
                        ? gongyiActivity.signRule === 1
                            ? '关联活动地址签到'
                            : '使用数字签到'
                        : '无需签到'}
                </Descriptions.Item>
                <Descriptions.Item label="签到范围">
                    {gongyiActivity.needSign
                        ? gongyiActivity.signRule === 3
                            ? '-'
                            : gongyiActivity.signRadius === 1000
                                ? '1公里范围内'
                                : `${gongyiActivity.signRadius}米范围内`
                        : '无需签到'}
                </Descriptions.Item>

                <Descriptions.Item label="签到密码">
                    {gongyiActivity.signRule === 3 ? gongyiActivity.signNumber : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="活动获取积分">
                    {gongyiActivity.credits}
                </Descriptions.Item>
                <Descriptions.Item label="工分">
                    {gongyiActivity.workPoints}
                </Descriptions.Item>
                <Descriptions.Item label="服务时长">
                    {gongyiActivity.duration}
                </Descriptions.Item>

                <Descriptions.Item label="活动负责人">
                    {gongyiActivity.username}
                </Descriptions.Item>
                <Descriptions.Item label="职务">{gongyiActivity.job}</Descriptions.Item>

                <Descriptions.Item label="电话">
                    {gongyiActivity.phone}
                </Descriptions.Item>
                <Descriptions.Item label="操作人">
                    {gongyiActivity.publisher}
                </Descriptions.Item>
                <Descriptions.Item label="开启募捐">
                    {gongyiActivity.linkedDonation
                        ? gongyiActivity.linkedDonation.title
                        : ' - '}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="关联募捐">
                    {donationList}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="活动详情">
                    <div dangerouslySetInnerHTML={{__html: gongyiActivity.content}}/>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
}

export default function GongyiActivityDetailIndex() {
    return (
        <App selectedKeys={['gongyi']} openKeys="sub1">
            <GongyiActivityDetail/>
        </App>
    );
}
