import React from 'react';
import {Button, Col, Divider, Input, message, Modal, Popover, Row, Select, Space, Spin, Table} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {PageData} from '../util/types';
import {listDefaultData, PaymentMethodArr, selectStatus} from "../util/AierCommon";
import encryptData from '../../common/crypto';
import BaseListPage from "../../common/listpage/BaseListPage";
import createModal from "../../common/createModal";
import ServiceUserReimbursedModal from "./ServiceUserReimbursed";
import ServiceUserReimbursedFailedModal from "./ServiceUserReimbursedFailed";
import common from "../../common/common";
import dayjs from 'dayjs';
import styles from "../hospitalauditv2/ServiceUserV2.module.css";
import {DatabaseOutlined} from '@ant-design/icons';
import RecordOperationsDetail from "../detail/RecordOperationsDetail";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import UpdateServiceUserFeeModal from "./UpdateServiceUserFee";
import ServiceUserReimbursedPassBackFailedModal from "./ServiceUserReimbursedPassBackFailed";
import {addParams, NUMBER_STR, STRING_STR} from "../bill/page/ListCommon";


const ImportResult = createModal(ServiceUserReimbursedModal);
const ServiceUserReimbursedFailedResult = createModal(ServiceUserReimbursedFailedModal);
const UpdateServiceUserFeeResult = createModal(UpdateServiceUserFeeModal);
const ServiceUserReimbursedPassBackFailedResult = createModal(ServiceUserReimbursedPassBackFailedModal);

const {confirm} = Modal;
type User = {};

class ServiceUserAddListClass extends BaseListPage {
    state: PageData = {
        ...listDefaultData,
        btnQ: {
            ROLE_AIER_F_AUDIT: 0,
            ROLE_AIER_F_DETAILS: 0,
            ROLE_82002: 0,
            ROLE_82003: 0
        }
    };
    private importResult: any = null;
    private serviceUserReimbursedFailedResult: any = null;
    private updateServiceUserFeeResult: any = null;
    private serviceUserReimbursedPassBackFailedResult: any = null;

    componentDidMount() {
        super.componentDidMount();
        selectStatus([207, 209, 210, 212, 213, 216, 217, 214], this);
        this.getReimbursedAmount();
        this.loadSelectData();
        this.loadHospital(null);
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.cooperateServiceUsersStatusV2.financial_audit}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("payee", searchData.payee, STRING_STR);
        url = url + addParams("backCard", searchData.backCard, STRING_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        // provinceId: e, hospitalId
        url = url + addParams("provinceId", searchData.provinceId, NUMBER_STR);
        url = url + addParams("hospitalId", searchData.hospitalId, NUMBER_STR);
        url = url + addParams("paymentMethod", searchData.paymentMethod, STRING_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };

    reimbursementExport = () => {

        let that = this;
        confirm({
            title: `是否确认导出？`,
            onOk() {
                that.setState({
                    importLoading: true,
                    tip: "数据导出中"
                });
                Fetch.postJSON(API.reimbursed_batch.batch_create).then((data) => {

                    let fileName = data.code + ".xls"
                    that.downloadTotal(fileName, data.path);
                    message.success('导出成功');
                    // window.location.href = '/#/reimbursedBatch/list';
                }).catch((e) => {
                    that.setState({
                        importLoading: false,
                    });
                });
            },
        });
    }

    exportFinancialStatistics = () => {
        let that = this;
        confirm({
            title: `是否确认导出？`,
            onOk() {
                that.setState({
                    importLoading: true,
                    tip: "数据导出中"
                });
                Fetch.get(API.user_financial_statistics.download_financial_statistics).then((data) => {
                    if (data) {
                        let fileName = dayjs().format('YYYYMMDD') + "情暖老兵劳务信息统计.xlsx";
                        that.downloadTotal(fileName, data);
                        message.success('导出成功');
                    } else {
                        message.error('没有数据可以导出');
                        that.setState({
                            importLoading: false,
                        });
                    }
                }).catch((e) => {
                    that.setState({
                        importLoading: false,
                    });
                });
            },
        });
    }

    goBatchVoucher = () => {

        window.location.href = '/#/financial/batch_voucher';
    }

    downloadTotal = (fileName: string, path: string) => {

        common.downloadFile(fileName, API.domain + path, (callback: any) => {
            if (callback === true) {
                message.success('处理完成');
            } else {
                message.error('下载失败，请联系管理员');
            }
            this.setState({
                importLoading: false,
            }, () => {
                window.location.reload();
            });
        });
    };

    goReimbursedBatchPage = () => {
        window.location.href = '/#/reimbursedBatch/list';
    }

    reimbursement = (sId: number) => {
        this.setState({
            selectId: sId,
        }, () => {
            this.importResult.show()
        })
    }

    reimbursementFail = (sId: number) => {
        this.setState({
            selectId: sId,
        }, () => {
            this.serviceUserReimbursedFailedResult.show()
        })
    }

    updateServiceUserFee = (sId: number) => {
        this.setState({
            selectId: sId,
        }, () => {
            this.updateServiceUserFeeResult.show()
        })
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
                this.getReimbursedAmount();
            }
        );
    }

    getReimbursedAmount() {
        let url = `${API.aierHospitalFee.get_reimbursed_amount}`;
        Fetch.get(url).then((data) => {
            this.setState({
                estimateNum: data.num,
                estimateAmount: data.amount
            })
        });
    }

    updateFinancialAuditsBackRefuse = (sId: number) => {

        this.setState({
            selectId: sId,
        }, () => {
            this.serviceUserReimbursedPassBackFailedResult.show()
        })
    }

    loadSelectData = () => {
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=1`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });

    };

    loadHospital(provId: any) {
        if (provId === undefined) {
            provId = null
        }
        this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.hospital_area.find_select_data}?provId=${provId}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
        if (condition.provinceId) {
            this.loadHospital(condition.provinceId)
        }
    };

    reset = (def: any) => {
        super.reset(def);
        this.loadHospital(null);
    };

    render() {
        let that = this;
        const {
            pageSize, totalElements, content,
            statusArr, genderArr, btnQ, statusListArr
            , statusColorArr, searchData, estimateNum, estimateAmount
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
                // width: 80,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '身份证号',
                dataIndex: 'idCard',
                align: `center`,
                // width: 100,
                render(value: any, record: any, index: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '收款方式',
                dataIndex: 'paymentMethod',
                align: `center`,
                // width: 80,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '收款人',
                dataIndex: 'payee',
                align: `center`,
                // width: 80,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '银行卡号',
                dataIndex: 'backCard',
                align: `center`,
                // width: 100,
                render(value: any, record: any, index: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '关联医院',
                dataIndex: 'hospitalName',
                align: `center`,
                // width: 120,
                render(value: any, record: any, index: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                // width: 100,
                render(value: any, record: any) {
                    return (
                        <>
                            <div className={styles.statusDiv}>
                                <div
                                    style={{
                                        wordBreak: 'break-all',
                                        color: statusColorArr[value]
                                    }}>{statusArr[value]}</div>
                                <>
                                    <Popover placement="left" title={record.name + "的操作记录"}
                                             content={<RecordOperationsDetail serviceId={record.id}/>}>
                                        <DatabaseOutlined className={styles.recordIcon}/>
                                    </Popover>
                                </>
                            </div>
                        </>
                    )
                },
            },
            {
                title: '操作',
                align: `center`,
                // width: 100,
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>} wrap>
                            {btnQ.ROLE_AIER_F_AUDIT && (record.status == 8 || record.status == 207) && (
                                <Link to={`/financialAuditDetail/audit?id=${encryptData(record.id + "")}`}>
                                    审核
                                </Link>
                            )}
                            {btnQ.ROLE_AIER_DO_REIMBURSEMENT && (record.status == 8) && (
                                <Link to={`/financialAuditDetail/audit?id=${encryptData(record.id + "")}`}>
                                    完成报销
                                </Link>
                            )}
                            {btnQ.ROLE_AIER_DO_REIMBURSEMENT && (record.status == 213) && (
                                <Button className={styles.antBtnPadding} type="link" size={"small"}
                                        onClick={() => this.reimbursement(record.id)}>
                                    完成报销
                                </Button>
                            )}
                            {btnQ.ROLE_AIER_REIMBURSEMENT_FAILED && (record.status == 213) && (
                                <Button className={styles.antBtnPadding} type="link" size={"small"}
                                        onClick={() => this.reimbursementFail(record.id)}>
                                    报销失败
                                </Button>
                            )}
                            {btnQ.ROLE_AIER_F_DETAILS && (
                                <Link to={`/serviceUserDetail/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            )}
                            {btnQ.ROLE_82003 && (record.status == 209) && (
                                <Button className={styles.antBtnPadding} type="link" size={"small"}
                                        onClick={() => this.updateFinancialAuditsBackRefuse(record.id)}>
                                    审核退回
                                </Button>
                            )}
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <Row>
                        <Col span={12}>
                            {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                            {/*    <Breadcrumb.Item>报销名单Ⅰ</Breadcrumb.Item>*/}
                            {/*</Breadcrumb>*/}
                            <BreadcrumbInfo/>
                        </Col>
                        <Col span={12}>
                            <div className={styles.estimate}>
                                <Space>
                                    <div>预计报销人数：{estimateNum}</div>
                                    <div> 预计报销金额：{estimateAmount}</div>
                                </Space>

                            </div>
                        </Col>
                    </Row>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        {/* status: '', search: '', type: '', */}
                        <Space onKeyDown={this.handleKeyDown} wrap>
                            <Select
                                showSearch
                                placeholder="选择省份"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, provinceId: e, hospitalId: undefined})
                                }}
                                value={searchData.provinceId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 120}}
                                options={this.state.zoneSelect}
                            />
                            <Select
                                showSearch
                                placeholder="选择执行机构名称"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, hospitalId: e})
                                }}
                                value={searchData.hospitalId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 200}}
                                options={this.state.hospitalSelect}
                            />
                            <Input
                                placeholder="姓名"
                                value={this.state.searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="收款人"
                                value={this.state.searchData.payee}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, payee: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="银行卡号"
                                value={this.state.searchData.backCard}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, backCard: e.target.value})
                                }
                                }
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="状态"
                                optionFilterProp="children"
                                options={statusListArr}
                                value={this.state.searchData.status}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, status: e})
                                }}
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="收款方式"
                                optionFilterProp="children"
                                options={PaymentMethodArr}
                                value={this.state.searchData.paymentMethod}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, paymentMethod: e})
                                }}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset(listDefaultData.searchData)}>重置</Button>
                            {btnQ.ROLE_EXPORT_THE_PENDING_LIST ? (
                                <Button onClick={this.reimbursementExport}>
                                    导出待报销名单
                                </Button>
                            ) : ('')}
                            {btnQ.ROLE_VIEW_THE_EXPORTED_RECORDS ? (
                                <Button onClick={this.goReimbursedBatchPage}>
                                    查看导出记录
                                </Button>
                            ) : ('')}
                            {btnQ.ROLE_AIER_FINANCIAL_STATISTICS ? (
                                <Button onClick={this.exportFinancialStatistics}>
                                    导出上月报表
                                </Button>
                            ) : ('')}
                            <Button onClick={this.goBatchVoucher}>
                                    批量上传凭证
                                </Button>
                        </Space>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                    <ImportResult
                        width={1200}
                        title={'报销'}
                        importResult={this.state.selectId}
                        onCancel={this.closeModal}
                        ref={(c) => (this.importResult = c)}
                    />
                    <UpdateServiceUserFeeResult
                        width={1200}
                        title={''}
                        importResult={this.state.selectId}
                        onCancel={this.closeModal}
                        ref={(c) => (this.updateServiceUserFeeResult = c)}
                    />
                    <ServiceUserReimbursedFailedResult
                        width={500}
                        title={'报销失败'}
                        importResult={this.state.selectId}
                        onCancel={this.closeModal}
                        ref={(c) => (this.serviceUserReimbursedFailedResult = c)}
                    />
                    <ServiceUserReimbursedPassBackFailedResult
                        width={500}
                        title={'审核退回'}
                        importResult={this.state.selectId}
                        onCancel={this.closeModal}
                        ref={(c) => (this.serviceUserReimbursedPassBackFailedResult = c)}
                    />
                </Spin>
            </>
        );
    }
}

export default function ServiceUserFinancialList() {
    return (
        <App selectedKeys={['financialAuditList']} openKeys="aierOphthalmology">
            <ServiceUserAddListClass/>
        </App>
    );
}
