import React, {Component} from 'react';
import {Button, Card, Form, FormInstance, message, Space, Spin} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {decryptData} from "../../common/crypto";
import dayjs from "dayjs";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {BoneDetailDataType, BoneDetailDataTypeDate} from "../common/BoneCommonType";
import ServiceUser from "../detail/ServiceUser";
import {buildBoneFileData} from "../common/BoneCommon";
import EditHospitalScreening from "../edit/EditHospitalScreening";


class BoneHospitalScreeningClass extends Component<{}, BoneDetailDataType> {

    formRef = React.createRef<FormInstance>();
    state: BoneDetailDataType = {
        ...BoneDetailDataTypeDate
    };

    componentDidMount() {
        this.setState({
            id: decryptData(common.getQuery('id')),
        }, () => {
            this.getData()
        })
    };

    getData() {

        const {id} = this.state;
        let url = `${API.bone.service_user.get_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            buildBoneFileData(data)
            console.log(data)
            this.setState({
                data: data,
                importLoading: false
            }, () => {
                const screeningDate = data.screening && data.screening.screeningDate ?
                    dayjs(data.screening.screeningDate) : undefined;
                this.formRef.current?.setFieldsValue({
                    ...data.screening,
                    screeningDate: screeningDate,
                })
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    submit = (values: any) => {
        console.log(values)
        const {data} = this.state;
        this.buildFile(values);
        this.buildTime(values);
        this.cleanField(values);
        values.serviceId = this.state.id;
        if (data.screening != null && data.screening.id > 0) {
            values.id = data.screening.id;
            this.update(values);
        } else {
            this.create(values);
        }

    }
    create = (values: any) => {
        Fetch.postJSON(API.bone.screening.do_save_fee, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    }

    update = (values: any) => {
        Fetch.postJSON(API.bone.screening.do_update_fee, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    }

    buildTime = (values: any) => {
        values.screeningDate = values.screeningDate.format('YYYY-MM-DD');
    }

    buildFile = (values: any) => {

        const files: any[] = [];
        this.addFiles(files, values.density, 1);
        this.addFiles(files, values.metabolism, 2);
        this.addFiles(files, values.invoice, 3);
        values.files = files;
    }

    cleanField = (values: any) => {
        delete values.density;
        delete values.metabolism;
        delete values.invoice;
    }

    addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                fileType: type,
                fileId: item.id
            })
        })
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <ServiceUser userDetail={data.detailVo}/>
                                    <Form
                                        onFinish={this.submit}
                                        ref={this.formRef}>
                                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                            <EditHospitalScreening form={this.formRef}/>
                                            <Card>
                                                <Space>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        size="middle"
                                                    >
                                                        保存
                                                    </Button>
                                                    <Button
                                                        size='middle'
                                                        onClick={this.cancel}
                                                    >
                                                        取消
                                                    </Button>
                                                </Space>
                                            </Card>
                                        </Space>
                                    </Form>
                                </>
                            ) : ('')}
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function BoneHospitalScreening() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <BoneHospitalScreeningClass/>
        </App>
    );
}
