import React, {Component} from 'react';
import {Button, Card, Space,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {defaultDhDetailData, DhDetailDataType} from "../common/DhDetailType";
import {decryptData} from "../../common/crypto";
import PatientInfo from "../patientDetail/PatientInfo";
import createModal from "../../common/createModal";
import DhPatientBookingModelImport from "./DhPatientBookingModel";
import BookingInfo from "../patientDetail/BookingInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

const DhPatientBookingModel = createModal(DhPatientBookingModelImport);


class DhPatientBookingClass extends Component<{}, DhDetailDataType> {

    state: DhDetailDataType = {
        id: decryptData(common.getQuery('id')),
        data: defaultDhDetailData.data,
    };
    private dhPatientBookingModelResult: any = null;

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id} = this.state;
        let url = `${API.dentistry.service_user.do_get_patient_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            this.setState({
                data: data,
            });
        });
    }

    cancel = () => {
        window.history.go(-1);
    }

    closeModal = () => {
    }

    onSaveSuccess = () => {
        window.history.go(-1);
    }

    openBookingModel = () => {

        this.dhPatientBookingModelResult.show()
    }

    render() {
        const {data, id} = this.state;
        return (
            <>
                <div>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <PatientInfo userDetail={data?.userDetail} addressDetail={data?.addressDetail}
                                         warVeteran={data.warVeteran}/>
                            <BookingInfo bookingVos={data?.bookingVos}/>
                        </Space>
                        <Card>
                            <Space>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={this.openBookingModel}
                                >
                                    预约
                                </Button>
                                <Button
                                    // type="primary"
                                    size="large"
                                    onClick={this.cancel}
                                >
                                    返回
                                </Button>
                            </Space>
                        </Card>
                    </div>
                </div>
                <DhPatientBookingModel
                    width={1300}
                    title={'预约医院'}
                    importResult={id}
                    onCancel={this.closeModal}
                    onSaveSuccess={this.onSaveSuccess}
                    ref={(c) => (this.dhPatientBookingModelResult = c)}
                />
            </>
        );
    }
}

export default function DhPatientBooking() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <DhPatientBookingClass/>
        </App>
    );
}
