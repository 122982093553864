import React, {useEffect, useState} from 'react';
import {
    Alert,
    Badge,
    BadgeProps,
    Button,
    Calendar,
    Card,
    Form,
    FormInstance,
    message,
    Modal,
    Radio,
    RadioChangeEvent,
    Select,
    Space
} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import styles from "./BookingModel.module.css";
import type {RangePickerProps} from 'antd/es/date-picker';
import dayjs, {Dayjs} from 'dayjs';
import FormItem from 'antd/lib/form/FormItem';

/**
 * 报销弹窗
 * @param props
 * @constructor
 */
type SelectType = {
    value: any,
    label: any
}

// type SchedulingType = {
//     schedulingDate: string,
//     planAmVisitors: number,
//     planPmVisitors: number,
//     actualAmVisitors: number,
//     actualPmVisitors: number,
// }
const {confirm} = Modal;
export default function DhPatientBookingModel(props: any) {

    const reason = props.importResult;
    const formRef = React.createRef<FormInstance>();
    const [value, setValue] = useState(() => dayjs());
    const [selectedValue, setSelectedValue] = useState(() => dayjs());
    const [hospitalSelect, setHospitalSelect] = useState<SelectType[]>([]);
    const [hospitalId, setHospitalId] = useState<String>("");
    const [scheduling, setScheduling] = useState<any>({});
    const [remainingTips, setRemainingTips] = useState<String>("");
    const [bookingTime, setBookingTime] = useState<number>();
    const [hospitalData, setHospitalData] = useState<any>({});

    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
        props.onSaveSuccess();
    }
    const submit = (values: any) => {
        confirm({
            title: `预约成功后暂不支持修改医院，是否确认预约？`,
            onOk() {
                values.serviceId = props.importResult
                values.bookingDate = values.bookingDate.format("YYYY-MM-DD");
                values.hospitalName = hospitalData[values.hospitalId].hospitalName
                Fetch.postJSON(API.dentistry.dh_service_user_booking.do_user_booking, values).then(() => {
                    message.success('提交成功');
                    ok();
                });
            }
        });
    }

    //TODO
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current < dayjs().startOf('month') || current >= dayjs().endOf('month'))
    };
    useEffect(() => {
        Fetch.get(`${API.dentistry.hospital.hospital_select}?serviceId=${props.importResult}`).then((data) => {
            let hospitalSelect: SelectType[] = [];
            data.forEach((item: any) => {
                let selectItem = {
                    value: item.hospitalId,
                    label: "医院名称：" + item.hospitalName + "---距离：" + (item.distance > 1000 ?
                        (item.distance / 1000).toFixed(2) + "千米" : item.distance + "米")
                };
                hospitalSelect.push(selectItem)
            })
            setHospitalSelect(hospitalSelect)
            const hospitalObject = data.reduce((result: any, item: any) => {
                const {hospitalId, ...rest} = item;
                result[hospitalId] = rest;
                return result;
            }, {});
            setHospitalData(hospitalObject)
        });
    }, [props.importResult]);

    useEffect(() => {
        if (!hospitalId) {
            return;
        }
        Fetch.get(`${API.dentistry.dh_hospital_scheduling.find_scheduling}?hospitalId=${hospitalId}`).then((data) => {

            const schedulingObject = data.reduce((result: any, item: any) => {
                const {schedulingDate, ...rest} = item;
                result[schedulingDate] = rest;
                return result;
            }, {});
            setScheduling(schedulingObject);
        });
    }, [hospitalId]);

    useEffect(() => {

        const time = bookingTime === 1 ? "上午" : "下午";
        const schedulingElement = scheduling[selectedValue.format("YYYY-MM-DD")];
        if (schedulingElement && bookingTime) {
            const number = bookingTime === 1 ? (schedulingElement.planAmVisitors -
                schedulingElement.actualAmVisitors) : (schedulingElement.planPmVisitors - schedulingElement.actualPmVisitors)
            let tips = `${time}剩余预约人数：${number}人`;
            setRemainingTips(tips);
        } else {
            setRemainingTips("");
        }
    }, [scheduling, selectedValue, bookingTime]);


    const getListData = (value: Dayjs, scheduling: any) => {
        let date = value.format("YYYY-MM-DD");
        let schedulingElement = scheduling[date];
        if (schedulingElement) {
            let listData = [
                {
                    type: schedulingElement.actualAmVisitors === schedulingElement.planAmVisitors ? 'error' : 'success',
                    content: '上午' + schedulingElement.planAmVisitors + '/' + schedulingElement.actualAmVisitors + '人'
                },
                {
                    type: schedulingElement.actualPmVisitors === schedulingElement.planPmVisitors ? 'error' : 'success',
                    content: '下午' + schedulingElement.planPmVisitors + '/' + schedulingElement.actualPmVisitors + '人'
                },
            ];
            return listData || [];
        }
        return [];
    };
    const dateCellRender = (value: Dayjs) => {
        const listData = getListData(value, scheduling);
        return (
            <ul className={styles.events}>
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge status={item.type as BadgeProps['status']} text={item.content}/>
                    </li>
                ))}
            </ul>
        );
    };

    const onSelect = (newValue: Dayjs) => {
        setValue(newValue);
        setSelectedValue(newValue);
    };

    const onRadioChange = (e: RadioChangeEvent) => {
        setBookingTime(e.target.value)
    }

    const selectChange = (e: string) => {
        setHospitalId(e);
    }
    return <>
        {props != '' ? (
            <>
                <Form onFinish={submit}
                      ref={formRef}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <Card size="small">
                            <FormItem
                                className={styles.mylabel}
                                name="hospitalId"
                                label="选择医院"
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Select
                                    style={{width: 500}}
                                    onChange={selectChange}
                                    options={hospitalSelect}
                                />
                            </FormItem>
                            <div>
                                <Alert message={`选择日期: ${selectedValue?.format('YYYY-MM-DD')}`}/>
                                {/*valuePropName*/}
                                <FormItem
                                    className={styles.mylabel}
                                    name="bookingDate"
                                    initialValue={dayjs()}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Calendar cellRender={dateCellRender}
                                              onSelect={onSelect}/>
                                </FormItem>
                                <FormItem
                                    className={styles.mylabel}
                                    name="bookingTime"
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Radio.Group onChange={onRadioChange}>
                                        <Radio.Button value={1}>上午</Radio.Button>
                                        <Radio.Button value={2}>下午</Radio.Button>
                                    </Radio.Group>
                                </FormItem>
                                {remainingTips}
                            </div>
                        </Card>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                保存
                            </Button>
                            <Button
                                size='large'
                                onClick={cancel}
                            >
                                取消
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </>
        ) : ('')}
    </>;
}
