export const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    // title: {
    //   width: '100%',
    //   fontSize: 36,
    //   fontWeight: 400,
    //   textAlign: 'center',
    //   padding: '20px 0',
    //   color: '#333',
    // },
    // subTitle: {
    //   fontSize: 20,
    //   fontWeight: 400,
    //   width: '100%',
    //   textAlign: 'left',
    //   color: '#333',
    // },
    // subTitle1: {
    //   fontSize: 20,
    //   fontWeight: 400,
    //   width: '100%',
    //   textAlign: 'left',
    //   color: '#333',
    //   padding: '30px 0',
    // },
    // link: {
    //   fontSize: 14,
    //   color: '#3FCFB8',
    //   textDecoration: 'none',
    // },
    // text: {
    //   fontSize: 14,
    //   width: '100%',
    //   textAlign: 'left',
    //   padding: '10px 0',
    //   color: '#333',
    // },
    // button: {
    //   backgroundColor: '#10a37f',
    //   color: '#fff',
    //   width: 142,
    //   height: 50,
    //   borderRadius: 5,
    //   border: 'none',
    //   cursor: 'pointer',
    // },
    // input: {
    //   width: 306,
    //   height: 50,
    //   border: '1px solid #10a37f',
    //   borderRadius: 5,
    //   marginRight: 20,
    //   paddingLeft: 10,
    // },
    // section: {
    //   width: 500,
    //   fontSize: 14,
    //   margin: '15px 0',
    //   alignItems: 'flex-start',
    // },
    // list: {
    //   fontSize: 14,
    //   margin: '10px 0',
    //   color: '#333',
    // },
    bottomSection: {
        position: 'absolute',
        right: 68,
        bottom: 24,
        textAlign: 'start',
        boxSizing: 'border-box',
        pointerEvents: 'none',
        userSelect: 'none'
    },
    // clickHint: {
    //   fontSize: 14,
    //   color: '#bcbcbc',
    //   width: '100%',
    //   marginBottom: -10
    // },
    // globalErrorContainer: {
    //   flexDirection: 'column',
    //   justifyContent: 'flex-start'
    // },
    // listItem: {
    //   lineHeight: '50px',
    //   color: '#666',
    //   width: '800px',
    //   fontSize: '14px'
    // },
    // listBottom: {
    //   padding: '20px 0',
    //   lineHeight: '40px',
    //   color: '#666',
    //   width: '500px',
    //   fontSize: '14px'
    // }
};