import React from "react";
import PatientInfo from "../../patientDetail/PatientInfo";
import {Space} from "antd";
import {DetailAndEdit} from "./DhEditAndDetailPageConfig";
import DhWarVeteranInfo from "../../patientDetail/DhWarVeteranInfo";
import DhAccountInfo from "../../patientDetail/DhAccountInfo";
import DiagnosisReportInfo from "../../patientDetail/DiagnosisReportInfo";
import EditAccount from "../../editPatient/EditAccount";
import EditFreeClinicsReport from "../../editPatient/EditFreeClinicsReport";
import EditServiceUserFee from "../../editPatient/EditServiceUserFee";
import ServiceUserFeeInfo from "../../patientDetail/ServiceUserFeeInfo";

export const HospitalEditConfig: React.FC<DetailAndEdit> = ({
                                                                form,
                                                                dictInfo,
                                                                data,
                                                            }) => {
    const module = [];
    const dhStatus = data.userDetail?.dhStatus;
    if (dhStatus === 104 || dhStatus === 105) {
        module.push(<PatientInfo key={"PatientInfo"} userDetail={data.userDetail}
                                 addressDetail={data.addressDetail} warVeteran={data.warVeteran}/>)
        module.push(<DhWarVeteranInfo key={"DhWarVeteranInfo"} warVeteran={data?.warVeteran}/>)
        module.push(<EditAccount key={"EditAccount"} form={form} dictInfo={dictInfo}
                                 fileEdit={true} initDate={data} required={false}/>)
    } else if (dhStatus === 107) {
        module.push(<PatientInfo key={"PatientInfo"} userDetail={data.userDetail}
                                 addressDetail={data.addressDetail} warVeteran={data.warVeteran}/>)
        module.push(<DhWarVeteranInfo key={"DhWarVeteranInfo"} warVeteran={data?.warVeteran}/>)
        module.push(<EditAccount key={"EditAccount"} form={form} dictInfo={dictInfo}
                                 fileEdit={true} initDate={data} required={false}/>)
        module.push(<EditFreeClinicsReport key={"EditAccount"} form={form} dictInfo={dictInfo}/>)

    } else if (dhStatus === 109 || dhStatus === 111) {
        module.push(<PatientInfo key={"PatientInfo"} userDetail={data.userDetail}
                                 addressDetail={data.addressDetail} warVeteran={data.warVeteran}/>)
        module.push(<DhWarVeteranInfo key={"DhWarVeteranInfo"} warVeteran={data?.warVeteran}/>)
        module.push(<EditAccount key={"EditAccount"} form={form} dictInfo={dictInfo}
                                 fileEdit={true} initDate={data} required={true}/>)
        module.push(<DiagnosisReportInfo report={data.report}/>)
        module.push(<EditServiceUserFee form={form} dictInfo={dictInfo} userDetail={data.userDetail}
                                        warVeteran={data.warVeteran}
        />)
    } else if (dhStatus === 113) {
        module.push(<PatientInfo key={"PatientInfo"} userDetail={data.userDetail}
                                 addressDetail={data.addressDetail} warVeteran={data.warVeteran}/>)
        module.push(<DhWarVeteranInfo key={"DhWarVeteranInfo"} warVeteran={data?.warVeteran}/>)
        module.push(<DhAccountInfo key={"DhAccountInfo"} account={data.account}/>)
        module.push(<DiagnosisReportInfo report={data.report}/>)
        module.push(<EditServiceUserFee form={form} dictInfo={dictInfo} userDetail={data.userDetail}
                                        warVeteran={data.warVeteran}
        />)
    } else if (dhStatus === 116 || dhStatus === 117) {
        module.push(<PatientInfo key={"PatientInfo"} userDetail={data.userDetail}
                                 addressDetail={data.addressDetail} warVeteran={data.warVeteran}/>)
        module.push(<DhWarVeteranInfo key={"DhWarVeteranInfo"} warVeteran={data?.warVeteran}/>)
        module.push(<EditAccount key={"EditAccount"} form={form} dictInfo={dictInfo}
                                 fileEdit={true} initDate={data} required={true}/>)
        module.push(<DiagnosisReportInfo report={data.report}/>)
        module.push(<ServiceUserFeeInfo serviceUserFee={data.serviceUserFee}
                                        warVeteran={data.warVeteran}
        />)
    }
    return <Space direction="vertical" size="middle" style={{display: 'flex'}}>{module}</Space>;
}

