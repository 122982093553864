import React from 'react';
import 'dayjs/locale/zh-cn';
import {Descriptions} from 'antd';

import styles from './login.module.css';
import {parseRole} from './UserDetail';
import UserTogetherList from './UserTogetherList';

export default function UserTogetherDonation(props: any) {
    const {donation} = props;
    return (
        <div className={styles.mycard}>
            <Descriptions column={4}>
                <Descriptions.Item span={2} label="微信昵称">
                    {donation.wxNickname}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="捐款时间">
                    {donation.createdDate}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="捐款金额">
                    {donation.amount}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="角色">
                    {parseRole(donation.roleId)}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="本次捐款获得积分">
                    {donation.credits}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="一起捐发起者">
                    {donation.publisher}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="目前筹款">
                    {donation.currentDonation}
                </Descriptions.Item>
            </Descriptions>
            <UserTogetherList togetherId={donation.togetherId}/>
        </div>
    );
}
