// import React, { Component, useEffect, useState } from 'react';

import 'dayjs/locale/zh-cn';
import {Tooltip} from 'antd';

import './detail.css';

export default function PersonCard(props: any) {
    const {datas, dictData} = props;
    let volunteerServiceTypeCodeall = '';
    if (datas.volunteerServiceTypeCode) {
        if (datas.volunteerServiceTypeCode.indexOf(',')) {
            let arr = datas.volunteerServiceTypeCode.split(',');
            let coder = arr.map((item: string) => {
                return dictData[
                    `volunteerType${datas.volunteerType}volunteerServiceType${item}`
                    ];
            });
            volunteerServiceTypeCodeall = coder.join(',');
        } else {
            volunteerServiceTypeCodeall =
                dictData[
                    `volunteerType${datas.volunteerType}volunteerServiceType${datas.volunteerServiceTypeCode}`
                    ];
        }
    }
    return (
        <div className="personBox">
            <div className="personTop">
                <div>
                    <img className="personHeader" src={datas.wxAvatar} alt="头像"/>
                </div>
                <div className="personNameBox">
          <span className="personName">
            {datas.name}{' '}
              <span
                  className={(datas.gender == 2 ? 'nv' : '') + ' sexImg'}
              ></span>
          </span>
                </div>
                <div className="company">{datas.workUnit}</div>
            </div>
            <div className="person-bottom">
                <div className="person-left">
                    <div className="item">
                        <div>民族</div>
                        <div className="title">
                            {dictData[`nationality${datas.nationalityCode}`]}
                        </div>
                    </div>

                    <div className="item">
                        <div>身高</div>
                        <div className="title number">
                            {datas.height ? datas.height + 'cm' : '--'}
                        </div>
                    </div>
                    <div className="item">
                        <div>志愿者类型</div>
                        <div className="title">
                            {datas.volunteerType ? '敬老志愿者' : '乐龄志愿者'}
                        </div>
                    </div>
                    <div className="item">
                        <div>当前积分</div>
                        <div className="title number">{datas.bonusPoints}</div>
                    </div>
                    <div className="item ">
                        <div>累计捐款</div>
                        <div className="title number">￥{datas.donations}</div>
                    </div>
                    <div className="item">
                        <div>累计服务时长</div>
                        <div className="title number">
                            {datas.duration} <span>小时</span>
                        </div>
                    </div>
                </div>

                <div className="person-right">
                    <div className="item">
                        <div>年龄</div>
                        <div className="title number">{datas.age}</div>
                    </div>
                    <div className="item">
                        <div>体重</div>
                        <div className="title number">
                            {datas.weight ? datas.weight + 'kg' : '--'}
                        </div>
                    </div>
                    <div className="item">
                        <div>志愿服务门类</div>
                        {/* volunteerType1volunteerServiceType01 */}
                        <div className="title service-type">
                            <Tooltip placement="top" title={volunteerServiceTypeCodeall}>
                                {volunteerServiceTypeCodeall}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="item">
                        <div>当前工分</div>
                        <div className="title number">{datas.workPoints}</div>
                    </div>
                    <div className="item">
                        <div>累计劝募</div>
                        <div className="title number">￥{datas.togetherDonations}</div>
                    </div>
                </div>
            </div>
            <div className="person-bottom">
                <div className="person-left">
                    <div className="item ">
                        <div>身份证号</div>
                        <div className="title number">{datas.idCardNumber}</div>
                    </div>
                    <div className="item">
                        <div>手机号码</div>
                        <div className="title number">
                            {datas.phone ? datas.phone : datas.wxPhone}
                        </div>
                    </div>
                    <div className="item">
                        <div>邮箱</div>
                        <div className="title number">
                            {datas.email ? datas.email : '--'}
                        </div>
                    </div>
                    <div className="item">
                        <div>居住地</div>
                        <div className="title">{datas.liveAddress}</div>
                    </div>
                    <div className="item">
                        <div>邮寄地址</div>
                        <div className="title">
                            {datas.postAddress ? datas.postAddress : '--'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
