import {getFromCache, removeFromCache, setToCache} from './ListCacheProvider';
import {getStackByPage, push} from './Stack';

export const SetListStorage = (data: any) => {

    const currentPage = window.location.hash.split('?')[0];
    push(currentPage, data);
    setToCache('search', data);
    setToCache('currentPage', currentPage);
};

export const GetListStorage = () => {

    const page = window.location.hash.split('?')[0];
    const currentPage = getFromCache('currentPage');
    const stackByPage = getStackByPage(page);
    if (stackByPage) {
        return stackByPage;
    }
    if (page === currentPage) {
        const search = getFromCache('search');
        return search ? search : {page: 0};
    } else {
        return {page: 0};
    }
};

export const GoOrg = () => {

    setToCache('insAdmin', getFromCache('search'));
};

export const BackOrg = () => {

    setToCache('search', getFromCache('insAdmin'));
    setToCache('currentPage', '#/institution');
    removeFromCache('insAdmin');
};

export const CleanListStorage = () => {

    removeFromCache('search');
};

