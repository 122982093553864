import React, {Component} from 'react';
import {Button, Form, Input, message, Radio} from 'antd';

import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './login.module.css';
import './login.remark.css';

const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const InputGroup = Input.Group;

export default class LoginOrganization extends Component<any> {
    state = {
        visible: false,
        value: '',
        text: '发送验证码',
        phone: '',
        showSet: false,
        preName: '',
        flag: false,
    };
    timer: any;

    componentDidMount() {

        if (this.props.list.length == 1) {
            if (this.props.userData.id && !this.state.flag) {
                // 跳转
                // this.gopage();

                this.setState({
                    flag: true,
                    preName: this.props.userData.username,
                });
                this.getData();
            }
        }
    }

    onChange = (e: any) => {
        this.setState({
            value: e.target.value,
            preName: e.target['data-name'],
        });
    };

    // 获取侧边栏
    getData = () => {
        let id =
            this.state.value === ''
                ? this.props.list[0].organizationId
                : this.state.value;
        Fetch.get(`${API.user.findUserMenu}?userOrganizationId=${id}`)
            .then((data) => {
                sessionStorage.token = data.accessToken;
                if (data.auditStatus === 1) {
                    //审核中

                } else if (data.auditStatus === 3) {
                    //审核不通过
                } else {
                    sessionStorage.token = data.accessToken;
                    // sessionStorage.category = data.category;
                    sessionStorage.orgName = data.orgName;
                    sessionStorage.buttons = JSON.stringify({
                        buttons: data.buttons,
                    });
                    sessionStorage.treevos = JSON.stringify({
                        arr: data.treeVos,
                    });
                    this.gopage(data.treeVos);
                }
            })
            .catch((err) => {

                if (err.code == 'U0001') {
                    this.setState({
                        showSet: true,
                    });
                }
            });
    };

    gopage = (datas: any) => {
        this.props.changeStaus(false);
        if (datas.length == 0) {
            message.error('您还没有访问权限，请联系管理员');
            return;
        }
        if (datas[0].routerUrl != '') {
            window.location.href = '/#' + datas[0].routerUrl;
        } else {
            window.location.href = '/#' + datas[0].child[0].routerUrl;
        }
    };

    // 更新老用户提交
    submit = (values: any) => {
        // category: "COUNTRY"
        let id =
            this.state.value === ''
                ? this.props.list[0].organizationId
                : this.state.value;

        Fetch.put(API.user.doUpdateOldUser, {...values, orgId: id}).then(
            (data) => {
                clearInterval(this.timer);
                this.setState({
                    text: '发送验证码',
                });
                sessionStorage.token = data.accessToken;
                sessionStorage.user = JSON.stringify(data.user);

                this.getData();

                // 保存登陆状态
                // sessionStorage.token = data.accessToken;
                // sessionStorage.user =  JSON.stringify(data.user);
                // sessionStorage.category = data.category;
                // this.props.changeStaus(true,data)
            }
        );
    };

    // 验证手机号是否正确
    checkPhone = () => {
        let reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
        let flag = reg.test(this.state.phone);
        return flag;
    };

    // 获取验证码
    sendCode = () => {
        if (this.state.text !== '发送验证码') {
            return;
        }
        if (this.checkPhone()) {
            this.codeChange();
            this.requireCode();
        } else {
            message.error('请输入正确的手机号');
        }
    };

    // 开启定时器
    codeChange = () => {
        let time = 59;
        this.timer = setInterval(() => {
            if (time === 0) {
                clearInterval(this.timer);
                this.setState({
                    text: '发送验证码',
                });
            } else {
                this.setState({
                    text: time + ' s',
                });
            }

            time--;
        }, 1000);
    };
    inptChange = (e: any) => {
        this.setState({
            phone: e.target.value,
        });
    };
    // 发送验证码
    requireCode = () => {
        Fetch.get(`${API.user.sendCode}?phone=${this.state.phone}&templateCode=REGISTERED`).then((data) => {
            message.success('发送成功！');
        });
    };

    render() {
        const {list} = this.props;
        const {visible, phone, text, showSet, preName} = this.state;
        return (
            <div className="remark">
                {list.length > 1 ? (
                    <div className="remark-content">
                        <div className="title">选择你想进入的后台</div>
                        <div className="content">
                            <RadioGroup
                                onChange={this.onChange}
                                value={
                                    this.state.value === '' && list.length
                                        ? list[0].organizationId
                                        : this.state.value
                                }
                            >
                                {list.map((item: any, index: number) => {
                                    return (
                                        <Radio
                                            className="item"
                                            key={index}
                                            value={item.organizationId}
                                            data-name={item.name}
                                        >
                                            {item.name}
                                        </Radio>
                                    );
                                })}
                            </RadioGroup>
                        </div>
                        <div className="footer">
                            <Button type="primary" onClick={this.getData}>
                                进入后台
                            </Button>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {/* 老数据清理 */}
                {showSet ? (
                    <div className="remark-content account-box">
                        <div className="title">设置账号和密码</div>
                        <div className="title-tip">
                            “乐龄志愿服务中心”管理后台账号体系现已升级，由多人共用一个账号改为一人一号，在下方设置你的账号和密码，之后使用新账号和密码进行登录。
                        </div>
                        <Form
                            onFinish={this.submit}
                            className={styles.form}
                            layout="vertical"
                        >
                            <FormItem labelCol={{span: 4}} label="原账号">
                                <Input
                                    disabled
                                    placeholder="原账号"
                                    value={preName}
                                    type="text"
                                />
                            </FormItem>
                            <FormItem
                                name="phone"
                                labelCol={{span: 4}}
                                label="新账号"
                                rules={[{required: true, message: '请输入新账号'}]}
                            >
                                <Input

                                    placeholder="请输入手机号"
                                    type="text"
                                    value={phone}
                                    onChange={this.inptChange}
                                />
                            </FormItem>
                            <FormItem
                                name="smsCode"
                                labelCol={{span: 4}}
                                label="验证码"
                                rules={[{required: true, message: '请输入验证码'}]}
                            >
                                <div className={styles.code_box}>
                                    <Input
                                        type="text"

                                        placeholder="验证码"
                                    />
                                    <span
                                        className={text !== '发送验证码' ? styles.active : ''}
                                        onClick={this.sendCode}
                                    >
                    {text}
                  </span>
                                </div>
                            </FormItem>
                            <FormItem
                                name="password"
                                labelCol={{span: 4}}
                                label="新密码"
                                rules={[{required: true, message: '请输入新密码'}]}
                            >
                                <Input placeholder="密码不能为空" type="password"/>
                            </FormItem>
                            <FormItem
                                name="username"
                                labelCol={{span: 4}}
                                label="真实姓名"
                                rules={[{required: true, message: '请输入真实姓名'}]}
                            >
                                <Input placeholder="真实姓名不能为空" type="text"/>
                            </FormItem>
                            <FormItem className={styles.btn_box + ' center'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.mybtn}
                                >
                                    进入后台
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}
