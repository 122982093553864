import React, {Component} from 'react';
import {Layout, Menu} from 'antd';
import {Link} from 'react-router-dom';

type FormProps = {
    selectedKey: string;
};

type FormState = {};

const {Sider} = Layout;

class UserSider extends Component<FormProps, FormState> {
    render() {
        let user = {roleId: -1};
        if (sessionStorage.user) {
            user = JSON.parse(sessionStorage.user);
        }
        let buttons = sessionStorage.getItem('buttons');
        //@ts-ignore
        let obj = JSON.parse(buttons);
        return (
            <Sider className="site-layout-background" width={200}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={[this.props.selectedKey]}
                    style={{height: '100%'}}
                >
                    <Menu.Item key="1">{getPath('基本设置')}</Menu.Item>
                    <Menu.Item key="2">
                        <Link to="/user/password">安全设置</Link>
                    </Menu.Item>
                    {/*<Menu.Item key="3">*/}
                    {/*    <Link to="/user/password">医院收款信息</Link>*/}
                    {/*</Menu.Item>*/}
                    {obj.buttons.ROLE_FUNDRAISING_QUALIFICATIONS && (
                        <Menu.Item key="3">
                            <Link to="/user/qualification">募捐资质</Link>
                        </Menu.Item>
                    )}
                </Menu>
            </Sider>
        );
    }
}

export function getPath(pathName: string) {
    let user = {roleId: -1};
    if (sessionStorage.user) {
        user = JSON.parse(sessionStorage.user);
    }

    if (user.roleId === 3) {
        return <Link to="/user/info">{pathName}</Link>;
    }
    return <Link to="/user/institution">{pathName}</Link>;
}

export default UserSider;
