import React, {useEffect, useState} from 'react';
import {Button, Card, Form, FormInstance, Input, message, Modal, Select, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';

/**
 * 报销弹窗
 * @param props
 * @constructor
 */
type SelectType = {
    value: any,
    label: any
}
const {confirm} = Modal;
export default function DhPatientBookingModel(props: any) {

    const formRef = React.createRef<FormInstance>();
    const [userSelect, setUserSelect] = useState<SelectType[]>([]);

    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        confirm({
            title: `是否提交数据`,
            onOk() {
                const targetUser = userSelect.find(user => user.value === values.userId);
                values.userName = targetUser?.label
                Fetch.postJSON(API.dentistry.dh_salesman.create, values).then(() => {
                    message.success('提交成功');
                    ok();
                });
            }
        })
    }


    useEffect(() => {
        Fetch.get(`${API.user.admin_users_select}`).then((data) => {

            setUserSelect(data)
        });
    }, []);

    const userChange = (e: any, e2: any) => {
    }

    return <>
        {props != '' ? (
            <>
                <Form onFinish={submit}
                      ref={formRef}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <Card size="small">
                            <FormItem
                                name="userId"
                                label="选择用户"
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Select
                                    style={{width: '100%'}}
                                    onChange={userChange}
                                    options={userSelect}
                                />
                            </FormItem>
                            <FormItem
                                name="seatUserId"
                                label="填写坐席"
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input/>
                            </FormItem>
                        </Card>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                保存
                            </Button>
                            <Button
                                size='large'
                                onClick={cancel}
                            >
                                取消
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </>
        ) : ('')}
    </>;
}
