import {Component} from 'react';
import {Breadcrumb, Button, message, Modal, Space, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {User} from './types';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import App from '../../App';

const confirm = Modal.confirm;

class Limits1 extends Component {
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        nickname: '',
        roleId: '',
        loading: true,
    };

    componentDidMount() {
        this.loadData();
    }

    addFn = () => {
        window.location.href = '/#/userlimitsedit';
    };
    editFn = (id: number) => {
        window.location.href = '/#/userlimitsedit?id=' + id;
    };
    viewFn = (id: number) => {
        window.location.href = `/#/userlimitsedit?id=${id}&see=1`;
    };
    delFn = (row: any) => {
        let that = this;
        confirm({
            title: `您是否确认要删除项目： “${row.name}”`,
            onOk() {
                Fetch.put(API.user.deletedRole, {id: row.id}).then(() => {
                    message.success('删除成功');
                    that.loadData();
                });
            },
        });
    };
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, nickname, roleId} = this.state;
        Fetch.get(`${API.user.limitsList}?page=${page}&size=${pageSize}`).then(
            (data) => {
                this.setState({
                    content: data.content,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages,
                    loading: false,
                });
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '角色名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '创建日期',
                dataIndex: 'createdDate',
                align: `center`,
            },

            {
                title: '操作',
                align: `center`,
                render: (text, record: any) => {
                    return (
                        <Space>
                            <Button
                                type="link"
                                onClick={() => {
                                    this.viewFn(record.id);
                                }}
                            >
                                查看
                            </Button>

                            {record.name == 'administrator' ? (
                                ''
                            ) : (
                                <>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            this.editFn(record.id);
                                        }}
                                    >
                                        编辑
                                    </Button>
                                    <Button
                                        type="link"
                                        danger
                                        onClick={() => {
                                            this.delFn(record);
                                        }}
                                    >
                                        删除
                                    </Button>
                                </>
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>权限管理</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <div>
                        <Button type="primary" onClick={this.addFn}>
                            新 增
                        </Button>
                    </div>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
            </>
        );
    }
}

export default function Limits() {
    return (
        <App selectedKeys={['limits']}>
            <Limits1/>
        </App>
    );
}
