import {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Input, Space, Table} from 'antd';

import {ColumnsType} from 'antd/es/table';

import Fetch from '../../common/FetchIt';
import API from '../../common/api';

import App from '../../../App';
import {AccountType} from './types';
import common from '../../common/common';
import {withRouter} from 'react-router';

class SelectTemplate1 extends Component {
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        account: null,
        title: '新建类别',
        loading: true,
        type1: common.getQuery('type'),
        name: '',
    };
    private modal: any = null;

    componentDidMount() {
        this.getData();
    }

    // 获取数据
    getData = () => {
        const {page, pageSize, type1, name} = this.state;
        Fetch.get(
            `${API.atl.active.selectList}?page=${page}&size=${pageSize}&type=${type1}&name=${name}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };
    // 选择模版
    select = (row: any) => {
        window.location.href = `/#/templatedetails/${row.id}/0/0?type=${this.state.type1}`;
    };
    search = () => {
        this.setState(
            {
                page: 0,
            },
            () => {
                this.getData();
            }
        );
    };
    changeName = (value: string) => {
        this.setState({
            name: value,
        });
    };

    // 下一页
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.getData();
                }
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;

        const columns: ColumnsType<AccountType> = [
            {
                title: '名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '类别',
                dataIndex: 'categoryName',
                align: `center`,
            },
            {
                title: '项目',
                dataIndex: 'projectName',
                align: `center`,
            },
            {
                title: '积分',
                dataIndex: 'credits',
                align: `center`,
                render: (text, record) => {
                    return text ? text : '-';
                },
            },
            {
                title: '工分',
                dataIndex: 'workPoints',
                align: `center`,
                render: (text, record) => {
                    return text ? text : '-';
                },
            },
            {
                title: '服务时长',
                dataIndex: 'duration',
                align: `center`,
                render: (text, record) => {
                    return text ? text : '-';
                },
            },

            {
                title: '操作',
                align: `center`,
                render: (text, record: any) => {
                    return (
                        <Button type="link" onClick={() => this.select(record)}>
                            选择此模版
                        </Button>
                    );
                },
            },
        ];

        const columns2: ColumnsType<AccountType> = [
            {
                title: '名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '类别',
                dataIndex: 'categoryName',
                align: `center`,
            },
            {
                title: '项目',
                dataIndex: 'projectName',
                align: `center`,
            },
            {
                title: '积分',
                dataIndex: 'credits',
                align: `center`,
                render: (text, record) => {
                    return text ? text : '-';
                },
            },
            {
                title: '工分',
                dataIndex: 'workPoints',
                align: `center`,
                render: (text, record) => {
                    return text ? text : '-';
                },
            },

            {
                title: '操作',
                align: `center`,
                render: (text, record: any) => {
                    return (
                        <Button type="link" onClick={() => this.select(record)}>
                            选择此模版
                        </Button>
                    );
                },
            },
        ];
        const {type1} = this.state;

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    {type1 === '1' ? (
                        <Breadcrumb.Item>
                            <a href="/#/gongyiActivity">公益活动</a>
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item>
                            <a href="/#/donationActivity">公益募捐</a>
                        </Breadcrumb.Item>
                    )}
                    <Breadcrumb.Item>选择模版</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space size="small">
                        <Input
                            placeholder="请输入活动名称"
                            value={this.state.name}
                            onChange={(e) => this.changeName(e.target.value)}
                        />
                        <Button onClick={this.search} type="primary">
                            搜索
                        </Button>
                    </Space>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey={(record) => {
                            return record.id;
                        }}
                        columns={type1 === '1' ? columns : columns2}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
            </>
        );
    }
}

export default withRouter(function SelectTemplate() {
    return (
        <>
            {common.getQuery('type') === '1' ? (
                <App selectedKeys={['gongyi']} openKeys="sub1">
                    <SelectTemplate1/>
                </App>
            ) : (
                <App selectedKeys={['donation']} openKeys="sub1">
                    <SelectTemplate1/>
                </App>
            )}
        </>
    );
});
