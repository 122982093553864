import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Checkbox, Col, Form, FormInstance, message, Row, Space} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import FormItem from 'antd/lib/form/FormItem';
import type {CheckboxChangeEvent} from 'antd/es/checkbox';

interface ModalType {
    provinceId: number,
    hospitalId: number,
    hide: any
}

const EditHospitalRoleModalClass: React.FC<ModalType> = (props) => {

    const [selectHospital, setSelectHospital] = useState<[{ value: number, label: string }]>([{value: 0, label: ''}]);
    const [viewRole, setViewRole] = useState<number[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [allViewIds, setAllViewIds] = useState<number[]>([]);
    const formRef = useRef<FormInstance>(null);

    useEffect(
        function () {
            Fetch.get(`${API.aier_hospital_role.select_hospital}?provinceId=${props.provinceId}`).then((data) => {
                const allViewIds: number[] = data.map((item: { value: number }) => item.value);
                setSelectHospital(data);
                setAllViewIds(allViewIds);
            });
        },
        [props.provinceId]
    );

    useEffect(
        function () {
            Fetch.get(`${API.hospital_view_role.find_hospital}?hospitalId=${props.hospitalId}`).then((data) => {

                if (formRef.current) {
                    const viewData = {viewIds: data}
                    formRef.current.setFieldsValue({
                        ...viewData,
                    });
                    onChangeCheckbox();
                }
            });
        },
        [selectHospital]
    );

    useEffect(
        function () {
            editCheckAll();
        },
        [viewRole]
    );

    const submit = (values: any) => {

        values.hospitalId = props.hospitalId;
        create(values);
    };

    const create = (values: any) => {
        Fetch.postJSON(API.aier_hospital_role.update_role, values).then(() => {
            message.success('提交成功');
            cancel();
        });
    }

    const cancel = () => {

        props.hide();
    }

    const editCheckAll = () => {

        setCheckAll(selectHospital.length === viewRole.length)
    }

    const onChangeCheckbox = () => {

        const fieldValue = formRef.current?.getFieldValue("viewIds");
        setViewRole(fieldValue)
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {

        const items = e.target.checked ? allViewIds : [];
        setViewRole(items)
        formRef.current?.setFieldsValue({
            ...{viewIds: items},
        });
    };
    const indeterminate = viewRole.length > 0 && viewRole.length < selectHospital.length;


    return (
        <>
            <div
                className="site-layout-background"
                style={{minHeight: 720}}
            >
                <div>
                    <Form
                        onFinish={submit}
                        ref={formRef}
                    >
                        <Card title="数据权限" size="small">
                            <Space direction="vertical">
                                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange}
                                          checked={checkAll}>
                                    全选
                                </Checkbox>
                                <FormItem
                                    name="viewIds"
                                    label="当前省医院"
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 15}}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Checkbox.Group style={{width: '100%', padding: '5px'}}
                                                    onChange={onChangeCheckbox}>
                                        <Row gutter={[16, 16]}>
                                            {selectHospital.map(item =>
                                                <Col span={8}>
                                                    <Checkbox value={item.value}>{item.label}</Checkbox>
                                                </Col>
                                            )}
                                        </Row>
                                    </Checkbox.Group>
                                </FormItem>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size="large"
                                        style={{marginRight: '15px'}}
                                        onClick={cancel}
                                    >
                                        取 消
                                    </Button>
                                </Space>
                            </Space>
                        </Card>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default EditHospitalRoleModalClass;
