//积分商城

import {Component} from 'react';
import {Breadcrumb, Button, Col, message, Modal, Row, Table} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/es/table';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import App from '../../App';
import MallModel from './mallModel';

const {confirm} = Modal;

class Mall1 extends Component {
    state = {
        content: [],
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        loading: true,
        visible: false,
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const {page, pageSize} = this.state;
        Fetch.get(`${API.goods.productList}?page=${page}&size=${pageSize}`).then(
            (data) => {
                this.setState({
                    content: data.content,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages,
                    loading: false,
                });
            }
        );
    };

    setVisible(flag: boolean) {
        this.setState({
            visible: flag,
        });
    }

    View = (row: any) => {
        window.location.href = '/#/goodsview?id=' + row.pid;
    };
    Add = () => {
        this.setState({
            visible: true,
        });
        this.modal.setState({
            page: 0,
            loading: true,
        });
        this.modal.loadData();
    };
    SortFn = (row: any, flag: number) => {
        // productSort;flag 1 上移 2 下移
        Fetch.put(`${API.goods.productSort}?id=${row.listId}&upDown=${flag}`).then(
            (data) => {
                message.success('操作成功');
                this.loadData();
            }
        );
    };
    DelFn = (row: any) => {
        const {totalPages, page, content} = this.state;
        let that = this;
        confirm({
            title: '您确定要删除下面的商品么?',
            icon: <ExclamationCircleOutlined/>,
            content: row.name,
            onOk() {
                Fetch.put(API.goods.productDel, {id: row.listId}).then((res) => {
                    message.success('删除成功！');
                    if (totalPages === page + 1 && content.length === 1 && page !== 0) {
                        that.setState({
                            page: page - 1,
                        });
                    } else {
                        that.loadData();
                    }
                });
            },
            onCancel() {
            },
        });
    };
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                loading: load,
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content, loading, visible} =
            this.state;
        const columns: ColumnsType<any> = [
            {
                title: '排序',
                width: 100,
                align: `center`,
                render: (text, row, index) => {
                    return index + page * pageSize + 1;
                },
            },
            {
                title: '封面图',
                dataIndex: 'coverUrl',
                align: `center`,
                render: (text: string) => {
                    return (
                        <img
                            src={API.domain + text}
                            alt="封面图"
                            style={{width: '50px', height: '30px'}}
                        />
                    );
                },
            },
            {
                title: '商品名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '兑换规则',
                dataIndex: 'convertibleDivide',
                align: `center`,
                render: (text: any, row: any) => {
                    return row.convertibleType === 1 ? text + '工分' : text + '积分';
                },
            },
            {
                title: '商品价值',
                dataIndex: 'worth',
                align: `center`,
                render: (text: any) => {
                    return text + '元';
                },
            },
            {
                title: '商品库存',
                dataIndex: 'stock',
                align: `center`,
            },
            {
                title: '供应商',
                dataIndex: 'supplierName',
                align: `center`,
            },
            {
                title: '商品编号',
                dataIndex: 'productCode',
                align: `center`,
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '操作',
                align: `center`,
                render: (text: any, record: any, index: number) => {
                    return (
                        <>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    this.View(record);
                                }}
                            >
                                查看
                            </Button>
                            {index === 0 && page === 0 ? (
                                ''
                            ) : (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        this.SortFn(record, 1);
                                    }}
                                >
                                    升序
                                </Button>
                            )}
                            {index + page * pageSize + 1 !== totalElements ? (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        this.SortFn(record, 2);
                                    }}
                                >
                                    降序
                                </Button>
                            ) : (
                                ''
                            )}
                            <Button
                                type="link"
                                danger
                                size="small"
                                onClick={() => {
                                    this.DelFn(record);
                                }}
                            >
                                删除
                            </Button>
                        </>
                    );
                },
            },
        ];
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>积分商城</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Row>
                        <Col span={6}></Col>
                        <Col span={18} style={{textAlign: 'right'}}>
                            <Button type="primary" onClick={this.Add}>
                                添 加
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        className="mgTop10"
                        bordered
                        rowKey={(record) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        loading={loading}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                    <MallModel
                        visible={visible}
                        setVisible={(flag: boolean) => {
                            this.setVisible(flag);
                        }}
                        loadData={this.loadData}
                        ref={(c) => {
                            this.modal = c;
                        }}
                    ></MallModel>
                </div>
            </>
        );
    }
}

export default function Mall() {
    return (
        <App selectedKeys={['goodsmall']} openKeys="PMallMenu">
            <Mall1/>
        </App>
    );
}
