import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';

const FormItem = Form.Item;

type FormProps = {
    account: any;
    hide: () => void;
    reload: () => void;
    flag: String;
};

type FormState = {};

class FormContent extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();
    state: FormState = {};
    submit = (values: any) => {
        if (values.id) {
            // 更改
            this.update(values);
        } else {
            // 增加
            let datas = {...values};
            delete datas.id;
            this.create(datas);
        }
    };

    create = (values: any) => {

        Fetch.postJSON(`${API.atl.category.create}`, values).then(() => {
            message.success('新增成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values: any) => {
        Fetch.put(API.atl.category.update, values).then(() => {
            message.success('修改成功');
            this.props.hide();
            this.props.reload();
        });

    };
    cancle = () => {
        this.props.hide();
    };

    render() {
        return (
            <div>
                <Form
                    initialValues={this.props.account}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem name="name" rules={[{required: true, message: '必填'}]}>
                        <Input placeholder="在此输入类别名称"/>
                    </FormItem>

                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button onClick={this.cancle} style={{marginRight: '15px'}}>
                            取 消
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确 定
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default FormContent;
