import api from "./api";

let start = false;

export function Upgrade() {

    if (start === false) {
        start = true;
        const interval = setInterval(() => {
            doCheckUpgrade(interval);
        }, 30000);
    }
}

export function doCheckUpgrade(interval) {

    const url = api.sys_config.admin_v;
    const newConfig = Object.assign(
        {},
        {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'SOURCE-REQUEST': 'ADMIN',
            },
        },
        {}
    );
    fetch(url, newConfig)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((result) => {
            if (result) {
                const v = result.data;
                let RefreshPage = sessionStorage.getItem('admin_v');
                if (RefreshPage !== v) {
                    window.location.reload();
                    sessionStorage.setItem("admin_v", v)
                }
            }
        })
        .catch((error) => {
            clearTime(interval)
        });
}

function clearTime(interval) {

    if (interval) {
        clearInterval(interval)
    }
}