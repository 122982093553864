import React from 'react';
import {Button, Divider, Input, Modal, Select, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BasePageType} from "../../common/listpage/BaseListPageType";
import {BaseDhPageDate} from "../../dhHospital/common/DhPageCommon";
import {Link} from "react-router-dom";
import encryptData from "../../common/crypto";
import {genderArr} from "../../cooperate/util/AierCommon";
import {addParams, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import CreateCertificateModelImport from "./CreateCertificateModel";
import createModal from "../../common/createModal";
import {RongTitleArr} from "../util/TroupeArtistUtil";
import {BoneRoleType} from "../util/TroupeArtistType";

const CreateCertificateModel = createModal(CreateCertificateModelImport);
type User = { name: string | '', msg: string | '', id: number | 0 };
const {confirm} = Modal;

type HospitalPatientType = BasePageType & {
    searchData: {
        name: undefined,
        phone: undefined,
        rongTitle: undefined,
        page: 0
    }
    btnQ: BoneRoleType,
    statusListArr: any[] | undefined
}
const defData: HospitalPatientType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        phone: undefined,
        rongTitle: undefined,
        page: 0
    },
    btnQ: {},
    statusListArr: []
}

class TroupGuruListListClass extends BaseListPage {

    state: HospitalPatientType = {
        ...defData,
        btnQ: {}
    };

    private createCertificateModelResult: any = null;

    componentDidMount() {
        // BoneSelectStatus([105, 106, 107], this)
        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.troup.guru.query_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("phone", searchData.phone, STRING_STR);
        url = url + addParams("rongTitle", searchData.rongTitle, STRING_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(defData.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {

        this.loadData();
    }
    createCertificate = (id: number) => {

        this.setState({
            selectId: id
        }, () => {
            this.createCertificateModelResult.show();
        })
    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content, selectId,
            statusListArr
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '艺术团荣称',
                dataIndex: 'rongTitle',
                align: `center`,
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render(value: any, record: any) {
                    return <>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {genderArr[value]}
                        </div>
                    </>;
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
            },
            {
                title: '生日',
                dataIndex: 'birthday',
                align: `center`,
            },
            {
                title: '原身份',
                dataIndex: 'identity',
                align: `center`,
            },

            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (value: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {(btnQ.ROLE_217001) && (
                                <Button type={"link"} onClick={() => this.createCertificate(record.id)}>
                                    设置发证时间
                                </Button>
                            )}
                            {(btnQ.ROLE_217002) && (
                                <Link to={`/troup/guru/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown}>
                            <Input
                                placeholder="姓名"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="手机号"
                                value={searchData.phone}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, phone: e.target.value})
                                }
                                }
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="艺术团荣称"
                                optionFilterProp="children"
                                options={RongTitleArr}
                                value={searchData.rongTitle}
                                allowClear={true}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, rongTitle: e})
                                }}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>

                        </Space>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <CreateCertificateModel
                    width={500}
                    title={'生成证书'}
                    id={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.createCertificateModelResult = c)}
                />
            </>
        );
    }

}

export default function TroupGuruListList() {

    return (

        <App selectedKeys={
            ['TroupList']
        }
             openKeys="TROUP">
            <TroupGuruListListClass/>
        </App>
    );
}
