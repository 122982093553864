import React, {useEffect, useState} from 'react';
import styles from "../financialaudit/ServiceUser.module.css";
import {Button, Form, FormInstance, message, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';

/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function ServiceUserReimbursed(props: any) {

    const reason = props.importResult;
    const formRef = React.createRef<FormInstance>();
    const [radioValue, setRadioValue] = useState(1);
    const [dictData, setDictData] = useState([{name: ''}]);
    const [submitButton, setSubmitButton] = useState(false);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        setSubmitButton(true)
        values.serviceUserId = reason;
        Fetch.postJSON(API.cooperateServiceUsersStatusV2.do_financial_reimbursement_failed, values).then(() => {
            message.success('提交成功');
            setSubmitButton(false)
            ok();
        });
    }
    useEffect(() => {
        Fetch.get(`${API.dict.find_by_types}?types=reimbursement_fail`).then((data) => {
            const dataArray = data['reimbursement_fail'];
            setDictData(dataArray)
        });
    }, []);

    return <>
        {dictData != null ? (
            <>
                <Form onFinish={submit}
                      ref={formRef}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="msg"
                            label="报销失败原因"
                            // wrapperCol={{span: 25}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <TextArea showCount rows={4} placeholder="拒绝原因" maxLength={50}/>
                        </FormItem>
                        {/*<Descriptions>*/}
                        {/*    <Descriptions.Item>*/}
                        {/*        <FormItem*/}
                        {/*            className={styles.mylabel}*/}
                        {/*            name="msg"*/}
                        {/*            label="快捷输入"*/}
                        {/*            rules={[{required: true, message: '必填'}]}*/}
                        {/*        >*/}
                        {/*            <Radio.Group value={1} className={styles.RadioPadding}>*/}
                        {/*                /!*<Space direction="vertical">*!/*/}
                        {/*                {dictData.map(item => (*/}
                        {/*                    // @ts-ignore*/}
                        {/*                    <Radio key={item.name} value={item.name}>*/}
                        {/*                        {item.name}*/}
                        {/*                    </Radio>*/}
                        {/*                ))}*/}
                        {/*                /!*</Space>*!/*/}
                        {/*            </Radio.Group>*/}
                        {/*        </FormItem>*/}
                        {/*    </Descriptions.Item>*/}
                        {/*</Descriptions>*/}
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={submitButton}
                                className={styles.postSave}
                            >
                                保存
                            </Button>
                            <Button
                                size='large'
                                onClick={cancel}
                            >
                                取消
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </>
        ) : ('')}
    </>;
}
