import React, {Component} from 'react';
import {Button, Card, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {defaultDhDetailData, DhDetailDataType} from "../common/DhDetailType";
import {decryptData} from "../../common/crypto";
import PatientInfo from "../patientDetail/PatientInfo";
import BookingInfo from "../patientDetail/BookingInfo";
import DhWarVeteranInfo from "../patientDetail/DhWarVeteranInfo";
import DhAccountInfo from "../patientDetail/DhAccountInfo";
import DiagnosisReportInfo from "../patientDetail/DiagnosisReportInfo";
import ServiceUserFeeInfo from "../patientDetail/ServiceUserFeeInfo";
import {dhShowFile} from "../common/edit/DhBackfillDataConfig";
import DhVoucher from "../patientDetail/DhVoucher";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

type HospitalPatientType = {
    importLoading: boolean
}

class HospitalPatientDetailClass extends Component<{}, DhDetailDataType & HospitalPatientType> {

    state: DhDetailDataType & HospitalPatientType = {
        id: decryptData(common.getQuery('id')),
        data: defaultDhDetailData.data,
        importLoading: true
    };

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id} = this.state;
        let url = `${API.dentistry.dh_service_user_booking.do_get_patient_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            dhShowFile(data).then(() => {
                this.setState({
                    data: data,
                    importLoading: false
                });
            })
        });
    }

    cancel = () => {
        window.history.back();
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <PatientInfo userDetail={data.userDetail} addressDetail={data.addressDetail}
                                                 warVeteran={data.warVeteran}/>
                                    <DhWarVeteranInfo warVeteran={data.warVeteran}/>
                                    <DhAccountInfo account={data.account}/>
                                    <DiagnosisReportInfo report={data.report}/>
                                    <ServiceUserFeeInfo serviceUserFee={data.serviceUserFee}
                                                        warVeteran={data.warVeteran}/>
                                    <BookingInfo bookingVos={data.bookingVos}/>
                                    <DhVoucher userDetail={data.userDetail}/>
                                </>
                            ) : ('')}
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function HospitalPatientDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <HospitalPatientDetailClass/>
        </App>
    );
}
