export const billStatus = {
    100: '待生成对账文件',
    101: '待盖章',
    102: '待提审',
    103: '待审核',
    104: '撤销',
    105: '审核通过', //红
    106: '审核退回',
    107: '打款中',
    108: '报销完成',
    109: '收据上传完成',
    110: '完成',
    111: '审核失败', //绿
}


export const billStatusArr = [
    {
        value: 100,
        label: '待生成对账文件',
    }, {
        value: 101,
        label: '待盖章',
    }, {
        value: 102,
        label: '待提审',
    }, {
        value: 103,
        label: '待审核',
    }, {
        value: 104,
        label: '撤销',
    }, {
        value: 105,
        label: '审核通过',
    }, {
        value: 106,
        label: '审核退回',
    }, {
        value: 107,
        label: '打款中',
    }, {
        value: 108,
        label: '报销完成',
    }, {
        value: 109,
        label: '收据上传完成',
    }, {
        value: 110,
        label: '完成',
    }, {
        value: 111,
        label: '审核失败',
    }]


export const billSelectStatus = (arr: any[], that: any) => {

    let temp: any[] = [];
    billStatusArr.forEach(item => {
        if (arr.indexOf(item.value) >= 0) {
            temp.push(item);
        }
    })
    that.setState({
        billShowStatusArr: temp
    })
};

export const addParams = (field: string, value: any, type: any) => {

    if (value) {
        return `&${field}=${value}`
    } else {
        if (type === NUMBER_STR) {
            return `&${field}=0`
        } else {
            return `&${field}=`
        }
    }
}

export const STRING_STR = 'string'

export const NUMBER_STR = 'number'
