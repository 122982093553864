import React, {useEffect, useState} from 'react';
import {Button, Card, Form, FormInstance, InputNumber, message, Space, Spin} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';

/**
 * 医院修改预约记录
 * @param props
 * @constructor
 */
type Scheduling = {
    hospitalId: number | 0,
    schedulingDate: string | null,
    planAmVisitors: number | 0,
    planPmVisitors: number | 0,
    actualAmVisitors: number | 0,
    actualPmVisitors: number | 0
}
export default function DhPatientBookingModel(props: any) {

    const formRef = React.createRef<FormInstance>();
    const [detailData, setDetailData] = useState<Scheduling>();
    const [spinningFlag, setSpinningFlag] = useState<boolean>(true);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.onCancel();
    }
    const submit = (values: any) => {
        values.id = props.importResult;
        setSaveLoading(true)
        Fetch.postJSON(API.dentistry.dh_hospital_scheduling.do_update_scheduling, values).then(() => {
            message.success('提交成功');
            ok();
        });
    }
    useEffect(() => {
        Fetch.get(`${API.dentistry.dh_hospital_scheduling.get_detail}?id=${props.importResult}`).then((data) => {
            setDetailData(data)
            setSpinningFlag(false)
        });
    }, [props.importResult]);

    useEffect(() => {
        formRef.current?.setFieldsValue({
            ...detailData
        });
    }, [detailData]);


    return <>
        {detailData && (
            <>
                <Spin tip="加载中..." spinning={spinningFlag}>
                    <Form onFinish={submit}
                          ref={formRef}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <Card size="small" title={detailData.schedulingDate}>
                                <Space align="center">
                                    <FormItem
                                        label="上午"
                                        name="planAmVisitors"
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <InputNumber min={detailData.actualAmVisitors} max={999999}/>
                                    </FormItem>
                                    <div
                                        style={{marginBottom: '24px'}}>上午已经预约：{detailData.actualAmVisitors}</div>
                                </Space>
                                <Space align="center">
                                    <FormItem
                                        label="下午"
                                        name="planPmVisitors"
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <InputNumber min={detailData.actualPmVisitors} max={999999}/>
                                    </FormItem>
                                    <div
                                        style={{marginBottom: '24px'}}>下午已经预约：{detailData.actualPmVisitors}</div>
                                </Space>
                            </Card>
                            <Space>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={saveLoading}
                                >
                                    保存
                                </Button>
                                <Button
                                    size='large'
                                    onClick={cancel}
                                >
                                    取消
                                </Button>
                            </Space>
                        </Space>
                    </Form>
                </Spin>
            </>
        )}
    </>;
}
