import {Button, Image} from 'antd';
import React, {useState} from 'react';
import styles from "./ViewFileList.module.css";
import {ViewFileListTypes} from "./ViewFileListTypes";
import api from "../api";
import FileViewer from './fullScreen/FileViewer';
import {IDocument} from 'doc-viewer';

const ViewFileListClass: React.FC<ViewFileListTypes> = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileData, setFileData] = useState<IDocument[] | { uri: any; fileName: any; }[]>([{uri: '', fileName: ''}]);
    const docs: IDocument[] | { uri: any; fileName: any; }[] = [];
    const [fileIndex, setFileIndex] = useState<number>(0);
    const openModal = (index: number) => {
        props.fileList.map((item: any) => {
            const data = {
                uri: item.url,
                fileName: item.name
            }
            docs.push(data)
        })
        setFileData(docs)
        setFileIndex(index)
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button disabled>{props.title}</Button>
            {props.fileList.map((item: any, index: any) => (
                <div className={styles.fileList} key={index}>
                    <span className={styles.box}>
                        <span className={styles.icon} onClick={() => openModal(index)}>
                            <Image
                                className={styles.img}
                                // // width={28}
                                // height={36}
                                src={item.url}
                                preview={false}
                                fallback={api.staticImg + "/admin/file-error.png"}
                            />
                        </span>
                        <div className={styles.fileName} onClick={() => openModal(index)}>{item.name}</div>
                    </span>
                </div>
            ))}
            <FileViewer
                isOpen={isModalOpen}
                onClose={closeModal}
                fileLists={fileData}
                fileIndex={fileIndex}
            />
        </>
    );
};

export default ViewFileListClass;
