import React from 'react';
import {Button, Divider, Input, Modal, Popover, Select, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BasePageType} from "../../common/listpage/BaseListPageType";
import {BoneRoleType, BoneStatusColorObj, BoneStatusObj} from "../common/BoneDictData";
import {BaseDhPageDate} from "../../dhHospital/common/DhPageCommon";
import {Link} from "react-router-dom";
import encryptData from "../../common/crypto";
import {DatabaseOutlined} from "@ant-design/icons";
import BoneRecordOperationsDetail from "../hospital/BoneRecordOperationsDetail";
import {genderArr} from "../../cooperate/util/AierCommon";
import {addParams, NUMBER_STR, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import {BoneSelectStatus} from "../common/BoneCommon";


type User = { name: string | '', msg: string | '', id: number | 0 };
const {confirm} = Modal;

type HospitalPatientType = BasePageType & {
    searchData: {
        name: undefined,
        phone: undefined,
        idCard: undefined,
        status: undefined,
        page: 0
    }
    btnQ: BoneRoleType,
    statusListArr: any[] | undefined
}
const defData: HospitalPatientType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        phone: undefined,
        idCard: undefined,
        status: undefined,
        page: 0
    },
    btnQ: {},
    statusListArr: []
}

class BoneServiceSubsidyListClass extends BaseListPage {

    state: HospitalPatientType = {
        ...defData,
        btnQ: {}
    };

    componentDidMount() {
        BoneSelectStatus([105, 106, 107], this)
        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.bone.service_user.subsidy_list}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("phone", searchData.phone, STRING_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(defData.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {

        this.loadData();
    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content, selectId,
            statusListArr
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render(value: any, record: any) {
                    return <>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {genderArr[value]}
                        </div>
                    </>;
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
            },
            // {
            //     title: '微信账号',
            //     dataIndex: 'wxAccount',
            //     align: `center`,
            // },
            {
                title: '患者状态',
                dataIndex: 'boneStatus',
                align: `center`,
                render(value: any, record: any) {
                    return <>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    color: BoneStatusColorObj[value]
                                }}>{BoneStatusObj[value]}</div>
                            <>
                                <Popover placement="left" title={record.name + "的操作记录"}
                                         content={<BoneRecordOperationsDetail serviceId={record.id}/>}>
                                    <DatabaseOutlined/>
                                </Popover>
                            </>
                        </div>
                    </>;
                },
            },
            {
                title: '报名时间',
                dataIndex: 'createdDate',
                align: `center`,
            },

            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (value: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>

                            {btnQ.ROLE_208002 && record.boneStatus === 105 && (
                                <Link to={`/bone/subsidy/audit?id=${encryptData(record.id + "")}`}>
                                    审核
                                </Link>
                            )}
                            {(btnQ.ROLE_208001) && (
                                <Link to={`/bone/hospital/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown}>
                            <Input
                                placeholder="姓名"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="手机号"
                                value={searchData.phone}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, phone: e.target.value})
                                }
                                }
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="状态"
                                optionFilterProp="children"
                                options={statusListArr}
                                value={searchData.status}
                                allowClear={true}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, status: e})
                                }}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>

                        </Space>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function BoneServiceSubsidyList() {

    return (

        <App selectedKeys={
            ['BoneSubsidyList']
        }
             openKeys="BONE">
            <BoneServiceSubsidyListClass/>
        </App>
    );
}
