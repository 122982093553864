import styles from '../import.module.css';
import {Button, Form, FormInstance, message, Select, Space} from "antd";
import API from '../api';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React from "react";
import Fetch from "../FetchIt";

export default function ImportResultModal(props: any) {

    const reason = props.importResult;
    const formRef = React.createRef<FormInstance>();
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        values.serviceUserId = reason.id;
        Fetch.putJSON(API.cooperateServiceUsersStatusV2.update_financial_audits_refuse, values).then(() => {
            message.success('审核成功');
            window.location.href = '/#/financialAuditList/auditList';
        });
    }

    const quickSelect = (e: any) => {

        formRef.current?.setFieldValue("msg", e);
    }

    return <>
        <div className={styles.constant}>
            <Form
                onFinish={submit}
                ref={formRef}
            >
                <FormItem
                    className={styles.mylabel}
                    name="select"
                    label=""
                    wrapperCol={{span: 25}}
                >
                    <Select
                        showSearch
                        style={{width: '100%'}}
                        placeholder="快速选择"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={[
                            {
                                value: '费用信息眼别与手术救治同意书不一致',
                                label: '费用信息眼别与手术救治同意书不一致',
                            },
                            {
                                value: '手术救治同意书/住院记录不完整',
                                label: '手术救治同意书/住院记录不完整',
                            },
                            {
                                value: '请上传诊断证明',
                                label: '请上传诊断证明',
                            },
                            {
                                value: '就诊材料不是该患者的',
                                label: '就诊材料不是该患者的',
                            },
                            {
                                value: '费用信息金额与发票金额不一致',
                                label: '费用信息金额与发票金额不一致',
                            }, {
                                value: '承诺书亲属关系勾选错误',
                                label: '承诺书亲属关系勾选错误',
                            }, {
                                value: '诊断证明未盖章',
                                label: '诊断证明未盖章',
                            }
                        ]}
                        onChange={quickSelect}
                    />
                </FormItem>
                <FormItem
                    className={styles.mylabel}
                    name="msg"
                    label="拒绝原因"
                    wrapperCol={{span: 25}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <TextArea showCount rows={4} placeholder="拒绝原因" maxLength={50}/>
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.backBtn}
                    >
                        保存
                    </Button>
                    <Button
                        className={styles.screeningSave}
                        style={{marginRight: '15px'}}
                        onClick={ok}
                    >
                        取 消
                    </Button>
                </Space>
            </Form>
        </div>
    </>;
}
