import FileUploader from './FileUploader';

/**
 * 在使用form表单回填时，参数
 *  name="stampBill"
 *  valuePropName="fileList"
 *  尤为重要
 *  其中valuePropName必须等于fileList 否则无法回填
 */
export default FileUploader;
