import {getFromCache, removeFromCache, setToCache} from './ListCacheProvider';

let stack: any[] = [];

let AIER_PAGE_LIST = ['#/monthlyStatistics/list', '#/serviceUserRoster/list']

const stackData = getFromCache('stack');
if (stackData) {
    stack = stackData;
}

export function push(page: string, item: any) {

    const stack1 = getStack();
    let index = -1;
    for (let i = 0; i < stack1.length; i++) {
        const check = stack1[i].pageUrl === page
        if (check) {
            index = i;
            break;
        }
    }
    if (index >= 0) {
        // 更新
        item.pageUrl = page
        stack[index] = item
        setToCache('stack', stack);
        return;
    }
    if (AIER_PAGE_LIST.indexOf(page) < 0) {
        clear()
        return;
    }
    item.pageUrl = page
    stack.push(item);
    setToCache('stack', stack);
}

export function pop() {
    stack.pop();
    setToCache('stack', stack);
}

export function clear() {
    stack = [];
    removeFromCache('stack');
}

export function getStack() {
    return stack;
}

export function getStackByPage(page: string) {

    const stack1 = getStack().copyWithin(0, stack.length);
    let item = null;
    for (let i = stack1.length - 1; i >= 0; i--) {
        const check = stack1[i].pageUrl === page
        if (check) {
            item = stack1[i];
            break;
        } else {
            if (stack1.length > 1) {
                pop();
            }
        }
    }
    return item;
}
