import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message, Select} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';

const FormItem = Form.Item;
const Option = Select.Option;
type FormProps = {
    account: any;
    hide: () => void;
    reload: () => void;
    flag: String;
    roleList: any;
};

type FormState = {};

class accountForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();

    state: FormState = {};

    submit = (values: any) => {
        if (values.id) {
            // 更改
            this.update(values);
        } else {
            // 增加
            let datas = {...values};
            delete datas.id;
            this.create(datas);
        }
    };

    create = (values: any) => {
        Fetch.postJSON(`${API.account.create}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values: any) => {
        // let values1 = { ...values };
        // delete values1.id;

        Fetch.put(API.account.update, values).then(() => {
            message.success('保存成功');
            this.props.hide();
            this.props.reload();
        });
    };
    cancle = () => {
        this.props.hide();
    };

    render() {
        return (
            <div>
                <Form
                    initialValues={this.props.account}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    {/* active: number | ''; username: String | ''; phone: number | ''; */}
                    <FormItem
                        name="phone"
                        label="账号名"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input
                            placeholder="请输入对方的手机号"
                            disabled={this.props.flag !== '新建账号' ? true : false}
                        />
                    </FormItem>
                    {/*{this.props.flag === '新建账号' ? (*/}
                    {/*    <FormItem*/}
                    {/*        name="password"*/}
                    {/*        label="密码"*/}
                    {/*        labelCol={{span: 4}}*/}
                    {/*        wrapperCol={{span: 20}}*/}
                    {/*        rules={[{required: true, message: '必填'}]}*/}
                    {/*    >*/}
                    {/*        <Input placeholder="格式：字母/数字/字母+数字，不少于8个字符"/>*/}
                    {/*    </FormItem>*/}
                    {/*) : (*/}
                    {/*    ''*/}
                    {/*)}*/}
                    <FormItem
                        name="username"
                        label="真实姓名"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input
                            placeholder="请输入对方的真实姓名"
                            disabled={this.props.flag === '查看账号' ? true : false}
                        />
                    </FormItem>
                    {(this.props.account && this.props.account.orgRoleName) ==
                    '负责人' ? (
                        <FormItem
                            name="orgRoleId"
                            label="角色"
                            labelCol={{span: 4}}
                            wrapperCol={{span: 20}}
                        >
                            负责人
                        </FormItem>
                    ) : (
                        <FormItem
                            name="orgRoleId"
                            label="角色"
                            labelCol={{span: 4}}
                            wrapperCol={{span: 20}}
                            rules={[{required: true, message: '角色不能为空'}]}
                        >
                            <Select
                                placeholder="请选择角色"
                                allowClear
                                disabled={this.props.flag === '查看账号' ? true : false}
                            >
                                {this.props.roleList.map((item: any) => {
                                    return <Option value={item.id}>{item.name}</Option>;
                                })}
                            </Select>
                        </FormItem>
                    )}

                    {this.props.flag !== '查看账号' ? (
                        <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                            <Button onClick={this.cancle} style={{marginRight: '15px'}}>
                                取 消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                提 交
                            </Button>
                        </FormItem>
                    ) : (
                        <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                            <Button type="primary" onClick={this.cancle}>
                                确 定
                            </Button>
                        </FormItem>
                    )}
                </Form>
            </div>
        );
    }
}

export default accountForm;
