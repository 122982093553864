import {useEffect, useState} from 'react';
import './App.scss';
import {styles} from './styles';
import API from "../api";
import Fetch from "../FetchIt";


const statusMap = {
    offline: '已下线',
    disconnect: '网络断开，重连中',
    free: '空闲中',
    busy: '忙碌中',
    rest: '小休中',
    countdown: '话后倒计时',
    arrange: '话后整理中',
    notReady: '示忙中',
};

const errorFieldMap = {
    'InvalidParameterValue.InstanceNotExist': 'sdkAppId',
    'InvalidParameterValue.AccountNotExist': 'userId',
    'AuthFailure.SignatureFailure': 'secretKey或secretId',
    'AuthFailure.SecretIdNotFound': 'secretId',
};

export const EndStatus = {
    0: '错误',
    1: '正常结束',
    2: '未接通',
    17: '坐席未接',
    100: '黑名单',
    101: '坐席转接',
    102: 'IVR 期间用户放弃',
    103: '会话排队期间用户放弃',
    104: '会话振铃期间用户放弃',
    105: '无坐席在线',
    106: '非工作时间',
    107: 'IVR后直接结束',
    201: '未知状态',
    202: '未接听',
    203: '拒接挂断',
    204: '关机',
    205: '空号',
    206: '通话中（或用户挂断）',
    207: '欠费',
    208: '运营商线路异常',
    209: '主叫取消',
    210: '不在服务区',
}

function AppTest() {

    const [show, setShow] = useState(false);
    const [status, setStatus] = useState('');
    const [seat, setSeat] = useState('');

    const injectTCCC = ({token, sdkAppId, userId, sdkUrl}) => {
        const scriptDom = document.createElement('script');
        scriptDom.setAttribute('crossorigin', 'anonymous');
        scriptDom.dataset.token = token;
        scriptDom.dataset.sdkAppId = sdkAppId;
        scriptDom.dataset.userid = userId;
        scriptDom.src = sdkUrl;
        document.body.appendChild(scriptDom);
        scriptDom.addEventListener('load', () => {
            // ready事件必须监听，否则容易发生tccc不存在的错误，所有呼入呼出的逻辑必须在ready事件触发后才可以调用
            window.tccc.on('ready', () => {
                // 以下为Demo逻辑，非业务必须。业务代码主要实现都在这个部分
                const status = window.tccc.Agent.getStatus();
                setStatus(status);
                setSeat(userId);
            });
            // 以下为Demo逻辑，非接入必须
            setInterval(() => {
                const status = window.tccc.Agent.getStatus();
                setStatus(status);
            }, 200);
        });
    };

    useEffect(() => {

        if (window.tccc) {
            return
        }
        // 获取Token的方法必须在页面初始化时第一优先级调用
        Fetch.get(API.dentistry.dh_salesman.tccc_get_token).then((data) => {
            if (data) {
                setShow(true)
                injectTCCC({
                    token: data.token,
                    userId: data.userId,
                    sdkUrl: data.sdkURL,
                    sdkAppId: data.sdkAppId,
                });
            }
        }).catch((error) => {
            console.error(`获取Token失败：${error.message}`);
        });
    }, []);

    return (
        <>
            {show === true ? (
                <div style={styles.container} className='sdk-demo'>
                    <section style={styles.bottomSection}/>
                </div>
            ) : ('')}
        </>
    )
}

export default AppTest;
