import {Component} from 'react';
import {AutoComplete, Button, Form, Input, message, Space} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './supplier.module.css';
import {Express} from './types';

const FormItem = Form.Item;

type FormProps = {
    data: Express;
    id: number;
    hide: () => void;
    reload: () => void;
    loadData: () => void;
};

type FormState = {
    provinceId: number | '';
    cityId: number | '';
    options: any | [];
    listData: any | [];
};

class ExpressFrom extends Component<FormProps, FormState> {
    state: FormState = {
        provinceId: '',
        cityId: '',
        options: [],
        listData: [],
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        // express_select;
        let that = this;
        Fetch.get(API.goods.express_select).then((data) => {
            that.setState({listData: data}, () => {
                this.onSearch('');
            });
        });
    };
    onSelect = (data: string) => {

    };
    onChange = (data: string) => {

    };
    onSearch = (searchText: string) => {
        // searchText
        const {listData} = this.state;
        let options = [];
        for (let i = 0; i < listData.length; i++) {
            if (listData[i].text.indexOf(searchText) != -1) {
                options.push(listData[i].text);
            }
        }

        this.setState({
            options: options,
        });
    };
    submit = (values: Express) => {

        let that = this;
        let datas = {...values, orderId: that.props.id};
        const {listData} = this.state;
        let expressCode;
        listData.map((item: any, index: number) => {
            if (item.text == values.expressName) {

                expressCode = item.value;
            }
        });

        if (!expressCode) {
            message.error('请在下拉菜单中选择相应的快递');
            return;
        }
        datas.expressCode = expressCode;
        this.update(datas);
    };

    update = (values: Express) => {
        Fetch.postJSON(`${API.goods.shipments}`, values).then(() => {
            message.success('发货成功');
            this.props.loadData();
            this.props.hide();
            this.props.reload();
        });
    };
    cancel = () => {
        this.props.hide();
    };

    render() {
        const {options} = this.state;
        return (
            <div className={styles.container}>
                <Form onFinish={this.submit}>
                    <FormItem
                        className={styles.mylabel}
                        name="expressName"
                        label="物流公司"
                        labelCol={{span: 6}}
                        wrapperCol={{span: 14}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <AutoComplete
                            dataSource={options}
                            onSelect={this.onSelect}
                            onSearch={this.onSearch}
                            placeholder="输入物流公司"
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="expressNumber"
                        label="物流单号"
                        labelCol={{span: 6}}
                        wrapperCol={{span: 14}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input placeholder="在此输入物流单号"/>
                    </FormItem>

                    <FormItem className="center">
                        <Space className={styles.mybtn}>
                            <Button
                                size="large"
                                className={styles.mybtnItem}
                                onClick={this.cancel}
                            >
                                取 消
                            </Button>
                            <Button
                                className={styles.mybtnItem}
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                确 定
                            </Button>
                        </Space>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default ExpressFrom;

function listData(listData: any, data: any) {
    throw new Error('Function not implemented.');
}
