import React, {RefObject, useEffect, useState} from 'react';
import {AutoComplete, Card, Col, FormInstance, Input, InputNumber, message, Radio, Row, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import {DictObjectType, registerType} from "../common/DhDictData";
import DhZoneSelect from "../patientApplication/DhZoneSelect";
import {getIdNumberRole, PHONE_NUMBER} from "../../common/VerifyRegex";


type EditBasicInfoType = DictObjectType & {
    form: RefObject<FormInstance>;
    taskId: number | undefined,
    setAddressSelect: any,
    isEdit: boolean
    data: { cityId: number, provinceId: number, lng: number | null, lat: number | null },
    areaStr: string | ''
};

type TaskDataType = {
    createdDate: string;
    registerType: number;
};
export default function EditBasicInfo(props: EditBasicInfoType) {

    const [taskId, setTaskId] = useState(0);
    const [taskData, setTaskData] = useState<TaskDataType>(
        {createdDate: '', registerType: 0});
    const [areaStr, setAreaStr] = useState<string>('');
    const [addressSelect, setAddressSelect] = useState<[] | undefined>([]);
    const [searchTimeout, setSearchTimeout] = useState<any>([]);
    const [locationChange, setLocationChange] = useState<boolean>(false);
    const [oldIdNumber, setOldIdNumber] = useState<string>();
    const [idNumberChange, setIdNumberChange] = useState<boolean>(false);
    const [data, setData] =
        useState<EditBasicInfoType['data']>({cityId: 0, provinceId: 0, lng: 0, lat: 0});

    useEffect(() => {

        let serviceIdentity = props.form.current?.getFieldValue("serviceIdentity");
        if (!serviceIdentity) {
            props.form.current?.setFieldsValue({
                serviceIdentity: '1'
            });
        }
        if (props.isEdit) {
            setData(props.data)
            setAreaStr(props.areaStr)
        } else {
            setData({cityId: 0, provinceId: 0, lng: 0, lat: 0})
        }
    }, [])

    useEffect(() => {

        props.setAddressSelect(addressSelect)
    }, [addressSelect]);

    const searchAddr = (addr: string) => {

        if (!areaStr || !areaStr.trim()) {
            message.error('请选择省市区');
            return
        }
        if (addr && addr.trim().length > 0) {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
            setAddressSelect([])
            setSearchTimeout(setTimeout(() => {
                doSearch(addr)
            }, 600))
        }
    }

    const locationAddrSelect = (e: string) => {
        const addr = e.split(' ')[1];
        props.form.current?.setFieldsValue({
            addressDetail: addr,
        });
    }

    const doSearch = (addr: string) => {

        Fetch.get(
            `${API.location2}?keyword=${addr.trim()}&region=${
                areaStr
            }`
        ).then((data) => {
            const result = JSON.parse(data);
            if (result.status === 0 && result.data && result.data.length > 0) {
                const options = result.data.map((item: any) => {
                    return {
                        label: item.title + ' ' + item.address,
                        value: item.title + ' ' + item.address,
                        location: item.location,
                    };
                });
                setLocationChange(true)
                setAddressSelect(options)
            } else {
                message.error("没有查询到地址信息，请检查省市区信息。")
            }
        });
    }

    const setAreaStrFun = (val: string) => {

        setAreaStr(val);
        if (!val) {
            setAddressSelect(undefined)
            props.form.current?.setFieldsValue({
                locationAddr: '',
            });
        }
    };
    const nameChange = (val: any) => {

        const paymentMethod = props.form.current?.getFieldValue('paymentMethod');
        if (paymentMethod && paymentMethod === '1') {
            props.form.current?.setFieldsValue({
                accountName: val.target.value,
            });
        }
    }

    const calculateAgeAndGender = (e: any) => {
        setIdNumberChange(true)
        const idNumber = e.target.value;
        if (idNumber.length !== 18) {
            props.form.current?.setFieldsValue({
                age: undefined,
                gender: undefined,
            });
        } else {
            // 假设身份证号的格式为前6位是出生日期（YYYYmmdd），第17位是性别（奇数为男，偶数为女）
            const birthDate = idNumber.substring(6, 14);
            const genderDigit = parseInt(idNumber.charAt(16), 10);

            // 计算年龄
            const today = new Date();
            const birthYear = parseInt(birthDate.substring(0, 4), 10);
            const birthMonth = parseInt(birthDate.substring(4, 6), 10);
            const birthDay = parseInt(birthDate.substring(6, 8), 10);

            let calculatedAge = today.getFullYear() - birthYear;
            if (
                today.getMonth() < birthMonth ||
                (today.getMonth() === birthMonth && today.getDate() < birthDay)
            ) {
                calculatedAge--;
            }
            // 确定性别
            const calculatedGender = genderDigit % 2 === 1 ? 1 : 2;
            if (calculatedAge < 0 || calculatedAge > 150) {
                props.form.current?.setFieldsValue({
                    age: undefined,
                    gender: undefined,
                });
            } else {
                props.form.current?.setFieldsValue({
                    age: calculatedAge,
                    gender: calculatedGender,
                });
            }
        }
    };

    const handleIdNumberFocus = (e: any) => {

        const fieldName = e.target.id;
        if (!props.isEdit) {
            return
        }
        const value = e.target.value;
        if (!oldIdNumber) {
            setOldIdNumber(value)
        }
        if (value.includes('*')) {
            // 清空带有星号的内容
            props.form.current?.setFieldsValue({
                [fieldName]: undefined,
            });
        }
    }

    const handleIdNumberBlur = (e: any) => {

        const fieldName = e.target.id;
        if (!props.isEdit) {
            return
        }
        const value = e.target.value;
        if (!value) {
            //重置修改状态
            setIdNumberChange(false)
            // 如果失去焦点时为空，则回填原来的数据
            props.form.current?.setFieldsValue({
                [fieldName]: oldIdNumber,
            })
        }
    }

    return (
        <>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <Card size="small"
                      title={`基本信息`}
                      extra={
                          <>
                              {taskId ? (
                                  <div>
                                      用户填写时间: {taskData.createdDate},
                                      填写平台: {registerType[taskData.registerType]}
                                  </div>
                              ) : ('')}
                          </>
                      }
                >
                    <Row gutter={20}>
                        <Col span={10}>
                            <FormItem
                                name="name"
                                label="姓名"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input style={{width: '100%'}} onChange={nameChange} placeholder="姓名"/>
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            {/*<Tooltip*/}
                            {/*    title={"填写身份证号后会自动带出"}*/}
                            {/*    placement="topLeft"*/}
                            {/*>*/}
                            <FormItem
                                name="age"
                                label="年龄"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                // rules={[{required: true, message: '必填'}]}
                            >
                                <Input style={{width: '100%'}} placeholder="年龄"/>
                            </FormItem>
                            {/*</Tooltip>*/}
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            {/*<Tooltip*/}
                            {/*    title={"填写身份证号后会自动带出"}*/}
                            {/*    placement="topLeft"*/}
                            {/*>*/}
                            <FormItem
                                name="gender"
                                label="性别"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Radio.Group>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={2}>女</Radio>
                                </Radio.Group>
                            </FormItem>
                            {/*</Tooltip>*/}
                        </Col>
                        <Col span={10}>
                            <FormItem
                                name="phone"
                                label="联系电话"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                rules={[{required: true, message: '必填'}, PHONE_NUMBER]}
                            >
                                <Input style={{width: '100%'}}
                                       placeholder="联系电话"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <FormItem
                                name="idNumber"
                                label="身份证号"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                rules={getIdNumberRole(idNumberChange)}
                            >
                                <Input style={{width: '100%'}}
                                       onFocus={handleIdNumberFocus}
                                       onBlur={handleIdNumberBlur}
                                       placeholder="身份证号" onChange={calculateAgeAndGender}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <FormItem
                                name="tempArea"
                                label="居住省份"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                            >
                                <DhZoneSelect
                                    setAreaStr={setAreaStrFun}
                                    form={props.form}
                                    loadData={{
                                        provinceId: data.provinceId,
                                        cityId: data.cityId
                                    }}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <FormItem
                                name="locationAddr"
                                label="定位地址"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                // rules={[{required: true, message: '必填'}]}
                            >
                                <AutoComplete
                                    style={{width: '100%'}}
                                    options={addressSelect}
                                    onSearch={searchAddr}
                                    // disabled={!areaStr}
                                    onSelect={locationAddrSelect}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <FormItem
                                name="addressDetail"
                                label="详细地址"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                // rules={[{required: true, message: '必填'}]}
                            >
                                <Input style={{width: '100%'}}
                                       placeholder="详细地址"/>
                            </FormItem>
                        </Col>
                    </Row>
                </Card>
                <Card size="small"
                      title={`病情信息`}
                      extra={<></>}
                >
                    <Row gutter={20}>
                        <Col span={10}>
                            <FormItem
                                name="toothNum"
                                label="预计缺牙颗数"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 15}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <InputNumber style={{width: '100%'}} min={0} max={50} defaultValue={0}
                                             placeholder="预计缺牙颗数"/>
                            </FormItem>
                        </Col>
                    </Row>
                </Card>
            </Space>
        </>
    );
}
