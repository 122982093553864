import React, {Component} from 'react';
import {Breadcrumb, Button, Checkbox, Form, FormInstance, message, Select,} from 'antd';
import {Link} from 'react-router-dom';
import styles from './supplier.module.css';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import './goodsview.css';

import App from '../../App';

const {Option} = Select;

const FormItem = Form.Item;

class GoodsView1 extends Component {
    formRef = React.createRef<FormInstance>();
    state = {
        data: {convertibleType: 1},
        supllerList: [],
        id: 0,
        operationalActivitiesSelect: [],
        productLabels: [],
        keyString: ""
    };

    componentDidMount() {
        const id = common.getQuery('id');
        if (id) {
            this.setState({id}, () => {
                this.getData();
            });
        }
        this.getOperationalActivitiesSelect();
        this.getsupller();
        // goodsDetail;
    }

    getData = () => {
        Fetch.get(`${API.goods.goodsDetail}?id=${this.state.id}`).then((res) => {
            this.setState({
                data: res,
            });
            this.setState({
                productLabels: res.productLabels,
                keyString: "success"
            });
            //@ts-ignore
            this.formRef.current.setFieldsValue({
                ...res,
                coverUrl: [{url: API.domain + res.coverUrl}],
                detailUrl: [{url: API.domain + res.detailUrl}],
            });
        });
    };
    getOperationalActivitiesSelect = () => {
        Fetch.get(`${API.operationalActivity.getOperationalActivitiesSelect}`).then((res) => {
            this.setState({
                operationalActivitiesSelect: res,
            });
        });
    };
    getsupller = () => {
        Fetch.get(API.goods.getsupllerlist).then((res) => {
            if (res.length > 0) {
                this.setState({
                    supllerList: res,
                });
                if (!common.getQuery('id')) {
                    //@ts-ignore
                    this.formRef.current.setFieldsValue({
                        pointsMallSupplierId: res[0].id,
                    });
                }
            }
        });
    };

    cancle = () => {
        window.history.go(-1);
    };
    update = (values: any) => {
        let that = this;
        let data = {
            ...values,
            coverUrl: that.changeUrl(values.coverUrl),
            detailUrl: that.changeUrl(values.detailUrl),
        };

        Fetch.put(API.goods.goodsUpdate, data).then(() => {
            message.success('保存成功');
            window.history.go(-1);
        });
    };

    changeUrl(url: string) {
        let urls = '';
        if (url.indexOf(API.domain) !== -1) {
            urls = url.replace(API.domain, '');
        } else {
            urls = url;
        }
        return urls;
    }

    render() {
        const {data, supllerList} = this.state;
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/goodslist">商品管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>商品详情</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                    id="goodsview"
                >
                    <div className={styles.container}>
                        <Form initialValues={data} ref={this.formRef}>
                            <FormItem
                                className={styles.mylabel}
                                name="name"
                                label="商品名称"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                {data['name']}
                            </FormItem>

                            {/* </Col>
              </Row> */}
                            <FormItem
                                className={styles.mylabel}
                                name="coverUrl"
                                valuePropName="fileList"
                                label="封面图"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <img
                                    src={API.domain + data['coverUrl']}
                                    alt="封面图"
                                    style={{width: '100px'}}
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="worth"
                                label="商品价值"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                {data['worth']} 元
                            </FormItem>

                            <FormItem
                                label="兑换需要"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                className={styles.mylabel}
                                name="convertibleDivide"
                                rules={[{required: true, message: '必填'}]}
                            >
                                {data['convertibleDivide']}
                                {data['convertibleType'] === 1 ? '工分' : '积分'}
                            </FormItem>

                            <FormItem
                                className={styles.mylabel}
                                name="detailUrl"
                                valuePropName="fileList"
                                label="商品详情图片"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <img
                                    src={API.domain + data['detailUrl']}
                                    alt="封面图"
                                    style={{width: '100px'}}
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="pointsMallSupplierId"
                                label="供应商"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Select style={{width: '100%'}} disabled variant="borderless">
                                    {supllerList.map((item: any) => {
                                        return (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                label="商品标签"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                valuePropName="checked"
                                name="productLabels"
                            >
                                <Checkbox.Group
                                    key={this.state.keyString}
                                    options={this.state.operationalActivitiesSelect}
                                    defaultValue={this.state.productLabels}
                                    disabled={true}
                                    onChange={productLabels => this.setState({productLabels})}
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="stock"
                                label="剩余库存"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                {data['stock']}
                            </FormItem>
                            <FormItem wrapperCol={{span: 12, offset: 2}} className="center">
                                <Button
                                    size="large"
                                    className={styles.mybtn}
                                    style={{marginRight: '15px'}}
                                    onClick={this.cancle}
                                >
                                    返 回
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}

export default function GoodsView() {
    return (
        <App selectedKeys={['goodsList']} openKeys="PMallMenu">
            <GoodsView1/>
        </App>
    );
}
