import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';

export function getWeekStart(year: number, week: number) {

    const firstDayOfYear = dayjs(`${year}-01-01`);
    // 计算给定周数的第一天
    const firstDayOfWeek = firstDayOfYear.add(week - 1, 'week');
    // 获取一周的开始
    return firstDayOfWeek.startOf('week');
}

export function getWeekEnd(year: number, week: number) {

    const firstDayOfYear = dayjs(`${year}-01-01`);
    // 计算给定周数的第一天
    const firstDayOfWeek = firstDayOfYear.add(week - 1, 'week');
    // 获取一周的开始
    return firstDayOfWeek.endOf('week');
}


export function getQuarterStart(year: number, quarter: number) {

    const firstDayOfYear = dayjs(`${year}-01-01`);
    const firstDayOfQuarter = firstDayOfYear.add(3 * (quarter - 1), 'month');
    return firstDayOfQuarter.startOf('month');
}

export function getQuarterEnd(year: number, quarter: number) {

    const firstDayOfYear = dayjs(`${year}-01-01`);
    const endDayOfQuarter = firstDayOfYear.add(3 * (quarter - 1), 'month');
    // 获取季度的结束日期
    return endDayOfQuarter.add(3, 'month').subtract(1, 'day');
}