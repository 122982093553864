export type BoneDetailDataType = {
    id: number,
    importLoading: boolean,
    snap: string,
    bId: number
    data: {
        detailVo: {
            name: string | undefined,
            idNumber: string | undefined,
            phone: string | undefined,
            dateBirth: string | undefined,
            gender: number | 0,
            age: number | undefined,
            wxAccount: string | undefined,
            community: string | undefined,
            illnessMsg: string | undefined,
            hospitalName: string | undefined,
            boneStatus: number | 0,
            toneSourceId: string | '',
            toneOtherSource: string | null
        },
        screening: {
            id: number | 0
            screeningDate: string | undefined
            totalPrice: number | 0,
            medicareReimbursement: number | 0
            selfPayPrice: number | 0
            otherGrants: number | 0
            subsidy: number | 0
            afterSelfPayPrice: number | 0
            remark: number | 0
            density: any[],
            metabolism: any[],
            invoice: any[]
        },
        files: [{
            fileId: number | null,
            name: string | null,
            type: number | null,
            url: string | null
        }],
    }
}

export const BoneDetailDataTypeDate: BoneDetailDataType = {

    id: 0,
    importLoading: true,
    snap: '',
    bId: 0,
    data: {
        detailVo: {
            name: undefined,
            idNumber: undefined,
            phone: undefined,
            dateBirth: undefined,
            gender: 0,
            age: undefined,
            wxAccount: undefined,
            community: undefined,
            illnessMsg: undefined,
            hospitalName: undefined,
            boneStatus: 0,
            toneSourceId: '',
            toneOtherSource: null
        },
        screening: {
            id: 0,
            screeningDate: undefined,
            totalPrice: 0,
            medicareReimbursement: 0,
            selfPayPrice: 0,
            otherGrants: 0,
            subsidy: 0,
            afterSelfPayPrice: 0,
            remark: 0,
            density: [],
            metabolism: [],
            invoice: []
        },
        files: [{
            fileId: null,
            name: null,
            type: null,
            url: null
        }]
    }
}