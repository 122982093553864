import React, {Component} from 'react';
import {Button, Form, Input, message, Select} from "antd";
import Fetch from "../common/FetchIt";
import API from "../common/api";
import styles from './message.module.css';
import {Message} from './types';

const FormItem = Form.Item;
const {TextArea} = Input;

type FormProps = {
    message: Message,
    hide: () => void,
    reload: () => void,
}

type FormState = {
    provinceId: number | '',
    cityId: number | '',
}

class MessageForm extends Component<FormProps, FormState> {
    state: FormState = {
        provinceId: '',
        cityId: '',
    };

    submit = (values: Message) => {
        if (values.id) {
            this.update(values);
        } else {
            this.create(values);
        }
    };

    create = (values: Message) => {
        Fetch.postJSON(`${API.message.zsgc}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    }

    update = (values: Message) => {
        Fetch.putJSON(`${API.message.zsgc}/${values.id}`, values).then(() => {
            message.success('保存成功');
            this.props.hide();
            this.props.reload();
        });
    }

    render() {
        return (
            <div className={styles.container}>
                <Form
                    initialValues={this.props.message}
                    onFinish={this.submit}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem className={styles.mylabel} name="title" label="标题" labelCol={{span: 4}}
                              wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                        <Input

                            placeholder="标题"
                        />
                    </FormItem>
                    <FormItem className={styles.mylabel} name="roleId" label="接收对象" labelCol={{span: 4}}
                              wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                        <Select>
                            <Select.Option value={0}>全部</Select.Option>
                            <Select.Option value={1}>志愿者</Select.Option>
                            <Select.Option value={4}>乐龄志愿服务组织</Select.Option>
                        </Select>
                    </FormItem>
                    <FormItem className={styles.mylabel} name="detail" label="内容" labelCol={{span: 4}}
                              wrapperCol={{span: 20}}
                              rules={[{required: true, message: '必填'}, {
                                  max: 2000,
                                  type: 'string',
                                  message: '内容不能超过2000个字符!'
                              }]}>
                        <TextArea
                            rows={5}
                        />
                    </FormItem>
                    <FormItem className={styles.mylabel} name="status" label="状态" labelCol={{span: 4}}
                              wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                        <Select>
                            <Select.Option value="SENT">已发送</Select.Option>
                            <Select.Option value="DRAFT">草稿件</Select.Option>
                        </Select>
                    </FormItem>
                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button type="primary" htmlType="submit" className={styles.mybtn}>提交</Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default MessageForm;
