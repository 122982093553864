import React, {useEffect, useState} from 'react';
import * as styles from './institution.module.css';
import {renderType} from "./InstitutionDetail";
import Fetch from "../common/FetchIt";
import API from "../common/api";

export default function InstitutionPoster({institution, setPosterRef}) {
    const [qrcode, setQrcode] = useState();
    useEffect(() => {
        const path = encodeURIComponent(`pages/home/index?source=组织二维码&sourceId=${institution.id}`);
        Fetch.get(`${API.institution.qrcode}?path=${encodeURIComponent(path)}`).then((data) => {
            setQrcode(data)
        });
    }, []);
    const date = institution.createdDate.split(' ')[0];
    const yearMonthDay = date.split('-');
    if (!qrcode) {
        return null;
    }
    return (
        <div className={styles.poster} ref={setPosterRef}>
            <div className={styles.ititle}>{institution.name}</div>
            <div className={styles.idesc}>您于{yearMonthDay[0]}年{yearMonthDay[1]}月{yearMonthDay[2]}日注册成为<span
                className={styles.uuu}>{renderType(institution.type, true)}志愿服务组织</span></div>
            <div className={styles.bg2}>
                <div className={styles.icode}>志愿组织编号/Organization ID</div>
                <div className={styles.code2}>{institution.code}</div>
            </div>
            <div className={styles.ifooter}>中国老龄事业发展基金会</div>
            <div className={styles.qrcode}><img src={qrcode} width={104} height={104}/></div>
        </div>
    );
}
