import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message, Radio, RadioChangeEvent,} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './gongyiActivity.module.css';
import Auth from '../common/Auth';

const FormItem = Form.Item;
const {TextArea} = Input;

type FormProps = {
    activityId: number;
    isFinal: number;
    category: string;
    institution: string;
    firstReviewDate: string;
    hide: () => void;
    reload: () => void;
};

type FormState = {
    status: number;
};

class ReviewForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();
    state = {
        status: -1,
    };
    submit = (values: any) => {
        values.activityId = this.props.activityId;

        values.category = 2;
        if (this.props.category == 'GONGYI') {
            values.category = 0;
        } else if (this.props.category == 'DONATION') {
            values.category = 1;
        }
        values.isFinal = this.props.isFinal;
        Fetch.put(`${API.review.zsgc}/status`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    changeStatus = (event: RadioChangeEvent) => {
        this.setState({
            status: event.target.value,
        });
    };

    render() {
        const auth = new Auth();
        return (
            <div className={styles.container}>
                <Form onFinish={this.submit} ref={this.formRef}>
                    {auth.isAdmin() && (
                        <>
                            <FormItem
                                className={styles.mylabel}
                                label="初审单位"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                            >
                                <div>{this.props.institution}</div>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                label="初审时间"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <div>{this.props.firstReviewDate}</div>
                            </FormItem>
                        </>
                    )}
                    <FormItem
                        className={styles.mylabel}
                        name="refuse"
                        label="结果"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Radio.Group onChange={this.changeStatus}>
                            <Radio value={0}>审核通过</Radio>
                            <Radio value={1}>审核未通过</Radio>
                        </Radio.Group>
                    </FormItem>
                    {this.state.status === 1 && (
                        <FormItem
                            className={styles.mylabel}
                            name="reason"
                            label="驳回原因"
                            labelCol={{span: 4}}
                            wrapperCol={{span: 20}}
                            rules={[
                                {required: true, message: '必填'},
                                {max: 100, type: 'string', message: '长度不能超过100个字符'},
                            ]}
                        >
                            <TextArea rows={5}/>
                        </FormItem>
                    )}
                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button type="primary" htmlType="submit" className={styles.mybtn}>
                            提交
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default ReviewForm;
