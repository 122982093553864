import {Card, Descriptions, Image, Space} from 'antd';
import React from 'react';
import styles from "../add/ServiceUser.module.css";
import {ServiceUserCommonType} from "../util/ServiceUserDetailTypes"

const IllnessInfo: React.FC<ServiceUserCommonType> = (props) => {
    const {data} = props;
    return (
        <>
            {data && data.user != null && data.user.illnessInfo != null ? (
                <Card size="small" className={styles.mycard}
                      title={`病情描述`}>
                    <Descriptions>
                        <Descriptions.Item label="病情描述">
                            {data.user.illnessInfo}
                        </Descriptions.Item>
                    </Descriptions>
                    {data.illnessInfo ? (
                        <Descriptions>
                            <Descriptions.Item label="病情资料">
                                {data.illnessInfo.informationOne ? (
                                    <>
                                        <Image.PreviewGroup
                                            preview={{}}
                                        >
                                            <Space>

                                                {data.illnessInfo.informationOne.map((item: any, index: any) => (
                                                    <Image
                                                        width={100}
                                                        src={item.url}
                                                    />
                                                ))}

                                            </Space>
                                        </Image.PreviewGroup>
                                    </>
                                ) : ('未上传')}
                            </Descriptions.Item>
                        </Descriptions>
                    ) : ('')}
                </Card>
            ) : ('')}
        </>
    );
};

export default IllnessInfo;
