import React from 'react';
import {Modal} from 'antd';

export default function createModal(WrappedComponent) {
    class InnerModal extends React.Component {
        state = {
            visible: false,
        };

        show = () => {
            this.setState({
                visible: true,
            });
        };

        handleOk = () => {
            this.setState({
                visible: false,
            });
            if (this.props.onOk) {
                this.props.onOk();
            }
        };

        handleCancel = () => {
            this.setState({
                visible: false,
            });
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        };

        render() {
            if (!this.state.visible) {
                return null;
            }
            const {
                title, width, reload: reload = () => {
                }, className, ...rest
            } = this.props;
            if (this.props.showFooter) {
                return (
                    <Modal
                        title={title}
                        width={width || 800}
                        open={this.state.visible}
                        onOk={this.handleOk}
                        okText={this.props.okText || '确定'}
                        onCancel={this.handleCancel}
                        className={className}
                    >
                        <WrappedComponent {...rest} hide={this.handleCancel} reload={reload}/>
                    </Modal>
                );
            }
            return (
                <Modal
                    title={title}
                    width={width || 800}
                    open={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className={className}
                    footer={null}
                >
                    <WrappedComponent {...rest} hide={this.handleCancel} reload={reload}/>
                </Modal>
            );
        }
    }

    return InnerModal;
}

