import React from 'react';
import {DatePicker, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {PageData} from '../util/types';
import {listDefaultData} from "../util/AierCommon";
import encryptData from "../../common/crypto";
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

type User = {};

const {RangePicker} = DatePicker;

class ReimbursedBatchListClass extends BaseListPage {
    state: PageData = {
        ...listDefaultData,
        btnQ: {
            ROLE_SERVICE_USERS_ROSTER_DETAIL: 0,
            ROLE_SERVICE_USERS_DOWNLOAD_T: 0
        },
        modalTitle: '导入结果'
    };
    private fileInput = document.getElementById('fileInput');

    componentDidMount() {
        super.componentDidMount();
    }

    loadData = () => {

        const {pageSize, searchData} = this.state;
        let url = `${API.reimbursed_batch.query_page}?page=${searchData.page}&size=${pageSize}`;
        // url = url + this.concatParam(searchData);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };


    render() {
        //
        const {
            searchData, pageSize, totalElements,
            content, btnQ
        } = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '导出时间',
                width: 90,
                dataIndex: 'createdDate',
                key: 'createdDate',
                align: `center`,
                fixed: 'left',
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '操作人',
                width: 80,
                dataIndex: 'username',
                key: 'username',
                align: `center`,
                fixed: 'left',
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '编码',
                width: 100,
                dataIndex: 'code',
                key: 'code',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '已报销/报销失败/总人数',
                dataIndex: 'num',
                key: 'num',
                align: `center`,
                width: 100,
                render(value, record: any) {
                    return (
                        <span
                            style={{wordBreak: 'break-all'}}>{record.reimbursed + "/" + record.failedNum + "/" + record.num}</span>
                    );
                },
            },
            {
                title: '批次总金额（元）',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '已报销金额（元）',
                dataIndex: 'amountReimbursed',
                key: 'amountReimbursed',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '报销失败金额（元）',
                dataIndex: 'failedAmount',
                key: 'failedAmount',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (text, record: any) => {
                    return (
                        <Space>
                            {/*TOOD 权限*/}
                            {/*{record.num != record.reimbursed ? (*/}
                            {/*    <>*/}
                            {/*        <Button type="link" onClick={() => this.downloadTotal(record.id, record.code)}>*/}
                            {/*            下载未报销名单*/}
                            {/*        </Button>*/}
                            {/*    </>*/}
                            {/*) : ('')}*/}
                            {btnQ.ROLE_REIMBURSED_EXPORT_RECORDS_DETAIL ? (
                                <>
                                    <Link
                                        to={`/reimbursedBatchDetail/list?bId=${encryptData(record.id + "")}&code=${record.code}`}>
                                        详情
                                    </Link>
                                </>
                            ) : ('')}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>

                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>*/}
                    {/*        <Link to="/financialAuditList/auditList">*/}
                    {/*            报销名单Ⅰ*/}
                    {/*        </Link>*/}
                    {/*    </Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>报销导出列表</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        {/* status: '', search: '', type: '', */}
                        {/*<Space onKeyDown={this.handleKeyDown}>*/}
                        {/*    <Select*/}
                        {/*        showSearch*/}
                        {/*        placeholder="选择省份"*/}
                        {/*        optionFilterProp="children"*/}
                        {/*        onChange={(e) => {*/}
                        {/*            this.changeCondition({page: 0, provinceId: e})*/}
                        {/*        }}*/}
                        {/*        value={searchData.provinceId}*/}
                        {/*        filterOption={(input: any, option: any) =>*/}
                        {/*            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                        {/*        }*/}
                        {/*        style={{width: 120}}*/}
                        {/*        options={this.state.zoneSelect}*/}
                        {/*    />*/}
                        {/*   */}
                        {/*    <Button onClick={this.loadData}>*/}
                        {/*        搜索*/}
                        {/*    </Button>*/}
                        {/*    <Button onClick={this.reset}>重置</Button>*/}

                        {/*</Space>*/}
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            scroll={{x: 1500}}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function ReimbursedBatchList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ReimbursedBatchListClass/>
        </App>
    );
}
