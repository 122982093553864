import {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Card, Descriptions, Steps, Tabs, Typography} from 'antd';

import styles from './donationActivity.module.css';
import App from '../../App';
import DonationList from './DonationList';
import DonationProgressList from './DonationProgressList';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {useParams} from 'react-router-dom';
import createModal from '../common/createModal';
import ReviewForm from '../gongyiActivity/ReviewForm';
import {getCurrentStep, getMenu, isFinal, renderReviewStatus,} from '../gongyiActivity/GongyiActivityDetail';
import ReviewItem from '../gongyiActivity/ReviewItem';
import dayjs from "dayjs";

const ReviewFormModal = createModal(ReviewForm);
const {Step} = Steps;

function DonationActivityDetail(props) {
    const [donationActivity, setDonationActivity] = useState();

    const params = useParams();

    const loadData = () => {
        Fetch.get(`${API.donationActivity.zsgc}?id=${params.id}`).then((data) => {
            setDonationActivity(data);
        });
    };

    const [reason, setReason] = useState();
    const loadReason = () => {
        Fetch.get(`${API.reason.zsgc}?activityId=${params.id}&category=1`).then(
            (data) => {
                setReason(data);
            }
        );
    };

    const [review, setReview] = useState();
    const loadReview = () => {
        Fetch.get(`${API.review.zsgc}?activityId=${params.id}&category=1`).then(
            (data) => {
                setReview(data);
            }
        );
    };

    useEffect(function () {
        loadData();
        loadReason();
        loadReview();
    }, []);

    function renderStatus() {
        if (!donationActivity) {
            return '';
        }
        const startDate = dayjs(donationActivity.startDate);
        const endDate = dayjs(donationActivity.endDate);
        const now = Date.now();
        if (startDate.isBefore(now)) {
            if (endDate.isBefore(now)) {
                return '活动已结束';
            }
            return '活动进行中';
        }
        return '活动未开始';
    }

    function renderStatus2() {
        if (!donationActivity) {
            return '';
        }
        const status = donationActivity.status;
        if (status === 'DRAFT') {
            return '草稿';
        } else if (status === 'PUBLISHED') {
            return '已发布';
        } else if (status === 'DISABLED') {
            return '已下架';
        }
    }

    let modal = null;

    if (!donationActivity) {
        return null;
    }

    const down = () => {
        const result = window.confirm('是否确定下架？');
        if (result) {
            Fetch.put(
                `${API.donationActivity.status}?id=${params.id}&status=DISABLED`
            ).then(() => {
                loadData();
            });
        }
    };
    const up = () => {
        Fetch.put(
            `${API.donationActivity.online}?id=${params.id}&status=PUBLISHED`
        ).then(() => {
            loadData();
        });
    };

    const isReviewing = () => {
        return (
            donationActivity.reviewStatus === 'PROVINCE' ||
            donationActivity.reviewStatus === 'FINAL' ||
            donationActivity.reviewStatus === 'PROVINCE_REJECTED' ||
            donationActivity.reviewStatus === 'FINAL_REJECTED'
        );
    };
    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>
                    <a href="/#/donationActivity">公益募捐</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {isReviewing() ? '审核详情' : '活动详情'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <Card
                    title={`活动名称: ${donationActivity.title}`}
                    extra={getMenu(
                        params.id,
                        donationActivity.reviewStatus,
                        () => {
                            modal.show();
                        },
                        down,
                        reason,
                        donationActivity.status,
                        up,
                        'DONATION',
                        donationActivity.offlineRole
                    )}
                >
                    {isReviewing() ? (
                        <Descriptions>
                            <Descriptions.Item label="发起人">
                                {donationActivity.publisher}
                            </Descriptions.Item>
                            <Descriptions.Item label="提交时间">
                                {donationActivity.submitDate
                                    ? donationActivity.submitDate
                                    : ' - '}
                            </Descriptions.Item>
                            <Descriptions.Item label="审核状态">
                                {renderReviewStatus(donationActivity.reviewStatus)}
                            </Descriptions.Item>
                        </Descriptions>
                    ) : (
                        <Descriptions>
                            <Descriptions.Item label="发起人">
                                {donationActivity.publisher}
                            </Descriptions.Item>
                            <Descriptions.Item label="审核状态">
                                {renderReviewStatus(donationActivity.reviewStatus)}
                            </Descriptions.Item>
                            <Descriptions.Item label="上架状态">
                                {renderStatus2()}
                            </Descriptions.Item>
                            <Descriptions.Item label="活动状态">
                                {renderStatus()}
                            </Descriptions.Item>

                            <Descriptions.Item label="初审机构">
                                {donationActivity.institution}
                            </Descriptions.Item>
                            <Descriptions.Item label="终审机构">
                                {donationActivity.finalInstitution}
                            </Descriptions.Item>
                            <Descriptions.Item label="募捐情况">
                                {donationActivity.currentDonation} / {donationActivity.total}
                            </Descriptions.Item>
                            <Descriptions.Item label="捐款人次">
                                {donationActivity.donationNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="活动编码">
                                {donationActivity.code || '-'}
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                </Card>
                {isReviewing() && (
                    <Card title="审核流程" style={{marginTop: '10px'}}>
                        <Steps
                            progressDot
                            current={getCurrentStep(donationActivity.reviewStatus)}
                        >
                            <Step
                                title={
                                    //@ts-ignore
                                    <ReviewItem
                                        category="DONATION"
                                        activity={donationActivity}
                                        title="省级初审"
                                        review={review}
                                        current={0}
                                        institution={donationActivity.institution}
                                        submitDate={donationActivity.submitDate}
                                    />
                                }
                            />
                            <Step
                                title={
                                    //@ts-ignore
                                    <ReviewItem
                                        category="GONGYI"
                                        activity={donationActivity}
                                        title="老基会终审"
                                        review={review}
                                        current={1}
                                        institution="国家老基会"
                                        submitDate={donationActivity.submitDate}
                                    />
                                }
                            />
                            <Step title="发布成功"/>
                        </Steps>
                    </Card>
                )}
                <Card title="活动信息" style={{marginTop: '10px'}}>
                    {!isReviewing() ? (
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="基本信息" key="1">
                                <BasicInfo donationActivity={donationActivity}/>
                                <Card>
                                    <div>立项时间：{donationActivity.publishDate}</div>
                                    <QualificationInfo
                                        qualification={{
                                            code: donationActivity.number,
                                            billInstitution: donationActivity.billInstitution,
                                            executeInstitution: donationActivity.executeInstitution,
                                            finalInstitution: donationActivity.finalInstitution,
                                        }}
                                    />
                                </Card>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="捐款列表" key="2">
                                <DonationList activityId={params.id} together={false}/>
                                <DonationList activityId={params.id} together={true}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="项目进展" key="3">
                                <DonationProgressList activityId={Number(params.id)}/>
                            </Tabs.TabPane>
                        </Tabs>
                    ) : (
                        <>
                            <BasicInfo donationActivity={donationActivity}/>
                        </>
                    )}
                </Card>
                <ReviewFormModal
                    title="审核"
                    width={600}
                    ref={(c) => (modal = c)}
                    isFinal={isFinal(donationActivity.reviewStatus)}
                    reload={loadData}
                    activityId={params.id}
                    institution={donationActivity.institution}
                    firstReviewDate={donationActivity.validateDate}
                    category="DONATION"
                />
            </div>
        </>
    );
}

function BasicInfo(props) {
    const {donationActivity} = props;
    return (
        <div className={styles.mycard}>
            <Descriptions>
                <Descriptions.Item span={3} label="封面图">
                    {/*{donationActivity.coverId && donationActivity.coverId.length === 0 && (*/}
                    {/*    <span>暂无</span>*/}
                    {/*)}*/}
                    {/*{donationActivity.covers &&*/}
                    {/*donationActivity.covers.map((file) => {*/}
                    {/*    return (*/}
                    {/*        <div key={file.id} className={styles.mgRight10}>*/}
                    {/*            <img src={API.domain + file.url} width={100}/>*/}
                    {/*        </div>*/}
                    {/*    );*/}
                    {/*})}*/}
                    <div key={donationActivity.coverId} className={styles.mgRight10}>
                        <img src={API.domain + donationActivity.coverImg} width={100}/>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="详细地址">
                    <Typography.Text ellipsis style={{width: '220px'}}>
                        {donationActivity.address}
                    </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="所属类别">
                    {donationActivity.template.categoryName}
                </Descriptions.Item>
                <Descriptions.Item label="所属类型">
                    {donationActivity.template.projectName}
                </Descriptions.Item>
                <Descriptions.Item label="募捐金额">
                    {donationActivity.total}
                </Descriptions.Item>
                <Descriptions.Item label="超过金额是否继续">
                    {donationActivity.continueWhenFull ? '是' : '否'}
                </Descriptions.Item>
                {/*<Descriptions.Item label="是否有时间限制">*/}
                {/*  {donationActivity.timeLimited ? '是' : '否'}*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label="募捐开始时间">
                    {donationActivity.startDate}
                </Descriptions.Item>
                <Descriptions.Item label="募捐结束时间">
                    {donationActivity.endDate}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="捐款描述">
                    {' '}
                    <div dangerouslySetInnerHTML={{__html: donationActivity.content}}/>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
}

function QualificationInfo(props) {
    const {qualification} = props;

    // if (!qualification) {
    //   return null;
    // }
    return (
        <div>
            <div>募捐编号：{qualification.code}</div>
            <div>收款机构：{qualification.billInstitution}</div>
            <div>执行机构：{qualification.executeInstitution}</div>
        </div>
    );
}

export default function DonationActivityDetailIndex() {
    return (
        <App selectedKeys={['donation']} openKeys="sub1">
            <DonationActivityDetail/>
        </App>
    );
}
