import {cdnDomain, pathDomain, staticImg} from "../../envConfig";

const ROOT = '/api';
const API = {
    upload: `${ROOT}/admin/media/upload`,
    location: `${ROOT}/admin/location`,
    location2: `${ROOT}/admin/location_region`,
    domain: cdnDomain,
    domain1: pathDomain,
    staticImg: staticImg,
    ignoreList: [1201, 1101, 3101, 5001, 5002, 81, 8100, 82, 8200],
    zoneRep: {
        "重庆城区": "重庆",
        "重庆郊县": "重庆"
    },
    troup: {
        guru: {
            query_page: `${ROOT}/admin/troup/guru/query_page`,
            get_admin_detail: `${ROOT}/admin/troup/guru/get_admin_detail`,
            create_certificate: `${ROOT}/admin/troup/guru/create_certificate`,
            query_group_page: `${ROOT}/admin/troup/guru/query_group_page`,
        },
        group: {
            query_page: `${ROOT}/admin/troup/group/query_page`,
            create: `${ROOT}/admin/troup/group/create`,
            update: `${ROOT}/admin/troup/group/update`,
            query_group_page: `${ROOT}/admin/troup/group/query_group_page`,
        },
        sign: {
            query_page: `${ROOT}/admin/troup/sign/query_page`,
            import_data: `${ROOT}/admin/troup/sign/import_data`,
        }
    },
    bone: {
        service_user: {
            hospital_list: `${ROOT}/admin/bone_service_users/hospital_list`,
            get_detail: `${ROOT}/admin/bone_service_users/get_detail`,
            subsidy_list:`${ROOT}/admin/bone_service_users/subsidy_list`,
            find_service_record:`${ROOT}/admin/bone_service_users/find_service_record`,
        },
        status: {
            update_confirm: `${ROOT}/admin/bone_service_status/update_confirm`,
            update_review_retraction: `${ROOT}/admin/bone_service_status/update_review_retraction`,
            update_to_reviewed: `${ROOT}/admin/bone_service_status/update_to_reviewed`,
            do_update_approved: `${ROOT}/admin/bone_service_status/do_update_approved`,
            update_review_returns: `${ROOT}/admin/bone_service_status/update_review_returns`
        },
        screening: {
            do_save_fee: `${ROOT}/admin/bone_service_screening/do_save_fee`,
            do_update_fee: `${ROOT}/admin/bone_service_screening/do_update_fee`
        }
    },
    exam: {
        exam_papers: {
            exam_papers: `${ROOT}/admin/exam_papers/query_list`
        },
        exam_ques: {
            query_list: `${ROOT}/admin/exam_ques/query_list`,
            update_status: `${ROOT}/admin/exam_ques/update_status`,
            save_question: `${ROOT}/admin/exam_ques/save_question`,
            update_question: `${ROOT}/admin/exam_ques/update_question`,
            get_detail: `${ROOT}/admin/exam_ques/get_detail`,
        },
        answer: {
            query_list: `${ROOT}/admin/answer/query_list`,
            find_detail: `${ROOT}/admin/answer/find_detail`,
        },
        user_answer: {
            query_list: `${ROOT}/admin/user_answer/query_list`,
            detail: `${ROOT}/admin/user_answer/detail`,
        },
        exam_statistics: {
            find_unit: `${ROOT}/admin/exam_statistics/find_unit`,
            find_ranking: `${ROOT}/admin/exam_statistics/find_ranking`,
            do_update_statistics_unit: `${ROOT}/admin/exam_statistics/do_update_statistics_unit`,
            do_update_turn_personal: `${ROOT}/admin/exam_statistics/do_update_turn_personal`,
            log_query_page: `${ROOT}/admin/exam_statistics/log_query_page`,
            do_undo: `${ROOT}/admin/exam_statistics/do_undo`,
        }
    },
    sys_config: {
        admin_v: `${ROOT}/admin/sys_config/admin_v`
    },
    taxes_user: {
        query_page: `${ROOT}/admin/taxes_user/query_page`,
        download_taxes: `${ROOT}/admin/taxes_user/download_taxes`,
        getDetail: `${ROOT}/admin/taxes_user/do_get_detail`,
    },
    dentistry: {
        nbsp_service_user: {
            do_get_detail: `${ROOT}/admin/nbsp_service_user/do_get_detail`,
        },
        dh_reimbursed_batch: {
            query_page: `${ROOT}/admin/dh_reimbursed_batch/query_page`,
            batch_create: `${ROOT}/admin/dh_reimbursed_batch/batch_create`,
            detail_query_page: `${ROOT}/admin/dh_reimbursed_batch/detail_query_page`,
            do_download: `${ROOT}/admin/dh_reimbursed_batch/do_download`,
        },
        service_user_status: {
            er_process_apply: `${ROOT}/admin/service_user_status/er_process_apply`,
            er_process_undo: `${ROOT}/admin/service_user_status/er_process_undo`,
            er_process_refuse: `${ROOT}/admin/service_user_status/er_process_refuse`,
            er_process_pass: `${ROOT}/admin/service_user_status/er_process_pass`,
            update_erp_fail: `${ROOT}/admin/service_user_status/update_erp_fail`,
            update_complete: `${ROOT}/admin/service_user_status/update_complete`,
            update_erp_fail_submit: `${ROOT}/admin/service_user_status/update_erp_fail_submit`,
            update_patient_approved: `${ROOT}/admin/service_user_status/update_patient_approved`,
            update_patient_review_failed: `${ROOT}/admin/service_user_status/update_patient_review_failed`,
        },
        teeth_fee: {
            teeth_fee_select: `${ROOT}/admin/teeth_fee/teeth_fee_select`,
        },
        dh_call_log: {
            get_list: `${ROOT}/admin/dh_call_log/get_list`,
        },
        service_user: {
            admin_list: `${ROOT}/admin/dh_service_user/admin_list`,
            do_get_patient_detail: `${ROOT}/admin/dh_service_user/do_get_patient_detail`,
            signed_by_doctor: `${ROOT}/admin/dh_service_user/signed_by_doctor`,
            do_user_application: `${ROOT}/admin/dh_service_user/do_user_application`,
            do_update_application: `${ROOT}/admin/dh_service_user/do_update_application`,
            do_get_edit_detail: `${ROOT}/admin/dh_service_user/do_get_edit_detail`,
            audit_download: `${ROOT}/admin/dh_service_user/audit_download`,
            file_import_audit: `${ROOT}/admin/dh_service_user/file_import_audit`,
            find_finance_page: `${ROOT}/admin/dh_service_user/find_finance_page`,
            get_finance_detail: `${ROOT}/admin/dh_service_user/get_finance_detail`,
            find_record: `${ROOT}/admin/dh_service_user/find_record`,
        },
        hospital: {
            hospital_select: `${ROOT}/admin/dh_hospital/hospital_select`,
        },
        dh_hospital_scheduling: {
            find_scheduling: `${ROOT}/admin/dh_hospital_scheduling/find_scheduling`,
            find_hospital_dashboard: `${ROOT}/admin/dh_hospital_scheduling/find_hospital_dashboard`,
            get_detail: `${ROOT}/admin/dh_hospital_scheduling/get_detail`,
            do_update_scheduling: `${ROOT}/admin/dh_hospital_scheduling/do_update_scheduling`,
        },
        dh_service_user_booking: {
            do_user_booking: `${ROOT}/admin/dh_service_user_booking/do_user_booking`,
            admin_list: `${ROOT}/admin/dh_service_user_booking/admin_list`,
            edit_booking_detail: `${ROOT}/admin/dh_service_user_booking/edit_booking_detail`,
            do_edit_user_booking: `${ROOT}/admin/dh_service_user_booking/do_edit_user_booking`,
            do_get_patient_detail: `${ROOT}/admin/dh_service_user_booking/do_get_patient_detail`,
            do_upload_report: `${ROOT}/admin/dh_service_user_booking/do_upload_report`,
            dashboard_list: `${ROOT}/admin/dh_service_user_booking/dashboard_list`,
            get_service_user_detail: `${ROOT}/admin/dh_service_user_booking/get_service_user_detail`,
            find_record: `${ROOT}/admin/dh_service_user_booking/find_record`,
            admin_group_list: `${ROOT}/admin/dh_service_user_booking/admin_group_list`,
            do_auto_user_booking: `${ROOT}/admin/dh_service_user_booking/do_auto_user_booking`,
        },
        dh_salesman: {
            admin_list: `${ROOT}/admin/dh_salesman/admin_list`,
            create: `${ROOT}/admin/dh_salesman/create`,
            get_sales_status: `${ROOT}/admin/dh_salesman/get_sales_status`,
            update_sales_status: `${ROOT}/admin/dh_salesman/update_sales_status`,
            tccc_get_token: `${ROOT}/admin/dh_salesman/tccc_get_token`,
        },
        phone_task: {
            admin_list: `${ROOT}/admin/phone_task/admin_list`,
            admin_all_list: `${ROOT}/admin/phone_task/admin_all_list`,
            do_get_detail_by_id: `${ROOT}/admin/phone_task/do_get_detail_by_id`,
        },
        phone_task_batch_detail: {
            do_update_processing: `${ROOT}/admin/phone_task_batch_detail/do_update_processing`,
            do_update_pending: `${ROOT}/admin/phone_task_batch_detail/do_update_pending`,
            do_get_detail_by_id: `${ROOT}/admin/phone_task_batch_detail/do_get_detail_by_id`,
            get_obligate_detail: `${ROOT}/admin/phone_task_batch_detail/get_obligate_detail`
        },
        obligate_phone: {
            detail_data: `${ROOT}/admin/obligate_phone/detail_data`,

        },
        dh_service_user_fee: {
            do_create_fee: `${ROOT}/admin/dh_service_user_fee/do_create_fee`,
            finance_complete: `${ROOT}/admin/dh_service_user_fee/finance_complete`,
        }
    },
    service_user_bill_batch: {
        list: `${ROOT}/admin/service_user_bill_batch/list`,
        detail_list: `${ROOT}/admin/service_user_bill_batch/detail_list`,
        download_excel: `${ROOT}/admin/service_user_bill_batch/download_excel`,
    },
    service_user_bill: {
        financial_query_page: `${ROOT}/admin/service_user_bill/financial_query_page`,
        hos_query_page: `${ROOT}/admin/service_user_bill/hos_query_page`,
        get_bill_detail: `${ROOT}/admin/service_user_bill/get_bill_detail`,
        update_upload_material: `${ROOT}/admin/service_user_bill/update_upload_material`,
        update_material_to_be_reviewed: `${ROOT}/admin/service_user_bill/update_material_to_be_reviewed`,
        update_material_quash: `${ROOT}/admin/service_user_bill/update_material_quash`,
        update_material_approved: `${ROOT}/admin/service_user_bill/update_material_approved`,
        update_material_review_bounces: `${ROOT}/admin/service_user_bill/update_material_review_bounces`,
        update_playing_money: `${ROOT}/admin/service_user_bill/update_playing_money`,
        update_reimbursement_complete: `${ROOT}/admin/service_user_bill/update_reimbursement_complete`,
        update_receipt_complete: `${ROOT}/admin/service_user_bill/update_receipt_complete`,
        update_complete: `${ROOT}/admin/service_user_bill/update_complete`,
        update_receipt_review_bounces: `${ROOT}/admin/service_user_bill/update_receipt_review_bounces`,
        detail_list_age: `${ROOT}/admin/service_user_bill/detail_list_age`,
        create_receipt: `${ROOT}/admin/service_user_bill/create_receipt`,
        find_expected_payment: `${ROOT}/admin/service_user_bill/find_expected_payment`,
    },
    aier_hospital_account: {
        create_or_update: `${ROOT}/admin/aier_hospital_account/create_or_update`,
        get_detail: `${ROOT}/admin/aier_hospital_account/get_detail`,
    },
    aier_hospital_applets_config: {
        list: `${ROOT}/admin/aier_hospital_applets_config/admin_list`,
        find_add_hospital: `${ROOT}/admin/aier_hospital_applets_config/find_add_hospital`,
        batch_create: `${ROOT}/admin/aier_hospital_applets_config/batch_create`
    },
    hospital_view_role: {
        find_hospital: `${ROOT}/admin/hospital_view_role/find_by_hospital_id`,
    },
    aier_hospital_role: {
        list: `${ROOT}/admin/aier_hospital/admin_list`,
        select_hospital: `${ROOT}/admin/aier_hospital/select_hospital`,
        select_participate_hospital: `${ROOT}/admin/aier_hospital/select_participate_hospital`,
        update_role: `${ROOT}/admin/aier_hospital/update_role`,
        update_participate: `${ROOT}/admin/aier_hospital/update_participate`,
        update_advance_money: `${ROOT}/admin/aier_hospital/update_advance_money`,
        update_protocol: `${ROOT}/admin/aier_hospital/update_protocol`,
        get_protocol_status: `${ROOT}/admin/aier_hospital/get_protocol_status`
    },
    hospital_group: {
        admin_list: `${ROOT}/admin/hospital_group/admin_list`,
        update: `${ROOT}/admin/hospital_group/update`,
        hospital_group_select: `${ROOT}/admin/hospital_group/hospital_group_select`,
        batch_update: `${ROOT}/admin/hospital_group/batch_update`,
        create: `${ROOT}/admin/hospital_group/create`,
    },
    record_operations: {
        find_service_record: `${ROOT}/admin/record_operations/find_service_record`
    },
    hospital_stats: {
        get_stats_page: `${ROOT}/admin/hospital_stats/get_stats_page`
    },
    aier_hospital_day: {
        find_hospital_day_stats: `${ROOT}/admin/aier_hospital_day/find_hospital_day_stats`,
        download_excel: `${ROOT}/admin/aier_hospital_day/download_excel`
    },
    cooperate_user_account: {
        do_admin_exit_account: `${ROOT}/admin/cooperate_user_account/do_admin_exit_account`
    },
    service_users_snap: {
        get_patient_details: `${ROOT}/admin/service_users_snap/get_patient_details`
    },
    user_financial_statistics: {
        download_financial_statistics: `${ROOT}/admin/user_financial_statistics/download_financial_statistics`
    },
    aier_publicize_video: {
        admin_list: `${ROOT}/aier_publicize_video/admin/admin_list`,
    },
    hospital_area: {
        find_select_data: `${ROOT}/admin/hospital_area/find_select_data`,
    },
    monthly_statistics: {
        query_page: `${ROOT}/admin/monthly_statistics/query_page`,
        query_detail_page: `${ROOT}/admin/monthly_statistics/query_detail_page`,
        download_total: `${ROOT}/admin/monthly_statistics/download_total`,
        do_download_detail: `${ROOT}/admin/monthly_statistics/do_download_detail`,
    },
    aierHospitalFee: {
        do_save_fee: `${ROOT}/admin/hospital_fee/do_save_fee`,
        do_update_fee: `${ROOT}/admin/hospital_fee/do_update_fee`,
        do_update_refine: `${ROOT}/admin/hospital_fee/do_update_refine`,
        get_detail: `${ROOT}/admin/hospital_fee/get_detail`,
        get_reimbursed_amount: `${ROOT}/admin/hospital_fee/get_reimbursed_amount`,
        admin_update_fee: `${ROOT}/admin/hospital_fee/admin_update_fee`
    },
    cooperateServiceUsers: {
        add_list: `${ROOT}/admin/cooperate_service_users/add_list`,
        // financial_audit: `${ROOT}/admin/cooperate_service_users/financial_audit`,
        // hospital_audit: `${ROOT}/admin/cooperate_service_users/hospital_audit`,
        get_patient_details: `${ROOT}/admin/cooperate_service_users/get_patient_details`,
        // update_surgery_complete: `${ROOT}/admin/cooperate_service_users/update_surgery_complete`,
        // update_hospital_submissions: `${ROOT}/admin/cooperate_service_users/update_hospital_submissions`,
        //v1 暂时无用
        // update_reimbursement: `${ROOT}/admin/cooperate_service_users/update_reimbursement`,
        // hospital_select: `${ROOT}/admin/affairs_hospital_rel/hospital_select`,
        // update_patient_review_failed: `${ROOT}/admin/cooperate_service_users/update_patient_review_failed`,
        province_download_excel: `${ROOT}/admin/cooperate_service_users/province_download_excel`,
        file_import_audit: `${ROOT}/admin/cooperate_service_users/file_import_audit`,
        create_qr_code: `${ROOT}/admin/cooperate_service_users/create_qr_code`,
        // update_revocation_financial_audits: `${ROOT}/admin/cooperate_service_users/update_revocation_financial_audits`,
        get_patient_fee_details: `${ROOT}/admin/cooperate_service_users/get_patient_fee_details`,
        get_edit_account_detail: `${ROOT}/admin/cooperate_service_users/get_edit_account_detail`,
    },
    cooperateServiceUsersStatusV2: {
        update_hospital_submissions: `${ROOT}/admin/service_status_v2/update_hospital_submissions`,
        update_reimbursement: `${ROOT}/admin/cooperate_service_users/do_reimbursement`,
        update_revocation_financial_audits: `${ROOT}/admin/service_status_v2/update_revocation_financial_audits`,
        hospital_audit: `${ROOT}/admin/service_users_v2/hospital_audit`,
        financial_audit: `${ROOT}/admin/service_users_v2/financial_audit`,
        update_financial_audits_refuse: `${ROOT}/admin/service_status_v2/update_financial_audits_refuse`,
        update_financial_pass: `${ROOT}/admin/service_status_v2/update_financial_pass`,
        do_financial_reimbursement_failed: `${ROOT}/admin/service_users_v2/do_financial_reimbursement_failed`,
        update_complete_account_submit: `${ROOT}/admin/service_status_v2/update_complete_account_submit`,
        update_service_deleted: `${ROOT}/admin/service_status_v2/update_service_deleted`,
        get_batch_audit_data: `${ROOT}/admin/service_status_v2/get_batch_audit_data`,
        finance_batch_confirm: `${ROOT}/admin/service_status_v2/finance_batch_confirm`,
        do_transfer_log: `${ROOT}/admin/service_status_v2/do_transfer_log`,
        update_patient_approved: `${ROOT}/admin/service_status_v2/update_patient_approved`,
        update_patient_review_failed: `${ROOT}/admin/service_status_v2/update_patient_review_failed`,
        finance_batch_multiple_confirm: `${ROOT}/admin/service_status_v2/finance_batch_multiple_confirm`,

        find_informed_consent: `${ROOT}/admin/service_status_v2/find_informed_consent`,
        batch_download_informed_consent: `${ROOT}/admin/service_status_v2/batch_download_informed_consent`,
        download_informed_consent: `${ROOT}/admin/service_status_v2/download_informed_consent`,
        audits_back_refuse: `${ROOT}/admin/service_status_v2/audits_back_refuse`
    },
    aierHospitalScreening: {
        do_save_hospital_screening: `${ROOT}/admin/aier_hospital_screening/do_save_hospital_screening`,
    },
    reimbursed_batch: {
        batch_create: `${ROOT}/admin/reimbursed_batch/batch_create`,
        query_page: `${ROOT}/admin/reimbursed_batch/query_page`,
        detail_query_page: `${ROOT}/admin/reimbursed_batch/detail_query_page`,
        do_download: `${ROOT}/admin/reimbursed_batch/do_download`
    },
    organizationRole: {
        get_org_leader_menu: `${ROOT}/admin/organization_role/get_org_leader_menu`,
        update_org_max_role: `${ROOT}/admin/organization_role/update_org_max_role`,
    },
    operationalActivity: {
        getOperationalActivitiesSelect: `${ROOT}/admin/operational_activities/get_operational_activities_select`,
        operationalActivitiesList: `${ROOT}/admin/operational_activities/list`,
        operationalActivitiesCreate: `${ROOT}/admin/operational_activities/create`,
        operationalActivitiesUpdate: `${ROOT}/admin/operational_activities/update`,
        operationalActivitiesGetDetail: `${ROOT}/admin/operational_activities/get_detail`
    },
    invoice: {
        list: `${ROOT}/admin/billing/admin_list`,
        detail: `${ROOT}/admin/billing/do_get_admin_detail`,
        upload: `${ROOT}/admin/billing_ticket/create`,
        del: `${ROOT}/admin/billing_ticket/deleted_by_id`,
        send_email: `${ROOT}/admin/billing/send_email`,
    },
    // 积分兑换
    goods: {
        list: `${ROOT}/admin/pm/product/list`,
        supller: `${ROOT}/admin/pm/supplier/list`,
        supllerCreate: `${ROOT}/admin/pm/supplier/create`,
        supllerUpdate: `${ROOT}/admin/pm/supplier/update`,
        supllerDel: `${ROOT}/admin/pm/supplier/deleted`,
        getsupllerlist: `${ROOT}/admin/pm/supplier/find_select_data`,
        goodscreate: `${ROOT}/admin/pm/product/create`,
        goodsDel: `${ROOT}/admin/pm/product/deleted`,
        goodsDetail: `${ROOT}/admin/pm/product/get_detail`,
        goodsUpdate: `${ROOT}/admin/pm/product/update`,
        productList: `${ROOT}/admin/pm/product_list/list`,
        productCreate: `${ROOT}/admin/pm/product_list/create`,
        productModel: `${ROOT}/admin/pm/product/search_product_list`,
        productDel: `${ROOT}/admin/pm/product_list/deleted`,
        productSort: `${ROOT}/admin/pm/product_list/sequencing`,
        expressList: `${ROOT}/admin/pm/order/list`,
        express_select: `${ROOT}/admin/pm/express/find_express_select`,
        shipments: `${ROOT}/admin/pm/order/shipments`,
        orderdetail: `${ROOT}/admin/pm/order/get_admin_detail`,
        expressdetail: `${ROOT}/admin/pm/express_info/search_express`,
    },
    // 活动库
    atl: {
        category: {
            list: `${ROOT}/admin/active_library_category/list`,
            update: `${ROOT}/admin/active_library_category/update`,
            create: `${ROOT}/admin/active_library_category/create`,
            active: `${ROOT}/admin/active_library_category/active`,
            deleted: `${ROOT}/admin/active_library_category/deleted`,
            // 类别
            select: `${ROOT}/admin/active_library_category/select`,
        },
        project: {
            list: `${ROOT}/admin/active_library_project/list`,
            // 项目
            select: `${ROOT}/admin/active_library_project/select`,
            create: `${ROOT}/admin/active_library_project/create`,
            update: `${ROOT}/admin/active_library_project/update`,
            deleted: `${ROOT}/admin/active_library_project/deleted`,
            active: `${ROOT}/admin/active_library_project/active`,
        },
        template: {
            list: `${ROOT}/admin/active_library_template/list`,
            create: `${ROOT}/admin/active_library_template/create`,
            active: `${ROOT}/admin/active_library_template/active`,
            deleted: `${ROOT}/admin/active_library_template/deleted`,
            get_detail: `${ROOT}/admin/active_library_template/get_detail`,
            update: `${ROOT}/admin/active_library_template/update`,
        },
        active: {
            selectList: `${ROOT}/admin/active_library_template/select_list`,
        },
        return_visit: {
            do_handle_return_visits: `${ROOT}/admin/return_visit/do_handle_return_visits`,
        }
    },
    activate: {
        institution: `${ROOT}/admin/institution`,
        active: `${ROOT}/admin/institution/active`,
    },
    account: {
        list: `${ROOT}/admin/admin_user/admin_users`,
        updateActive: `${ROOT}/admin/user_org/update_active`,
        detail: `${ROOT}/admin/admin_user/detail`,
        update: `${ROOT}/admin/admin_user/update`,
        create: `${ROOT}/admin/admin_user/create`,
        remove: `${ROOT}/admin/user_org/remove`,
        handedOverUser: `${ROOT}/admin/user_org_role/handed_over_user`,
    },
    user: {
        login: `${ROOT}/admin/admin_user/login`,
        // show: `${ROOT}/admin/admin_user/info`,
        show: `${ROOT}/admin/info`,
        list: `${ROOT}/admin/users`,
        loginCount: `${ROOT}/admin/user/loginCount`,
        loginRandom: `${ROOT}/admin/user/image`,
        password: `${ROOT}/admin/admin_user/password`,
        resetPassword: `${ROOT}/admin/admin_user/resetPassword`,
        sendCode: `${ROOT}/user/sendCheckCode`,
        loginSms: `${ROOT}/admin/admin_user/login_sms`,
        findUserMenu: `${ROOT}/admin/admin_user/find_user_menu`,
        doUpdateOldUser: `${ROOT}/admin/admin_user/do_update_old_user`,
        limitsList: `${ROOT}/admin/organization_role/list`,
        limitsSelect: `${ROOT}/admin/organization_role/select_menu`,
        saveOrganizationRole: `${ROOT}/admin/organization_role/do_save_organization_role`,
        findUpdateDetail: `${ROOT}/admin/organization_role/find_update_detail`,
        UpdateOrganizationRole: `${ROOT}/admin/organization_role/do_update_organization_role`,
        deletedRole: `${ROOT}/admin/organization_role/deleted`,
        findBrganization: `${ROOT}/admin/organization_role/find_by_organization`,
        createPassword: `${ROOT}/admin/admin_user/create_password`,
        admin_users_select: `${ROOT}/admin/admin_user/admin_users_select`,
    },
    dict: {
        list: `${ROOT}/listDictInfoByType`,
        find_by_types: `${ROOT}/admin/dict/find_by_types`,
    },
    organization: {
        list: `${ROOT}/admin/organizations`,
        zsgc: `${ROOT}/admin/organization`,
    },
    institution: {
        list: `${ROOT}/admin/institutions`,
        zsgc: `${ROOT}/admin/institution`,
        qrcode: `${ROOT}/admin/institution/qrcode`,
        status: `${ROOT}/admin/institution/status`,
        tree: `${ROOT}/admin/institution/tree`,
        enterOrg: `${ROOT}/admin/admin_user/enter_organization_menu`,
        exitOrg: `${ROOT}/admin/admin_user/exit_organization_menu`,
        do_register: `${ROOT}/admin/institution/do_register`,
        reviewed_ist: `${ROOT}/admin/institution/reviewed_list`,
        update_status_approved: `${ROOT}/admin/institution/update_status_approved`,
        update_status_refuse: `${ROOT}/admin/institution/update_status_refuse`,
        select_by_pid: `${ROOT}/admin/institution/select_by_pid`,
        import_aier_institution: `${ROOT}/admin/institution/import_aier_institution`,
    },
    gongyiActivity: {
        list: `${ROOT}/admin/gongyiActivities`,
        zsgc: `${ROOT}/admin/gongyiActivity`,
        qrcode: `${ROOT}/admin/gongyiActivity/qrcode`,
        status: `${ROOT}/admin/gongyiActivity/status`,
        online: `${ROOT}/admin/gongyiActivity/online`,
        statistics: `${ROOT}/admin/gongyiActivity/statistics`,
        reviewStatistics: `${ROOT}/admin/gongyiActivity/reviewStatistics`,
        activityUsers: `${ROOT}/admin/activityUsers`,
        updateStatusRecruit: `${ROOT}/admin/activityUser/update_status_recruit`,
        updateStatusRefuse: `${ROOT}/admin/activityUser/update_status_refuse`,
        updateStatusRecruitCancel: `${ROOT}/admin/activityUser/update_status_recruit_cancel`,
    },
    donationActivity: {
        list: `${ROOT}/admin/donationActivities`,
        listById: `${ROOT}/admin/donationActivitiesById`,
        zsgc: `${ROOT}/admin/donationActivity`,
        statistics: `${ROOT}/admin/donationActivity/statistics`,
        status: `${ROOT}/admin/donationActivity/status`,
        online: `${ROOT}/admin/donationActivity/online`,
        reviewStatistics: `${ROOT}/admin/donationActivity/reviewStatistics`,
    },
    qualification: {
        zsgc: `${ROOT}/admin/qualification`,
    },
    zone: {
        list: `${ROOT}/zones`,
        find_zone_by_pid: `${ROOT}/admin/zone/find_zone_by_pid`,
    },
    review: {
        zsgc: `${ROOT}/admin/review`,
    },
    reason: {
        list: `${ROOT}/admin/reasons`,
        zsgc: `${ROOT}/admin/reason`,
    },
    volunteer: {
        // list: `${ROOT}/admin/volunteers`,
        list: `${ROOT}/admin/volunteer_apply/list`,
        del: `${ROOT}/admin/volunteer_apply/admin_remove`,
        audit_List: `${ROOT}/admin/volunteer_apply/audit_List`,
        pass: `${ROOT}/admin/volunteer_apply/pass`,
        refuse: `${ROOT}/admin/volunteer_apply/refuse`,
        zsgc: `${ROOT}/admin/volunteer`,
        volunteerBasicInfo: `${ROOT}/admin/volunteer/find_volunteer_basic_info`,
        volunteerWorkInfo: `${ROOT}/admin/volunteer/find_volunteer_work_edu_info`,
        volunteerActive: `${ROOT}/admin/volunteer/find_activity_user_by_userId`,
        DonationUser: `${ROOT}/admin/volunteer/find_volunteer_donation_by_userId`,
        DonationTogether: `${ROOT}/admin/volunteer/find_by_volunteer_together_list`,
        volunteer_file_card: `${ROOT}/admin/volunteer_file/card`,
        dict: `${ROOT}/admin/dict/find_admin_dict`,
        do_import_volunteer: `${ROOT}/admin/volunteer/do_import_volunteer`,
    },
    ad: {
        list: `${ROOT}/admin/ad/ads`,
        zsgc: `${ROOT}/admin/ad/ad`,
        del: `${ROOT}/admin/ad/delete`,
        status: `${ROOT}/admin/ad/status`,
        get_detail: `${ROOT}/admin/ad/get_detail`,
        update: `${ROOT}/admin/ad/update`,
    },
    project: {
        list: `${ROOT}/admin/projects`,
        zsgc: `${ROOT}/admin/project`,
        statistics: `${ROOT}/admin/project/statistics`,
        status: `${ROOT}/admin/project/status`,
        online: `${ROOT}/admin/project/online`,
        reviewStatistics: `${ROOT}/admin/project/reviewStatistics`,
    },
    progress: {
        list: `${ROOT}/admin/donationProgresses`,
        zsgc: `${ROOT}/admin/donationProgress`,
    },
    apply: {
        list: `${ROOT}/admin/applies`,
        zsgc: `${ROOT}/admin/apply`,
        status: `${ROOT}/admin/apply/status`,
        refuse: `${ROOT}/admin/apply/refuse`,
    },
    activityUser: {
        list: `${ROOT}/admin/activityUsers`,
        gongyiActivities: `${ROOT}/admin/activityUser/gongyiActivities`,
        export: `${ROOT}/admin/activityUser/export`,
    },
    activityProgress: {
        list: `${ROOT}/admin/activityRecords`,
        zsgc: `${ROOT}/activityRecord`,
    },
    donation: {
        // list: `${ROOT}/admin/donations2`,
        list: `${ROOT}/admin/donation/get_donation_activity_detail_donation_list`,
        list2: `${ROOT}/donations`,
        activities: `${ROOT}/admin/donation/activities`,
        export: `${ROOT}/admin/donation/export`,
    },
    signLog: {
        list: `${ROOT}/admin/signLogs`,
        export: `${ROOT}/admin/signLog/export`,
    },
    message: {
        list: `${ROOT}/admin/messages`,
        zsgc: `${ROOT}/admin/message`,
        status: `${ROOT}/admin/message/status`,
    },
    dhHospital: {
        list: `${ROOT}/admin/dh_hospital/list`,
        update: `${ROOT}/admin/dh_hospital/update`,
        status: `${ROOT}/admin/message/status`,
        find_select_data: `${ROOT}/admin/dh_hospital/find_select_data`,
    },
    dhHospitalTeethFee: {
        list: `${ROOT}/admin/teeth_fee/list`,
        update: `${ROOT}/admin/teeth_fee/update`,
        create: `${ROOT}/admin/teeth_fee/create`,
        details: `${ROOT}/admin/teeth_fee/detail`,
    },
    dhHospitalTimeConfig: {
        list: `${ROOT}/admin/dh_hospital_time_config/list`,
        create: `${ROOT}/admin/dh_hospital_time_config/create`,
        update: `${ROOT}/admin/dh_hospital_time_config/update`,
        details: `${ROOT}/admin/dh_hospital_time_config/details`,
        status: `${ROOT}/admin/dh_hospital_time_config/status`,
    },
};

export default API;
