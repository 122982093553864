import {Component} from 'react';
import {Col, DatePicker, Form, Input, Radio, Row, Select, Space} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import FileUploader from '../../common/uploader';
import Editor from '../../common/editor/Editor';
import './temp.css';

const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;
export default class Donation extends Component<any> {
    state = {
        categoryList: [],
        options: [],
        projectList: [],
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.getCategorySelect();
    }

    // onSearch = (searchText: String) => {
    //   this.props.onSearch(searchText, '');
    // };
    // 获取项目
    getSelect = (id: string) => {
        Fetch.get(`${API.atl.project.select}?categoryId=${id}`).then((data) => {
            this.setState({
                projectList: data,
            });
        });
    };
    //选项联动
    handleChange = (value: string) => {
        this.getSelect(value);
        const {form} = this.props;
        form.setFieldsValue({
            projectId: null,
        });
    };
    // 获取分类
    getCategorySelect = () => {
        Fetch.get(API.atl.category.select).then((data) => {
            this.setState({
                categoryList: data,
            });
        });
    };

    render() {
        const {categoryList, projectList} = this.state;
        const {disabledFlag, account} = this.props;
        return (
            <>
                {/* <FormItem
          name="address"
          label="详细地址"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true, message: '必填' }]}
        >
          <Input />
        </FormItem> */}
                <Row>
                    <Col span={8}>
                        <FormItem
                            name="categoryId"
                            label="所属类别"
                            labelCol={{span: 9}}
                            wrapperCol={{span: 15}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                placeholder="请选择分类"
                                disabled={true}
                                onChange={this.handleChange}
                            >
                                {categoryList.map((item: any) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="projectId"
                            label="所属项目"
                            labelCol={{span: 9}}
                            wrapperCol={{span: 15}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select placeholder="请选择项目" disabled={true}>
                                {projectList.map((item: any) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                {/* <Row>
          <Col span={8}>
            <FormItem
              name="credits"
              label="对应积分"
              rules={[{ required: true, message: '必填' }]}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
            >
              {account.credits}分
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name="workPoints"
              label="工分"
              rules={[{ required: true, message: '必填' }]}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
            >
              {account.workPoints}分
            </FormItem>
          </Col>
        </Row> */}

                <FormItem
                    name="date2"
                    label="募捐时间"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <RangePicker
                        disabled={disabledFlag || !account.date2Flag}
                        showTime={{format: 'HH:mm'}}
                        format="YYYY-MM-DD HH:mm"
                    />
                </FormItem>

                <Row>
                    <Col span={8}>
                        <FormItem
                            name="total"
                            label="募捐金额"
                            rules={[{required: true, message: '必填'}]}
                            labelCol={{span: 9}}
                            wrapperCol={{span: 15}}
                        >
                            <Input
                                placeholder="单位（元）"
                                disabled={disabledFlag || !account.totalFlag}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <FormItem
                    name="continueWhenFull"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                    label="金额上限制"
                >
                    <Radio.Group disabled={disabledFlag || !account.continueWhenFullFlag}>
                        <Space direction="vertical">
                            <Radio value={true}>可以超出上限</Radio>
                            <Radio value={false}>不可以超出上限</Radio>
                        </Space>
                    </Radio.Group>
                </FormItem>
                {/*·=========== */}

                <FormItem
                    name="covers"
                    valuePropName="fileList"
                    label="封面图"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 21}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <FileUploader
                        disabled={disabledFlag || !account.coverIdsFlag}
                        multiple
                        maxCount={1}
                        listType="picture-card"
                        checkSize={false}
                        desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸375 * 210; 最多1张"
                    />
                </FormItem>

                <FormItem
                    name="content"
                    label="捐款文案内容"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 18}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Editor
                        disabled={disabledFlag || !account.contentFlag}
                        url={API.upload}
                    />
                </FormItem>
            </>
        );
    }
}
