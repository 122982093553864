import React, {Component} from 'react';
import {Button, Card, Col, DatePicker, Form, FormInstance, message, Row, Space} from 'antd';
import App from '../../../App';
import styles from './ServiceUserV2.module.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import {DetailClass} from '../util/ServiceUserDetailTypes';
import UserInfo from "../detail/UserInfo";
import ScreeningInfo from "../detail/ScreeningInfo";
import AccountInfo from "../detail/AccountInfo";
import FormItem from 'antd/lib/form/FormItem';
import FileUploader from "../../common/uploader";
import {buildAierData, defaultData} from "../util/AierCommon";
import {UploadOutlined} from '@ant-design/icons';
import IllnessInfo from "../detail/IllnessInfo";
import {decryptData} from "../../common/crypto";
import HospitalFeeInfo from "../detail/HospitalFeeInfo";
import WarVeteranInfo from "../detail/WarVeteranInfo";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import dayjs from "dayjs";

class ServiceUserHospitalScreeningAuditClass extends Component<{}, DetailClass> {

    formRef = React.createRef<FormInstance>();
    refd = React.createRef<FileUploader>();
    state: DetailClass = {
        id: decryptData(common.getQuery('id')),
        snap: defaultData.snap,
        data: defaultData.data,
        hospitalSelect: [],
    };

    componentDidMount() {
        this.getData()
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.cooperateServiceUsers.get_patient_details}?id=${id}`).then((data) => {
            buildAierData(data);
            this.setState({
                data: data,
            });

            if (data.fee && this.formRef.current !== null) {
                const timeOfDischarge = data.fee.timeOfDischarge == null ? undefined : dayjs(data.fee.timeOfDischarge);
                const invoices = data.fee.invoices ? data.fee.invoices : undefined;
                this.formRef.current.setFieldsValue({
                    timeOfDischarge: timeOfDischarge,
                    invoices: invoices
                })
            }
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    submit = (values: any) => {
        const {data} = this.state;
        this.buildFile(values);
        this.buildTime(values);
        this.cleanField(values);
        values.serviceId = this.state.id;
        if (data.fee != null && data.fee.id > 0) {
            values.id = data.fee.id;
            this.update(values);
        }
    }

    update = (values: any) => {

        Fetch.postJSON(API.aierHospitalFee.do_update_refine, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };


    cleanField = (values: any) => {
        delete values.invoices;
    }
    buildTime = (values: any) => {
        values.timeOfDischarge = values.timeOfDischarge.format('YYYY-MM-DD');
    }

    buildFile = (values: any) => {

        const files: any[] = [];
        this.addFiles(files, values.invoices, 4);
        values.files = files;
    }

    addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }

    render() {
        const {data, snap, id} = this.state;
        return (
            <div>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <Breadcrumb.Item>*/}
                {/*        <Link to="/hospitalAuditList/auditList">患者名单</Link>*/}
                {/*    </Breadcrumb.Item>*/}
                {/*    <Breadcrumb.Item>检查审核</Breadcrumb.Item>*/}
                {/*</Breadcrumb>*/}
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <UserInfo data={data}/>
                        <WarVeteranInfo id={id} hospitalSelect={[]} snap={snap} data={data}/>
                        <IllnessInfo data={data}/>
                        <ScreeningInfo data={data}/>
                        <AccountInfo data={data}/>
                        <HospitalFeeInfo data={data}/>
                        <Card className={styles.mycard}
                              title={`费用明细`}>
                            <Form
                                onFinish={this.submit}
                                ref={this.formRef}
                            >
                                <Row gutter={20}>

                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="timeOfDischarge"
                                            label="出院时间"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <DatePicker style={{width: '100%'}}
                                                        placeholder="出院时间"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                    <Card style={{width: '100%'}}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="invoices"
                                            valuePropName="fileList"
                                            label="发票"
                                            // labelCol={{span: 0}}
                                            wrapperCol={{span: 17}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <FileUploader
                                                multiple
                                                listType="picture"
                                                className="upload-list-inline"
                                                accept='*'
                                                checkSize={false}
                                                noChekckSizeMal={true}
                                                icon={<UploadOutlined/>}
                                            />
                                        </FormItem>
                                    </Card>
                                </Space>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className={styles.postSave}
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size='large'
                                        className={styles.backBtn}
                                        style={{marginRight: '15px'}}
                                        onClick={this.cancel}
                                    >
                                        取消
                                    </Button>
                                </Space>
                            </Form>
                        </Card>
                    </Space>

                </div>
            </div>
        );
    }
}

export default function ServiceUserHospitalScreeningAudit() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ServiceUserHospitalScreeningAuditClass/>
        </App>
    );
}
