import React from 'react';
import {Button, DatePicker, message, Select, Space, Spin, Table} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {PageData} from '../util/types';
import styles from "./MonthlyStatistics.module.css";
import {listDefaultData} from "../util/AierCommon";
import common from "../../common/common";
import encryptData from "../../common/crypto";
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";

type User = {};

const {RangePicker} = DatePicker;

class ServiceUserRosterListClass extends BaseListPage {
    state: PageData = {
        ...listDefaultData,
        btnQ: {
            ROLE_SERVICE_USERS_ROSTER_DETAIL: 0,
            ROLE_SERVICE_USERS_DOWNLOAD_T: 0
        },
        modalTitle: '导入结果'
    };
    private fileInput = document.getElementById('fileInput');

    componentDidMount() {
        super.componentDidMount();
        this.loadSelectData();
        this.loadHospital(null)
    }

    changeCondition = (condition: any) => {

        if (condition.settDate) {
            condition.settDate[0] = condition.settDate[0].startOf('month');
            condition.settDate[1] = condition.settDate[1].startOf('month');
        }
        if (condition.executeDate) {
            condition.executeDate[0] = condition.executeDate[0].startOf('month');
            condition.executeDate[1] = condition.executeDate[1].endOf('month');
        }
        if (condition.provinceId) {
            this.loadHospital(condition.provinceId)
        }
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition}
        });
    };

    loadSelectData = () => {
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=1`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });

    };

    loadHospital(provId: any) {
        if (provId === undefined) {
            provId = null
        }
        this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.hospital_area.find_select_data}?provId=${provId}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    loadData = () => {

        const {pageSize, searchData} = this.state;
        let url = `${API.monthly_statistics.query_page}?page=${searchData.page}&size=${pageSize}&provinceStr=${searchData.provinceId}&hospitalStr=${searchData.hospitalId}`;
        url = url + this.concatParam(searchData);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };

    reset = () => {
        super.reset(listDefaultData.searchData);
        this.loadHospital(null);
    };

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                window.location.reload()
            }
        );
    }

    concatParam = (searchData: any) => {

        let url = "";
        const settDateTemp = searchData.settDate;
        if (settDateTemp != undefined && settDateTemp.length > 0) {
            url = url + `&settStart=${settDateTemp[0].format('YYYY-MM-DD')}&settEnd=${settDateTemp[1].format('YYYY-MM-DD')}`;
        }
        const executeDateTemp = searchData.executeDate;
        if (executeDateTemp != undefined && executeDateTemp.length > 0) {
            url = url + `&execStart=${executeDateTemp[0].format('YYYY-MM-DD')}&execEnd=${executeDateTemp[1].format('YYYY-MM-DD')}`;
        }
        return url;
    }
    downloadTotal = () => {

        this.setState({
            importLoading: true,
            tip: "数据导出中"
        });
        const {searchData} = this.state;
        let url = `${API.monthly_statistics.download_total}?provinceStr=${searchData.provinceId}&hospitalStr=${searchData.hospitalId}`;
        url = url + this.concatParam(searchData);
        Fetch.get(url).then((data) => {
            if (!data) {
                message.error('下载失败，请联系管理员');
                this.setState({
                    importLoading: false,
                });
                return
            }
            common.downloadFile("“情暖老兵·守护光明”项目实施情况总览表.xlsx", API.domain + data, (callback: any) => {
                if (callback === true) {
                    message.success('处理完成');
                } else {
                    message.error('下载失败，请联系管理员');
                }
                this.setState({
                    importLoading: false,
                });
            });
        });
    };

    render() {
        //
        const {
            searchData, pageSize, totalElements,
            content, btnQ
        } = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '执行机构名称',
                width: 180,
                dataIndex: 'hospitalName',
                key: 'hospitalName',
                align: `center`,
                fixed: 'left',
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '结算时间',
                width: 120,
                dataIndex: 'settDate',
                key: 'settDate',
                align: `center`,
                fixed: 'left',
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '结算金额（元）',
                width: 100,
                dataIndex: 'totalSubsidy',
                key: 'totalSubsidy',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '执行时间',
                dataIndex: 'minDate',
                key: 'minDate',
                align: `center`,
                width: 180,
                render(value, record: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}-{record.maxDate}</span>
                    );
                },
            },
            {
                title: '患者治疗总费用（元）',
                dataIndex: 'totalPrice',
                key: 'totalPrice',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '医保报销（元）',
                dataIndex: 'totalHealthCare',
                key: 'totalHealthCare',
                align: `center`,
                width: 150,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '患者支付（元）',
                dataIndex: 'totalSelfPayPrice',
                key: 'totalSelfPayPrice',
                align: `center`,
                width: 150,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '项目补助额（元）',
                dataIndex: 'totalSubsidy',
                key: 'totalSubsidy',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '其他补助（元）',
                dataIndex: 'totalOtherGrants',
                key: 'totalOtherGrants',
                align: `center`,
                width: 100,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '受益患者数（人次/例）',
                dataIndex: 'totalNumber',
                key: 'totalNumber',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '补助额（元）',
                dataIndex: 'totalSubsidy',
                key: 'totalSubsidy',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '人次/例',
                dataIndex: 'totalNumber',
                key: '6',
                align: `center`,
                width: 140,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (text, record: any) => {
                    return (
                        <Space>
                            {btnQ.ROLE_SERVICE_USERS_ROSTER_DETAIL ? (
                                <Link to={`/serviceUserRoster/list?sId=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            ) : ('')}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>花名册</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        {/* status: '', search: '', type: '', */}

                        <Space onKeyDown={this.handleKeyDown}>
                            {sessionStorage.orgName === '管理中心' && (
                                <Select
                                    showSearch
                                    placeholder="选择省份"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, provinceId: e})
                                    }}
                                    value={searchData.provinceId}
                                    filterOption={(input: any, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: 120}}
                                    options={this.state.zoneSelect}
                                />
                            )}
                            <Select
                                showSearch
                                placeholder="选择执行机构名称"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, hospitalId: e})
                                }}
                                value={searchData.hospitalId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 200}}
                                options={this.state.hospitalSelect}
                            />
                            <RangePicker picker="month"
                                         onChange={(e) => {
                                             this.changeCondition({page: 0, settDate: e})
                                         }}
                                         allowClear={false}
                                         value={searchData.settDate}
                                         placeholder={['结算开始时间', '结算结束时间']}
                            />
                            <RangePicker picker="month"
                                         onChange={(e) => {
                                             this.changeCondition({page: 0, executeDate: e})
                                         }}
                                         allowClear={false}
                                         value={searchData.executeDate}
                                         placeholder={['执行开始时间', '执行结束时间']}/>
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={this.reset}>重置</Button>

                        </Space>
                        {btnQ.ROLE_SERVICE_USERS_DOWNLOAD_T ? (
                            <Button className={styles.downloadBtn} onClick={this.downloadTotal}>
                                导出
                            </Button>
                        ) : ('')}
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            scroll={{x: 1500}}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function MonthlyStatisticsList() {

    return (
        <App selectedKeys={['ServiceUserRoster']}
             openKeys={'aierOphthalmology'}>
            <ServiceUserRosterListClass/>
        </App>
    );
}
