import React from 'react';
import {Button, Card, Form, FormInstance, message, Modal, Space, Spin} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import createModal from "../../common/createModal";
import DhPatientBookingModelImport from "./DhPatientBookingModel";
import {decryptData} from "../../common/crypto";
import common from "../../common/common";
import AddEditCommon from "./AddEditCommon";
import {ComDhDetailOrEdit} from "../common/edit/DhEditAndDetailPageConfig";
import {defaultDhDetailData, EditType} from "../common/DhDetailType";
import PATH from "../../common/pagePath";
import {buildData} from "../common/edit/DhSubmitDataConfig";
import {defDictData, getDictData} from "../common/DhDictData";
import {buildBackFillData, dhShowFile} from "../common/edit/DhBackfillDataConfig";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

const {confirm} = Modal;

const DhPatientBookingModel = createModal(DhPatientBookingModelImport);

class EditDhPatientApplicationClass extends AddEditCommon {

    formRef = React.createRef<FormInstance>();
    state = {
        id: decryptData(common.getQuery('id')),
        hospitalSelect: [],
        addressSelect: [],
        areaStr: '',
        saveResult: {
            id: 0,
            dhStatus: 0
        },
        data: defaultDhDetailData.data,
        dataAddress: {cityId: 0, provinceId: 0, lng: 0, lat: 0},
        dictInfo: defDictData,
        paymentMethodId: '',
        importLoading: true,
        isWarVeteran: '',
        loadingDate: true
    };

    componentDidMount() {
        this.getData();
    };

    getData() {
        const {id} = this.state;
        let url = `${API.dentistry.service_user.do_get_patient_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            getDictData().then((dictData) => {
                let dataAddress = {
                    cityId: data.addressDetail.cityId,
                    provinceId: data.addressDetail.provinceId,
                    lng: data.addressDetail.lng,
                    lat: data.addressDetail.lat,
                }
                dhShowFile(data).then(() => {
                    this.setState({
                        data: data,
                        dataAddress: dataAddress,
                        importLoading: false,
                        dictInfo: dictData,
                        loadingDate: false
                    }, () => {
                        let formValue: EditType = {};
                        buildBackFillData(formValue, data, PATH.dhPatientApplication.edit)
                        this.formRef.current?.setFieldsValue({
                            ...formValue
                        });
                    })
                })
            })
        });
    }


    cancel = () => {
        window.history.go(-1);
    }
    submit = (values: any) => {

        let that = this;
        this.setState({
            importLoading: true
        }, () => {
            confirm({
                title: `是否提交数据`,
                onOk() {
                    const {id, data} = that.state;
                    const point = that.getLat(values);
                    if (!point) {
                        return
                    }
                    const target = buildData(values, point, data.userDetail?.dhStatus, PATH.dhPatientApplication.edit);
                    target.id = id;
                    that.update(target);
                },
                onCancel() {
                    that.setState({
                        importLoading: false
                    })
                }
            });
        })
    }


    update = (values: any) => {

        Fetch.postJSON(API.dentistry.service_user.do_update_application, values).then((data) => {
            this.setState({
                saveResult: data
            })
            message.success('修改成功');
            window.history.go(-1);
        }).catch(() => {
            this.setState({
                importLoading: false
            })
        });
    };

    getLat = (values: any) => {
        const {addressSelect, dataAddress} = this.state;
        const {locationAddr} = values;
        //说明修改地址了
        if (addressSelect && addressSelect.length > 0) {
            let lat = 0,
                lng = 0;
            const address: any = addressSelect.find((item: {
                value: string | ''
            }) => {
                return item.value === locationAddr;
            });
            if (address && address.location) {
                lat = address.location.lat;
                lng = address.location.lng;
            } else {
                message.error('您输入的地址不正确，请通过下拉列表选择输入');
                return false;
            }
            return {
                lng: lng,
                lat: lat,
            };
            //没有修改地址
        } else if (dataAddress.lng !== 0) {
            return {
                lng: dataAddress.lng,
                lat: dataAddress.lat,
            };
            //异常
        } else {
            message.error("无法获取定位地址");
            return false;
        }
    };

    render() {
        const {
            saveResult,
            data,
            dictInfo,
        } = this.state;
        return (
            <div>
                <BreadcrumbInfo key={"BreadcrumbInfo"}/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Form
                        onFinish={this.submit}
                        ref={this.formRef}
                    >
                        <Spin tip={'加载中'} spinning={this.state.importLoading}>
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                                {!this.state.loadingDate && (
                                    <ComDhDetailOrEdit key={"ComDhDetailOrEdit"} data={data} form={this.formRef}
                                                       dictInfo={dictInfo}
                                                       setAddressSelect={this.setAddressSelect}/>
                                )}
                                <Card>
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                        >
                                            保存
                                        </Button>
                                        {saveResult.dhStatus === 101 && (
                                            <Button
                                                type="primary"
                                                size='large'
                                                onClick={this.openBookingModel}
                                            >
                                                预约医院
                                            </Button>
                                        )}
                                        <Button
                                            size='large'
                                            onClick={this.cancel}
                                        >
                                            取消
                                        </Button>
                                    </Space>
                                </Card>
                            </Space>
                        </Spin>
                    </Form>
                </div>
                <DhPatientBookingModel
                    width={1300}
                    title={'预约医院'}
                    importResult={saveResult.id}
                    onCancel={this.closeModal}
                    onSaveSuccess={this.onSaveSuccess}
                    ref={(c) => (this.dhPatientBookingModelResult = c)}
                />
            </div>
        );
    }
}

export default function EditDhPatientApplication() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <EditDhPatientApplicationClass/>
        </App>
    );
}
