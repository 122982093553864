import {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Input, message, Modal, Select, Space, Table,} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/es/table';

import Fetch from '../common/FetchIt';
import API from '../common/api';
import createModal from '../common/createModal';

import App from '../../App';
import {AccountType} from './types';
import accountForm from './form';

const confirm = Modal.confirm;
const FormModal = createModal(accountForm);
export default class Account extends Component {
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        status: '',
        phone: '',
        username: '',
        account: null,
        title: '新建账号',
        loading: true,
        roleList: [],
        btnQ: {
            ROLE_ACCOUNT_REMOVE: 0,
            ROLE_ACCOUNT_HAND_OVER: 0,
            ROLE_ACCOUNT_ADD: 0,
            ROLE_ACCOUNT_UPDATE: 0,
            ROLE_ACCOUNT_ENABLE: 0,
        },
    };
    private modal: any = null;

    componentDidMount() {
        let btnQ = window.sessionStorage.getItem('buttons');
        let btn = {};
        if (typeof btnQ === 'string') {
            btn = JSON.parse(btnQ);

            this.setState({
                btnQ: btn['buttons'],
            });
        }

        this.getData();
        this.getRoleData();
    }

    // 获取数据
    getData = () => {
        const {page, pageSize, phone, status, username} = this.state;
        Fetch.get(
            `${API.account.list}?page=${page}&size=${pageSize}&phone=${phone}&active=${status}&username=${username}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };
    getRoleData = () => {
        Fetch.get(API.user.findBrganization).then((data) => {
            this.setState({
                roleList: data,
            });
        });
    };
    // 编辑
    edit = (row: any) => {
        Fetch.get(`${API.account.detail}/${row.id}`).then((data) => {

            const {username, id, phone, orgRoleId, orgRoleName} = data;
            this.setState(
                {
                    title: '编辑账号',
                    account: {
                        username,
                        id,
                        phone,
                        orgRoleId,
                        orgRoleName,
                    },
                },
                this.modal.show
            );
        });
    };
    // 查看
    view = (row: any) => {
        Fetch.get(`${API.account.detail}/${row.id}`).then((data) => {
            const {username, id, phone, orgRoleId, orgRoleName} = data;
            this.setState(
                {
                    title: '查看账号',
                    account: {username, id, phone, orgRoleId, orgRoleName},
                },
                () => {
                    this.modal.show();
                }
            );
        });
    };
    //  禁用
    close = (id: number, type: number) => {
        Fetch.put(`${API.account.updateActive}`, {id: id}).then(() => {
            if (type === 0) {
                message.success('禁用成功');
            } else {
                message.success('启用成功');
            }

            this.getData();
        });
    };
    // 移除
    delFn = (id: number) => {
        let that = this;
        confirm({
            title: ' 移除后,管理员将无法登陆您的组织后台',
            onOk() {
                Fetch.put(API.account.remove, {id: id}).then(() => {
                    message.success('移除成功');
                    that.getData();
                });
            },
        });
    };
    // 移交
    transfer = (id: number) => {
        let that = this;
        confirm({
            title: ' 移交后,该管理员将成为组织负责人，你将成为普通管理员',
            onOk() {
                Fetch.put(API.account.handedOverUser, {id: id}).then(() => {
                    message.loading('移交成功,将为您跳转登陆界面', 1).then(() => {
                        window.sessionStorage.clear();
                        window.location.href = '/#/';
                    });
                });
            },
        });
    };
    creat = () => {
        this.setState(
            {
                title: '新建账号',
                account: null,
            },
            this.modal.show
        );
    };
    changeInput = (val: String, type: any) => {
        this.setState({
            [type]: val,
        });
    };
    // 下一页
    changeCondition = (condition: any, load = false) => {

        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.getData();
                }
            }
        );
    };

    // 重置
    reset = () => {
        this.setState(
            {
                status: '',
                username: '',
                phone: '',
            },
            () => {
                this.getData();
            }
        );
    };

    render() {
        const {
            page,
            pageSize,
            totalElements,
            content,
            phone,
            account,
            title,
            username,
            roleList,
            btnQ,
        } = this.state;

        const columns: ColumnsType<AccountType> = [
            {
                title: '真实姓名',
                dataIndex: 'username',
                align: `center`,
            },
            {
                title: '账号',
                dataIndex: 'phone',
                align: `center`,
            },
            {
                title: '角色',
                dataIndex: 'orgRoleName',
                align: `center`,
            },
            {
                title: '创建日期',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'active',
                render: (text, record) => {
                    if (text === true) {
                        return '已启用';
                    } else if (text === false) {
                        return '已禁用';
                    } else {
                        return '';
                    }
                },
                align: `center`,
            },

            {
                title: '操作',
                align: `center`,
                render: (text, record: any) => {
                    return (
                        <Space>
                            <Button type="text" onClick={() => this.view(record)}>
                                查看
                            </Button>
                            {/* ROLE_ACCOUNT_ADD: 0,
               ROLE_ACCOUNT_UPDATE: 0,
                ROLE_ACCOUNT_ENABLE:
              0, */}
                            {btnQ.ROLE_ACCOUNT_UPDATE ? (
                                <Button type="link" onClick={() => this.edit(record)}>
                                    编辑
                                </Button>
                            ) : (
                                ''
                            )}
                            {btnQ.ROLE_ACCOUNT_ENABLE ? (
                                record.active ? (
                                    <Button
                                        type="link"
                                        danger
                                        onClick={() => this.close(record.id, 0)}
                                    >
                                        禁用
                                    </Button>
                                ) : (
                                    <Button type="text" onClick={() => this.close(record.id, 1)}>
                                        启用
                                    </Button>
                                )
                            ) : (
                                ''
                            )}

                            {btnQ.ROLE_ACCOUNT_HAND_OVER ? (
                                <Button type="text" onClick={() => this.transfer(record.id)}>
                                    移交
                                </Button>
                            ) : (
                                ''
                            )}
                            {btnQ.ROLE_ACCOUNT_REMOVE ? (
                                <Button
                                    type="text"
                                    danger
                                    onClick={() => this.delFn(record.id)}
                                >
                                    移除
                                </Button>
                            ) : (
                                ''
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <App selectedKeys={['account']}>
                <>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>账户管理</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space>
                            状态：
                            <Select
                                placeholder="请选择状态"
                                value={this.state.status}
                                onChange={(e) => this.changeCondition({page: 0, status: e})}
                            >
                                <Select.Option value="">全部</Select.Option>
                                <Select.Option value="true">启用</Select.Option>
                                <Select.Option value="false">禁用</Select.Option>
                            </Select>
                            真实姓名：
                            <Input
                                value={username}
                                onChange={(e) => {
                                    this.changeInput(e.target.value, 'username');
                                }}
                            />
                            账号：
                            <Input
                                value={phone}
                                onChange={(e) => {
                                    this.changeInput(e.target.value, 'phone');
                                }}
                            />
                            <Button onClick={this.getData} type="primary">
                                搜索
                            </Button>
                            <Button onClick={this.reset}>重置</Button>
                        </Space>
                        {btnQ.ROLE_ACCOUNT_ADD ? (
                            <div style={{float: 'right', marginLeft: 'auto'}}>
                                <Button
                                    onClick={this.creat}
                                    type="primary"
                                    icon={<PlusOutlined/>}
                                >
                                    新建账户
                                </Button>
                            </div>
                        ) : (
                            ''
                        )}

                        <Table
                            loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey={(record) => {
                                return record.id;
                            }}
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.changeCondition(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    },
                                    true
                                )
                            }
                        />
                    </div>
                    <FormModal
                        title={title}
                        reload={this.getData}
                        account={account}
                        roleList={roleList}
                        flag={title}
                        ref={(c) => (this.modal = c)}
                    ></FormModal>
                </>
            </App>
        );
    }
}
