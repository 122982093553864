import React, {useEffect, useState} from 'react';
import styles from "../financialaudit/ServiceUser.module.css";
import {Button, Form, FormInstance, Input, message, Space} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";

/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function HospitalGroupEditName(props: any) {

    const formRef = React.createRef<FormInstance>();
    const [submitButton, setSubmitButton] = useState(false);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {

        let url;
        if (props.groupId) {
            values.id = props.groupId;
            url = API.hospital_group.update;
        } else {
            url = API.hospital_group.create;
        }
        setSubmitButton(true)
        Fetch.postJSON(url, values).then(() => {
            message.success('提交成功');
            setSubmitButton(false)
            ok();
        }).finally(() => {
            setSubmitButton(false)
        });
    }

    useEffect(() => {

        if (props.groupId) {
            formRef.current?.setFieldValue("groupName", props.groupName);
        }
    }, []);

    return <>
        <Form onFinish={submit}
              ref={formRef}>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <FormItem
                    className={styles.mylabel}
                    name="groupName"
                    label="组名称"
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input placeholder="组名称" maxLength={50}/>
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={submitButton}
                        className={styles.postSave}
                    >
                        保存
                    </Button>
                    <Button
                        size='large'
                        onClick={cancel}
                    >
                        取消
                    </Button>
                </Space>
            </Space>
        </Form>
    </>;
}
