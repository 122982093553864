export type FormTitle = {
    cardTitle: string | '',
    warVeteran: string | '',
    warVeteranName: string | '',
    warVeteranIdNumber: string | '',
    warVeteranRelations: string | '',
    warVeteranPhone: string | '',
    warVeteranShow: string,
    proofOfIdentity: string | null
}

export const Veteran: FormTitle = {
    cardTitle: '身份信息',
    warVeteran: '是否是老兵本人',
    warVeteranName: '老兵姓名',
    warVeteranIdNumber: '老兵身份证号',
    warVeteranRelations: '申请人与老兵关系',
    warVeteranPhone: '老兵手机号',
    warVeteranShow: '老兵本人',
    proofOfIdentity: "退伍老兵优待证"
}

const Teacher: FormTitle = {
    cardTitle: '身份信息',
    warVeteran: '是否是退休教师本人',
    warVeteranName: '退休教师姓名',
    warVeteranIdNumber: '退休教师身份证号',
    warVeteranRelations: '申请人与退休教师关系',
    warVeteranPhone: '退休教师手机号',
    warVeteranShow: '退休教师本人',
    proofOfIdentity: "退休证"
}

const Police: FormTitle = {
    cardTitle: '身份信息',
    warVeteran: '是否是退休警察本人',
    warVeteranName: '退休警察姓名',
    warVeteranIdNumber: '退休警官身份证号',
    warVeteranRelations: '申请人与退休警察关系',
    warVeteranPhone: '退休警察手机号',
    warVeteranShow: '退休警察本人',
    proofOfIdentity: "退休证"
}

const Error: FormTitle = {
    cardTitle: '身份信息',
    warVeteran: '显示错误',
    warVeteranName: '显示错误',
    warVeteranIdNumber: '显示错误',
    warVeteranRelations: '显示错误',
    warVeteranPhone: '显示错误',
    warVeteranShow: '显示错误',
    proofOfIdentity: "显示错误"
}

export const getTitle = (val: string | null | undefined) => {

    if (val === "1") {
        return Veteran;
    } else if (val === "2") {
        return Teacher;
    } else if (val === "3") {
        return Police;
    } else if (val === "4") {
        return Error;
    } else {
        return Error;
    }
}

export const showProofOfIdentity = (serviceIdentity: string | undefined) => {

    if (serviceIdentity) {
        return ['1', '2', '3'].includes(serviceIdentity);
    } else {
        return false;
    }
}