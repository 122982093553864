import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message, Select} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';

const Option = Select.Option;

const FormItem = Form.Item;

type FormProps = {
    account: any;
    hide: () => void;
    reload: () => void;
    flag: String;
    categoryList: any;
};

type FormState = {};

class FormContent extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();
    state: FormState = {};
    submit = (values: any) => {
        if (values.id) {
            // 更改
            this.update(values);
        } else {
            // 增加
            let datas = {...values};
            delete datas.id;
            this.create(datas);
        }
    };

    create = (values: any) => {
        Fetch.postJSON(`${API.atl.project.create}`, values).then(() => {
            message.success('新增成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values: any) => {
        Fetch.put(API.atl.project.update, values).then(() => {
            message.success('修改成功');
            this.props.hide();
            this.props.reload();
        });
    };
    cancle = () => {
        this.props.hide();
    };

    render() {
        const {categoryList, account} = this.props;
        return (
            <div>
                <Form initialValues={account} onFinish={this.submit} ref={this.formRef}>
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem
                        name="name"
                        label="项目"
                        rules={[{required: true, message: '必填'}]}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                    >
                        <Input placeholder="在此输入项目名称"/>
                    </FormItem>
                    <FormItem
                        name="categoryId"
                        label="所属类别"
                        rules={[{required: true, message: '必填'}]}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                    >
                        <Select placeholder="请选择分类">
                            {categoryList.map((item: any) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>

                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button onClick={this.cancle} style={{marginRight: '15px'}}>
                            取 消
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确 定
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default FormContent;
