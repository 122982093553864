import PATH from "../../../common/pagePath";
import {message} from "antd";
import {EditType} from "../DhDetailType";

export const buildAccountFile = (values: EditType, target: any) => {

    if (values.paymentMethod === '2') {
        const files: any[] = target.files ? target.files : [];
        addFiles(files, values.accountApplicant, 2);
        addFiles(files, values.accountPayee, 3);
        addFiles(files, values.accountSignature, 4);
        target.files = files;
    }
}

const buildReportFile = (values: EditType, target: any) => {

    const files: any[] = target.files ? target.files : [];
    addFiles(files, values.rDiagnosticReports, 1);
    target.files = files;
}

const addFiles = (files: any[], data: any, type: number) => {
    if (data && data.length > 0) {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }
}

/**
 * buildAccount 和 buildAccountFile
 * 无法通过调用buildAccount来触发buildAccountFile
 * 因为某些情况下是没有文件信息的
 * @param values 表单数据
 * @param target 准备提交的数据
 */
export const buildAccount = (values: EditType, target: any) => {
    target.accountVo = {
        id: 0,
        paymentMethod: values.paymentMethod,
        idNumber: values.accountIdNumber,
        name: values.accountName,
        phone: values.accountPhone,
        backCard: values.accountBackCard,
        accountBank: values.accountBank,
        relationship: values.accountRelationshipId,
    }
}

const buildWarVeteran = (values: EditType, target: any) => {
    target.warVeteranVo = {
        serviceIdentity: values.serviceIdentity,
        warVeteranName: values.warVeteranName,
        warVeteranIdNumber: values.warVeteranIdNumber,
        warVeteranPhone: values.warVeteranPhone,
        warVeteranRelations: values.warVeteranRelations,
        warVeteran: values.warVeteran
    }
}

const buildAddr = (values: EditType, point: any, target: any) => {
    target.address = {
        province: values.provinceId,
        city: values.cityId,
        district: values.districtId,
        areaStr: values.areaStr,
        address: values.addressDetail,
        locationAddr: values.locationAddr,
        lng: point.lng,
        lat: point.lat
    }
}

const buildServiceUser = (values: EditType, target: any) => {

    target.serviceUser = {
        name: values.name,
        age: values.age,
        gender: values.gender,
        phone: values.phone,
        idNumber: values.idNumber,
        toothNum: values.toothNum
    }
}

const buildFreeClinicsReport = (values: EditType, target: any) => {

    target.report = {
        surgery: values.rSurgery,
        noSurgeryReason: values.rNoSurgeryReason
    }
    buildReportFile(values, target);
}

export const buildServiceUserFee = (values: EditType, target: any) => {

    target.serviceUserFee = {
        surgeryStart: values.surgeryStart?.format('YYYY-MM-DD'),
        surgeryEnd: values.surgeryEnd?.format('YYYY-MM-DD'),
        teethNum: values.teethNum,
        totalPrice: values.totalPrice,
        remark: values.remark,
        teethType: values.teethType
    }
    buildFeeFile(values, target)
}

export const buildBasicInfoByFee = (values: EditType, target: any) => {

    target.serviceUser = {
        idNumber: values.idNumber,
    }
    target.serviceUser = removeEmptyFields(target.serviceUser);
}

export const buildAddrByFee = (values: EditType, point: any, target: any) => {
    target.address = {
        province: values.provinceId,
        city: values.cityId,
        district: values.districtId,
        areaStr: values.areaStr,
        address: values.addressDetail,
        locationAddr: values.locationAddr
    }
    if (point) {
        target.address = {
            ...target.address,
            lng: point.lng,
            lat: point.lat
        }
    }
    target.address = removeEmptyFields(target.address);
}

function removeEmptyFields(obj: any) {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => v != null && v !== '')
    );
}

const buildFeeFile = (values: EditType, target: any) => {

    const files: any[] = target.files ? target.files : [];
    // addFiles(files, values.diagnosticReports, 2);
    addFiles(files, values.consentForms, 5);
    addFiles(files, values.hospitalizationRecords, 6);
    addFiles(files, values.invoices, 7);
    addFiles(files, values.proofIdentity, 8);
    addFiles(files, values.identityPromise, 9);
    addFiles(files, values.informed, 11);
    target.files = files;
}


export const buildData = (value: any, point: any, dhStatus: any, router: any) => {

    let target: any = {};
    if (PATH.dhHospitalPatient.edit === router) {
        buildHospital(value, point, dhStatus, target);
    } else if (PATH.dhPatientApplication.edit === router) {
        buildAdmin(value, point, dhStatus, target);
    } else if (PATH.dhPatientApplication.add === router) {
        adminAdd(value, point, dhStatus, target)
    } else {
        return null;
    }
    target.page = router;
    return target;
};

const buildHospital = (value: any, point: any, dhStatus: number, target: any) => {

    if (dhStatus === 104 || dhStatus === 105) {
        buildAccount(value, target);
        buildAccountFile(value, target);
    } else if (dhStatus === 107) {
        buildAccount(value, target);
        buildAccountFile(value, target);
        buildFreeClinicsReport(value, target);
    } else if (dhStatus === 109 || dhStatus == 111) {
        buildAccount(value, target);
        buildAccountFile(value, target);
        buildServiceUserFee(value, target);
    } else if (dhStatus == 113) {
        buildServiceUserFee(value, target);
    } else if (dhStatus === 116 || dhStatus == 117) {
        buildAccount(value, target);
        buildAccountFile(value, target);
    }
}

const buildAdmin = (value: any, point: any, dhStatus: number, target: any) => {

    if (dhStatus === 102 || dhStatus === 101 || dhStatus == 103) {
        buildServiceUser(value, target)
        buildWarVeteran(value, target)
        buildAddr(value, point, target)
        buildAccount(value, target);
        buildAccountFile(value, target);
    } else if (dhStatus === 104 || dhStatus === 105) {
        buildAccount(value, target);
        buildAccountFile(value, target);
    }
}

/**
 * 管理台修改
 *
 *     101: '待分配',
 *     102: '待审核',
 *     103: '审核退回',
 *     104: '待签到',
 *     105: '已签到',
 *     106: '不需要进行手术',
 *     107: '义诊已完成',
 *
 * @see dhStatusObj
 * @constructor
 */
const adminAdd = (value: any, point: any, dhStatus: number, target: any) => {
    if (dhStatus === 0 || dhStatus === 101) {
        buildServiceUser(value, target)
        buildWarVeteran(value, target)
        buildAddr(value, point, target)
        buildAccount(value, target);
    } else {
        message.error("请返回到列表页重新进入")
    }
}