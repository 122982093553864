import React, {useEffect} from 'react';
import {Button, DatePicker, Form, FormInstance, Input, message, Modal, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import dayjs from "dayjs";

const {confirm} = Modal;

type CreateCertificateType = {
    id: number,
    hide: any,
    reload: any,
    data: { founded: any }
}

export default function TroupGroupAddModel(props: CreateCertificateType) {

    const reason = props.id;
    const formRef = React.createRef<FormInstance>();
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {

        values.id = props.id;
        const title = props.id ? '是否确认修改' : '是否确认添加';
        confirm({
            title: title,
            onOk() {
                if (props.id) {
                    update(values);
                } else {
                    create(values);
                }
            }
        })
    }

    const create = (values: any) => {
        Fetch.postJSON(API.troup.group.create, values).then(() => {
            message.success('提交成功');
            ok();
        });
    }

    const update = (values: any) => {

        Fetch.postJSON(API.troup.group.update, values).then(() => {
            message.success('提交成功');
            ok();
        });
    }

    useEffect(() => {
        if (props.data) {
            props.data.founded = dayjs(props.data.founded)
                formRef.current?.setFieldsValue({
                    ...props.data,
                })
        }
    }, []);


    return <>

        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <Form
                onFinish={submit}
                ref={formRef}
            >
                <FormItem
                    name="groupName"
                    label="社团名称"
                    wrapperCol={{span: 17}}
                    labelCol={{span: 4}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input style={{width: '100%'}}/>
                </FormItem>
                <FormItem
                    name="founded"
                    label="成立时间"
                    wrapperCol={{span: 17}}
                    labelCol={{span: 4}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <DatePicker style={{width: '100%'}}/>
                </FormItem>
                <FormItem
                    name="leader"
                    label="负责人"
                    wrapperCol={{span: 17}}
                    labelCol={{span: 4}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input style={{width: '100%'}}/>
                </FormItem>
                <FormItem
                    name="phone"
                    label="联系电话"
                    wrapperCol={{span: 17}}
                    labelCol={{span: 4}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input style={{width: '100%'}}/>
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="middle"
                    >
                        保存
                    </Button>
                    <Button
                        size='middle'
                        style={{marginRight: '15px'}}
                        onClick={cancel}
                    >
                        取消
                    </Button>
                </Space>
            </Form>
        </Space>
    </>;
}
