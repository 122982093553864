import React, {RefObject, useEffect, useState} from 'react';
import {Form, FormInstance, Select} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './institution.module.css';

const Option = Select.Option;

type ZoneSelectProps = {
    form: RefObject<FormInstance>;
    setCitystr: any;
};

type Zone = {
    id: number;
    name: string;
};

export default function ZoneSelect(props: ZoneSelectProps) {
    const [pId, setPId] = useState(0);
    const [provinces, setProvinces] = useState<Zone[]>([]);
    const [cities, setCities] = useState<Zone[]>([]);

    useEffect(() => {
        if (pId === undefined) {
            return;
        }
        Fetch.get(`${API.zone.list}?parentId=${pId}`).then((data: Zone[]) => {
            if (pId !== 0) {
                setCities(data);
            } else {
                setProvinces(data);
            }
        });
    }, [pId]);

    if (props.form.current) {
        const provinceId = props.form.current.getFieldValue('provinceId');
        if (provinceId != pId) {
            setPId(provinceId);
        }
    }
    const getCity = (val: any, e: any) => {

        props.setCitystr(e.name);
    };
    return (
        <>
            <Form.Item
                name="provinceId"
                className={styles.mylabelP}
                rules={[{required: true, message: '必填'}]}
                style={{display: 'inline-block', width: 'calc(50% - 4px)'}}
            >
                <Select
                    placeholder="请选择"
                    onChange={(value: number) => {
                        setPId(value);
                        props.form.current?.setFieldsValue({
                            cityId: '',
                        });
                    }}
                >
                    {provinces.map((item) => {
                        return (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                name="cityId"
                className={styles.mylabelP}
                rules={[{required: true, message: '必填'}]}
                style={{
                    display: 'inline-block',
                    width: 'calc(50% - 4px)',
                    marginLeft: '8px',
                }}
            >
                <Select placeholder="请选择" onChange={getCity}>
                    {cities.map((item) => {
                        return (
                            <Option key={item.id} value={item.id} name={item.name}>
                                {item.name}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </>
    );
}
