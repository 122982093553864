import PAGE_KEY_MAP from "./PageKeyConfig";

export function getBreadcrumb() {
    const breadcrumb = sessionStorage.getItem("Breadcrumb") || '[]';
    return JSON.parse(breadcrumb);
}

export function getKey() {

    const breadcrumb = getBreadcrumb();
    const p = breadcrumb[0];
    const configType = PAGE_KEY_MAP.get(p.url);
    if (configType) {
        return configType;
    }
    return {
        selectedKeys: '',
        openKeys: ''
    };
}