import React, {useEffect, useState} from 'react';
import {Alert, Badge, BadgeProps, Button, Calendar, Card, Radio, RadioChangeEvent, Space} from 'antd';
import Fetch from "../../../common/FetchIt";
import API from "../../../common/api";
import styles from "./TaskModel.module.css";
import type {RangePickerProps} from 'antd/es/date-picker';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/zh-cn';
import FormItem from 'antd/lib/form/FormItem';

/**
 * 报销弹窗
 * @param props
 * @constructor
 */
type SelectType = {
    value: any,
    label: any
}

type EditBookingType = {
    code: string | null,
    hospitalName: string | null,
    bookingDate: string | null,
    bookingTime: number | 0,
    scheduling: Scheduling[]
}

type Scheduling = {
    hospitalId: number | 0,
    schedulingDate: string | null,
    planAmVisitors: number | 0,
    planPmVisitors: number | 0,
    actualAmVisitors: number | 0,
    actualPmVisitors: number | 0
}
export default function DhPatientBookingModel(props: any) {

    // const formRef = React.createRef<FormInstance>();
    const [selectedValue, setSelectedValue] = useState(() => dayjs());
    const [remainingTips, setRemainingTips] = useState<String>("");
    const [bookingTime, setBookingTime] = useState<number>(1);
    const [editBookingData, setEditBookingData] = useState<EditBookingType>();

    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.onCancel();
    }
    const submit = (e: any) => {

        // values.bookingDate = values.bookingDate.format("YYYY-MM-DD");
        const bookingDate = selectedValue.format("YYYY-MM-DD");
        // values.bookingId = props.importResult;
        props.form.current?.setFieldsValue({
            bookingDate: bookingDate,
            bookingTime: bookingTime,
            bookingTimeStr: bookingTime === 1 ? "上午" : "下午"
        }, () => {

        });
        ok();
        // Fetch.postJSON(API.dentistry.dh_service_user_booking.do_edit_user_booking, values).then(() => {
        //     message.success('提交成功');
        //     ok();
        // });
    }

    //TODO
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current < dayjs().startOf('month') || current >= dayjs().endOf('month'))
    };
    useEffect(() => {
        Fetch.get(`${API.dentistry.dh_service_user_booking.edit_booking_detail}?id=${props.importResult}`).then((data) => {

            data.scheduling = data.scheduling.reduce((result: any, item: any) => {
                const {schedulingDate, ...rest} = item;
                result[schedulingDate] = rest;
                return result;
            }, {});

            setEditBookingData(data)
        });
    }, [props.importResult]);


    useEffect(() => {
        const time = bookingTime === 1 ? "上午" : "下午";
        const schedulingElement = editBookingData?.scheduling[selectedValue.format("YYYY-MM-DD")];
        if (schedulingElement) {
            const number = bookingTime === 1 ? (schedulingElement.planAmVisitors -
                schedulingElement.actualAmVisitors) : (schedulingElement.planPmVisitors - schedulingElement.actualPmVisitors)
            let tips = `${time}剩余预约人数：${number}人`;
            setRemainingTips(tips);
        } else {
            setRemainingTips("");
        }
    }, [selectedValue, bookingTime]);

    useEffect(() => {

        if (editBookingData) {
            //TODO
            // formRef.current?.setFieldsValue({
            //     ...editBookingData,
            //     bookingDate: moment(editBookingData.bookingDate)
            // });
            setSelectedValue(dayjs(editBookingData.bookingDate))
        }
    }, [editBookingData])


    const getListData = (value: Dayjs, scheduling: any) => {
        let date = value.format("YYYY-MM-DD");
        let schedulingElement = scheduling[date];
        if (schedulingElement) {
            let listData = [
                {
                    type: schedulingElement.actualAmVisitors === schedulingElement.planAmVisitors ? 'error' : 'success',
                    content: '上午' + schedulingElement.planAmVisitors + '/' + schedulingElement.actualAmVisitors + '人'
                },
                {
                    type: schedulingElement.actualPmVisitors === schedulingElement.planPmVisitors ? 'error' : 'success',
                    content: '下午' + schedulingElement.planPmVisitors + '/' + schedulingElement.actualPmVisitors + '人'
                },
            ];
            return listData || [];
        }
        return [];
    };

    const dateCellRender = (value: Dayjs) => {

        const listData = getListData(value, editBookingData?.scheduling);
        return (
            <ul className={styles.events}>
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge status={item.type as BadgeProps['status']} text={item.content}/>
                    </li>
                ))}
            </ul>
        );
    };

    const onSelect = (newValue: Dayjs) => {
        setSelectedValue(newValue);
    };

    const onRadioChange = (e: RadioChangeEvent) => {
        setBookingTime(e.target.value)
    }

    return <>
        {editBookingData && (
            <>
                {/*<Form onFinish={submit}*/}
                {/*      ref={formRef}>*/}
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Card size="small"
                          title={editBookingData.hospitalName + ' 当前预约：' + editBookingData.bookingDate + ' ' +
                              (editBookingData.bookingTime === 1 ? '上午' : '下午')}>
                        <Alert message={`选择日期: ${selectedValue?.format('YYYY-MM-DD')}`}/>
                        <FormItem
                            className={styles.mylabel}
                            name="bookingDateTemp"
                            initialValue={dayjs()}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Calendar cellRender={dateCellRender}
                                      onSelect={onSelect}/>
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            name="bookingTimeTemp"
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Radio.Group onChange={onRadioChange}>
                                <Radio.Button value={1}>上午</Radio.Button>
                                <Radio.Button value={2}>下午</Radio.Button>
                            </Radio.Group>
                        </FormItem>
                        {remainingTips}
                    </Card>
                    <Space>
                        <Button
                            type="primary"
                            // htmlType="submit"
                            size="large"
                            onClick={submit}
                        >
                            确认
                        </Button>
                        <Button
                            size='large'
                            onClick={cancel}
                        >
                            取消
                        </Button>
                    </Space>
                </Space>
                {/*</Form>*/}
            </>
        )}
    </>;
}
