import {Card, Descriptions, Image} from 'antd';
import React from 'react';
import {DhDetailDataType} from "../common/DhDetailType";

type DhAccountInfoType = {
    account: DhDetailDataType["data"]["account"]
}

const DhAccountInfo: React.FC<DhAccountInfoType> = (props) => {
        return (
            <>
                {props.account && props.account.paymentMethodStr ? (
                    <Card size="small"
                          title={`银行账户`}>
                        {props.account.paymentMethodStr === "医院垫付" ? (
                            <Descriptions>
                                <Descriptions.Item label="收款方式">
                                    {props.account.paymentMethodStr}
                                </Descriptions.Item>
                            </Descriptions>
                        ) : (
                            <>
                                <Descriptions>
                                    <Descriptions.Item label="收款方式">
                                        {props.account.paymentMethodStr}
                                    </Descriptions.Item>
                                    {props.account.paymentMethod === "家属账户" ? (
                                        <>
                                            <Descriptions.Item label="联系电话">
                                                {props.account.phone}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="身份证号">
                                                {props.account.idNumber}
                                            </Descriptions.Item>
                                        </>
                                    ) : ('')}
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="收款人姓名">
                                        {props.account.name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="开户行名称">
                                        {props.account.accountBank ? props.account.accountBank : "暂未填写"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="银行卡号">
                                        {props.account.backCard ? props.account.backCard : "暂未填写"}
                                    </Descriptions.Item>
                                </Descriptions>
                                {props.account.paymentMethodStr === "家属账户" ? (
                                    <>
                                        <Descriptions>
                                            <Descriptions.Item label="收款人与申请人的关系">
                                                {props.account.relationshipStr}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions>
                                            <Descriptions.Item label="申请人户口本个人登记页">
                                                {(props.account.applicantShow) ? (
                                                    <Image
                                                        width={100}
                                                        src={props.account.applicantShow}
                                                    />
                                                ) : ("暂未上传")}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="收款人户口本个人登记页">
                                                {(props.account.payeeShow) ? (
                                                    <Image
                                                        width={100}
                                                        src={props.account.payeeShow}
                                                    />
                                                ) : ("暂未上传")}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="患者签名">
                                                {(props.account.signatureShow) ? (
                                                    <Image
                                                        width={100}
                                                        src={props.account.signatureShow}
                                                    />
                                                ) : ("暂未上传")}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                ) : ('')}
                            </>
                        )}
                    </Card>
                ) : ('')}
            </>
        );
    }
;

export default DhAccountInfo;
