import React, {Component} from 'react';
import {AutoComplete, Button, DatePicker, Form, Input, InputNumber, message, Radio, Space, Switch,} from 'antd';
import Fetch from '../common/FetchIt';
import Editor from '../common/editor/Editor';
import API from '../common/api';
import styles from './gongyiActivity.module.css';
import FileUploader from '../common/uploader';
import {LinkTable} from './LinkTable';

const FormItem = Form.Item;
const {RangePicker} = DatePicker;

class GongyiActivityForm extends Component {
    formRef = React.createRef();
    draft = 'PUBLISHED';
    changed = false;
    loading = false;

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            needSign: props.gongyiActivity ? props.gongyiActivity.needSign : true,
            signRule: props.gongyiActivity ? props.gongyiActivity.signRule : 1,
            needDonation: props.gongyiActivity
                ? props.gongyiActivity.needDonation
                : false,
        };
    }

    saveDraft = () => {
        this.draft = 'DRAFT';
        this.formRef.current?.submit();
    };

    publish = () => {
        this.draft = 'PUBLISHED';
        this.formRef.current?.submit();
    };

    submit = (values) => {
        if (this.loading) {
            return;
        }
        values.coverIds = values.coverIds.map((item) => item.id).join(',');
        values.content = values.content.toHTML
            ? values.content.toHTML()
            : values.content;
        if (values.content == '<p></p>') {
            message.error('内容不能为空！');
            return;
        }
        if (values.date2[0].isBefore(values.date1[1])) {
            message.error('活动开始时间不能早于活动报名结束时间');
            return;
        }

        values.registerStartDate = values.date1[0].format('YYYY-MM-DD HH:mm');
        values.registerEndDate = values.date1[1].format('YYYY-MM-DD HH:mm');

        values.startDate = values.date2[0].format('YYYY-MM-DD HH:mm');
        values.endDate = values.date2[1].format('YYYY-MM-DD HH:mm');

        values.signStartDate = values.signDate[0].format('YYYY-MM-DD HH:mm');
        values.signEndDate = values.signDate[1].format('YYYY-MM-DD HH:mm');

        const address = this.state.options.find(
            (item) => item.value === values.address
        );
        if (address) {
            values.lat = address.location.lat;
            values.lng = address.location.lng;
        } else if (!values.id || this.changed) {
            message.error('您输入的地址不正确，请通过下拉列表选择输入');
            return;
        }

        delete values.date1;
        delete values.date2;

        this.loading = true;
        if (values.id) {
            this.update(values);
        } else {
            values.status = this.draft;
            this.create(values);
        }
    };

    create = (values) => {
        Fetch.postJSON(`${API.gongyiActivity.zsgc}`, values).then(() => {
            this.loading = false;
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values) => {
        Fetch.putJSON(`${API.gongyiActivity.zsgc}/${values.id}`, values).then(
            () => {
                this.loading = false;
                message.success('保存成功');
                this.props.hide();
                this.props.reload();
            }
        );
    };

    onSearch = (searchText) => {
        this.changed = true;
        if (searchText && searchText.trim().length > 0) {
            Fetch.get(`${API.location}?keyword=${searchText.trim()}`).then((data) => {
                const result = JSON.parse(data);
                if (result.status === 0) {
                    const options = result.data.map((item) => {
                        return {
                            label: item.title + ' ' + item.address,
                            value: item.title + ' ' + item.address,
                            location: item.location,
                        };
                    });
                    this.setState({
                        options,
                    });
                } else {

                }
            });
        }
    };

    changeNeedSign = (value) => {
        this.setState({
            needSign: value,
        });
    };

    changeNeedDonation = (value) => {
        this.setState({
            needDonation: value,
        });
    };

    changeSignRule = (e) => {
        this.setState({
            signRule: e.target.value,
        });
    };

    render() {
        const needSign = this.state.needSign;
        const signRule = this.state.signRule;
        const needDonation = this.state.needDonation;

        const id = this.props.gongyiActivity && this.props.gongyiActivity.id;
        return (
            <div className={styles.container}>
                <Form
                    initialValues={{
                        ...this.props.gongyiActivity,
                        needSign,
                        signRule,
                        needDonation,
                    }}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem hidden noStyle name="lat">
                        <Input/>
                    </FormItem>
                    <FormItem hidden noStyle name="lng">
                        <Input/>
                    </FormItem>
                    <FormItem hidden noStyle name="status">
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="title"
                        label="活动名称"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="coverIds"
                        valuePropName="fileList"
                        label="封面图"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <FileUploader
                            multiple
                            maxCount={6}
                            listType="picture-card"
                            checkSize={false}
                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸375 * 210; 最多6张"
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="address"
                        label="活动地址"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <AutoComplete
                            options={this.state.options}
                            onSearch={this.onSearch}
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="date1"
                        label="报名时间"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <RangePicker
                            showTime={{format: 'HH:mm'}}
                            format="YYYY-MM-DD HH:mm"

                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="date2"
                        label="活动时间"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <RangePicker
                            showTime={{format: 'HH:mm'}}
                            format="YYYY-MM-DD HH:mm"

                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="username"
                        label="活动负责人"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input className={styles.myinput}/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="phone"
                        label="电话"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input className={styles.myinput}/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="job"
                        label="职务"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input className={styles.myinput}/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="needSign"
                        label="是否需要签到"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                        valuePropName="checked"
                    >
                        <Switch onChange={this.changeNeedSign}/>
                    </FormItem>
                    {needSign && (
                        <FormItem
                            className={styles.mylabel}
                            name="signDate"
                            label="签到时间"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 19}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <RangePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"
                                className={styles.myinput}
                            />
                        </FormItem>
                    )}
                    {needSign && (
                        <FormItem
                            className={styles.mylabel}
                            name="signRule"
                            label="签到规则"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 19}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Radio.Group onChange={this.changeSignRule}>
                                <Radio value={1}>关联活动地址签到</Radio>
                                <Radio value={3}>使用数字签到</Radio>
                            </Radio.Group>
                        </FormItem>
                    )}
                    {needSign && signRule === 3 && (
                        <FormItem
                            className={styles.mylabel}
                            name="signNumber"
                            label="签到ID"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 19}}
                            rules={[
                                {required: true, message: '必填'},
                                {len: 4, message: '请输入4位数字!'},
                            ]}
                        >
                            <Input type="number" className={styles.myinput}/>
                        </FormItem>
                    )}
                    {needSign && signRule === 1 && (
                        <FormItem
                            className={styles.mylabel}
                            name="signRadius"
                            label="签到范围"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 19}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Radio.Group>
                                <Radio value={100}>100米范围</Radio>
                                <Radio value={200}>200米范围</Radio>
                                <Radio value={500}>500米范围</Radio>
                                <Radio value={1000}>1公里范围</Radio>
                            </Radio.Group>
                        </FormItem>
                    )}
                    <FormItem
                        className={styles.mylabel}
                        name="content"
                        label="活动详情"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Editor url={API.upload}/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="minutes"
                        label="活动时长(分钟)"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[
                            {required: true, message: '必填'},
                            {min: 1, message: '最小1分钟', type: 'number'},
                        ]}
                    >
                        <InputNumber min={1} precision={0} className={styles.myinput}/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="needDonation"
                        label="开启募捐"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                        valuePropName="checked"
                    >
                        <Switch onChange={this.changeNeedDonation}/>
                    </FormItem>
                    {needDonation && (
                        <FormItem
                            className={styles.mylabel}
                            name="linkDonation"
                            label="开启募捐活动"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 19}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <LinkTable selectionType="radio"/>
                        </FormItem>
                    )}
                    <FormItem
                        className={styles.mylabel}
                        name="relatedDonations"
                        label="关联募捐活动"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                    >
                        <LinkTable selectionType="checkbox"/>
                    </FormItem>
                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Space>
                            {!id && (
                                <Button onClick={this.saveDraft} className={styles.mybtn}>
                                    存草稿
                                </Button>
                            )}
                            <Button
                                type="primary"
                                onClick={this.publish}
                                className={styles.mybtn}
                            >
                                提交
                            </Button>
                        </Space>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default GongyiActivityForm;
