import {CheckboxType, EyeDiseasesConfig} from "./ServiceUserDetailTypes";
import {eyeTypeArr, eyeTypeLeft, eyeTypeRight, getCataract, getFundus, getGillium} from "./AierCommon";
import {RefObject} from "react";
import {FormInstance} from "antd";

/**
 *
 * @param any
 * @param selectCheckbox
 * @constructor
 */
export type EyeQuotaType = {
    quotaCode: string,
    complete: number
}
export type EyeStatusType = {
    left: number,
    right: number,
    allComplete: number,
    partComplete: number
}

export const CATARACT = "CATARACT";
export const FUNDUS = "FUNDUS";
export const GILLIUM = "GILLIUM";

function init(eyeQuotaType: EyeQuotaType[], formRef: RefObject<FormInstance>) {

    const cataractStatus: EyeStatusType = {left: 0, right: 0, allComplete: 0, partComplete: 0};
    const fundusStatus: EyeStatusType = {left: 0, right: 0, allComplete: 0, partComplete: 0};
    const gilliumStatus: EyeStatusType = {left: 0, right: 0, allComplete: 0, partComplete: 0};
    let cataract: CheckboxType = getCataract();
    let fundus: CheckboxType = getFundus();
    let gillium: CheckboxType = getGillium();
    eyeQuotaType.forEach(item => {
        if (item.quotaCode === "B01") {
            cataractStatus.left = item.complete
        } else if (item.quotaCode === "B02") {
            cataractStatus.right = item.complete
        }
        if (item.quotaCode === "FUNDUS01") {
            fundusStatus.left = item.complete
        } else if (item.quotaCode === "FUNDUS02") {
            fundusStatus.right = item.complete
        }
        if (item.quotaCode === "G01") {
            gilliumStatus.left = item.complete
        } else if (item.quotaCode === "G02") {
            gilliumStatus.right = item.complete
        }
    })
    checkAll(cataractStatus);
    checkAll(fundusStatus);
    checkAll(gilliumStatus);
    let eyeDiseases: CheckboxType[] = [];
    let cataractSelect: any[] = [];

    addCataract(cataractStatus, cataract, eyeDiseases, cataractSelect, formRef);
    let fundusSelect: any[] = [];
    addFundus(fundusStatus, fundus, eyeDiseases, fundusSelect);
    let gilliumSelect: any[] = [];
    addGillium(gilliumStatus, gillium, eyeDiseases, gilliumSelect);
    //默认选择？
    let cataractEyeType = true;
    if (cataractStatus.partComplete === 0 &&
        cataractStatus.allComplete === 0) {
        cataractEyeType = false;
    }
    const result: EyeDiseasesConfig = {
        checkBox: eyeDiseases,
        cataractEyeType: cataractEyeType,
        fundusEyeType: true,
        gilliumEyeType: true,
        cataractSelect: cataractSelect,
        fundusSelect: fundusSelect,
        gilliumSelect: gilliumSelect,
        cataractStatus: cataractStatus,
        fundusStatus: fundusStatus,
        gilliumStatus: gilliumStatus,
    }
    return result;
}

function addGillium(gilliumStatus: EyeStatusType,
                    gillium: CheckboxType,
                    eyeDiseases: CheckboxType[],
                    gilliumSelect: any[]) {
    //翼状胬肉条件 翼状胬肉不支持和白内障同时报销，只支持眼底病单独报销。
    //具体的选择无法通过init 方法来控制
    if (gilliumStatus.partComplete === 0 &&
        gilliumStatus.allComplete === 0) {
        //一只眼都没有做
        eyeDiseases.push(gillium)
        addAll(eyeTypeArr, gilliumSelect);
    } else if (gilliumStatus.partComplete === 1 &&
        gilliumStatus.allComplete === 0) {
        //做了其中一只眼
        eyeDiseases.push(gillium)
        if (gilliumStatus.left === 1) {
            gilliumSelect.push(eyeTypeRight)
        } else if (gilliumStatus.right === 1) {
            gilliumSelect.push(eyeTypeLeft)
        }
    } else if (gilliumStatus.allComplete === 1) {
        //双眼全部完成
        gillium.disabled = true;
        eyeDiseases.push(gillium)
    }
}

function addAll(arr: any[], target: any[]) {

    if (arr) {
        arr.forEach((item) => {
            target.push(item)
        })
    }
}

function addFundus(fundusStatus: EyeStatusType,
                   fundus: CheckboxType,
                   eyeDiseases: CheckboxType[],
                   fundusSelect: any[]) {
    //眼底病条件 眼底病支持和白内障同时报销，同时也支持眼底病单独报销。
    //因为白内障在首次填写时已经默认勾选，所以眼底病只需要关注自身是否全部完成。
    if (fundusStatus.partComplete === 0 &&
        fundusStatus.allComplete === 0) {
        //一只眼都没有做
        eyeDiseases.push(fundus)
        addAll(eyeTypeArr, fundusSelect);
    } else if (fundusStatus.partComplete === 1 &&
        fundusStatus.allComplete === 0) {
        //做了其中一只眼
        eyeDiseases.push(fundus)
        if (fundusStatus.left === 1) {
            fundusSelect.push(eyeTypeRight)
        } else if (fundusStatus.right === 1) {
            fundusSelect.push(eyeTypeLeft)
        }
    } else if (fundusStatus.allComplete === 1) {
        //双眼全部完成
        fundus.disabled = true;
        eyeDiseases.push(fundus)
    }
}

function addCataract(cataractStatus: EyeStatusType,
                     cataract: CheckboxType,
                     eyeDiseases: CheckboxType[],
                     cataractSelect: any[],
                     formRef: RefObject<FormInstance>) {

    //白内障条件
    //如果白内障一只眼睛都没有完成，那么白内障是必填的且不可操作 （这个只能通过form 来操作）
    //如果白内障完成一直眼睛（部分完成），那么白内障是可选的
    //如果白内障全部完成，那么白内障是不可选择的
    if (cataractStatus.partComplete === 0 &&
        cataractStatus.allComplete === 0) {
        //一只眼都没有做
        cataract.disabled = true;
        eyeDiseases.push(cataract)
        addAll(eyeTypeArr, cataractSelect);
        formRef.current?.setFieldValue("eyeDiseases", ["CATARACT"])
    } else if (cataractStatus.partComplete === 1 &&
        cataractStatus.allComplete === 0) {
        //做了其中一只眼
        eyeDiseases.push(cataract)
        if (cataractStatus.left === 1) {
            cataractSelect.push(eyeTypeRight)
        } else if (cataractStatus.right === 1) {
            cataractSelect.push(eyeTypeLeft)
        }
    } else if (cataractStatus.allComplete === 1) {
        //双眼全部完成
        cataract.disabled = true;
        eyeDiseases.push(cataract)
    }
}

function checkAll(status: EyeStatusType) {

    if (status.left === 1 && status.right === 1) {
        status.allComplete = 1;
    }
    if (status.left === 1 || status.right === 1) {
        status.partComplete = 1;
    }
}

export function EyeDiseasesUtil(EyeQuotaType: EyeQuotaType[], formRef: RefObject<FormInstance>) {

    return init(EyeQuotaType, formRef)
}

export const EyeDiseasesObj = {

    B01: '白内障左眼',
    B02: '白内障右眼',
    FUNDUS01: '眼底病左眼',
    FUNDUS02: '眼底病右眼',
    G01: '翼状胬肉左眼',
    G02: '翼状胬肉右眼'
}

export const EyeDiseasesCompleteObj = {

    0: '未手术',
    1: '手术完成'
}
