import React, {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Card, Descriptions, message,} from 'antd';
import copy from 'copy-to-clipboard';

import styles from './institution.module.css';
import {useParams} from 'react-router-dom';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import App from '../../App';
import createModal from '../common/createModal';
import InstitutionForm from './InstitutionForm';
import InstitutionPoster from './InstitutionPoster';
import html2canvas from 'html2canvas';

const FormModal = createModal(InstitutionForm);
const CertModal = createModal(InstitutionPoster);

export const renderType = function (text, concise = false) {
    if (concise) {
        if (text === '1') {
            return '乐龄';
        } else if (text === '2') {
            return '敬老';
        } else {
            return '乐龄/敬老';
        }
    }
    if (text === '1') {
        return '乐龄志愿服务组织';
    } else if (text === '2') {
        return '敬老志愿服务组织';
    } else {
        return '乐龄志愿服务组织/敬老志愿服务组织';
    }
};

export const renderCategory = function (text) {
    if (text === 'PROVINCE') {
        return '省级老基会';
    } else if (text === 'COUNTRY') {
        return '国家老基会';
    } else if (text === 'YUELING_ORG') {
        return '乐龄之家组织';
    } else if (text === 'YUELING_SUB_ORG') {
        return '乐龄下属组织';
    }
    return '';
};

function InstitutionDetail() {
    const [institution, setInstitution] = useState();
    const params = useParams();

    const loadData = () => {
        Fetch.get(`${API.institution.zsgc}/${params.id}`).then((data) => {
            setInstitution(data);
        });
    };

    useEffect(
        function () {
            loadData();
        },
        [params.id]
    );

    function renderStatus(status) {
        if (status === 'DRAFT') {
            return '草稿';
        } else if (status === 'PUBLISHED') {
            return '已发布';
        } else if (status === 'DISABLED') {
            return '已下架';
        }
    }

    let modal = null;
    let certModal = null;
    let posterRef = null;

    if (!institution) {
        return null;
    }

    const copyIt = () => {
        https: copy(API.domain1 + '/#/activate/?id=' + institution.id);
        message.success('已复制!');
    };

    const edit = () => {
        modal.show();
    };

    const renderIdImg = (url) => {
        if (!url) {
            return ' - ';
        }
        const imgs = url.split(',');
        return (
            <div>
                {imgs.map((img, index) => {
                    return (
                        <a href={API.domain + img} target="_blank" key={index}>
                            <img src={API.domain + img} width={100}/>
                        </a>
                    );
                })}
            </div>
        );
    };

    const onOk = () => {
        html2canvas(posterRef, {
            backgroundColor: null,
        }).then(function (canvas) {
            const link = document.createElement('a');
            link.download = 'certification.png';
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>
                    <a href="/#/institution">组织列表</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>组织详情</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: '100%'}}
            >
                <Card
                    title={`组织名称: ${institution.name}`}
                    extra={
                        <Button type="primary" onClick={edit}>
                            编辑
                        </Button>
                    }
                >
                    <Descriptions column={4}>
                        <Descriptions.Item label="组织编码">
                            {institution.code}
                        </Descriptions.Item>
                        <Descriptions.Item label="组织海报">
                            {' '}
                            <a href="javascript:void(0)" onClick={() => certModal.show()}>
                                查看
                            </a>{' '}
                        </Descriptions.Item>
                        <Descriptions.Item label="状态">
                            {institution.isActive === 1 ? '已激活' : '未激活'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card title="组织信息" className={styles.mycard}>
                    <Descriptions>
                        <Descriptions.Item label="组织名称">
                            {institution.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属省市">
                            {institution.province}
                            {institution.city}
                        </Descriptions.Item>
                        <Descriptions.Item label="地址">
                            {institution.address.detail}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="组织性质">
              {renderCategory(institution.category)}
            </Descriptions.Item>
            <Descriptions.Item label="上级组织">
              {institution.pname}
            </Descriptions.Item> */}
                        <Descriptions.Item label="组织类型">
                            {renderType(institution.type)}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属行业">
                            {institution.industryName}
                        </Descriptions.Item>
                        <Descriptions.Item label="负责人">
                            {institution.username}
                        </Descriptions.Item>
                        <Descriptions.Item label="负责人联系电话">
                            {institution.phone}
                        </Descriptions.Item>
                        <Descriptions.Item label="财务负责人">
                            {institution.financeUsername}
                        </Descriptions.Item>
                        <Descriptions.Item label="财务负责人电话">
                            {institution.financePhone}
                        </Descriptions.Item>
                        <Descriptions.Item label="发起活动数">
                            {institution.gongyiActivityNum}
                        </Descriptions.Item>
                        <Descriptions.Item span="3" label="发起募捐数">
                            {institution.donationActivityNum}
                        </Descriptions.Item>
                        <Descriptions.Item label="法人身份证复印件">
                            {renderIdImg(institution.legalPersonIdcardUrl)}
                        </Descriptions.Item>
                        <Descriptions.Item span="2" label="营业执照扫描件盖公章">
                            {renderIdImg(institution.businessLicenseUrl)}
                        </Descriptions.Item>
                        <Descriptions.Item label="银行开户许可证扫描件（加盖公章）">
                            {renderIdImg(institution.bankAccountUrl)}
                        </Descriptions.Item>
                    </Descriptions>
                    {/* {!institution.isActive && (
            <Row
              className={styles.activeDiv}
              justify="space-between"
              align="middle"
            >
              <Col span={18}>
                <div>
                  激活链接: {API.domain1 + '#/activate?id=' + institution.id}
                </div>
                <div>激活码: {institution.activeCode}</div>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Button onClick={copyIt}>复制</Button>
              </Col>
            </Row>
          )} */}
                </Card>
                <FormModal
                    title={'编辑组织'}
                    reload={loadData}
                    institution={format(institution)}
                    ref={(c) => (modal = c)}
                />
                <CertModal
                    title={'组织海报'}
                    width={808}
                    institution={institution}
                    showFooter
                    onOk={onOk}
                    okText="下载海报"
                    setPosterRef={(element) => (posterRef = element)}
                    ref={(c) => (certModal = c)}
                />
            </div>
        </>
    );
}

export const format = function (institution) {
    const newInstitution = {...institution};
    if (typeof newInstitution.address !== 'string') {
        newInstitution.provinceId = newInstitution.address.provinceId;
        newInstitution.cityId = newInstitution.address.cityId;
        newInstitution.address = newInstitution.address.detail;
    }

    if (newInstitution.legalPersonIdcardUrl) {
        const idFiles = newInstitution.legalPersonIdcardUrl.split(',');
        newInstitution.legalPersonIdcardUrl = idFiles.map((item, index) => {
            const url = item.indexOf('http') !== -1 ? item : API.domain + item;
            return {id: index, uid: index, status: 'done', name: item, url};
        });
    }
    if (newInstitution.businessLicenseUrl) {
        const url =
            newInstitution.businessLicenseUrl.indexOf('http') !== -1
                ? newInstitution.businessLicenseUrl
                : API.domain + newInstitution.businessLicenseUrl;
        newInstitution.businessLicenseUrl = [
            {
                id: 1,
                uid: 1,
                status: 'done',
                name: newInstitution.businessLicenseUrl,
                url,
            },
        ];
    }
    if (newInstitution.bankAccountUrl) {
        const url =
            newInstitution.bankAccountUrl.indexOf('http') !== -1
                ? newInstitution.bankAccountUrl
                : API.domain + newInstitution.bankAccountUrl;
        newInstitution.bankAccountUrl = [
            {
                id: 1,
                uid: 1,
                status: 'done',
                name: newInstitution.bankAccountUrl,
                url,
            },
        ];
    }
    if (newInstitution.type) {
        newInstitution.type = newInstitution.type.split(',');
    }

    return newInstitution;
};

export default function InstitutionDetailIndex() {
    return (
        <App selectedKeys={['institution']} openKeys="InstitutionMenu">
            <InstitutionDetail/>
        </App>
    );
}
