import {BasePageType} from "../common/listpage/BaseListPageType";
import {Dayjs} from "dayjs";

export type TaxesListType = BasePageType & {
    searchData: {
        page: number | 0,
        taxesDate: Dayjs | undefined,
        name: string | '',
        idNumber: string | ''
    },
}

export function initTaxesList(): TaxesListType {

    return {
        searchData: {
            page: 0, taxesDate: undefined,
            name: '',
            idNumber: ''
        },
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        btnQ: {},
        content: [],
        importLoading: undefined,
        tip: undefined,
        selectId: 0
    }
}

export const initTaxesListData = initTaxesList();

export type TaxesDetailType = {
    userDetail: {
        code: string | '',
        userName: string | '',
        idNumber: string | '',
        phone: string | '',
        totalAmount: number | 0,
        payoutNum: number | 0,
        maxTaxes: string | '',
    },
    logDetails: [
        {
            code: string | '',
            activityId: number | 0,
            activityName: string | '',
            serviceId: number | 0,
            serviceUserName: string | '',
            account: number | 0,
            releaseTime: string | '',
            records: [
                {
                    "enterLogCode": string | '',
                    "taxes": string | '',
                    "account": number | 0,
                }
            ]
        }
    ]
}