import React, {Component} from 'react';
import {Breadcrumb, Button, Checkbox, Col, Form, FormInstance, Input, message, Row, Select,} from 'antd';
import {Link} from 'react-router-dom';
import styles from './supplier.module.css';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import FileUploader from '../common/uploader';

import App from '../../App';

const {Option} = Select;

const FormItem = Form.Item;

class GoodsEdit1 extends Component {
    formRef = React.createRef<FormInstance>();
    state = {
        data: {convertibleType: 1, stock: 0, stock1: 0, productLabels: []},
        supllerList: [],
        id: 0,
        operationalActivitiesSelect: [],
        productLabels: [],
        keyString: ""
    };

    componentDidMount() {
        const id = common.getQuery('id');
        if (id) {
            this.setState({id}, () => {
                this.getData();
            });
        }
        this.getsupller();
        this.getOperationalActivitiesSelect();
        // goodsDetail;
    }

    getData = () => {
        Fetch.get(`${API.goods.goodsDetail}?id=${this.state.id}`).then((res) => {
            this.setState({
                data: {...res, operationType: 1, stock1: 0},
            });
            this.setState({
                productLabels: res.productLabels,
                keyString: "success"
            });
            //@ts-ignore
            this.formRef.current.setFieldsValue({
                ...res,
                coverUrl: [{url: API.domain + res.coverUrl}],
                detailUrl: [{url: API.domain + res.detailUrl}],
                operationType: 1,
                stock1: 0
            });
        });
    };
    getOperationalActivitiesSelect = () => {
        Fetch.get(`${API.operationalActivity.getOperationalActivitiesSelect}`).then((res) => {
            this.setState({
                operationalActivitiesSelect: res,
            });
        });
    };
    getsupller = () => {
        Fetch.get(API.goods.getsupllerlist).then((res) => {
            if (res.length > 0) {
                this.setState({
                    supllerList: res,
                });
                if (!common.getQuery('id')) {
                    //@ts-ignore
                    this.formRef.current.setFieldsValue({
                        pointsMallSupplierId: res[0].id,
                        operationType: 1,
                    });
                }
            }
        });
    };
    submit = (values: any) => {
        let datas = {
            ...values,
            coverUrl: values.coverUrl[0].url,
            detailUrl: values.detailUrl[0].url,
            stockVo: {operationType: values.operationType, stock: values.stock1},
        };
        delete datas.operationType;
        delete datas.stock1;
        if (values.id) {
            // 更改
            this.update(datas);
        } else {
            // 增加
            delete datas.id;
            this.create(datas);
        }
    };

    create = (values: any) => {
        Fetch.postJSON(API.goods.goodscreate, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };
    cancle = () => {
        window.history.go(-1);
    };
    update = (values: any) => {
        let that = this;
        let data = {
            ...values,
            coverUrl: that.changeUrl(values.coverUrl),
            detailUrl: that.changeUrl(values.detailUrl),
        };

        Fetch.putJSON(API.goods.goodsUpdate, data).then(() => {
            message.success('保存成功');
            window.history.go(-1);
        });
    };

    changeUrl(url: string) {
        let urls = '';

        if (url.indexOf(API.domain) !== -1) {
            urls = url.replace(API.domain, '');
        } else {
            urls = url;
        }
        return urls;
    }

    render() {
        const {data, supllerList} = this.state;
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/goodslist">商品管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {common.getQuery('id') ? '修改商品' : '新建商品'}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <div className={styles.container}>
                        <Form
                            initialValues={data}
                            onFinish={this.submit}
                            ref={this.formRef}
                        >
                            <FormItem hidden noStyle name="id">
                                <Input/>
                            </FormItem>
                            {/* <Row>
                <Col> */}

                            <FormItem
                                className={styles.mylabel}
                                name="name"
                                label="商品名称"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="商品名称"/>
                            </FormItem>

                            {/* </Col>
              </Row> */}
                            <FormItem
                                className={styles.mylabel}
                                name="coverUrl"
                                valuePropName="fileList"
                                label="封面图"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <FileUploader
                                    checkSize={false}
                                    desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸 345 * 180"
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="worth"
                                label="商品价值"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input

                                    placeholder="单位元"
                                    type="number"
                                />
                            </FormItem>
                            <Row>
                                <Col span={6}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="convertibleType"
                                        label="兑换类型"
                                        labelCol={{span: 16}}
                                        wrapperCol={{span: 8}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <Select style={{width: '98%'}}>
                                            <Option value={1}>工分</Option>
                                            <Option value={2}>积分</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="convertibleDivide"
                                        wrapperCol={{span: 16}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <Input placeholder="填入具体分值" type="number"/>
                                    </FormItem>
                                </Col>
                            </Row>
                            <FormItem
                                className={styles.mylabel}
                                name="detailUrl"
                                valuePropName="fileList"
                                label="商品详情图片"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <FileUploader
                                    checkSize={false}
                                    noChekckSizeMal={true}
                                    desc="支持扩展名：.jpg、.png"
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="pointsMallSupplierId"
                                label="供应商"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Select style={{width: '100%'}}>
                                    {supllerList.map((item: any) => {
                                        return (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>

                            <FormItem
                                className={styles.mylabel}
                                label="剩余库存"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                            >
                                {data.stock}
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                label="商品标签"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 10}}
                                valuePropName="checked"
                                name="productLabels"
                            >
                                <Checkbox.Group
                                    key={this.state.keyString}
                                    options={this.state.operationalActivitiesSelect}
                                    defaultValue={this.state.productLabels}
                                    disabled={false}
                                    onChange={productLabels => this.setState({productLabels})}
                                />
                            </FormItem>
                            <Row>
                                <Col span={1}></Col>

                                <Col span={3}>
                                    <FormItem className={styles.mylabel} name="operationType">
                                        <Select>
                                            <Option value={1}>增加</Option>
                                            {common.getQuery('id') ? (
                                                <Option value={2}>减少</Option>
                                            ) : (
                                                ''
                                            )}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={10}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="stock1"
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <Input placeholder="输入具体数值" type="number"/>
                                    </FormItem>
                                </Col>
                            </Row>
                            <FormItem wrapperCol={{span: 12, offset: 2}} className="center">
                                <Button
                                    size="large"
                                    className={styles.mybtn}
                                    style={{marginRight: '15px'}}
                                    onClick={this.cancle}
                                >
                                    取 消
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className={styles.mybtn}
                                >
                                    提 交
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}

export default function GoodsEdit() {
    return (
        <App selectedKeys={['goodsList']} openKeys="PMallMenu">
            <GoodsEdit1/>
        </App>
    );
}
