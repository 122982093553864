//身份证号
export const ID_NUMBER =
    {
        pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/,
        message: "身份证号格式错误"
    }
//手机号
export const PHONE_NUMBER =
    {
        pattern: /^1\d{10}$/,
        message: "手机号格式错误"
    }
//银行卡
export const BACK_CARD =
    {
        pattern: /^([1-9]{1})(\d{11}|\d{15}|\d{16}|\d{17}|\d{18})$/,
        message: "银行卡格式错误"
    }


export const getIdNumberRole = (change: boolean) => {

    if (change === true) {
        return [{required: false, message: '必填'}, ID_NUMBER]
    } else {
        return [{required: false, message: '必填'}]
    }
}

export const getBackCardRole = (change: boolean, required: boolean | undefined) => {

    if (change === true) {
        return [{required: required, message: '必填'}, BACK_CARD]
    } else {
        return [{required: required, message: '必填'}]
    }
}