import React, {Component} from 'react';
import {Button, Space,} from 'antd';
import App from '../../../App';
import styles from '../add/ServiceUser.module.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import {DetailClass} from '../util/ServiceUserDetailTypes';
import {buildAierData, defaultData} from '../util/AierCommon'
import UserInfo from "./UserInfo";
import ScreeningInfo from "./ScreeningInfo";
import AccountInfo from "./AccountInfo";
import HospitalFeeInfo from "./HospitalFeeInfo";
import IllnessInfo from "./IllnessInfo";
import {decryptData} from "../../common/crypto";
import Voucher from "./Voucher";
import WarVeteranInfo from "./WarVeteranInfo";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

class ServiceUserHospitalDetailClass extends Component<{}, DetailClass> {

    state: DetailClass = {
        id: decryptData(common.getQuery('id')),
        snap: decryptData(common.getQuery('snap')),
        data: defaultData.data,
        hospitalSelect: []
    };

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id, snap} = this.state;
        let url = `${API.cooperateServiceUsers.get_patient_details}?id=${id}`;
        if (snap === 'true') {
            let bId = decryptData(common.getQuery('bId'));
            url = `${API.service_users_snap.get_patient_details}?id=${id}&bId=${bId}`;
        }
        Fetch.get(url).then((data) => {
            buildAierData(data);
            this.setState({
                data: data,
            });
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const {data, id, snap} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <UserInfo data={data}/>
                        <WarVeteranInfo id={id} hospitalSelect={[]} snap={snap} data={data}/>
                        <IllnessInfo data={data}/>
                        <ScreeningInfo data={data}/>
                        <AccountInfo data={data}/>
                        <HospitalFeeInfo data={data}/>
                        <Voucher data={data}/>
                    </Space>
                    <Button
                        // type="primary"
                        size="large"
                        onClick={this.cancel}
                        className={styles.detailBackBtn}
                    >
                        返回
                    </Button>
                </div>
            </div>
        );
    }
}

export default function ServiceUserHospitalDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ServiceUserHospitalDetailClass/>
        </App>
    );
}
