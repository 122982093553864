import {RoleType} from "./DhDictData";
import {BasePageType} from "../../common/listpage/BaseListPageType";
import {Dayjs} from "dayjs";

// export type DhPageType = {
//     searchData: { page: number | 0 },
//     pageSize: number | 0,
//     totalElements: number | 0,
//     totalPages: number | 0,
//     btnQ: {},
//     content: [],
//     importLoading: boolean | undefined,
//     tip: string | undefined,
//     selectId: number | 0
// }

export const BaseDhPageDate: BasePageType = {
    searchData: {page: 0},
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    btnQ: {},
    content: [],
    importLoading: undefined,
    tip: undefined,
    selectId: 0
}

export type ApplicationListType = BasePageType & {

    searchData: {
        name: string | undefined,
        phone: string | undefined,
        idCard: string | undefined,
        status: string | undefined,
        hospitalId: string | undefined,
    },
    btnQ: RoleType
    modalTitle: string | null,
    importResultData: string | null,
    selectId: number | 0,
    hospitalSelect: [] | undefined,
    statusListArr: [] | undefined,
}

export const ApplicationList: ApplicationListType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        phone: undefined,
        idCard: undefined,
        status: undefined,
        hospitalId: undefined,
        page: 0
    },
    modalTitle: '导入结果',
    importResultData: null,
    selectId: 0,
    hospitalSelect: undefined,
    statusListArr: []
}

export type HospitalPatientType = BasePageType & {
    searchData: {
        name: undefined,
        phone: undefined,
        idCard: undefined,
        status: undefined,
        bookingDate: Dayjs | undefined,
        page: 0
    }
    btnQ: RoleType,
    statusListArr: [] | undefined
}

export const HospitalPatientData: HospitalPatientType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        phone: undefined,
        idCard: undefined,
        status: undefined,
        bookingDate: undefined,
        page: 0
    },
    statusListArr: undefined
}


export type DhSalesmanType = BasePageType & {
    searchData: {
        name: undefined,
        seatUserId: undefined,
        page: 0
    }
}

export const DhSalesmanData: DhSalesmanType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        seatUserId: undefined,
        page: 0
    },
}

