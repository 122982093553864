import React, {Component} from 'react';
import {Button, Card, Form, Input, Layout, message} from "antd";
import Fetch from "../common/FetchIt";
import API from "../common/api";
import styles from './login.module.css';
import {Password} from './types';
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import App from "../../App";
import UserSider from "./UserSider";

const FormItem = Form.Item;

type FormProps = {}

type FormState = {}

const {Content} = Layout;

class PasswordForm extends Component<FormProps, FormState> {
    submit = (values: any) => {
        this.save(values);
    };

    save = (values: Password) => {
        Fetch.put(`${API.user.password}`, values).then(() => {
            message.success('保存成功');
        });
    }

    render() {
        return (
            <Card className="userCard">
                <Layout>
                    <UserSider selectedKey="2"/>
                    <Content className={styles.mycontent}>
                        <Form
                            onFinish={this.submit}
                        >
                            <FormItem className={styles.mylabel} name="oldPassword" label="旧密码" labelCol={{span: 4}}
                                      wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                <Input.Password

                                />
                            </FormItem>
                            <FormItem className={styles.mylabel} name="newPassword" label="新密码" labelCol={{span: 4}}
                                      wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                <Input.Password

                                />
                            </FormItem>
                            <FormItem className={styles.mylabel} name="rePassword" label="重复新密码"
                                      labelCol={{span: 4}}
                                      wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                <Input.Password

                                    iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                />
                            </FormItem>
                            <FormItem wrapperCol={{span: 6, offset: 4}} className="center">
                                <Button type="primary" htmlType="submit" className={styles.mybtn}>确认</Button>
                            </FormItem>
                        </Form>
                    </Content>
                </Layout>
            </Card>
        );
    }
}

export default function PasswordIndex() {
    return (
        <App selectedKeys={['password']}>
            <PasswordForm/>
        </App>
    )
}
