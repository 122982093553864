import React, {Component} from 'react';
import {Button, Card, Form, FormInstance, Input, Layout, message} from "antd";
import Fetch from "../common/FetchIt";
import API from "../common/api";
import styles from './login.module.css';
import {Qualification} from './types';
import App from "../../App";
import UserSider from "./UserSider";

const FormItem = Form.Item;

type FormProps = {}

type FormState = {
    qualification: Qualification | undefined,
}

const {Content} = Layout;

class QualificationForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();

    submit = (values: any) => {
        this.save(values)
    };

    save = (values: Qualification) => {
        Fetch.putJSON(`${API.qualification.zsgc}/1`, values).then(() => {
            message.success('提交成功');
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        Fetch.get(`${API.qualification.zsgc}/1`).then(data => {
            this.formRef.current?.setFieldsValue(data)
        });
    };

    render() {
        return (
            <Card className="userCard">
                <Layout>
                    <UserSider selectedKey="3"/>
                    <Content className={styles.mycontent}>
                        <Form
                            onFinish={this.submit}
                            ref={this.formRef}
                        >
                            <FormItem hidden noStyle name="id">
                                <Input/>
                            </FormItem>
                            <FormItem className={styles.mylabel} name="code" label="募捐编号" labelCol={{span: 4}}
                                      wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                <Input

                                />
                            </FormItem>
                            <FormItem className={styles.mylabel} name="billInstitution" label="收款机构"
                                      labelCol={{span: 4}}
                                      wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                <Input

                                />
                            </FormItem>
                            <FormItem className={styles.mylabel} name="executeInstitution" label="执行机构"
                                      labelCol={{span: 4}}
                                      wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                <Input

                                />
                            </FormItem>
                            <FormItem wrapperCol={{span: 12, offset: 4}} className="center">
                                <Button type="primary" htmlType="submit" className={styles.mybtn}>更新基本信息</Button>
                            </FormItem>
                        </Form>
                    </Content>
                </Layout>
            </Card>
        );
    }
}


export default function QualificationIndex() {
    return (
        <App selectedKeys={['qualification']}>
            <QualificationForm/>
        </App>
    )
}