import React from 'react';
import {Table} from 'antd';
import {Chart} from "../util/ServiceUserDetailTypes";
import {ColumnsType} from "antd/es/table";

type User = {
    name: string,
    totalUser: number,
    toReviewed: number,
    auditFailed: number,
    waitScreening: number,
    waitSurgery: number,
    notSurgery: number,
    completeSurgery: number,
    completeReimburse: number,
    reimburseFail: number,
    reconciling: number
};
const ProvinceTable: React.FC<Chart> = (props) => {

    const columns: ColumnsType<User> = [
        {
            title: sessionStorage.orgName === '管理中心' ? '省份/医院名称' : '医院名称',
            dataIndex: 'name',
            key: 'name',
            align: `center`,
            width: 250
        },
        {
            title: '总报名人数',
            dataIndex: 'totalUser',
            key: 'totalUser',
            sorter: (a: User, b: User) => a.totalUser - b.totalUser,
            align: `center`,
        },
        {
            title: '待审核',
            dataIndex: 'toReviewed',
            key: 'toReviewed',
            sorter: (a: User, b: User) => a.toReviewed - b.toReviewed,
            align: `center`,
        },
        {
            title: '审核退回',
            dataIndex: 'auditFailed',
            key: 'auditFailed',
            sorter: (a: User, b: User) => a.auditFailed - b.auditFailed,
            align: `center`,
        },
        {
            title: '等待检查',
            dataIndex: 'waitScreening',
            key: 'waitScreening',
            sorter: (a: User, b: User) => a.waitScreening - b.waitScreening,
            align: `center`,
        },
        {
            title: '等待手术',
            dataIndex: 'waitSurgery',
            key: 'waitSurgery',
            sorter: (a: User, b: User) => a.waitSurgery - b.waitSurgery,
            align: `center`,
        },
        {
            title: '不适合手术',
            dataIndex: 'notSurgery',
            key: 'notSurgery',
            sorter: (a: User, b: User) => a.notSurgery - b.notSurgery,
            align: `center`,
        },
        {
            title: '手术完成',
            dataIndex: 'completeSurgery',
            key: 'completeSurgery',
            sorter: (a: User, b: User) => a.completeSurgery - b.completeSurgery,
            align: `center`,
        }, {
            title: '对账中',
            dataIndex: 'reconciling',
            key: 'reconciling',
            sorter: (a: User, b: User) => a.reconciling - b.reconciling,
            align: `center`,
        },
        {
            title: '报销完成',
            dataIndex: 'completeReimburse',
            key: 'completeReimburse',
            sorter: (a: User, b: User) => a.completeReimburse - b.completeReimburse,
            align: `center`,
        }, {
            title: '报销失败',
            dataIndex: 'reimburseFail',
            key: 'reimburseFail',
            sorter: (a: User, b: User) => a.reimburseFail - b.reimburseFail,
            align: `center`,
        },
        {
            title: '描述',
            dataIndex: 'msg',
            key: 'msg',
            align: `center`,
        }
    ];
    return (
        <>
            <Table
                className="mgTop15"
                rowKey="name"
                dataSource={props.data.provinceStats}
                columns={columns}
                bordered
                pagination={{position: [], pageSize: 1000}}
                scroll={{y: 800}}
            />
        </>
    );
};

export default ProvinceTable;
