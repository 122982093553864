import React from 'react';
import {Button, Card, Form, FormInstance, message, Modal, Space, Spin} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';

import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";

import createModal from "../../common/createModal";
import DhPatientBookingModelImport from "./DhPatientBookingModel";
import {decryptData} from "../../common/crypto";
import common from "../../common/common";
import {v4 as uuidV4} from 'uuid';
import AddEditCommon from "./AddEditCommon"
import {buildData} from "../common/edit/DhSubmitDataConfig";
import PATH from "../../common/pagePath";
import {ComDhDetailOrEdit} from "../common/edit/DhEditAndDetailPageConfig";
import {defDictData, DictType, getDictData, RoleType} from "../common/DhDictData";
import VisitTaskInfo from "../patientDetail/VisitTaskInfo";
import DhTaskCallLog from "../patientDetail/DhTaskCallLog";
import {getBtn} from "../../common/btnQ/ButtonsRole";
import {DhDetailDataType} from "../common/DhDetailType";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

const DhPatientBookingModel = createModal(DhPatientBookingModelImport);

const {confirm} = Modal;

type AddDhPatientApplication = DhDetailDataType & {
    taskId: number,
    hospitalSelect: [],
    addressSelect: [],
    areaStr: string | undefined,
    saveResult: {
        id: number,
        dhStatus: number
    },
    taskData: { createdDate: '', registerType: 0, servicePhone: '', serviceName: '' },
    cancelLoading: boolean | undefined,
    subLoading: boolean | undefined,
    token: string,
    paymentMethodId: string | undefined,
    advanceMoney: boolean | undefined,
    dictInfo: DictType,
    isWarVeteran: string | undefined,
    loading: boolean | undefined,
    btnQ: RoleType
}

class AddDhPatientApplicationClass extends AddEditCommon {


    formRef = React.createRef<FormInstance>();
    state: AddDhPatientApplication = {
        id: 0,
        data: {},
        taskId: decryptData(common.getQuery('taskId')),
        hospitalSelect: [],
        addressSelect: [],
        areaStr: '',
        saveResult: {
            id: 0,
            dhStatus: 0
        },
        taskData: {createdDate: '', registerType: 0, servicePhone: '', serviceName: ''},
        cancelLoading: false,
        subLoading: false,
        token: uuidV4(),
        paymentMethodId: '1',
        advanceMoney: false,
        dictInfo: defDictData,
        isWarVeteran: '1',
        loading: true,
        btnQ: {}
    };

    componentDidMount() {
        getBtn().then((data) => {
            this.setState({
                btnQ: data['buttons'],
            });
        })
        getDictData().then((data) => {
            this.getTaskInfo();
            this.setState({
                dictInfo: data
            })
        })
    };

    getTaskInfo = () => {
        const {taskId} = this.state;
        if (!taskId || taskId === 0) {
            this.setState({loading: false})
            return
        }
        Fetch.get(`${API.dentistry.obligate_phone.detail_data}?id=${taskId}`).then((data) => {
            this.setState({
                taskData: data,
                loading: false
            }, () => {
                this.formRef.current?.setFieldsValue({
                    name: data.serviceName,
                    phone: data.servicePhone
                });
            })
            // setTaskData(data)

        });
    }
    cancel = () => {
        const {taskId} = this.state
        if (!taskId) {
            window.history.go(-1);
            return;
        }
        this.setState({
            cancelLoading: true
        })
        Fetch.putJSON(API.dentistry.phone_task_batch_detail.do_update_pending, {id: taskId}).then((data) => {
            window.history.go(-1);
        });
    }
    submit = (values: any) => {

        this.setState({
            importLoading: true,
            subLoading: true
        })
        const {saveResult, token} = this.state;
        const point = this.getLat(values);
        const target = buildData(values, point, saveResult.dhStatus, PATH.dhPatientApplication.add);
        //默认值
        target.serviceUser.taskId = 0;
        if (saveResult.id === 0) {
            this.create(target, token);
        } else {
            target.id = saveResult.id;
            this.update(target);
        }
    }

    update = (target: any) => {

        Fetch.postJSON(API.dentistry.service_user.do_update_application, target).then((data) => {
            this.setState({
                saveResult: data,
                subLoading: false
            })
            message.success('修改成功');
        }).catch((e) => {
            this.setState({
                subLoading: false
            })
        });
    };

    create = (target: any, token: string) => {
        const {taskData, taskId, btnQ} = this.state;

        if (taskId) {
            target.serviceUser.taskId = taskId;
            target.serviceUser.registerType = taskData.registerType;
        }
        target.serviceUser.token = token;
        Fetch.postJSON(API.dentistry.service_user.do_user_application, target).then((data) => {
            this.setState({
                saveResult: data,
                subLoading: false
            })
            message.success('提交成功');
            {
                btnQ.ROLE_140001 && (
                    confirm({
                        title: `是否自动预约当天?`,
                        onOk() {
                            Fetch.postJSON(API.dentistry.dh_service_user_booking.do_auto_user_booking, {serviceId: data.id}).then((data) => {
                                message.success('预约成功').then(() => {
                                })
                                window.history.go(-1);
                            }).catch((data) => {
                            })
                        },
                    })
                )
            }
        }).catch((e) => {
            this.setState({
                subLoading: false
            })
        });
    };

    render() {
        const {
            saveResult,
            cancelLoading,
            subLoading,
            taskId,
            dictInfo,
            taskData
        } = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Form
                        onFinish={this.submit}
                        ref={this.formRef}
                    >
                        <Spin tip={'加载中'} spinning={this.state.loading}>
                            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                {!this.state.loading && (
                                    <>
                                        <VisitTaskInfo tasksDetail={taskData} taskDetailId={taskId}
                                                       showCallPhone={true}/>
                                        <ComDhDetailOrEdit key={"ComDhDetailOrEdit"} data={{}}
                                                           form={this.formRef}
                                                           dictInfo={dictInfo}
                                                           setAddressSelect={this.setAddressSelect} taskId={taskId}/>
                                        <DhTaskCallLog taskId={taskId}/>
                                    </>
                                )}
                                <Card>
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                            loading={subLoading}
                                        >
                                            保存
                                        </Button>
                                        {saveResult.dhStatus === 101 && (
                                            <Button
                                                type="primary"
                                                size='large'
                                                onClick={this.openBookingModel}
                                            >
                                                预约医院
                                            </Button>
                                        )}
                                        <Button
                                            size='large'
                                            onClick={this.cancel}
                                            loading={cancelLoading}
                                        >
                                            取消
                                        </Button>
                                    </Space>
                                </Card>
                            </Space>
                        </Spin>
                    </Form>
                </div>
                <DhPatientBookingModel
                    width={1300}
                    title={'预约医院'}
                    importResult={saveResult.id}
                    onCancel={this.closeModal}
                    onSaveSuccess={this.onSaveSuccess}
                    ref={(c) => (this.dhPatientBookingModelResult = c)}
                />
            </div>
        );
    }
}

export default function AddDhPatientApplication() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <AddDhPatientApplicationClass/>
        </App>
    );
}
