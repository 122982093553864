import React, {useEffect, useState} from 'react';
import Fetch from '../common/FetchIt';
import API from '../common/api';

type ReviewType = {
    reason: string;
    finalReason: string;
    reviewStatus: string;
};
export default function ReviewDetail(props: any) {
    const [review, setReview] = useState<ReviewType>();

    useEffect(
        function () {
            // props.category;
            let category = 2;
            if (props.category == 'GONGYI') {
                category = 0;
            } else if (props.category == 'DONATION') {
                category = 1;
            }
            Fetch.get(
                `${API.review.zsgc}?activityId=${props.activity.id}&category=${category}`
            ).then((data) => {
                setReview(data);
            });
        },
        [props.activity.id]
    );

    if (!review) {
        return null;
    }
    return <p>{review.finalReason ? review.finalReason : review.reason}</p>;
}
