import React from 'react';
import {Button, Flex, Modal, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {listDefaultData} from "../util/AierCommon";
import BaseListPage from "../../common/listpage/BaseListPage";
import styles from "../hospitalauditv2/ServiceUserV2.module.css";
import createModal from "../../common/createModal";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import HospitalGroupEditNameModal from "./HospitalGroupEditName";
import HospitalGroupEditDetailModal from "./HospitalGroupEditDetail";


type User = { id: number, groupName: string };
type BtnQType = {
    btnQ: {
        ROLE_199001: number,
        ROLE_199002: number,
        ROLE_199003: number
    }
}
type HospitalGroupType = {
    searchData: {
        page: number,
    },
    pageSize: number;
    totalElements: number,
    totalPages: number;
    content: [];
    btnQ: {},
    selectId: number,
    selectName: string,
    tip: string,
    importLoading: boolean,
}

const HospitalGroupTypeDef: HospitalGroupType = {

    searchData: {
        page: 0,
    },
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    content: [],
    btnQ: {},
    selectId: 0,
    selectName: '',
    tip: '',
    importLoading: true,
}

const {confirm} = Modal;

const ImportHospitalGroupEditNameModal = createModal(HospitalGroupEditNameModal);
const ImportHospitalGroupEditDetailModal = createModal(HospitalGroupEditDetailModal);

class HospitalGroupListClass extends BaseListPage {
    state: HospitalGroupType & BtnQType = {
        ...HospitalGroupTypeDef,
        btnQ: {ROLE_199001: 0, ROLE_199002: 0, ROLE_199003: 0}
    };
    private importHospitalGroupEditNameModal: any = null;
    private importHospitalGroupEditDetailModal: any = null;

    componentDidMount() {
        super.componentDidMount();
    }

    loadData = () => {

        const {pageSize, searchData} = this.state;
        let url = `${API.hospital_group.admin_list}?page=${searchData.page}&size=${pageSize}`;
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };
    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    reset = () => {
        super.reset(listDefaultData.searchData);
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    updateGroupName = (item: any) => {

        this.setState({
            selectId: item.id,
            selectName: item.groupName,
        }, () => {
            this.importHospitalGroupEditNameModal.show();
        })
    }

    updateGroupHospital = (item: any) => {

        this.setState({
            selectId: item.id,
        }, () => {
            this.importHospitalGroupEditDetailModal.show();
        })
    }

    doCreate = () => {

        this.setState({
            selectId: null,
        }, () => {
            this.importHospitalGroupEditNameModal.show();
        })
    }

    render() {
        //
        const {
            searchData, pageSize, totalElements,
            content, btnQ
        } = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '组名称',
                dataIndex: 'groupName',
                key: 'groupName',
                align: `center`
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                key: 'createdDate',
                align: `center`
            },
            {
                title: '操作',
                key: 'operation',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <>
                                {(btnQ.ROLE_199002) && (
                                    <Button className={styles.antBtnPadding} type="link" size={"small"}
                                            onClick={() => this.updateGroupName(record)}>
                                        修改组名称
                                    </Button>
                                )}
                                {(btnQ.ROLE_199003) && (
                                    <Button className={styles.antBtnPadding} type="link" size={"small"}
                                            onClick={() => this.updateGroupHospital(record)}>
                                        添加或删除医院
                                    </Button>
                                )}
                            </>
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div className="site-layout-background"
                         style={{padding: 24, minHeight: 720}}
                    >
                        {(btnQ.ROLE_199001) && (
                        <Flex justify={"flex-end"}>
                            <Button onClick={this.doCreate}>
                                添加医院统计组
                            </Button>
                        </Flex>
                        )}
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <ImportHospitalGroupEditNameModal
                    width={500}
                    title={this.state.selectId ? '修改医院称' : '添加医院组'}
                    groupName={this.state.selectName}
                    groupId={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importHospitalGroupEditNameModal = c)}
                />
                <ImportHospitalGroupEditDetailModal
                    width={1500}
                    title={'添加或修改医院组'}
                    groupId={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importHospitalGroupEditDetailModal = c)}
                />
            </>
        );
    }
}

export default function HospitalGroupList() {

    return (
        <App selectedKeys={['hospitalGroup']} openKeys="aierOphthalmology">
            <HospitalGroupListClass/>
        </App>
    );
}
