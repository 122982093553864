import React, {RefObject} from 'react';
import {Card, Col, DatePicker, FormInstance, InputNumber, Row, Space} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import FileUploader from "../../common/uploader";
import {UploadOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Decimal from 'decimal.js';

type EditFreeClinicsReportType = {
    form: RefObject<FormInstance>;
};

export default function EditHospitalScreening(props: EditFreeClinicsReportType) {

    const computeSelfPayPrice = (e: any) => {
        if (props.form.current) {
            const totalPrice = props.form.current.getFieldValue("totalPrice")
            const medicareReimbursement = props.form.current.getFieldValue("medicareReimbursement");
            const otherGrants = props.form.current.getFieldValue("otherGrants");
            if (isNnumber(totalPrice) && isNnumber(medicareReimbursement) && isNnumber(otherGrants)) {
                props.form.current.setFieldsValue({
                    selfPayPrice: new Decimal(totalPrice).minus(new Decimal(medicareReimbursement)).minus(new Decimal(otherGrants))
                });
            } else {
                props.form.current.setFieldsValue({
                    selfPayPrice: null
                });
            }
        }
    }

    const isNnumber = (value: number) => {

        return !isNaN(value) && value !== null && value !== undefined && isFinite(value);
    }

    return (
        <>
            <Card size="small" className={styles.mycard}
                  title={`筛查费用信息`}>

                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="screeningDate"
                            label="筛查时间"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <DatePicker style={{width: '100%'}}
                                        placeholder="筛查时间"/>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="totalPrice"
                            label="总费用"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <InputNumber
                                precision={2}
                                style={{width: '100%'}}
                                max={1000}
                                min={0}
                                onChange={computeSelfPayPrice}
                                placeholder="总费用"
                            />
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="medicareReimbursement"
                            label="医保报销"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <InputNumber
                                precision={2}
                                style={{width: '100%'}}
                                max={1000}
                                min={0}
                                onChange={computeSelfPayPrice}
                                placeholder="医保报销"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="otherGrants"
                            label="其他补助"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <InputNumber
                                precision={2}
                                style={{width: '100%'}}
                                max={1000}
                                min={0}
                                onChange={computeSelfPayPrice}
                                placeholder="其他补助"
                            />
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="selfPayPrice"
                            label="自付费用"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <InputNumber
                                precision={2}
                                style={{width: '100%'}}
                                max={999999}
                                min={0}
                                disabled
                                placeholder="自付费用"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={20}>

                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="remark"
                            label="备注"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: false, message: '必填'}]}
                        >
                            <TextArea rows={4} placeholder="备注" maxLength={500}/>
                        </FormItem>
                    </Col>
                </Row>
                {/*align="start"*/}
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="density"
                            valuePropName="fileList"
                            label="骨密度报告"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="metabolism"
                            valuePropName="fileList"
                            label="骨代谢报告"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="invoice"
                            valuePropName="fileList"
                            label="收费凭证"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                                rules={[{required: true, message: '必填'}]}
                            />
                        </FormItem>
                    </Card>
                </Space>
            </Card>
        </>
    );
}
