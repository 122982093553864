import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Col, Dropdown, Input, Menu, message, Modal, Row, Select, Space, Table,} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import createModal from '../common/createModal';
import DonationActivityForm from './DonationActivityForm';
import {DonationActivity} from './types';
import styles from './donationActivity.module.css';
import App from '../../App';
import {Link} from 'react-router-dom';
import ReviewDetail from '../gongyiActivity/ReviewDetail';
import ReviewStatistics from '../gongyiActivity/ReviewStatistics';
import Statistics from '../gongyiActivity/Statistics';
import {renderStatusOp} from '../gongyiActivity/GongyiActivityList';
import Auth from '../common/Auth';
import {renderReviewStatus, showReason,} from '../gongyiActivity/GongyiActivityDetail';
import {DownOutlined} from '@ant-design/icons';
import ReasonDetail from '../gongyiActivity/ReasonDetail';
import ReasonForm from '../gongyiActivity/ReasonForm';

const {confirm} = Modal;

type TheState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: DonationActivity[];
    status: number;
    reasons: any[];
    reason: any;
    name: string;
    donationActivity: DonationActivity | null;
    loading: boolean;
};

const FormModal = createModal(DonationActivityForm);
const ReviewModal = createModal(ReviewDetail);
const ReasonDetailModal = createModal(ReasonDetail);
const ReasonFormModal = createModal(ReasonForm);

class DonationActivityList extends Component<{ review: boolean }, TheState> {
    state = {
        page: 0,
        pageSize: 10,
        reason: null,
        reasons: [],
        totalElements: 0,
        totalPages: 0,
        content: [],
        status: 0,
        name: '',
        reviewStatus: -1,
        donationActivity: null,
        loading: true,
    };
    private modal: any = null;
    private reviewModal: any = null;
    private reasonModal: any = null;
    private formModal: any = null;

    showReasonModal = (reason: any) => {
        this.setState(
            {
                reason,
            },
            this.reasonModal.show
        );
    };

    showDownModal = (record: any) => {
        this.setState(
            {
                donationActivity: record,
            },
            this.formModal.show
        );
    };

    loadReasons = (ids: number[]) => {
        Fetch.get(`${API.reason.list}?ids=${ids.join(',')}&category=1`).then(
            (data) => {
                this.setState({
                    reasons: data,
                });
            }
        );
    };

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, status, name, reviewStatus} = this.state;
        let url = `${API.donationActivity.list}?page=${page}&size=${pageSize}&name=${name}&status=${status}`;
        if (this.props.review) {
            url = `${API.donationActivity.list}?page=${page}&size=${pageSize}&name=${name}&reviewStatus=${reviewStatus}`;
        }
        Fetch.get(url).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
            const ids = data.content.map((item: any) => item.id);
            this.loadReasons(ids);
        });
    };

    create = () => {
        window.location.href = '/#/selectTemplate?type=2';
        // this.setState(
        //   {
        //     // @ts-ignore
        //     donationActivity: {
        //       continueWhenFull: false,
        //       timeLimited: true,
        //       creditsMethod: [1],
        //     },
        //   },
        //   this.modal.show
        // );
    };

    edit = (record: any) => {
        window.location.href = `/#/templatedetails/${record.templateId}/0/${record.id}?type=2`;
        // const coverIds = record.covers.map((item: any) => {
        //   return {
        //     id: item.id,
        //     uid: item.id,
        //     status: 'done',
        //     name: item.fileName,
        //     url: API.domain + item.url,
        //   };
        // });
        // const creditsMethod: number[] = [];
        // if (record.creditsMethod) {
        //   if (record.creditsMethod.length > 0) {
        //     const methods = record.creditsMethod.split(',');
        //     methods.forEach((item: string) => {
        //       if (item.length > 0) {
        //         creditsMethod.push(Number(item));
        //       }
        //     });
        //   }
        // }
        // const date1 = record.timeLimited
        //   ? [moment(record.startDate), moment(record.endDate)]
        //   : [];
        // const donationActivity = {
        //   ...record,
        //   date1,
        //   coverIds,
        //   creditsMethod: creditsMethod,
        // };
        // this.setState(
        //   {
        //     donationActivity,
        //   },
        //   this.modal.show
        // );
    };

    changeStatus = (id: number, status: string, reviewStatus: string) => {
        let newStatus = '';
        if (status === 'DRAFT') {
            newStatus = 'PUBLISHED';
        } else if (status === 'DISABLED') {
            newStatus = 'PUBLISHED';
            Fetch.put(`${API.donationActivity.online}`, {
                id: id,
                status: newStatus,
            }).then(() => {
                message.success('更新成功！');
                this.loadData();
            });
            return;
        } else if (reviewStatus === 'ENABLE') {
            newStatus = 'DISABLED';
        }
        Fetch.put(`${API.donationActivity.status}`, {
            id: id,
            status: newStatus,
        }).then(() => {
            message.success('更新成功！');
            this.loadData();
        });
    };

    showReview = (record: any) => {
        this.setState(
            {
                donationActivity: record,
            },
            this.reviewModal.show
        );
    };

    revoke = (id: any) => {
        confirm({
            title: '是否撤销提交?',
            content:
                '注意撤销提交后，该活动将不会继续审批流程，只有初审状态才能撤销提交，撤销提交后该项目将被打回草稿箱，请谨慎操作！',
            onOk: () => {
                Fetch.put(`${API.gongyiActivity.status}`, {
                    id: id,
                    status: 'DRAFT',
                }).then(() => {
                    message.success('撤销成功！');
                    this.loadData();
                });
            },
            onCancel() {

            },
        });
    };

    renderStatus = (status: string) => {
        if (status === 'DRAFT') {
            return '草稿';
        } else if (status === 'PUBLISHED') {
            return '已提交';
        } else if (status === 'DISABLED') {
            return '已下架';
        }
    };

    reset = () => {
        this.setState(
            {
                name: '',
                status: 0,
                page: 0,
            },
            this.loadData
        );
    };

    del = (id: number) => {
        const result = window.confirm('是否确定删除？');
        if (result) {
            Fetch.del(`${API.donationActivity.zsgc}/${id}`).then(() => {
                message.success('删除成功！');
                window.setTimeout(function () {
                    window.location.reload();
                }, 200);
            });
        }
    };

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        let columns: ColumnsType<DonationActivity> = [
            {
                title: '活动名称',
                dataIndex: 'title',
                align: `center`,
            },
            {
                title: '活动编码',
                dataIndex: 'code',
                align: `center`,
            },
            {
                title: '发起人',
                dataIndex: 'publisher',
                align: `center`,
            },
            {
                title: '所属组织',
                dataIndex: 'institution',
                align: `center`,
            },
            {
                title: '捐款情况',
                align: `center`,
                render: (text, record) => {
                    return `${record.currentDonation} / ${record.total}`;
                },
            },
            {
                title: '募捐时间',
                align: `center`,
                render: (text, record) => {
                    // if (!record.timeLimited) {
                    //   return '时间不限';
                    // }
                    return (
                        <>
                            <div>{record.startDate}</div>
                            <div>{record.endDate}</div>
                        </>
                    );
                },
            },
        ];

        const {review} = this.props;
        const auth = new Auth();
        if (review) {
            columns = columns.concat([
                {
                    title: '提交状态',
                    dataIndex: 'status',
                    align: `center`,
                    render: (text, record) => {
                        return this.renderStatus(record.status);
                    },
                },
                {
                    title: '审核状态',
                    dataIndex: 'status',
                    align: `center`,
                    render: (text, record) => {
                        return renderReviewStatus(record.reviewStatus);
                    },
                },
                {
                    title: '操作',
                    align: `center`,
                    fixed: 'right',
                    width: 210,
                    render: (text, record: any) => {
                        const operationText = renderStatusOp(
                            record.status,
                            record.reviewStatus,
                            record.userId,
                            record.offlineRole
                        );
                        const items = [];
                        if (record.status === 'DRAFT') {
                            items.push(
                                <Menu.Item onClick={() => this.edit(record)}>编辑</Menu.Item>
                            );
                            items.push(
                                <Menu.Item onClick={() => this.del(record.id)}>删除</Menu.Item>
                            );
                        }
                        if (operationText) {
                            items.push(
                                <Menu.Item
                                    onClick={() =>
                                        this.changeStatus(
                                            record.id,
                                            record.status,
                                            record.reviewStatus
                                        )
                                    }
                                >
                                    {operationText}
                                </Menu.Item>
                            );
                        }
                        if (
                            record.reviewStatus === 'PROVINCE_REJECTED' ||
                            record.reviewStatus === 'FINAL_REJECTED'
                        ) {
                            items.push(
                                <Menu.Item onClick={() => this.showReview(record)}>
                                    驳回原因
                                </Menu.Item>
                            );
                        }
                        if (auth.isYueling() && record.reviewStatus === 'PROVINCE') {
                            items.push(
                                <Menu.Item onClick={() => this.revoke(record.id)}>
                                    撤销提交
                                </Menu.Item>
                            );
                        }
                        return (
                            <Space>
                                <Button>
                                    <Link to={`/donationActivity/detail/${record.id}`}>查看</Link>
                                </Button>
                                {items.length > 0 && (
                                    <Dropdown overlay={<Menu>{items}</Menu>}>
                                        <Button>
                                            更多操作 <DownOutlined/>
                                        </Button>
                                    </Dropdown>
                                )}
                            </Space>
                        );
                    },
                },
            ]);
        } else {
            columns = columns.concat([
                {
                    title: '募捐状态',
                    dataIndex: 'currentStatus',
                    align: `center`,
                },
                {
                    title: '操作',
                    align: `center`,
                    fixed: 'right',
                    width: 210,
                    render: (text, record: any) => {
                        const operationText = renderStatusOp(
                            record.status,
                            record.reviewStatus,
                            record.userId,
                            record.offlineRole
                        );
                        const reason = this.state.reasons.find(
                            (item: any) => item.activityId === record.id
                        );
                        const items = [];
                        if (auth.isAdmin()) {
                            items.push(
                                <Menu.Item key={items.length} onClick={() => this.edit(record)}>
                                    编辑
                                </Menu.Item>
                            );
                        }
                        if (operationText) {
                            if (operationText === '申请下架') {
                                items.push(
                                    <Menu.Item
                                        key={items.length}
                                        onClick={() => this.showDownModal(record)}
                                    >
                                        {operationText}
                                    </Menu.Item>
                                );
                            } else {
                                items.push(
                                    <Menu.Item
                                        key={items.length}
                                        onClick={() =>
                                            this.changeStatus(
                                                record.id,
                                                record.status,
                                                record.reviewStatus
                                            )
                                        }
                                    >
                                        {operationText}
                                    </Menu.Item>
                                );
                            }
                        }
                        if (showReason(reason, record.status, record.reviewStatus)) {
                            items.push(
                                <Menu.Item
                                    key={items.length}
                                    onClick={() => this.showReasonModal(reason)}
                                >
                                    申请理由
                                </Menu.Item>
                            );
                        }
                        return (
                            <Space>
                                <Button>
                                    <Link to={`/donationActivity/detail/${record.id}`}>查看</Link>
                                </Button>
                                {items.length > 0 && (
                                    <Dropdown overlay={<Menu>{items}</Menu>}>
                                        <Button>
                                            更多操作 <DownOutlined/>
                                        </Button>
                                    </Dropdown>
                                )}
                            </Space>
                        );
                    },
                },
            ]);
        }

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>公益募捐</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    {review ? (
                        <ReviewStatistics category="donation"/>
                    ) : (
                        <Statistics category="donation"/>
                    )}
                    <div className="mgTop15">
                        <Row align="middle">
                            <Col span={12}>
                                <Space>
                                    <div>{review ? '审核列表' : '活动列表'}</div>
                                    <Button
                                        onClick={this.create}
                                        className={styles.addBtn}
                                        type="primary"
                                    >
                                        新建捐款
                                    </Button>
                                </Space>
                            </Col>
                            <Col span={12} style={{float: 'right'}}>
                                <Space
                                    style={{float: 'right'}}
                                    onKeyDown={this.handleKeyDown}
                                >
                                    {review ? (
                                        <Select
                                            value={this.state.reviewStatus}
                                            onChange={(e) =>
                                                this.changeCondition({page: 0, reviewStatus: e})
                                            }
                                        >
                                            <Select.Option value={-1}>全部</Select.Option>
                                            <Select.Option value={0}>初审中</Select.Option>
                                            <Select.Option value={1}>终审中</Select.Option>
                                        </Select>
                                    ) : (
                                        <Select
                                            value={this.state.status}
                                            onChange={(e) =>
                                                this.changeCondition({page: 0, status: e})
                                            }
                                        >
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>进行中</Select.Option>
                                            <Select.Option value={2}>已结束</Select.Option>
                                        </Select>
                                    )}

                                    <Input
                                        placeholder="请输入名称"
                                        value={this.state.name}
                                        onChange={(e) =>
                                            this.changeCondition({page: 0, name: e.target.value})
                                        }
                                    />
                                    <Button onClick={this.loadData}>搜索</Button>
                                    <Button onClick={this.reset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                    <Table
                        loading={this.state.loading}
                        bordered
                        rowKey={(record) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        scroll={{x: 1300}}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
                <FormModal
                    title={this.state.donationActivity === null ? '添加活动' : '编辑活动'}
                    reload={this.loadData}
                    donationActivity={this.state.donationActivity}
                    ref={(c) => (this.modal = c)}
                />
                <ReasonDetailModal
                    reason={this.state.reason}
                    title="查看申请理由"
                    width={600}
                    ref={(c) => (this.reasonModal = c)}
                />
                <ReasonFormModal
                    title="下架理由"
                    width={600}
                    category="DONATION"
                    activityId={
                        //@ts-ignore
                        this.state.donationActivity ? this.state.donationActivity.id : ''
                    }
                    ref={(c) => (this.formModal = c)}
                />
                {review && (
                    <ReviewModal
                        title={'驳回原因'}
                        category="DONATION"
                        activity={this.state.donationActivity}
                        ref={(c) => (this.reviewModal = c)}
                    />
                )}
            </>
        );
    }
}

export default function DonationActivityIndex() {
    return (
        <App selectedKeys={['donation']} openKeys="sub1">
            <DonationActivityList review={false}/>
        </App>
    );
}

export function DonationActivityReviewIndex() {
    return (
        <App selectedKeys={['donation']} openKeys="sub1">
            <DonationActivityList review={true}/>
        </App>
    );
}
