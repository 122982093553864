import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Form, FormInstance, message, Modal, Space} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";

const {confirm} = Modal;

type CreateCertificateType = {
    id: number,
    hide: any,
    reload: any
}

export default function CreateCertificateModel(props: CreateCertificateType) {

    const reason = props.id;
    const formRef = React.createRef<FormInstance>();
    const [loading, setLoading] = useState(false);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        values.id = props.id;
        values.certificateDate = values.certificateDate?.format('YYYY-MM-DD')
        confirm({
            title: `是否确认修改`,
            onOk() {
                setLoading(true)
                values.serviceId = reason;
                Fetch.putJSON(API.troup.guru.create_certificate, values).then(() => {
                    message.success('提交成功');
                    setLoading(false)
                    ok();
                });
            }
        })
    }

    useEffect(() => {

    }, []);


    return <>

        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <Card size="small"
                  title={``}>
                <Form
                    onFinish={submit}
                    ref={formRef}
                >
                    <FormItem
                        name="certificateDate"
                        label="发证时间"
                        wrapperCol={{span: 17}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <DatePicker
                            style={{width: '100%'}}/>
                    </FormItem>
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={loading}
                        >
                            保存
                        </Button>
                        <Button
                            size='large'
                            style={{marginRight: '15px'}}
                            onClick={cancel}
                            loading={loading}
                        >
                            取消
                        </Button>
                    </Space>
                </Form>
            </Card>
        </Space>
    </>;
}
