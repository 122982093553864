import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './gongyiActivity.module.css';

const FormItem = Form.Item;
const {TextArea} = Input;

type FormProps = {
    activityId: number;
    category: string;
    hide: () => void;
    reload: () => void;
};

type FormState = {};

class ReasonForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();

    submit = (values: any) => {
        values.activityId = this.props.activityId;
        if (this.props.category == 'GONGYI') {
            values.category = 0;
        } else if (this.props.category == 'DONATION') {
            values.category = 1;
        } else {
            values.category = 2;
        }
        Fetch.postJSON(`${API.reason.zsgc}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    render() {
        return (
            <div className={styles.container}>
                <Form onFinish={this.submit} ref={this.formRef}>
                    <FormItem
                        className={styles.mylabel}
                        name="text"
                        label="申请原因"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                    >
                        <TextArea rows={5}/>
                    </FormItem>
                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button type="primary" htmlType="submit" className={styles.mybtn}>
                            提交
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default ReasonForm;
