import React from 'react';
import {Modal, Space, Spin, Table} from 'antd';
import API from '../../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../../App';
import encryptData from '../../../common/crypto';
import BaseListPage from "../../../common/listpage/BaseListPage";
import {FinancialPageType} from "../page/PageTypeTypes";
import {financialPageData} from "../page/PageCommon";
import BreadcrumbInfo from "../../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../../common/breadcrumb/BreadcrumbUtil";


type list = {
    amountReimbursed: number | 0
    batchId: number | 0
    code: string | ''
    createdDate: string | ''
    numBill: number | 0
    reimbursed: number | 0
    totalAmount: number | 0
    username: string | '',
    numReimbursed: number | 0
};
const {confirm} = Modal;

class FinancialBillBatchListClass extends BaseListPage {

    state: FinancialPageType = {
        ...financialPageData
    };

    componentDidMount() {

        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.service_user_bill_batch.list}?page=${searchData.page}&size=${pageSize}`;
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };


    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                window.location.reload()
            }
        );
    }

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(financialPageData.searchData);
    };

    render() {

        const {
            pageSize, totalElements,
            content, btnQ, searchData
        } = this.state;
        const columns: ColumnsType<list> = [

            {
                title: '导出时间',
                dataIndex: 'createdDate',
                align: `center`,
                width: 60,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '操作人',
                dataIndex: 'username',
                align: `center`,
                width: 60,
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '编码',
                dataIndex: 'code',
                align: `center`,
                width: 80,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '已报销/总账单数',
                dataIndex: 'numBill',
                align: `center`,
                width: 50,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{record.reimbursed + "/" + value}</span>
                    );
                },
            }, {
                title: '已报销/总人数',
                dataIndex: 'totalNum',
                align: `center`,
                width: 50,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{record.numReimbursed + "/" + value}</span>
                    );
                },
            }, {
                title: '批次总金额（元）',
                dataIndex: 'totalAmount',
                align: `center`,
                width: 50,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '已报销金额（元）',
                dataIndex: 'amountReimbursed',
                align: `center`,
                width: 50,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                width: 50,
                render: (text, record: any) => {
                    return (
                        <Space>
                            <Link
                                to={`/service_user_bill_batch/detail_list?batchId=${encryptData(record.batchId + "")}`}>
                                详情
                            </Link>
                        </Space>
                    );
                }
                ,
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>报销名单Ⅱ</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>导出记录</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Table
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function FinancialBillBatchList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <FinancialBillBatchListClass/>
        </App>
    );
}
