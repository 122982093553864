import {Button, Form, FormInstance, message, Space} from "antd";
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React from "react";
import Fetch from "../../common/FetchIt";
import API from "../../common/api";


export default function BoneAuditRejectionModule(props: any) {

    const reason = props.importResult;
    const formRef = React.createRef<FormInstance>();
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        values.serviceId = reason.id;
        Fetch.putJSON(API.bone.status.update_review_returns, values).then(() => {
            message.success('审核成功');
            window.location.href = '/#/bone/subsidy/list';
        });
    }

    return <>
        <div>
            <Form
                onFinish={submit}
                ref={formRef}
            >
                <FormItem
                    name="msg"
                    label="拒绝原因"
                    wrapperCol={{span: 25}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <TextArea showCount rows={4} placeholder="拒绝原因" maxLength={50}/>
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        保存
                    </Button>
                    <Button
                        style={{marginRight: '15px'}}
                        onClick={ok}
                    >
                        取 消
                    </Button>
                </Space>
            </Form>
        </div>
    </>;
}
