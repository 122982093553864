/**
 * Created by sunlong on 16/12/28.
 */
import React from 'react';
import {Button, message, Space, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import './FileUploader.css'
import API from '../api';
import common from '../common';
import {ImgCompression} from "../modal/ImgCompression";

export default class FileUploader extends React.Component {
    state = {
        fileList: this.props.fileList ? this.props.fileList : [],
    };

    handleUpload = (info) => {
        let {fileList} = info;
        if (!this.props.multiple) {
            fileList = fileList.slice(-1);
        }
        // 2. read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.data.url;
                file.id = file.response.data.id;
            }
            return file;
        });

        // 3. filter successfully uploaded files according to response from server
        fileList = fileList.filter((file) => {
            if (file.response) {
                return file.response.success;
            }
            return true;
        });
        this.setState({fileList});
        return fileList;
    };

    onUploadChange = (info) => {
        this.props.onChange(this.handleUpload(info), info);
    };

    beforeUpload = (file, fileList) => {
        if (!this.props.noChekckSizeMal) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片大小超出限制');
                return Upload.LIST_IGNORE;
            }
        }
        if (this.props.checkSize) {
            return this.isSize(file);
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            return new Promise((resolve, reject) => {
                const maxSize = file.size / 1024 / 1024 * 0.5;
                console.log(maxSize)
                ImgCompression(file, maxSize).then((res) => {
                    console.log(res)
                    resolve(new File([res], file.name, {type: file.type}));
                }).catch(err => {
                    resolve(file);
                })
            })
        }
        return true;
    };
    //检测尺寸
    isSize = (file) => {
        return new Promise((resolve, reject) => {
            let width = this.props.width;
            let height = this.props.height;
            let _URL = window.URL || window.webkitURL;
            let img = new Image();
            img.onload = function () {
                let valid = img.width === width && img.height === height;
                valid ? resolve() : reject();
            };
            img.src = _URL.createObjectURL(file);
        }).then(
            () => {
                return file;
            },
            () => {
                message.error(file.name + '图片尺寸不符合要求，请修改后重新上传！');
                return Promise.reject();
            }
        );
    };
    // componentDidUpdate = (prevProps, prevState) => {
    //   if (prevProps.fileList) {
    //     this.setState({
    //       fileList: prevProps.fileList,
    //     });
    //   }
    // };
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.fileList) {
            this.setState({
                fileList: nextProps.fileList,
            });
        }
    };

    renderButton = () => {
        if (
            this.props.maxCount !== undefined &&
            this.state.fileList.length >= this.props.maxCount
        ) {
            return null;
        }
        if (this.props.listType === 'picture-card') {
            return (
                <div>
                    <PlusOutlined/>
                </div>
            );
        } else {
            return (
                // disabled={this.props.disabled ? true : false}
                <Button icon={this.props.icon ? this.props.icon : ''}
                        disabled={!!this.props.disabled}>
                    {this.props.text ? this.props.text : '上传'}
                </Button>
            );
        }
    };

    render() {
        const {disabled: disabled = false} = this.props;
        return (
            <Space>
                <Upload
                    disabled={disabled}
                    name="file"
                    multiple={!!this.props.multiple}
                    action={API.upload}
                    listType={this.props.listType ? this.props.listType : 'picture'}
                    accept={this.props.accept ? this.props.accept : 'image/*'}
                    headers={{Authorization: `Bearer ${common.getAccessToken()}`}}
                    onChange={this.onUploadChange}
                    maxCount={this.props.maxCount || 99}
                    beforeUpload={this.beforeUpload}
                    fileList={this.state.fileList}
                    className={this.props.className || ''}
                >
                    {this.renderButton()}
                </Upload>
                {this.state.fileList.length === 0 && (
                    <div style={{color: 'gray', fontSize: '12px'}}>
                        {this.props.desc || ''}
                    </div>
                )}
            </Space>
        );
    }
}
