import React, {Component} from 'react';
import {Button, Card, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {decryptData} from "../../common/crypto";

import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {BoneDetailDataType, BoneDetailDataTypeDate} from "../common/BoneCommonType";
import ServiceUser from "./ServiceUser";
import DoneHospitalScreening from "./DoneHospitalScreening";
import {buildBoneFileData} from "../common/BoneCommon";


class DoneServiceUserDetailClass extends Component<{}, BoneDetailDataType> {

    state: BoneDetailDataType = {
        ...BoneDetailDataTypeDate
    };

    componentDidMount() {
        this.setState({
            id: decryptData(common.getQuery('id')),
        }, () => {
            this.getData()
        })
    };

    getData() {

        const {id} = this.state;
        let url = `${API.bone.service_user.get_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            buildBoneFileData(data)
            this.setState({
                data: data,
                importLoading: false
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <ServiceUser userDetail={data.detailVo}/>
                                    <DoneHospitalScreening screening={data.screening}/>
                                </>
                            ) : ('')}
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function DoneServiceUserDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <DoneServiceUserDetailClass/>
        </App>
    );
}
