import React, {Component} from 'react';
import {Button, Form, Input, message} from 'antd';

import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './login.module.css';

const FormItem = Form.Item;
const InputGroup = Input.Group;

type User = {
    phone: '';
    smsCode: '';
};

export default class LoginForm extends Component<any> {
    state = {
        text: '发送验证码',
        phone: '',
    };
    timer: any;

    submit = (values: User) => {
        if (!this.checkPhone()) {
            message.error('请输入正确的手机号');
            return;
        }
        if (!values.smsCode) {
            message.error('验证码不能为空');
            return;
        }

        Fetch.post(API.user.loginSms, values).then((data) => {
            clearInterval(this.timer);
            this.setState({
                text: '发送验证码',
            });
            // 保存登陆状态
            sessionStorage.token = data.accessToken;
            sessionStorage.user = JSON.stringify(data.user);
            // sessionStorage.category = data.category;
            this.props.changeStaus(true, data);
        });
    };

    sendCode = () => {
        if (this.state.text !== '发送验证码') {
            return;
        }
        if (this.checkPhone()) {
            this.codeChange();
            this.requireCode();
        } else {
            message.error('请输入正确的手机号');
        }
    };

    // 验证手机号是否正确
    checkPhone = () => {
        let reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
        let flag = reg.test(this.state.phone);
        return flag;
    };

    // 发送验证码
    requireCode = () => {
        Fetch.get(`${API.user.sendCode}?phone=${this.state.phone}&templateCode=REGISTERED`).then((data) => {
            message.success('发送成功！');
        });
    };

    // 开启定时器
    codeChange = () => {
        let time = 59;
        this.timer = setInterval(() => {
            if (time === 0) {
                clearInterval(this.timer);
                this.setState({
                    text: '发送验证码',
                });
            } else {
                this.setState({
                    text: time + ' s',
                });
            }

            time--;
        }, 1000);
    };
    inptChange = (e: any) => {
        this.setState({
            phone: e.target.value,
        });
    };

    goRegister = () => {
        window.location.href = '/#/register';
    }

    render() {
        const {text, phone} = this.state;
        return (
            <div>
                <Form onFinish={this.submit} className={styles.form}>
                    <FormItem
                        className={styles.mylabel}
                        name="phone"
                        labelCol={{span: 4}}
                    >
                        <Input
                            size="large"
                            placeholder="手机号"
                            type="text"
                            value={phone}
                            onChange={this.inptChange}
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="smsCode"
                        labelCol={{span: 4}}
                    >
                        <div className={styles.code_box}>
                            <Input
                                type="text"
                                size="large"
                                placeholder="验证码"
                            />
                            <span
                                className={text !== '发送验证码' ? styles.active : ''}
                                onClick={this.sendCode}
                            >
                {text}
              </span>
                        </div>
                    </FormItem>
                    <FormItem className={styles.btn_box + ' center'}>
                        <Button type="primary" htmlType="submit" className={styles.mybtn}>
                            登录
                        </Button>
                    </FormItem>
                </Form>
                <div className={styles.goRegister}>
                    <Button type="link" onClick={this.goRegister}>&gt;&gt;&gt;去注册</Button>
                </div>
            </div>
        );
    }
}
