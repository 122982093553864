import React from 'react';
import {Column} from '@ant-design/charts';
import {Chart} from "../util/ServiceUserDetailTypes";

const histogramChart: React.FC<Chart> = (props) => {

    const config = {
        forceFit: true,
        data: props.data.provinceTotal,
        xField: 'key',
        yField: 'value',
        meta: {
            key: {
                alias: '省份',
            },
            value: {
                alias: '报名人数',
            },
        },
        style: {
            width: props.data.offsetWidth / 2 - 20 + 'px'
        }
    };
    return <Column {...config} />;
};

export default histogramChart;
