export default class Auth {
    user = {roleId: -1};

    constructor() {
        if (sessionStorage.user) {
            this.user = JSON.parse(sessionStorage.user);
        }
    }

    isAdmin() {
        return this.user.roleId === 2;
    }

    isOwn(userId) {
        return userId === this.user.id;
    }

    offlineRoleValid(roleId) {
        if (roleId === 2) {
            return this.isAdmin();
        }
        return true;
    }

    isInstitution() {
        return this.user.roleId === 4;
    }

    isYueling() {
        return this.user.roleId === 3;
    }
}
