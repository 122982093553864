import React, {Component} from 'react';
import {Button, Space} from 'antd';
import API from '../../../common/api';
import 'dayjs/locale/zh-cn';
import App from '../../../../App';

import {decryptData} from '../../../common/crypto';
import common from "../../../common/common";
import Fetch from "../../../common/FetchIt";
import {HospitalBillDetail} from "./types/HospitalBillType";
import {billDetailDefault, showFile} from "./types/HospitalDetailCommon";
import styles from "../../add/ServiceUser.module.css";
import BillInfo from "./BillInfo";
import BillDetailInfo from "./BillDetailInfo";
import BillFileInfo from "./BillFileInfo";
import BillAccountInfo from "./BillAccountInfo";
import BreadcrumbInfo from "../../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../../common/breadcrumb/BreadcrumbUtil";

type User = {};

class HospitalBillListClass extends Component<{}, HospitalBillDetail> {

    state: HospitalBillDetail = {
        billId: decryptData(common.getQuery('billId')),
        data: billDetailDefault.data,
    };

    componentDidMount() {

        this.getData();
    }

    getData() {

        const {billId} = this.state;
        let url = `${API.service_user_bill.get_bill_detail}?billId=${billId}`;
        Fetch.get(url).then((data) => {
            showFile(data)
            this.setState({
                data: data,
            });
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const p = common.getQuery('p') + '';
        const {
            billId, data
        } = this.state;
        return (
            <>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <BreadcrumbInfo pre={p} current={"7"}/>*/}
                {/*</Breadcrumb>*/}
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <BillInfo data={data}/>
                        <BillAccountInfo data={data}/>
                        <BillFileInfo data={data}/>
                        <BillDetailInfo billId={billId}/>
                    </Space>
                    <Space>
                        <Button
                            // type="primary"
                            size="large"
                            onClick={this.cancel}
                            className={styles.detailBackBtn}
                        >
                            返回
                        </Button>
                    </Space>
                </div>
            </>
        );
    }
}

export default function HospitalBillList() {

    const pageKey = getKey();
    return (
        <>
            <App selectedKeys={[pageKey.selectedKeys]}
                 openKeys={pageKey.openKeys}>
                <HospitalBillListClass/>
            </App>
        </>
    );
}
