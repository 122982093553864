import React, {useEffect, useState} from 'react';
import styles from "../add/ServiceUser.module.css";
import {Button, message, Modal, Select, Space, Spin, Table, Tag} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import {ColumnsType} from "antd/es/table";
import {DescEyeDisease, eyeTypeArr} from "../util/AierCommon";
import common from "../../common/common";

type SearchData = {
    page: number | 0,
    eyeType?: number | undefined,
    illnessType?: string | undefined,
    bEyeType?: number | undefined,
    fundusEyeType?: number | undefined,
    gEyeType?: number | undefined,
    eyeDisease?: number | undefined
}

const defSearch = {
    page: 0,
    eyeType: undefined,
    illnessType: undefined,
    bEyeType: undefined,
    fundusEyeType: undefined,
    gEyeType: undefined,
    eyeDisease: undefined
}
//B白内障 F眼底病 G翼状胬肉
const EyeTypeMap = {
    1: {B: false, F: false, G: true},
    2: {B: false, F: true, G: true},
    3: {B: true, F: false, G: true},
    4: {B: true, F: true, G: false},
    5: {B: false, F: false, G: true},
    6: {B: false, F: true, G: true},
    7: {B: true, F: false, G: true},
    8: {B: true, F: true, G: false},
}
// {
//         value: 1,
//         label: '左眼',
//     },
//     {
//         value: 2,
//         label: '右眼',
//     },
//     {
//         value: 3,
//         label: '双眼',
//     }]
const DescEyeDiseaseCodeMap = {
    '111': ['B01', 'FUNDUS01'],
    '112': ['B01', 'FUNDUS02'],
    '113': ['B01', 'FUNDUS01', 'FUNDUS02'],
    '121': ['B02', 'FUNDUS01'],
    '122': ['B02', 'FUNDUS02'],
    '123': ['B02', 'FUNDUS01', 'FUNDUS02'],
    '131': ['B01', 'B02', 'FUNDUS01'],
    '132': ['B01', 'B02', 'FUNDUS02'],
    '133': ['B01', 'B02', 'FUNDUS01', 'FUNDUS02'],
    '21': ['B01'],
    '22': ['B02'],
    '23': ['B01', 'B02'],
    '31': ['FUNDUS01'],
    '32': ['FUNDUS02'],
    '33': ['FUNDUS01', 'FUNDUS02'],
    '41': ['G01'],
    '42': ['G02'],
    '43': ['G01', 'G02'],
}
const DescEyeDiseaseMap = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 1,
    6: 2,
    7: 3,
    8: 4
}
const defEyeTypeMap = {B: true, F: true, G: true}

type ColumnsTypes = {}
/**
 * 账户信息不一致弹窗
 *
 * @param props
 * @constructor
 */
const {confirm} = Modal;
export default function AierDownloadInformedConsent(props: any) {

    //分页
    const [content, setContent] = useState<any[]>([]);
    const [pageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [searchData, setSearchData] = useState<SearchData>(defSearch);
    //选择患者
    const [allSelect, setAllSelect] = useState<React.Key[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedShow, setSelectedShow] = useState<any[]>([]);
    const [pageInit, setPageInit] = useState<React.Key[]>([]);
    //加载过的数据
    const [contentData, setContentData] = useState<{}>({});
    //加载中
    // const [loading, setLoading] = useState<boolean>(false);
    //眼别下拉筛选框
    const [selectStatus, setSelectStatus] = useState<{ B: boolean, F: boolean, G: boolean }>(defEyeTypeMap);


    const [tip, setTip] = useState<string>("加载中...");
    const [importLoading, setImportLoading] = useState<boolean>(false);


    const cancel = () => {
        props.hide();
    }

    const findData = () => {

        setImportLoading(true)
        const quotaCode = getQuotaCode();
        if (!quotaCode) {
            setImportLoading(false)
            return
        }
        setTip("加载中...")
        let myself = getMyself();
        let url = `${API.cooperateServiceUsersStatusV2.find_informed_consent}?page=${searchData.page}&size=${pageSize}&myself=${myself}`;
        url = url + '&quotaCodes=' + quotaCode;
        // url = url + addParams("phone", searchData.phone, STRING_STR);
        // url = url + addParams("idCard", searchData.idCard, STRING_STR);
        // url = url + addParams("status", searchData.status, NUMBER_STR);
        // url = url + addParams("hospitalId", searchData.hospitalId, NUMBER_STR);
        Fetch.get(url, {}).then((data) => {
            setContent(data.content);
            setTotalElements(data.totalElements);
            buildContentData(data.content);
            buildPageInit(data.content);
            setImportLoading(false)
        })
    }

    const getMyself = () => {

        if (!searchData.eyeDisease) {
            return
        }
        return searchData.eyeDisease > 4 ? "2" : "1";
    }

    const getQuotaCode = () => {
        if (!searchData.eyeDisease) {
            return
        }
        const eyeDisease = DescEyeDiseaseMap[searchData.eyeDisease];
        let concatStr = null;
        if (eyeDisease === 1) {
            if (!searchData.bEyeType || !searchData.fundusEyeType) {
                message.error("请选择眼别")
                return;
            }
            concatStr = eyeDisease.toString()
                .concat(searchData.bEyeType.toString())
                .concat(searchData.fundusEyeType.toString());
        } else if (eyeDisease === 2) {
            if (!searchData.bEyeType) {
                message.error("请选择眼别")
                return;
            }
            concatStr = eyeDisease.toString()
                .concat(searchData.bEyeType.toString());
        } else if (eyeDisease === 3) {
            if (!searchData.fundusEyeType) {
                message.error("请选择眼别")
                return;
            }
            concatStr = eyeDisease.toString()
                .concat(searchData.fundusEyeType.toString());
        } else if (eyeDisease === 4) {
            if (!searchData.gEyeType) {
                message.error("请选择眼别")
                return;
            }
            concatStr = eyeDisease.toString()
                .concat(searchData.gEyeType.toString());
        }
        console.log(concatStr)
        return concatStr === null ? null : DescEyeDiseaseCodeMap[concatStr];
    }

    const buildSelectedShow = () => {

        let acc: any[] = [];
        allSelect.forEach((item: any) => {
            acc.push(contentData[item]);
        });
        setSelectedShow(acc);
    }

    const buildContentData = (data: []) => {

        let contentDataTemp = contentData;
        data.forEach((item: { id: number }) => {
            contentDataTemp[item.id] = item;
        })
        setContentData(contentDataTemp);
    }

    const buildPageInit = (data: []) => {

        if (data) {
            let initData: React.Key[] = [];
            data.forEach((dataItem: any) => {
                if (allSelect.includes(dataItem.id)) {
                    initData.push(dataItem.id)
                }
            })
            setPageInit(initData);
            setSelectedRowKeys(initData);
        }
        // setLoading(false)
    }

    const continueSubmit = () => {
        console.log(allSelect)
        const params = {
            serviceIds: allSelect,
            eyeDisease: searchData.eyeDisease,
            cataractEyeType: searchData.bEyeType,
            fundusEyeType: searchData.fundusEyeType,
            gEyeType: searchData.gEyeType
        }
        confirm({
            title: `是否确认下载文件`,
            okText: `确认`,
            onOk() {
                setImportLoading(true)
                setTip("文件下载中...")
                Fetch.postJSON(API.cooperateServiceUsersStatusV2.batch_download_informed_consent, params).then((data) => {
                    const promises: any[] = [];
                    data.forEach((item: {
                        fileName: string,
                        url: string
                    }) => {
                        const promise = common.downloadFile2(item.fileName,
                            API.domain + item.url).catch(() => {
                            message.error("部分文件下载失败，请联系管理员。")
                        });
                        promises.push(promise)
                    })
                    Promise.all(promises).then(() => {
                        message.success("下载完成")
                        setImportLoading(false)
                    }).catch(() => {
                        message.success("文件下载失败或部分失败")
                        setImportLoading(false)
                    })
                });
            },
        });
    }

    useEffect(() => {

        findData()
    }, []);

    useEffect(() => {

        findData()
    }, [searchData]);

    useEffect(() => {

        buildSelectedShow()
    }, [allSelect]);

    const pageChange = (searchDataTemp: SearchData) => {

        const search = {
            ...searchData,
            ...searchDataTemp
        }
        setSearchData(search)
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {

        // 当前页面实际
        setSelectedRowKeys(newSelectedRowKeys);

        // 使用 Set 提高查找效率
        const allSelectTempSet = new Set(allSelect);
        const pageInitSet = new Set(pageInit);
        const newSelectedSet = new Set(newSelectedRowKeys);

        const add: React.Key[] = [];
        newSelectedRowKeys.forEach(item => {
            if (!pageInitSet.has(item) && !allSelectTempSet.has(item)) {
                add.push(item);
            }
        });

        const remove: React.Key[] = [];
        pageInit.forEach(item => {
            if (!newSelectedSet.has(item)) {
                remove.push(item);
            }
        });

        // 合并
        const endSet = new Set([...allSelect, ...add]);
        remove.forEach(item => endSet.delete(item));
        const end = Array.from(endSet);
        setAllSelect(end);
        setPageInit(newSelectedRowKeys);

    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const tagClose = (e: any) => {
        // 生成过滤后的 selects 数组
        const updatedSelects = allSelect.filter((item: any) => e.id !== item);
        // 更新 selects 状态
        setAllSelect(updatedSelects);
        // 生成过滤后的 selectedRowKeys 数组
        const updatedSelectedRowKeys = selectedRowKeys.filter((item: any) => e.id !== item);
        // 更新 selectedRowKeys 状态
        setSelectedRowKeys(updatedSelectedRowKeys);
    };

    const columns: ColumnsType<ColumnsTypes> = [
        {
            title: '姓名',
            dataIndex: 'name',
            align: `center`,
        },
        {
            title: '身份证号码',
            dataIndex: 'idCard',
            align: `center`,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            align: `center`,
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: `center`,
            render(value: any, record: any) {
                return (
                    <>
                        {value}
                    </>
                )
            },
        },
        {
            title: '眼别',
            dataIndex: 'eyeType',
            align: `center`,
        },
        {
            title: '病',
            dataIndex: 'illnessName',
            align: `center`,
        }
    ];

    const changeCondition = (searchDataTemp: SearchData) => {

        if (searchDataTemp.gEyeType || searchDataTemp.eyeDisease ||
            searchDataTemp.fundusEyeType || searchDataTemp.bEyeType) {
            setPageInit([])
            setSelectedRowKeys([])
            setSelectedShow([])
            setAllSelect([])
        }
        const search = {
            ...searchData,
            ...searchDataTemp
        }
        setSearchData(search)
    }

    const reset = () => {
        setSearchData(defSearch)
    }

    const changeEyeDisease = (e: any) => {

        console.log(e)
        const selectStatus = EyeTypeMap[e];
        const obj = {
            page: 0, eyeDisease: e
        }
        setSelectStatus(selectStatus)
        setSearchData(obj)
    }

    return <>
        <Spin tip={tip} spinning={importLoading}>
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <>
                <Space wrap={true}>
                    {selectedShow.map((item: any) => {
                        return (
                            <Tag
                                key={item.id}
                                closeIcon
                                onClose={(e) => {
                                    tagClose(item);
                                }}
                                closable>
                                {item.name}
                            </Tag>
                        )
                    })}
                </Space>
                <Space>
                    <Select
                        showSearch
                        placeholder="眼病"
                        optionFilterProp="children"
                        onChange={(e) => {
                            changeEyeDisease(e)
                        }}
                        value={searchData.eyeDisease}
                        filterOption={(input: any, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{width: 200}}
                        options={DescEyeDisease}
                    />
                    <Select
                        showSearch
                        placeholder="白内障眼别"
                        optionFilterProp="children"
                        onChange={(e) => {
                            changeCondition({page: 0, bEyeType: e})
                        }}
                        value={searchData.bEyeType}
                        filterOption={(input: any, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{width: 120}}
                        options={eyeTypeArr}
                        disabled={selectStatus.B}
                    />
                    <Select
                        showSearch
                        placeholder="眼底病眼别"
                        optionFilterProp="children"
                        onChange={(e) => {
                            changeCondition({page: 0, fundusEyeType: e})
                        }}
                        value={searchData.fundusEyeType}
                        filterOption={(input: any, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{width: 120}}
                        options={eyeTypeArr}
                        disabled={selectStatus.F}
                    />
                    <Select
                        showSearch
                        placeholder="翼状胬肉眼别"
                        optionFilterProp="children"
                        onChange={(e) => {
                            changeCondition({page: 0, gEyeType: e})
                        }}
                        value={searchData.gEyeType}
                        filterOption={(input: any, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{width: 120}}
                        options={eyeTypeArr}
                        disabled={selectStatus.G}
                    />
                    {/*<Button onClick={findData}>*/}
                    {/*    搜索*/}
                    {/*</Button>*/}
                    {/*<Button onClick={() => reset()}>重置</Button>*/}
                </Space>
                <Table
                    rowSelection={rowSelection}
                    className="mgTop15"
                    bordered
                    rowKey="id"
                    columns={columns}
                    dataSource={content}
                    pagination={{
                        current: searchData.page + 1,
                        pageSize,
                        total: totalElements,
                        showTotal: (total) => `共${total}条`,
                        showSizeChanger: false,
                    }}
                    onChange={(pagination: any) =>
                        pageChange(
                            {
                                page: pagination.current - 1
                            }
                        )
                    }
                />
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        onClick={continueSubmit}
                        className={styles.postSave}
                    >
                        下载
                    </Button>
                    {/*<Button*/}
                    {/*    type="primary"*/}
                    {/*    htmlType="submit"*/}
                    {/*    size="large"*/}
                    {/*    onClick={overwriteCommits}*/}
                    {/*    className={styles.postSave}*/}
                    {/*    danger*/}
                    {/*>*/}
                    {/*    使用上次报名账户信息*/}
                    {/*</Button>*/}
                    <Button
                        size='large'
                        onClick={cancel}
                    >
                        取消
                    </Button>
                </Space>
            </>
        </Space>
        </Spin>
    </>;
}
