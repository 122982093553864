import API from "../../common/api";
import {BoneDetailDataType} from "./BoneCommonType";
import {BoneStatusSelectArr, OtherSource} from "./BoneDictData";


export function buildBoneFileData(data: BoneDetailDataType["data"]) {

    if (data.files && data.files.length > 0) {
        if (data.screening) {
            data.screening.density = [];
            data.screening.metabolism = [];
            data.screening.invoice = [];
        }
        data.files.forEach((item: any) => {
            const path = API.domain + item.url;
            const obj = {
                id: item.fileId,
                uid: item.fileId,
                fileId: item.fileId,
                name: item.name,
                url: API.domain + item.url,
                status: 'done',
            };
            if (item.fileType === 1) {
                data.screening.density.push(obj);
            } else if (item.fileType === 2) {
                data.screening.metabolism.push(obj);
            } else if (item.fileType === 3) {
                data.screening.invoice.push(obj);
            }
        });
    }
}

export const BoneSelectStatus = (arr: any[], that: any) => {
    let temp: any[] = [];
    BoneStatusSelectArr.forEach(item => {
        if (arr.indexOf(item.value) >= 0) {
            temp.push(item);
        }
    })
    that.setState({
        statusListArr: temp
    })
};

export function checkShowOtherSource(toneSourceId: string, toneOtherSource: string | null) {

    return (toneSourceId && toneSourceId === OtherSource && toneOtherSource)
}