import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message} from "antd";
import Fetch from "../common/FetchIt";
import API from "../common/api";
import styles from './apply.module.css';
import {Apply} from './types';

const FormItem = Form.Item;
const {TextArea} = Input;

type FormProps = {
    apply: Apply,
    hide: () => void,
    reload: () => void,
}

type FormState = {
    provinceId: number | '',
    cityId: number | '',
}

class RefuseForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();

    submit = (values: Apply) => {
        Fetch.put(`${API.apply.refuse}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    render() {
        return (
            <div className={styles.container}>
                <Form
                    initialValues={this.props.apply}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem className={styles.mylabel} name="reason" label="原因" labelCol={{span: 4}}
                              wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}, {
                        max: 20,
                        type: 'string',
                        message: '长度不能超过20个字符'
                    }]}>
                        <TextArea
                            readOnly={this.props.apply.readonly}
                            rows={2}
                        />
                    </FormItem>
                    {
                        !this.props.apply.readonly && (
                            <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                                <Button type="primary" htmlType="submit" className={styles.mybtn}>提交</Button>
                            </FormItem>
                        )
                    }
                </Form>
            </div>
        );
    }
}

export default RefuseForm;
