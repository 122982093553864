import React, {useEffect, useState} from 'react';
import {Card, Col, DatePicker, InputNumber, Row, Select, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import FileUploader from "../../common/uploader";
import {UploadOutlined} from "@ant-design/icons";
import {EditBaseType} from "../common/edit/DhEditAndDetailPageConfig";
import TextArea from "antd/lib/input/TextArea";
import {DhDetailDataType} from "../common/DhDetailType";
import {FormTitle, getTitle, showProofOfIdentity} from "../common/IdentityData";

type EditFreeClinicsReportType = EditBaseType & {
    userDetail: DhDetailDataType["data"]['userDetail'],
    warVeteran: DhDetailDataType["data"]['warVeteran']
};

export default function EditServiceUserFee(props: EditFreeClinicsReportType) {

    const [dhOperation, setDhOperation] = useState<string>('1');
    const [teethTypes, setTeethTypes] = useState<any>();
    const [titleObj, setTitleObj] = useState<FormTitle>();

    useEffect(() => {
        if (!props.userDetail) {
            return
        }
        Fetch.get(`${API.dentistry.teeth_fee.teeth_fee_select}?serviceId=${props.userDetail.serviceUser}`).then((data) => {
            setTeethTypes(data)
        });
    }, [])

    useEffect(() => {
        setTitleObj(getTitle(props.warVeteran?.serviceIdentity));
    }, [props])


    // const dhOperationChange = (e: any) => {
    //
    //     setDhOperation(e.target.value)
    // };

    return (
        <>
            <Card size="small" className={styles.mycard}
                  title={`费用信息`}>

                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="surgeryStart"
                            label="手术时间"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <DatePicker style={{width: '100%'}}
                                        placeholder="种植手术开始时间"/>
                        </FormItem>
                    </Col>
                    {/*<Col span={10}>*/}
                    {/*    <FormItem*/}
                    {/*        className={styles.mylabel}*/}
                    {/*        name="surgeryEnd"*/}
                    {/*        label="种植手术结束时间"*/}
                    {/*        labelCol={{span: 5}}*/}
                    {/*        wrapperCol={{span: 11}}*/}
                    {/*        rules={[{required: true, message: '必填'}]}*/}
                    {/*    >*/}
                    {/*        <DatePicker style={{width: '100%'}}*/}
                    {/*                    placeholder="种植手术结束时间"/>*/}
                    {/*    </FormItem>*/}
                    {/*</Col>*/}
                </Row>
                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="teethNum"
                            label="种牙颗数"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <InputNumber
                                precision={0}
                                style={{width: '100%'}}
                                max={50}
                                min={0}
                                // onChange={this.computeSelfPayPrice}
                                placeholder="种牙颗数"
                            />
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="totalPrice"
                            label="总金额"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <InputNumber
                                // precision={2}
                                style={{width: '100%'}}
                                max={999999}
                                min={0}
                                placeholder="总金额"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="teethType"
                            label="牙齿类型"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                style={{width: '100%'}}
                                options={teethTypes}
                            >
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="remark"
                            label="备注"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: false, message: '必填'}]}
                        >
                            <TextArea rows={4} placeholder="备注" maxLength={500}/>
                        </FormItem>
                    </Col>
                </Row>
                {/*align="start"*/}
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="informed"
                            valuePropName="fileList"
                            label="知情同意书"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="consentForms"
                            valuePropName="fileList"
                            label="手术/救治同意书"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="hospitalizationRecords"
                            valuePropName="fileList"
                            label="病例"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="invoices"
                            valuePropName="fileList"
                            label="收费凭证"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                                rules={[{required: true, message: '必填'}]}
                            />
                        </FormItem>
                    </Card>
                    {showProofOfIdentity(props.warVeteran?.serviceIdentity) && (
                        <Card style={{width: '100%'}}>
                            <FormItem
                                className={styles.mylabel}
                                name="proofIdentity"
                                valuePropName="fileList"
                                label={titleObj?.proofOfIdentity}
                                labelCol={{span: 2}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <FileUploader
                                    multiple
                                    listType="picture"
                                    className="upload-list-inline"
                                    accept='*'
                                    checkSize={false}
                                    noChekckSizeMal={true}
                                    icon={<UploadOutlined/>}
                                    rules={[{required: true, message: '必填'}]}
                                />
                            </FormItem>
                        </Card>
                    )}
                    {(props.warVeteran?.warVeteran === '2' &&
                        showProofOfIdentity(props.warVeteran?.serviceIdentity)) && (
                        <Card style={{width: '100%'}}>
                            <FormItem
                                className={styles.mylabel}
                                name="identityPromise"
                                valuePropName="fileList"
                                label="身份承诺书"
                                labelCol={{span: 2}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <FileUploader
                                    multiple
                                    listType="picture"
                                    className="upload-list-inline"
                                    accept='*'
                                    checkSize={false}
                                    noChekckSizeMal={true}
                                    icon={<UploadOutlined/>}
                                    rules={[{required: true, message: '必填'}]}
                                />
                            </FormItem>
                        </Card>
                    )}
                </Space>
            </Card>
        </>
    );
}
