import React, {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Card, Col, message, Row, Space, Tag} from "antd";
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {useParams} from "react-router-dom";
import styles from './apply.module.css';
import createModal from "../common/createModal";
import RefuseForm from "./RefuseForm";
import App from "../../App";

const RefuseFormModal = createModal(RefuseForm);

function reload() {
    window.location.reload();
}

function ApplyDetail() {
    const [apply, setApply] = useState<any>({});

    const {id} = useParams<{ id: string | undefined }>()

    useEffect(function () {
        if (!id) {
            return;
        }
        Fetch.get(`${API.apply.zsgc}/${id}`).then(data => {
            setApply(data)
        });
    }, [id]);

    const organization = apply.organization || {}

    let modal: any = null;

    function refuse() {
        if (modal) {
            modal.show();
        }
    }

    function pass() {
        Fetch.put(`${API.apply.status}`, {id, status: 'ENABLE'}).then(() => {
            message.success('操作成功！');
            reload();
        });
    }

    function renderStatus() {
        if (apply.status === 'UNCHECKED') {
            return (
                <Space>
                    <Button onClick={pass} type="primary">通过</Button>
                    <Button onClick={refuse}>驳回</Button>
                </Space>
            )
        } else if (apply.status === 'ENABLE') {
            return (
                <Tag color="green">已通过</Tag>
            )
        } else if (apply.status === 'DISABLE') {
            return (
                <Tag color="red">已禁用</Tag>
            )
        } else if (apply.status === 'REJECTED') {
            return (
                <Tag color="gray">已驳回</Tag>
            )
        }
        return '';
    }

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>乐龄志愿服务组织管理</Breadcrumb.Item>
                <Breadcrumb.Item>乐龄志愿服务组织详情</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 720}}>
                <Card className={styles.mycard}>
                    <Row justify="space-around">
                        <Col span={6}>
                            <div>申请人: {apply.username}</div>
                            <div>申请时间: {apply.createdDate}</div>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={6} style={{textAlign: 'right'}}>
                            <div>&nbsp;</div>
                            {
                                renderStatus()
                            }
                        </Col>
                    </Row>
                </Card>
                <Card title="基本信息" className={styles.mycard}>
                    <Row>
                        <Col span={6}>组织名称: {organization.name}</Col>
                        <Col span={6}>上级组织: {apply.institution}</Col>
                        <Col span={6}>负责人: {organization.username}</Col>
                        <Col span={6}>负责人电话: {organization.phone}</Col>
                    </Row>
                </Card>
                <Card title="区域信息" className={styles.mycard}>
                    <Row>
                        <Col span={6}>所在区域: {apply.province}/{apply.city}/{apply.district}</Col>
                        <Col span={18}>详细地址: {organization.address ? organization.address.detail : ''}</Col>
                    </Row>
                </Card>
                <Card title="文件信息">
                    <Row>
                        {
                            apply.mediaList && apply.mediaList.length == 0 && <span>暂无</span>
                        }
                        {
                            apply.mediaList && apply.mediaList.map((file: any) => {
                                return (
                                    <Col
                                        key={file.id}
                                        span={8}
                                    >
                                        <div>{file.name}</div>
                                        <div><a href={file.url} target="_blank" rel="noreferrer">在线查看</a></div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card>
            </div>
            <RefuseFormModal
                title={'驳回原因'}
                reload={reload}
                apply={{id}}
                ref={c => modal = c}
            />
        </>
    );
}


export default function ADetail() {
    return (
        <App selectedKeys={['yueling']}>
            <ApplyDetail/>
        </App>
    )
}
