import {Card, Descriptions} from 'antd';
import React from 'react';
import {DhDetailDataType} from "../common/DhDetailType";

type DhWarVeteranType = {

    subsidyInfo: DhDetailDataType["data"]["subsidyInfo"]
}

const DhSubsidyInfoInfo: React.FC<DhWarVeteranType> = (props) => {

    return (
        <>
            {(props.subsidyInfo && props.subsidyInfo.subsidy) && (
                <Card size="small"
                      title={`报销信息`}
                >
                    <Descriptions>
                        <Descriptions.Item label="牙齿类型">
                            {props.subsidyInfo.teethName}
                        </Descriptions.Item>
                        <Descriptions.Item label="牙齿金额(单颗)">
                            {props.subsidyInfo.teethPrice}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="预计报销">
                            {props.subsidyInfo.subsidy}
                        </Descriptions.Item>
                        <Descriptions.Item label="预计最少花费">
                            {props.subsidyInfo.minTotalPrice}
                        </Descriptions.Item>
                        {props.subsidyInfo.msg && (
                            <Descriptions.Item label="错误信息">
                                {props.subsidyInfo.msg}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Card>
            )
            }
        </>
    );
};

export default DhSubsidyInfoInfo;
