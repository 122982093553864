import React, {Component} from 'react';
import {Button, Form, Input, message} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './login.module.css';
import {Password} from './types';
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';

const FormItem = Form.Item;

type FormProps = {
    id: number;
    hide: () => void;
};

type FormState = {};

class ResetPasswordForm extends Component<FormProps, FormState> {
    submit = (values: any) => {
        this.save(values);
    };

    save = (values: Password) => {

        values.userId = this.props.id;
        Fetch.put(`${API.user.resetPassword}`, values).then(() => {
            this.props.hide();
            message.success('保存成功');
        });
    };

    render() {
        return (
            <Form onFinish={this.submit}>
                <FormItem
                    className={styles.mylabel}
                    name="newPassword"
                    label="新密码"
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input.Password/>
                </FormItem>
                <FormItem
                    className={styles.mylabel}
                    name="rePassword"
                    label="重复新密码"
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input.Password

                        iconRender={(visible) =>
                            visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                        }
                    />
                </FormItem>
                <FormItem wrapperCol={{span: 6, offset: 4}} className="center">
                    <Button type="primary" htmlType="submit" className={styles.mybtn}>
                        确认
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

export default ResetPasswordForm;
