import {Card, Descriptions} from 'antd';
import React from 'react';
import styles from "../../add/ServiceUser.module.css";
import {BillDetailCommonType} from "./types/HospitalBillType";

const BillInfo: React.FC<BillDetailCommonType> = (props) => {
    const {data} = props;
    return (
        <Card size="small" className={styles.mycard}
              title={`收款账户`}
        >
            <Descriptions>
                <Descriptions.Item label="名称">
                    {data.accountVo.name}
                </Descriptions.Item>
                <Descriptions.Item label="开户行">
                    {data.accountVo.accountBank}
                </Descriptions.Item>
                <Descriptions.Item label="账户">
                    {data.accountVo.accountNum}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions>
                <Descriptions.Item label="联行号">
                    {data.accountVo.bankRnt}
                </Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default BillInfo;
