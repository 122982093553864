import {Card, Space, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import encryptData from "../../common/crypto";
import {Link} from 'react-router-dom';
import API from "../../common/api";
import Fetch from "../../common/FetchIt";
import {EndStatus} from "../../common/tencent/TcccSdkTest";
import {ColumnsType} from "antd/es/table";

type table = {};
type billDetailType = {
    taskId: number | null
}
const DhTaskCallLog: React.FC<billDetailType> = (props) => {

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(15);
    const [name, setName] = useState<string>('');
    const [idCard, setIdCard] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [content, setContent] = useState<[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [isReset, setReset] = useState<boolean>(false);

    const [isPlaying, setPlaying] = useState<boolean>(false);

    const loadData = () => {

        if (props.taskId) {
            let url = `${API.dentistry.dh_call_log.get_list}?page=${page}&size=${pageSize}&taskId=${props.taskId}`;
            if (!isReset) {
                // url = url + addParams("name", name, STRING_STR);
                // url = url + addParams("idCard", idCard, STRING_STR);
                // url = url + addParams("phone", phone, STRING_STR);
            }
            Fetch.get(url).then((data: any) => {
                setContent(data.content)
                setTotalElements(data.totalElements)
                setTotalPages(data.totalPages)
                setReset(false)
            })
        }
    };

    useEffect(function () {

        loadData();
    }, [props.taskId, page]);

    useEffect(function () {

        if (isReset) {
            loadData();
        }
    }, [isReset]);

    // useEffect(function () {
    //
    //     loadData();
    // }, [page]);

    const pageChange = (condition: any) => {
        setPage(condition.page)
    };

    const reset = () => {
        setName('')
        setIdCard('')
        setPhone('')
        setReset(true)
    };

    const changeCondition = (condition: any) => {

        setPage(condition.page)
        if (condition.name) {
            setName(condition.name)
        }
        if (condition.idCard) {
            setIdCard(condition.idCard)
        }
        if (condition.phone) {
            setPhone(condition.phone)
        }
    };

    const togglePlay = () => {
        setPlaying(!isPlaying);
    };

    const columns: ColumnsType<table> = [
        {
            title: '通话时间',
            dataIndex: 'talkTime',
            key: 'talkTime',
            align: 'center',
        },
        {
            title: '通话时长',
            dataIndex: 'duration',
            key: 'duration',
            align: `center`,
            render(value: any) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{value}s</span>
                );
            },
        },
        {
            title: '呼入呼出',
            dataIndex: 'direction',
            key: 'direction',
            align: `center`,
            render(value: any) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{value === 0 ? ('呼入') : ('呼出')}</span>
                );
            },
        },
        {
            title: '结束通话状态',
            dataIndex: 'endStatus',
            align: `center`,
            key: 'endStatus',
            render(value: any) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{EndStatus[value]}</span>
                );
            },
        },
        {
            title: '通话录音',
            dataIndex: 'customRecordUrl',
            align: `center`,
            key: 'customRecordUrl',
            render(value: any) {
                return (
                    <>
                        <audio style={{height: '30px'}}
                               controls
                               autoPlay={isPlaying}
                        >
                            <source
                                src={value.replace('https://file-service-prod-1309224092.cos.ap-beijing.tencentcos.cn'
                                    , 'https://appcdn.lelingzhiyuan.com')} type="audio/mp3"/>
                        </audio>
                    </>
                );
            },
        },
        {
            title: '操作',
            align: `center`,
            dataIndex: 'status',
            width: 120,
            render: (text: any, record: any) => {
                return (
                    <Space>
                        <Link to={`/serviceUserDetail/detail?id=${encryptData(record.serviceId + "")}`}>
                            详情
                        </Link>
                    </Space>
                );
            },
        }
    ];
    // @ts-ignore
    return (
        <>
            {props.taskId && (
                <Card size="small"
                      title={`通话记录`}>
                    <div
                        className="site-layout-background"
                    >
                        <Space>
                        </Space>
                        <Table
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Card>
            )}
        </>
    );
};

export default DhTaskCallLog;
