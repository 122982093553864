import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Table} from "antd";
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';

type TheState = {
    page: number,
    pageSize: number,
    totalElements: number,
    totalPages: number,
    content: any[],
};

export default class UserTogetherList extends Component<{ togetherId: string }, TheState> {
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        activityUserNum: 0,
    };

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState({
            ...condition,
        }, () => {
            if (load) {
                this.loadData();
            }
        });
    };

    loadData = () => {
        const {page, pageSize} = this.state;
        Fetch.get(`${API.donation.list2}?page=${page}&size=${pageSize}&togetherId=${this.props.togetherId}`).then(data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            })
        });
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<any> = [
            {
                title: "微信昵称",
                dataIndex: ["user", "wxNickname"],
                align: `center`,
            },
            {
                title: "捐款时间",
                dataIndex: "createdDate",
                align: `center`,
            },
            {
                title: "捐款金额",
                dataIndex: "amount",
                align: `center`,
            }];

        return (
            <>
                <div className="site-layout-background">
                    <Table
                        bordered
                        rowKey={record => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) => this.changeCondition({
                            page: pagination.current - 1,
                            pageSize: pagination.pageSize,
                        }, true)}
                    />
                </div>
            </>
        );
    }
}
