import React from 'react';
import {Button, Divider, message, Modal, Space, Spin, Table} from "antd";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import BaseListPage from "../../common/listpage/BaseListPage";
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import {ColumnsType} from "antd/es/table";
import {BaseDhPageDate} from "../../dhHospital/common/DhPageCommon";
import App from '../../../App';
import {BasePageType} from "../../common/listpage/BaseListPageType";
import encryptData, {decryptData} from "../../common/crypto";
import {Link} from "react-router-dom";
import common from "../../common/common";
import styles from "../../cooperate/add/ServiceUser.module.css";
import {QuesStatus, QuesType} from "../ExamCommonData";
import {PlusOutlined} from "@ant-design/icons";
import ExamQuesDetailModal from "./ExamQuesDetailModal";
import createModal from "../../common/createModal";

const confirm = Modal.confirm;
const ImportExamQuesDetailModal = createModal(ExamQuesDetailModal);
type User = { quesStatus: number, id: number };
type ExamQuesListType = BasePageType & {
    paperCode: string
}
export const DEFAULT: ExamQuesListType = {
    ...BaseDhPageDate,
    paperCode: '',
}

class ExamQuesListClass extends BaseListPage {

    state: ExamQuesListType = {
        ...DEFAULT,
        btnQ: {}
    }
    private importExamQuesDetailModal: any = null;

    componentDidMount() {
        this.setState({
            paperCode: decryptData(common.getQuery('paperCode')),
        }, () => {
            super.componentDidMount();
        })
    }

    loadData = () => {
        const {pageSize, searchData, paperCode} = this.state;
        let url = `${API.exam.exam_ques.query_list}?page=${searchData.page}&size=${pageSize}&paperCode=${paperCode}`;

        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(BaseDhPageDate.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    updateQuesStatus = (id: number, quesStatus: number) => {
        let title = "";
        if (quesStatus == 2) {
            title = "下架"
        }
        if (quesStatus === 3 || quesStatus === 1) {
            title = "上架"
        }
        const that = this;
        confirm({
            title: `是否${title}当前试题`,
            onOk() {
                Fetch.postJSON(`${API.exam.exam_ques.update_status}`, {id: id}).then(() => {
                    message.success("操作成功")
                    that.loadData();
                });
            }
        })
    }

    goCreate() {
        window.location.href = '/#/exam/ques/add?paperCode=' + common.getQuery('paperCode');
    }

    getDetail = (id: number) => {

        this.setState({
            selectId: id
        }, () => {
            this.importExamQuesDetailModal.show();
        })
    }


    render() {
        const {
            btnQ, searchData, content, pageSize, totalElements
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '试题',
                dataIndex: 'quesTitle',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: '分值',
                dataIndex: 'quesScore',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: '类型',
                dataIndex: 'quesType',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{QuesType[text]}</span>
                        </div>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'quesStatus',
                align: `center`,

                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{QuesStatus[text]}</span>
                        </div>
                    );
                },
            },

            {
                title: '操作',
                align: `center`,
                width: 300,
                dataIndex: 'status',
                render: (text: any, record: any) => {
                    return (
                        <>
                            <Space split={<Divider type="vertical"/>}>
                                {(record.quesStatus === 1) &&
                                    <Link to={`/exam/ques/add?quesId=${encryptData(record.id + "")}`}>
                                        编辑
                                    </Link>
                                }
                                {(record.quesStatus === 1 || record.quesStatus === 3) &&
                                    <Button className={styles.antBtnPadding} type="link" size={"small"}
                                            onClick={() => this.updateQuesStatus(record.id, record.quesStatus)}>
                                        上架
                                    </Button>
                                }
                                {(record.quesStatus === 2) &&
                                    <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                            onClick={() => this.updateQuesStatus(record.id, record.quesStatus)}>
                                        下架
                                    </Button>
                                }
                                <Button className={styles.antBtnPadding} type="link" size={"small"}
                                        onClick={() => this.getDetail(record.id)}>
                                    详情
                                </Button>

                            </Space>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Space onKeyDown={this.handleKeyDown}>
                                {/*<Input*/}
                                {/*    placeholder="姓名"*/}
                                {/*    value={searchData.name}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, name: e.target.value})*/}
                                {/*    }*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<Input*/}
                                {/*    placeholder="手机号"*/}
                                {/*    value={searchData.phone}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, phone: e.target.value})*/}
                                {/*    }*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<Input*/}
                                {/*    placeholder="身份证号"*/}
                                {/*    value={searchData.idCard}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, idCard: e.target.value})*/}
                                {/*    }*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<Select*/}
                                {/*    style={{width: 220}}*/}
                                {/*    placeholder="状态"*/}
                                {/*    optionFilterProp="children"*/}
                                {/*    options={dhStatusArray}*/}
                                {/*    value={searchData.status}*/}
                                {/*    allowClear={true}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, status: e})*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*<Select*/}
                                {/*    showSearch*/}
                                {/*    placeholder="选择执行机构名称"*/}
                                {/*    optionFilterProp="children"*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, hospitalId: e})*/}
                                {/*    }}*/}
                                {/*    value={searchData.hospitalId}*/}
                                {/*    filterOption={(input: any, option: any) =>*/}
                                {/*        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                                {/*    }*/}
                                {/*    style={{width: 200}}*/}
                                {/*    options={hospitalSelect}*/}
                                {/*/>*/}

                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                            </Space>
                            <div style={{float: 'right', marginLeft: 'auto'}}>

                                <Space className={styles.optMenu}>
                                    <Button
                                        onClick={this.goCreate}
                                        type="primary"
                                        icon={<PlusOutlined/>}
                                    >
                                        添加试题
                                    </Button>
                                </Space>
                            </div>
                        </Space>


                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <ImportExamQuesDetailModal
                    width={1500}
                    title={"试题详情"}
                    quesId={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importExamQuesDetailModal = c)}
                />
            </>
        );
    }

}

export default function ExamQuesList() {

    return (

        <App selectedKeys={
            ['EXAM_QUES_LIST']
        }
             openKeys="EXAM">
            <ExamQuesListClass/>
        </App>
    )
}
