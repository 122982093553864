import React, {Component} from 'react';
import {Button, Form, Input, message} from 'antd';

import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './login.module.css';

const FormItem = Form.Item;
type User = {};

export default class LoginForm2 extends Component<any> {
    submit = (values: User) => {
        Fetch.post(`${API.user.login}`, values).then((data) => {
            if (data.userOrganizationSelectVos.length === 0) {
                message.error("您无法进入任何组织");
                return false;
            }
            sessionStorage.token = data.accessToken;
            sessionStorage.user = JSON.stringify(data.user);
            // sessionStorage.category = data.category;
            this.props.changeStaus(true, data);

            // if(data.user.roleId === 3){
            //     window.location.href = '/#/gongyiActivity'
            // } else if(data.user.roleId === 4 && data.category==='YUELING_SUB_ORG'){
            //     window.location.href = '/#/institution'
            // } else {
            //     window.location.href = '/#/volunteer'
            // }
        });
    };

    goRegister = () => {
        window.location.href = '/#/register';
    }

    render() {
        return (
            <Form onFinish={this.submit} className={styles.form}>
                <FormItem
                    className={styles.mylabel}
                    name="username"
                    labelCol={{span: 4}}
                >
                    <Input size="large" placeholder="账号"/>
                </FormItem>
                <FormItem
                    className={styles.mylabel}
                    name="password"
                    labelCol={{span: 4}}
                >
                    <Input
                        type="password"
                        size="large"
                        placeholder="密码"
                    />
                </FormItem>
                <FormItem className={styles.btn_box + ' center'}>
                    <Button type="primary" htmlType="submit" className={styles.mybtn}>
                        登录
                    </Button>
                </FormItem>
                <div className={styles.goRegister}>
                    <Button type="link" onClick={this.goRegister}>&gt;&gt;&gt;去注册</Button>
                </div>
            </Form>
        );
    }
}
