import {Button, Card, Input, Space, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {genderArr} from "../../util/AierCommon";
import encryptData from "../../../common/crypto";
import {Link} from 'react-router-dom';
import API from "../../../common/api";
import {addParams, STRING_STR} from "../page/ListCommon";
import Fetch from "../../../common/FetchIt";
import styles from "../../add/ServiceUser.module.css";

type billDetailType = {
    billId: number | 0
}
const BillDetailInfo: React.FC<billDetailType> = (props) => {

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(15);
    const [name, setName] = useState<string>('');
    const [idCard, setIdCard] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [content, setContent] = useState<[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [isReset, setReset] = useState<boolean>(false);

    const loadData = () => {

        if (props.billId) {
            let url = `${API.service_user_bill.detail_list_age}?page=${page}&size=${pageSize}&billId=${props.billId}`;
            if (!isReset) {
                url = url + addParams("name", name, STRING_STR);
                url = url + addParams("idCard", idCard, STRING_STR);
                url = url + addParams("phone", phone, STRING_STR);
            }
            Fetch.get(url).then((data) => {
                setContent(data.content)
                setTotalElements(data.totalElements)
                setTotalPages(data.totalPages)
                setReset(false)
            })
        }
    };

    useEffect(function () {

        loadData();
    }, [props.billId]);

    useEffect(function () {

        if (isReset) {
            loadData();
        }
    }, [isReset]);

    useEffect(function () {

        loadData();
    }, [page]);

    const pageChange = (condition: any) => {
        setPage(condition.page)
    };

    const reset = () => {
        setName('')
        setIdCard('')
        setPhone('')
        setReset(true)
    };

    const changeCondition = (condition: any) => {

        setPage(condition.page)
        if (condition.name) {
            setName(condition.name)
        }
        if (condition.idCard) {
            setIdCard(condition.idCard)
        }
        if (condition.phone) {
            setPhone(condition.phone)
        }
    };

    const columns = [
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'name',
            // align: 'center',
        },
        {
            title: '性别',
            dataIndex: 'gender',
            key: 'totalUser',
            // align: `center`,
            render(value: any) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{genderArr[value]}</span>
                );
            },
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
            // align: `center`,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            // align: `center`,
            key: 'phone',
        },
        {
            title: '入院时间',
            dataIndex: 'timeOfAdmission',
            // align: `center`,
            key: 'timeOfAdmission',
        },
        {
            title: '出院时间',
            dataIndex: 'timeOfDischarge',
            // align: `center`,
            key: 'timeOfDischarge',
        },
        {
            title: '项目资助金额',
            dataIndex: 'subsidy',
            // align: `center`,
            key: 'subsidy',
        },
        {
            title: '资助类型',
            dataIndex: 'economicSituation',
            // align: `center`,
            key: 'economicSituation',
        },
        {
            title: '操作',
            // align: `center`,
            dataIndex: 'status',
            width: 120,
            render: (text: any, record: any) => {
                return (
                    <Space>
                        <Link to={`/serviceUserDetail/detail?id=${encryptData(record.serviceId + "")}`}>
                            详情
                        </Link>
                    </Space>
                );
            },
        }
    ];
    // @ts-ignore
    return (
        <>
            <Card size="small" className={styles.mycard}
                  title={`患者列表`}>
                <div
                    className="site-layout-background"
                    style={{padding: 24}}
                >
                    <Space>
                        <Input
                            placeholder="姓名"
                            value={name}
                            onChange={(e) => {
                                changeCondition({page: 0, name: e.target.value})
                            }
                            }
                        />
                        <Input
                            placeholder="手机号"
                            value={phone}
                            onChange={(e) => {
                                changeCondition({page: 0, phone: e.target.value})
                            }
                            }
                        />
                        <Input
                            placeholder="身份证号"
                            value={idCard}
                            onChange={(e) => {
                                changeCondition({page: 0, idCard: e.target.value})
                            }
                            }
                        />
                        <Button onClick={loadData}>
                            搜索
                        </Button>
                        <Button onClick={() => reset()}>重置</Button>
                    </Space>
                    <Table
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            pageChange(
                                {
                                    page: pagination.current - 1
                                }
                            )
                        }
                    />
                </div>
            </Card>
        </>
    );
};

export default BillDetailInfo;
