import React from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Col, Dropdown, Input, Menu, message, Row, Space, Spin, Table} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import createModal from '../common/createModal';
import InstitutionForm from './InstitutionForm';
import App from '../../App';
import ResetPasswordForm from '../user/ResetPasswordForm';
import {Link} from 'react-router-dom';
import {format} from './InstitutionDetail';
import styles from "../cooperate/add/ServiceUser.module.css";
import * as XLSX from 'xlsx';
import ImportResultModal from "../common/modal/ImportResultModal";
import {GoOrg, SetListStorage} from "../common/listpage/SessionStorageUtil";
import BaseListPage from "../common/listpage/BaseListPage"

const ResetFormModal = createModal(ResetPasswordForm);
const FormModal = createModal(InstitutionForm);
const ImportResult = createModal(ImportResultModal);

const def = {
    name: '',
    page: 0,
}

class InstitutionList extends BaseListPage {
    importResult = null;
    modal = null;
    resetModal = null;

    state = {
        searchData: def,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        type: 'VOLUNTEER',
        date: [],
        institution: null,
        loading: true,
        importLoading: false,
        importResultData: null,
        modalTitle: "导入结果",
        btnQ: {
            ROLE_IMPORT_AIER_ORG: 0
        }
    };

    componentDidMount() {
        super.componentDidMount();
    };

    loadData = () => {
        const {pageSize, searchData} = this.state;
        SetListStorage(searchData)
        Fetch.get(
            `${API.institution.list}?page=${searchData.page}&size=${pageSize}&name=${searchData.name}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    create = () => {
        this.setState(
            {
                institution: null,
            },
            this.modal.show
        );
    };

    edit = (record) => {
        this.setState(
            {
                institution: format(record),
            },
            this.modal.show
        );
    };

    del = (id) => {
        Fetch.del(`${API.institution.zsgc}/${id}`).then(() => {
            this.loadData();
        });
    };


    add = () => {
        this.setState(
            {
                institution: null,
            },
            this.modal.show
        );
    };

    resetPassword = (record) => {
        this.setState(
            {
                institution: record,
            },
            this.resetModal.show
        );
    };

    changeStatus = (id, status) => {
        Fetch.put(`${API.institution.status}/${id}`, {disabled: status}).then(
            () => {
                message.success('操作成功！');
                this.loadData();
            }
        );
    };

    onImportExcel = (file) => {
        this.setState({
            importLoading: true,
            tip: "数据导入中"
        });
        // 获取上传的文件对象
        const {files} = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                // @ts-ignore
                const {result} = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, {type: 'binary'});
                let data = []; // 存储获取到的数据
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {

                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
                const param = this.buildData(data);
                Fetch.postJSON(
                    `${API.institution.import_aier_institution}`, param
                ).then((data) => {
                    this.setState(
                        {
                            importResultData: data
                        },
                        this.importResult.show
                    );
                });
            } catch (e) {
                this.setState({
                    importLoading: false,
                });
            } finally {

            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    };
    buildData = (dates) => {
        const result = [];
        for (let i = 0; i < dates.length; i++) {
            const item = dates[i];
            result.push({
                name: item.医院名称,
                provinceStr: item.省,
                cityStr: item.市,
                districtStr: item.区,
                detail: item.医院地址,
                username: item.账号负责人姓名,
                phone: item.账户负责人手机号,
                pid: item.上级,
                correct: item.修正,
            })
        }
        return result;
    }
    goOrg = (row) => {
        Fetch.get(`${API.institution.enterOrg}?orgId=${row.id}`).then((data) => {
            if (data.treeVos.length === 0) {
                message.error('该组织没有后台管理权限');
            } else {
                sessionStorage.token = data.accessToken;
                // sessionStorage.category = data.category;
                sessionStorage.orgName = data.orgName;
                // 是否可以返回
                sessionStorage.goback = 1;
                sessionStorage.buttons = JSON.stringify({
                    buttons: data.buttons,
                });
                sessionStorage.treevos = JSON.stringify({
                    arr: data.treeVos,
                });
                GoOrg()
                this.gopage(data.treeVos);
            }
        });
    };
    gopage = (datas) => {
        if (datas.length === 0) {
            message.error('您还没有访问权限，请联系管理员');
            return;
        }
        let urlPath;
        if (datas[0].routerUrl !== '') {
            urlPath = datas[0].routerUrl;
        } else {
            urlPath = datas[0].child[0].routerUrl;
        }
        if (urlPath === "/institution") {
            window.location.reload();
        } else {
            window.location.href = '/#' + urlPath;
        }
    };

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                window.location.reload()
            }
        );
    };
    clickFileInput = () => {
        // @ts-ignore
        document.getElementById('fileInput').click()
    };

    handleKeyDown = (e) => {
        super.handleKeyDown(e);
    }

    render() {
        const {searchData, pageSize, totalElements, content, btnQ, modalTitle} = this.state;
        const columns = [
            {
                title: '组织名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '组织编码',
                dataIndex: 'code',
                align: `center`,
            },
            {
                title: '负责人',
                dataIndex: 'username',
                align: `center`,
            },
            {
                title: '负责人手机号',
                dataIndex: 'phone',
                align: `center`,
            },

            // {
            //   title: '状态',
            //   dataIndex: 'isActive',
            //   align: `center`,
            //   render: (text) => {
            //     return text === 1 ? '已激活' : '未激活';
            //   },
            // },
            {
                title: '操作',
                align: `center`,
                render: (text, record) => {
                    if (record.category === 'COUNTRY') {
                        return ' - ';
                    }
                    const items = [];
                    let buttons = sessionStorage.getItem('buttons');
                    let obj = JSON.parse(buttons);
                    if (obj.buttons.ROLE_INSTITUIONS_ENTER_ORGANIZATION_MENU == 1) {
                        items.push(
                            <Menu.Item key="0" onClick={() => this.goOrg(record)}>
                                进入组织
                            </Menu.Item>
                        );
                    }
                    items.push(
                        <Menu.Item key="1" onClick={() => this.edit(record)}>
                            编辑
                        </Menu.Item>
                    );
                    if (!record.disabled) {
                        items.push(
                            <Menu.Item
                                key="2"
                                onClick={() => this.changeStatus(record.id, 1)}
                            >
                                禁用
                            </Menu.Item>
                        );
                    } else {
                        items.push(
                            <Menu.Item onClick={() => this.changeStatus(record.id, 0)}>
                                启用
                            </Menu.Item>
                        );
                    }
                    if (record.isActive) {
                        items.push(
                            <Menu.Item key="3" onClick={() => this.resetPassword(record)}>
                                重置
                            </Menu.Item>
                        );
                    }

                    return (
                        <Space>
                            <Button>
                                <Link to={`/institution/${record.id}`}>查看</Link>
                            </Button>
                            <Button>
                                <Link to={`/institutionPermissions?id=${record.id}`}>分配权限</Link>
                            </Button>
                            {items.length > 0 && (
                                <Dropdown overlay={<Menu>{items}</Menu>}>
                                    <Button>
                                        更多操作 <DownOutlined/>
                                    </Button>
                                </Dropdown>
                            )}
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>组织列表</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Row>
                            <Col span={6}>
                                <Space>
                                    <Button type="primary" onClick={this.add}>
                                        新建组织
                                    </Button>
                                    {btnQ.ROLE_IMPORT_AIER_ORG ? (
                                        // <div className={styles['upload-wrap']}>
                                        //     <input className={styles['file-uploader']} type='file' accept='.xlsx, .xls'
                                        //            onChange={this.onImportExcel}/>
                                        //     <span className={styles['upload-text']}>导入爱尔眼科</span>
                                        // </div>
                                        <Button
                                            onClick={this.clickFileInput}
                                        >
                                            导入眼科医院
                                        </Button>
                                    ) : ('')}
                                </Space>
                            </Col>
                            <Col span={18} style={{textAlign: 'right'}}>
                                <Space onKeyDown={this.handleKeyDown}>
                                    组织名称：
                                    <Input
                                        placeholder="请输入组织名称"
                                        value={searchData.name}
                                        onChange={(e) =>
                                            this.changeCondition({page: 0, name: e.target.value})
                                        }
                                    />
                                    <Button onClick={this.loadData}>搜索</Button>
                                    <Button onClick={() => this.reset(def)}>重置</Button>
                                </Space>
                            </Col>
                        </Row>

                        <Table
                            className="mgTop15"
                            bordered
                            loading={this.state.loading}
                            rowKey={(record) => {
                                return record.id;
                            }}
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                    <FormModal
                        title={this.state.institution === null ? '创建组织' : '编辑组织'}
                        reload={this.loadData}
                        width={900}
                        institution={this.state.institution}
                        ref={(c) => (this.modal = c)}
                    />
                    <ResetFormModal
                        title={'重置密码'}
                        width={600}
                        id={this.state.institution ? this.state.institution.userId : ''}
                        ref={(c) => (this.resetModal = c)}
                    />
                </Spin>
                <ImportResult
                    width={400}
                    title={modalTitle}
                    importResult={this.state.importResultData}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importResult = c)}
                />
                <input id="fileInput" className={styles.fileUploader} type='file'
                       accept='.xlsx, .xls'
                       onChange={this.onImportExcel}/>
            </>
        );
    }
}

export default function InstitutionIndex() {
    return (
        <App selectedKeys={['institution']} openKeys="InstitutionMenu">
            <InstitutionList/>
        </App>
    );
}
