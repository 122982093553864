import React, {Component} from 'react';
import {Button, Form, Input} from 'antd';

import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './login.module.css';
import './login.remark.css';

const FormItem = Form.Item;
type User = {};

export default class LoginFormSetPassword extends Component<any> {
    submit = (values: User) => {

        // return;
        Fetch.put(`${API.user.createPassword}`, values).then((data) => {
            // sessionStorage.token = data.accessToken;
            // sessionStorage.user = JSON.stringify(data.user);
            this.props.changeStaus(true);
        });
    };

    render() {
        return (
            <div className="remark">
                <div className="remark-content">
                    <div className="title">设置密码</div>
                    <div className="content">
                        <Form
                            onFinish={this.submit}
                            className={styles.form}
                            layout="vertical"
                        >
                            <FormItem
                                className={styles.mylabel}
                                name="newPassword"
                                hasFeedback
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input.Password

                                    placeholder="输入密码"
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="rePassword"
                                hasFeedback
                                rules={[
                                    {required: true, message: '必填'},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('两次输入密码不一致!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password

                                    placeholder="确认密码"
                                />
                            </FormItem>
                            <FormItem className={styles.btn_box + ' center'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.mybtn}
                                >
                                    确认
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
