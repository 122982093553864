import React, {useEffect, useState} from 'react';
import {Form, Select} from "antd";
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from "./login.module.css";

const Option = Select.Option;

type ZoneSelectProps = {
    provinceId: number | null,
    onChange: (province: number) => void,
}

type Zone = {
    id: number,
    name: string
}

export default function ZoneSelect(props: ZoneSelectProps) {
    const [provinces, setProvinces] = useState<Zone[]>([]);
    const [cities, setCities] = useState<Zone[]>([]);

    useEffect(() => {
        Fetch.get(`${API.zone.list}?parentId=${0}`).then((data: Zone[]) => {
            setProvinces(data)
        });
    }, []);

    useEffect(() => {
        if (props.provinceId) {
            Fetch.get(`${API.zone.list}?parentId=${props.provinceId}`).then((data: Zone[]) => {
                setCities(data)
            });
        }
    }, [props.provinceId]);

    return (
        <Form.Item className={styles.mylabel} label={<span className={styles.custom}>所在省市</span>}>
            <Form.Item
                name={['address', 'provinceId']}
                className={styles.mylabel}
                rules={[{required: true, message: '必填'}]}
                style={{display: 'inline-block', width: 'calc(50% - 4px)'}}
            >
                <Select
                    onChange={props.onChange}
                >
                    {
                        provinces.map(item => {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                name={['address', 'cityId']}
                className={styles.mylabel}
                rules={[{required: true, message: '必填'}]}
                style={{display: 'inline-block', width: 'calc(50% - 4px)', marginLeft: '8px'}}
            >
                <Select>
                    {
                        cities.map(item => {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
        </Form.Item>
    );
}
