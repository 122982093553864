import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Table, Tag} from 'antd';
import './active.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';

export default function Active() {
    const {userid} = useParams<{ userid: string }>();
    const [page, setPage] = useState<number>(0);
    const [datas, setData] = useState<any>({
        content: [],
        totalElements: 0,
        totalPages: 0,
        loading: true,
    });
    useEffect(() => {
        getActiveData();
    }, [page]);
    const getActiveData = () => {
        Fetch.get(
            `${API.volunteer.volunteerActive}/${userid}?page=${page}&size=6`
        ).then((data) => {
            setData(data);
        });
    };

    const changeCondition = (condition: any, load = false) => {
        setPage(condition.page);
    };
    const columns = [
        {
            title: '起始时间',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text: any, row: any) => {
                return text + ' 至 ' + row.endDate;
            },
        },

        {
            title: '志愿项目名称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '活动状态',
            dataIndex: 'activityStatus',
            key: 'activityStatus',
            render: (text: any) => {
                //  <!-- (1, "未开始"),(2, "报名中"),(3, "报名结束"),(4, "签到中"),(5, "签到结束"),(6, "活动中"),(7, "活动结束"), -->
                let status = '';
                switch (text) {
                    case 1:
                        return <Tag color="blue">未开始</Tag>;
                    case 2:
                        return <Tag color="cyan">报名中</Tag>;
                    case 3:
                        return <Tag>报名结束</Tag>;
                    case 4:
                        return <Tag color="green">签到中</Tag>;
                    case 5:
                        return <Tag>签到结束</Tag>;
                    case 6:
                        return <Tag color="volcano">活动中</Tag>;

                    default:
                        return <Tag>活动结束</Tag>;
                }
            },
        },
        {
            title: '当前状态',
            dataIndex: 'userStatus',
            key: 'userStatus',
            render: (text: any) => {
                let status = '';
                switch (text) {
                    case 1:
                        status = '审核中';
                        break;
                    case 2:
                        status = '已报名';
                        break;
                    case 3:
                        status = '拒绝';
                        break;
                    case 4:
                        status = '订阅';
                        break;
                    case 5:
                        status = '已签到';
                        break;
                    case 6:
                        status = '已签出';
                        break;
                }
                return status;
            },
        },
        // {
        //   title: '结束日期',
        //   dataIndex: 'endDate',
        //   key: 'endDate',
        // },
    ];
    return (
        <div className="active">
            <Table
                dataSource={datas.content}
                bordered
                rowKey={(record) => {
                    return record.id;
                }}
                columns={columns}
                pagination={{
                    current: page + 1,
                    pageSize: 6,
                    total: datas.totalElements,
                    showTotal: (total) => `共${total}条`,
                    showSizeChanger: false,
                }}
                onChange={(pagination: any) =>
                    changeCondition(
                        {
                            page: pagination.current - 1,
                        },
                        true
                    )
                }
            />
            ;
        </div>
    );
}
