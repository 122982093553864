import React, {useEffect, useState} from 'react';
import {Button, DatePicker, message, Modal, Space, Spin, Table} from 'antd';
import {Chart} from "../util/ServiceUserDetailTypes";
import {ColumnsType} from "antd/es/table";
import API from "../../common/api";
import Fetch from "../../common/FetchIt";
import common from "../../common/common";
import dayjs, {Dayjs} from "dayjs";
import {getQuarterEnd, getQuarterStart, getWeekEnd, getWeekStart} from "../../common/DayJsUtil";

const {RangePicker} = DatePicker;

type User = {
    name: string | "",
    totalUser: number | 0,
    cataractSurgery: number | 0,
    cataractReimburse: number | 0,
    cataractCases: number | 0,
    cataractReimburseCases: number | 0,

    fundusSurgery: number | 0,
    fundusReimburse: number | 0,
    fundusCases: number | 0,
    fundusReimburseCases: number | 0,

    gilliumSurgery: number | 0,
    gilliumReimburse: number | 0,
    gilliumCases: number | 0,
    gilliumReimburseCases: number | 0,
    msg: string | ''
};

type param = {
    start: string,
    end: string
}
const confirm = Modal.confirm;
const initParamDate = {start: "2023-01-01", end: dayjs().format('YYYY-MM-DD')}
const ProvinceTable: React.FC<Chart> = (props) => {

    const [day, setDay] = useState<Dayjs | null>(null);
    const [week, setWeek] = useState<Dayjs | null>(null);
    const [month, setMonth] = useState<Dayjs | null>(null);
    const [quarter, setQuarter] = useState<Dayjs | null>(null);
    const [init, setInit] = useState<boolean>(true);
    const [year, setYear] = useState<Dayjs | null>(null);
    const [paramDate, setParamDate] =
        useState<param>(initParamDate);
    const [tableData, setTableData] =
        useState<User[]>([]);
    const [loading, setLoading] =
        useState<boolean>(true);
    const [rangeMonth, setRangeMonth] = useState();

    useEffect(() => {

        getDate(paramDate?.start, paramDate?.end);

    }, [])

    useEffect(() => {
        if (!init) {
            getDate(paramDate?.start, paramDate?.end);
        }
    }, [paramDate])

    const getDate = (start: string, end: string) => {

        let url_day = `${API.aier_hospital_day.find_hospital_day_stats}?start=${paramDate?.start}&end=${paramDate?.end}`;
        Fetch.get(url_day).then((data) => {
            setInit(false);
            setTableData(data.provinceStats)
            setLoading(false)
        });
    }

    const cleanField = () => {
        setDay(null)
        setWeek(null)
        setMonth(null)
        setQuarter(null)
        setYear(null)
        setRangeMonth(undefined)
    }

    const onChange = (date: Dayjs | null, dateString: string | string[], picker: string) => {
        cleanField();
        setLoading(true)
        if (!dateString) {
            setParamDate(initParamDate)
            return;
        }
        const pattern = /(\d{4})-(\d+)/;
        if (typeof dateString === 'string') {
            const match = dateString.match(pattern);
            switch (picker) {
                case 'day':
                    setDay(date)
                    getDay(dateString)
                    break;
                case 'week':
                    setWeek(date)
                    if (match) {
                        getWeek(Number(match[1]), Number(match[2]))
                    } else {
                        message.error("没有获取到正确日期");
                    }
                    break;
                case 'month':
                    setMonth(date)
                    if (match) {
                        getMonths(Number(match[1]), Number(match[2]))
                    } else {
                        message.error("没有获取到正确日期");
                    }
                    break;
                case 'quarter':
                    setQuarter(date)
                    const patternQuarter = /(\d{4})-Q(\d+)/;
                    const matchQuarter = dateString.match(patternQuarter);
                    if (matchQuarter) {
                        getQuarter(Number(matchQuarter[1]), Number(matchQuarter[2]))
                    } else {
                        message.error("没有获取到正确日期");
                    }
                    break;
                case 'year':
                    setYear(date)
                    getYear(Number(dateString))
                    break;
                default:
                    console.log('Unknown Picker');
            }
        }
    };

    const onRangeChange = (dates: any, dateString: [string, string], picker: string) => {
        cleanField();
        if (dates.length === 2) {
            const startOfWeek = dates[0].startOf('month');
            const endOfWeek = dates[1].endOf('month');
            setParamDate({
                start: startOfWeek.format('YYYY-MM-DD'),
                end: endOfWeek.format('YYYY-MM-DD')
            });
        }
        setRangeMonth(dates);
    };

    const getWeek = (year: number, week: number) => {

        // 获取给定周数的开始和结束时间
        const startOfWeek = getWeekStart(year, week);
        const endOfWeek = getWeekEnd(year, week);
        setParamDate({
            start: startOfWeek.format('YYYY-MM-DD'),
            end: endOfWeek.format('YYYY-MM-DD')
        });
    }

    const getMonths = (year: number, month: number) => {

        // 获取给定周数的开始和结束时间
        const startOfWeek = dayjs().year(year).month(month - 1).startOf('month');
        const endOfWeek = dayjs().year(year).month(month - 1).endOf('month');
        setParamDate({
            start: startOfWeek.format('YYYY-MM-DD'),
            end: endOfWeek.format('YYYY-MM-DD')
        });
    }

    const getQuarter = (year: number, quarter: number) => {

        // 获取给定周数的开始和结束时间
        const startOfWeek = getQuarterStart(year, quarter);
        const endOfWeek = getQuarterEnd(year, quarter);
        setParamDate({
            start: startOfWeek.format('YYYY-MM-DD'),
            end: endOfWeek.format('YYYY-MM-DD')
        });
    }

    const getYear = (year: number) => {

        // 获取给定周数的开始和结束时间
        const startOfYear = dayjs().year(Number(year)).startOf('year');
        const endOfYear = dayjs().year(Number(year)).endOf('year');
        setParamDate({
            start: startOfYear.format('YYYY-MM-DD'),
            end: endOfYear.format('YYYY-MM-DD')
        });
    }

    const getDay = (day: string) => {

        // 获取给定周数的开始和结束时间
        setParamDate({
            start: day,
            end: day
        });
    }

    const exportExcel = () => {
        confirm({
            title: `是否确认导出？`,
            onOk() {
                setLoading(true);
                let downloadUrl = `${API.aier_hospital_day.download_excel}?start=${paramDate?.start}&end=${paramDate?.end}`;
                Fetch.get(
                    downloadUrl
                ).then((data) => {
                    if (data) {
                        const currentTimeStamp = Math.floor(Date.now() / 1000);
                        common.downloadFile("手术完成统计表" + currentTimeStamp + ".xlsx", API.domain + data, (callback: any) => {
                            if (callback === true) {
                                message.success('处理完成');
                            } else {
                                message.error('下载失败，请联系管理员');
                            }
                            setLoading(false);
                        });
                    } else {
                        message.error('下载失败，请联系管理员');
                        setLoading(false);
                    }
                });
            }
        })
    };

    const columns: ColumnsType<User> = [
        {
            title: sessionStorage.orgName === '管理中心' ? '省份/医院名称' : '医院名称',
            dataIndex: 'name',
            key: 'name',
            align: `center`,
            width: 250
        },
        {
            title: '总报名人数(人数)',
            dataIndex: 'totalUser',
            key: 'totalUser',
            sorter: (a: User, b: User) => a.totalUser - b.totalUser,
            align: `center`,
        },
        {
            title: '白内障手术完成(人数)',
            dataIndex: 'cataractSurgery',
            key: 'cataractSurgery',
            sorter: (a: User, b: User) => a.cataractSurgery - b.cataractSurgery,
            align: `center`,
        },
        {
            title: '白内障报销完成(人数)',
            dataIndex: 'cataractReimburse',
            key: 'cataractReimburse',
            sorter: (a: User, b: User) => a.cataractReimburse - b.cataractReimburse,
            align: `center`,
        },
        {
            title: '白内障手术完成(例)',
            dataIndex: 'cataractCases',
            key: 'cataractCases',
            sorter: (a: User, b: User) => a.cataractCases - b.cataractCases,
            align: `center`,
        },
        {
            title: '白内障报销完成(例)',
            dataIndex: 'cataractReimburseCases',
            key: 'cataractReimburseCases',
            sorter: (a: User, b: User) => a.cataractReimburseCases - b.cataractReimburseCases,
            align: `center`,
        },
        {
            title: '眼底病手术完成(人数)',
            dataIndex: 'fundusSurgery',
            key: 'fundusSurgery',
            sorter: (a: User, b: User) => a.fundusSurgery - b.fundusSurgery,
            align: `center`,
        },
        {
            title: '眼底病报销完成(人数)',
            dataIndex: 'fundusReimburse',
            key: 'fundusReimburse',
            sorter: (a: User, b: User) => a.fundusReimburse - b.fundusReimburse,
            align: `center`,
        },
        {
            title: '眼底病手术完成(例)',
            dataIndex: 'fundusCases',
            key: 'fundusCases',
            sorter: (a: User, b: User) => a.fundusCases - b.fundusCases,
            align: `center`,
        },
        {
            title: '眼底病报销完成(例)',
            dataIndex: 'fundusReimburseCases',
            key: 'fundusReimburseCases',
            sorter: (a: User, b: User) => a.fundusReimburseCases - b.fundusReimburseCases,
            align: `center`,
        },
        {
            title: '翼状胬肉手术完成(人数)',
            dataIndex: 'gilliumSurgery',
            key: 'gilliumSurgery',
            sorter: (a: User, b: User) => a.gilliumSurgery - b.gilliumSurgery,
            align: `center`,
        },
        {
            title: '翼状胬肉报销完成(人数)',
            dataIndex: 'gilliumReimburse',
            key: 'gilliumReimburse',
            sorter: (a: User, b: User) => a.gilliumReimburse - b.gilliumReimburse,
            align: `center`,
        },
        {
            title: '翼状胬肉手术完成(例)',
            dataIndex: 'gilliumCases',
            key: 'gilliumCases',
            sorter: (a: User, b: User) => a.gilliumCases - b.gilliumCases,
            align: `center`,
        },
        {
            title: '翼状胬肉报销完成(例)',
            dataIndex: 'gilliumReimburseCases',
            key: 'gilliumReimburseCases',
            sorter: (a: User, b: User) => a.gilliumReimburseCases - b.gilliumReimburseCases,
            align: `center`,
        },
        {
            title: '描述',
            dataIndex: 'msg',
            key: 'msg',
            align: `center`,
        }
    ];
    return (
        <>
            <Spin tip={'加载中'} spinning={loading}>
                <Space>
                    <DatePicker onChange={(date, dateString) =>
                        onChange(date, dateString, 'day')} value={day}
                                inputReadOnly={true}/>
                    <DatePicker onChange={(date, dateString) =>
                        onChange(date, dateString, 'week')}
                                value={week}
                                inputReadOnly={true}
                                picker="week"/>
                    <DatePicker onChange={(date, dateString) =>
                        onChange(date, dateString, 'month')} value={month} inputReadOnly={true} picker="month"/>
                    <DatePicker onChange={(date, dateString) =>
                        onChange(date, dateString, 'quarter')} value={quarter} inputReadOnly={true}
                                picker="quarter"/>
                    <DatePicker onChange={(date, dateString) =>
                        onChange(date, dateString, 'year')} value={year} inputReadOnly={true} picker="year"/>
                    <RangePicker picker="month" onChange={(date, dateString) =>
                        onRangeChange(date, dateString, 'RangeMonth')} value={rangeMonth} inputReadOnly={true}/>
                </Space>
                <Space style={{float: 'right'}}>
                    <Button onClick={exportExcel}>导出</Button>
                </Space>
                <Table
                    className="mgTop15"
                    rowKey="name"
                    dataSource={tableData}
                    columns={columns}
                    bordered
                    pagination={{position: [], pageSize: 1000}}
                    scroll={{y: 800}}
                />
            </Spin>
        </>
    );
};

export default ProvinceTable;
