import {Button, Card, Descriptions, message, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {DhDetailDataType} from "../common/DhDetailType";
import {dhStatusObj, visitTasksType} from "../common/DhDictData";
import {PhoneOutlined} from "@ant-design/icons";

type VisitTaskType = {
    tasksDetail: DhDetailDataType["data"]["tasksDetail"],
    taskDetailId: number | null,
    showCallPhone: boolean | false
}

const VisitTaskInfo: React.FC<VisitTaskType> = (props) => {

    const [loading, setLoading] = useState(false);
    const [tcccStatus, setTcccStatus] = useState(false);

    useEffect(() => {
        // @ts-ignore
        if (window.tccc && window.tccc.Agent) {
            // @ts-ignore
            const status = window.tccc.Agent.getStatus();
            setTcccStatus(status === "free")
        }
    }, [])

    const handleCallout = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        // 调用呼出方法的核心代码
        try {
            const number = props.tasksDetail?.servicePhone;
            if (number) {
                // @ts-ignore
                await window.tccc.Call.startOutboundCall({phoneNumber: number, uui: "DENTISTRY-" + props.taskDetailId});
            } else {
                message.error("未获取到手机号")
            }
        } catch (error) {
            console.error(error.errorMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {props.tasksDetail && props.taskDetailId && (
                <Card size="small"
                      title={`电话任务`}
                      extra={<div>
                          {props.tasksDetail.serviceStatus && (
                              <Descriptions.Item label="回访类型">
                                  回访生成时患者状态: {dhStatusObj[props.tasksDetail.serviceStatus]}
                              </Descriptions.Item>
                          )}
                      </div>
                      }>
                    <Descriptions>
                        <Descriptions.Item label="姓名">
                            {props.tasksDetail.serviceName}
                        </Descriptions.Item>
                        <Descriptions.Item label="手机号">
                            <Space>
                                {props.tasksDetail.servicePhone}
                                {(props.showCallPhone && tcccStatus) ? (
                                    <Button
                                        type="primary"
                                        size="small"
                                        icon={<PhoneOutlined/>}
                                        onClick={handleCallout}
                                        loading={loading}
                                    >
                                        拨打电话
                                    </Button>
                                ) : "   云客服未在线或其他状态暂无法拨打电话"}
                            </Space>

                        </Descriptions.Item>
                        {props.tasksDetail.taskType && (
                            <Descriptions.Item label="回访类型">
                                {visitTasksType[props.tasksDetail.taskType]}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Card>
            )}
        </>
    );
};

export default VisitTaskInfo;
