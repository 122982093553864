import {Card, Descriptions, Space} from 'antd';
import React from 'react';
import {BoneDetailDataType} from "../common/BoneCommonType";
import ViewFileListClass from "../../common/modal/ViewFileList";

type PatientInfoType = {
    screening: BoneDetailDataType["data"]['screening']
}
const DoneHospitalScreening: React.FC<PatientInfoType> = (props) => {

    return (
        <>
            {(props && props.screening) && (
                <Card size="small"
                      title={`筛查费用信息`}>
                    <Space direction="vertical">
                        <>
                            <Descriptions>
                                <Descriptions.Item label="筛查时间">
                                    {props.screening.screeningDate}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="总费用">
                                    {props.screening.totalPrice}
                                </Descriptions.Item>
                                <Descriptions.Item label="医保报销">
                                    {props.screening.medicareReimbursement}
                                </Descriptions.Item>
                                <Descriptions.Item label="其他补助">
                                    {props.screening.otherGrants}
                                </Descriptions.Item>
                                <Descriptions.Item label="自付费用">
                                    {props.screening.selfPayPrice}
                                </Descriptions.Item>
                                {props.screening.subsidy && (
                                    <Descriptions.Item label="本项目资助">
                                        {props.screening.subsidy}
                                    </Descriptions.Item>
                                )}
                                {props.screening.afterSelfPayPrice && (
                                    <Descriptions.Item label="报销后自付费用">
                                        {props.screening.afterSelfPayPrice}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                            <Space size={[8, 16]} wrap align="start">

                                {props.screening.density.length > 0 && (
                                    <Card style={{width: '100%'}}>
                                        <ViewFileListClass
                                            title="骨密度报告"
                                            fileList={props.screening.density}
                                        />
                                    </Card>
                                )}
                                {props.screening.metabolism.length > 0 && (
                                    <Card style={{width: '100%'}}>
                                        <ViewFileListClass
                                            title="骨代谢报告"
                                            fileList={props.screening.metabolism}
                                        />
                                    </Card>
                                )}
                                {props.screening.invoice.length > 0 && (
                                    <Card style={{width: '100%'}}>
                                        <ViewFileListClass
                                            title="收费凭证"
                                            fileList={props.screening.invoice}
                                        />
                                    </Card>
                                )}
                            </Space>
                        </>
                    </Space>
                </Card>
            )}
        </>
    );
};

export default DoneHospitalScreening;
