import React, {Component} from 'react';
import {Table} from "antd";
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {SignLog} from './types';
import 'dayjs/locale/zh-cn';

type TheState = {
    content: SignLog[],
};

type TheProps = {
    activityId: number,
    userId: number,
}

class SignLogList extends Component<TheProps, TheState> {
    state: TheState = {
        content: [],
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const {activityId, userId} = this.props;
        Fetch.get(`${API.signLog.list}?activityId=${activityId}&userId=${userId}`).then(data => {
            this.setState({
                content: data.content,
            })
        });
    };

    render() {
        const {content} = this.state;
        const columns: ColumnsType<SignLog> = [
            {
                title: "日期",
                dataIndex: "date",
                align: `center`,
            },
            {
                title: "实际签到时间",
                dataIndex: 'createdDate',
                align: `center`,
            }];

        return (
            <>
                <div className="site-layout-background" style={{padding: 24, minHeight: 720}}>
                    <Table
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={false}
                    />
                </div>
            </>
        );
    }
}

export default SignLogList;
