import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import API from "../../common/api";
import Fetch from "../../common/FetchIt";
import {statusArrV2} from "../util/AierCommonStatusV2";
import styles from "../hospitalauditv2/ServiceUserV2.module.css";
import {ColumnsType} from 'antd/es/table';

type Record = {
    serviceId: number | null
}
type User = {};
const RecordOperationsDetail: React.FC<Record> = (props) => {

    const [recordData, setRecordData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(
        function () {
            let url = `${API.record_operations.find_service_record}?serviceId=` + props.serviceId;
            Fetch.get(url).then((data) => {
                setRecordData(data)
                setLoading(false)
            });
        },
        [props.serviceId]
    );
    const columns: ColumnsType<User> = [
        {
            title: '时间',
            dataIndex: 'createdDate',
            align: `center`,
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: `center`,
            render(value: any) {
                return <span>{statusArrV2[value]}</span>;
            },
        },
        {
            title: '描述',
            dataIndex: 'msg',
            align: `center`,
        }
    ];
    return (
        <>
            <Table
                className={styles.popover}
                rowKey="name"
                dataSource={recordData}
                columns={columns}
                bordered
                loading={loading}
                pagination={{position: [], pageSize: 100}}
                scroll={{y: 430}}
            />
        </>
    );
};

export default RecordOperationsDetail;
