import React from "react";
import createModal from "../common/createModal";
import ReviewDetail from "./ReviewDetail";

const ReviewModal = createModal(ReviewDetail);

export default function ReviewItem(props) {
    let reviewModal = null;

    if (!props.review) {
        return (
            <div>
                <h4>{props.title}</h4>
            </div>
        )
    }

    let displayDate = props.review.validateDate;
    let text = '';
    if (props.current === 0) {
        if (!displayDate) {
            displayDate = props.submitDate;
        }
        if (props.review.status === 'PROVINCE') {
            text = '初审中'
            displayDate = '';
        } else if (props.review.status === 'PROVINCE_REJECTED') {
            text = '审批不通过'
        } else if (props.review.status === 'FINAL' || props.review.status === 'FINAL_REJECTED' || props.review.status === 'ENABLE') {
            text = '审批通过'
        }
    } else if (props.current === 1) {
        displayDate = props.review.finalValidateDate;
        if (props.review.status === 'FINAL') {
            text = '终审中';
            displayDate = '';
        } else if (props.review.status === 'FINAL_REJECTED') {
            text = '审批不通过'
        } else if (props.review.status === 'ENABLE') {
            text = '审批通过'
        }
    }

    const showReview = () => {
        reviewModal.show()
    }

    return (
        <div>
            <h4>{props.title}</h4>
            <div style={{fontSize: "12px"}}>{props.institution}</div>
            <div style={{fontSize: "12px"}}>{text}</div>
            <div style={{fontSize: "12px"}}>{displayDate}</div>
            {
                text === '审批不通过' &&
                <div style={{fontSize: "12px", color: '#1890ff', cursor: 'pointer'}}
                     onClick={showReview}>查看驳回理由</div>
            }
            <ReviewModal
                title={'驳回原因'}
                category={props.category}
                activity={props.activity}
                ref={c => reviewModal = c}
            />
        </div>
    )
}
