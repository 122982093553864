import {Link} from 'react-router-dom';
import {Button} from 'antd';
import App from '../../App';
import './style.css';

export default function NotFound() {
    const goback = function () {
        window.history.go(-2);
    };
    return (
        <App selectedKeys={['']}>
            <div className="content">
                <div className="ErrorPage-text">
                    <h1 className="ErrorPage-title">404</h1>
                    <div className="ErrorPage-subtitle ">
                        对不起，这个页面不存在，或者您没有权限访问
                    </div>
                    <div className="ErrorPage-primaryButton">
                        <Button type="primary" onClick={goback}>
                            返回上一页
                        </Button>
                    </div>
                    <div className="ErrorPage-otherButtonContainer">
                        或者
                        <Link className="goLogin" to="/">
                            去往登录
                        </Link>
                    </div>
                </div>
            </div>
        </App>
    );
}
