import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Button, Col, message, Row, Space, Table} from "antd";
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {Progress} from './types';
import styles from './donationActivity.module.css';
import createModal from "../common/createModal";
import DonationProgressForm from "./DonationProgressForm";
import ProgressDetail from "./ProgressDetail";

type TheState = {
    content: Progress[],
    progress: Progress | null,
};

const FormModal = createModal(DonationProgressForm);

const DetailModal = createModal(ProgressDetail);

class DonationProgressList extends Component<{ activityId: number }, TheState> {
    state = {
        content: [],
        progress: null,
    };
    private modal: any = null;
    private detailModal: any = null;

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        Fetch.get(`${API.progress.list}?activityId=${this.props.activityId}`).then(data => {
            this.setState({
                content: data,
            })
        });
    };

    show = (record: any) => {
        this.setState({
            progress: record,
        }, this.detailModal.show);
    }

    create = () => {
        this.setState({
            progress: {
                id: '',
                activityId: this.props.activityId,
                covers: [],
                description: '',
                publisher: '',
                content: ''
            },
        }, this.modal.show);
    };

    edit = (record: Progress) => {
        const coverIds = record.covers.map((item: any) => {
            return {id: item.id, uid: item.id, status: 'done', name: item.fileName, url: API.domain + item.url}
        })
        this.setState({
            progress: {
                ...record,
                coverIds
            },
        }, this.modal.show);
    };

    del = (id: number) => {
        Fetch.del(`${API.progress.zsgc}/${id}`).then(() => {
            message.success('删除成功！');
            this.loadData();
        });
    };

    render() {
        const {content} = this.state;
        const columns: ColumnsType<Progress> = [
            {
                title: "进展名称",
                dataIndex: "name",
                align: `center`,
            },
            {
                title: "发布时间",
                dataIndex: "createdDate",
                align: `center`,
            },
            {
                title: "发布者",
                dataIndex: "publisher",
                align: `center`,
            },
            {
                title: "操作",
                align: `center`,
                render: (text, record: any) => {
                    return (
                        <Space>
                            <Button onClick={() => this.show(record)}>查看</Button>
                            <Button onClick={() => this.edit(record)}>编辑</Button>
                            <Button onClick={() => this.del(record.id)}>删除</Button>
                        </Space>
                    )
                }
            }];

        return (
            <>
                <div className="site-layout-background">
                    <div>
                        <Row>
                            <Col span={6}>
                                <Space>
                                    <Button onClick={this.create} className={styles.addBtn}
                                            type="primary">新增进展</Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                    <Table
                        bordered
                        rowKey={record => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={false}
                    />
                    <FormModal
                        title={this.state.progress === null ? '新建项目进展' : '编辑项目进展'}
                        reload={this.loadData}
                        progress={this.state.progress}
                        ref={c => this.modal = c}
                    />
                    <DetailModal
                        title="项目进展详情"
                        progress={this.state.progress}
                        ref={c => this.detailModal = c}
                    />
                </div>
            </>
        );
    }
}

export default DonationProgressList;
