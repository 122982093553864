import React, {Component} from 'react';
import {Button, Card, message, Modal, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {decryptData} from "../../common/crypto";

import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {BoneDetailDataType, BoneDetailDataTypeDate} from "../common/BoneCommonType";
import {buildBoneFileData} from "../common/BoneCommon";
import ServiceUser from "../detail/ServiceUser";
import DoneHospitalScreening from "../detail/DoneHospitalScreening";
import styles from "../../cooperate/financialaudit/ServiceUser.module.css";
import createModal from "../../common/createModal";
import BoneAuditRejectionModule from "./BoneAuditRejectionModule";

const AuditRejection = createModal(BoneAuditRejectionModule);

const {confirm} = Modal;
class BoneServiceSubsidyAuditClass extends Component<{}, BoneDetailDataType> {

    state: BoneDetailDataType = {
        ...BoneDetailDataTypeDate
    };

    private importResult: any = null;

    componentDidMount() {
        this.setState({
            id: decryptData(common.getQuery('id')),
        }, () => {
            this.getData()
        })
    };

    getData() {

        const {id} = this.state;
        let url = `${API.bone.service_user.get_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            buildBoneFileData(data)
            this.setState({
                data: data,
                importLoading: false
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }

    doUpdateApproved = ()=>{
        let id = this.state.id;
        confirm({
            title: `是否确认报销？`,
            onOk() {
                const data = {
                    serviceId: id,
                }
                Fetch.putJSON(API.bone.status.do_update_approved, data).then(() => {
                    message.success('审核成功');
                    window.location.href = '/#/bone/subsidy/list';
                });
            },
        });
    }

    updateReviewReturns = () =>{

        this.importResult.show();
    }


    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <ServiceUser userDetail={data.detailVo}/>
                                    <DoneHospitalScreening screening={data.screening}/>
                                </>
                            ) : ('')}
                            <Card>
                                <Button
                                    size="middle"
                                    className={styles.backBtn}
                                    style={{marginRight: '15px'}}
                                    onClick={this.cancel}
                                >
                                    返回
                                </Button>
                                <Space style={{float:"right"}}>
                                    <Button
                                        type="primary"
                                        size="middle"
                                        onClick={this.doUpdateApproved}
                                    >
                                        通过
                                    </Button>
                                    <Button
                                        type="primary"
                                        danger
                                        size="middle"
                                        onClick={this.updateReviewReturns}
                                    >
                                        拒绝
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
                <AuditRejection
                    width={500}
                    title={'审核拒绝'}
                    importResult={{id: this.state.id}}
                    onCancel={()=>{}}
                    ref={(c) => (this.importResult = c)}
                />
            </div>
        );
    }
}

export default function BoneServiceSubsidyAudit() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <BoneServiceSubsidyAuditClass/>
        </App>
    );
}
