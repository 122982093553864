import React from "react";
import {DhDetailDataType} from "../common/DhDetailType";
import {message} from "antd";

class AddEditCommon extends React.Component<{}, any & DhDetailDataType> {

    protected dhPatientBookingModelResult: any = null;

    setAddressSelect = (select: any) => {
        this.setState({
            addressSelect: select
        })
    }

    closeModal = () => {

    }

    onSaveSuccess = () => {

        window.history.go(-1);
    }

    openBookingModel = () => {

        this.dhPatientBookingModelResult.show()
    }

    getLat = (values: any) => {

        const {addressSelect} = this.state;
        const {locationAddr} = values;
        let lat = 0,
            lng = 0;
        if (addressSelect && locationAddr) {
            const address: any = addressSelect.find((item: {
                value: string | ''
            }) => {
                return item.value === locationAddr;
            });
            if (address && address.location) {
                lat = address.location.lat;
                lng = address.location.lng;
            } else {
                message.error('您输入的地址不正确，请通过下拉列表选择输入');
                return false;
            }
            return {
                lng: lng,
                lat: lat,
            };
        } else {
            return {
                lng: 116.397504,
                lat: 39.908821,
            };
        }
    };
}


export default AddEditCommon