import {Component} from 'react';
import {Button, Form, Input, message} from 'antd';

import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './index.module.css';

const FormItem = Form.Item;
export default class Activate extends Component {
    state = {
        phone: '',
        text: '发送验证码',
        id: '',
        data: {
            name: '',
            code: '',
        },
    };
    timer: any;

    // institution
    getQuery = (name: String) => {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let r = window.location.hash.split('?')[1].match(reg); //获取url中"?"符后的字符串并正则匹配
        let context = '';
        if (r != null) context = r[2];
        r = null;
        return context == null || context === '' || context == 'undefined'
            ? ''
            : context;
    };

    componentDidMount() {
        let id = this.getQuery('id');
        this.setState({
            id: id,
        });
        Fetch.get(`${API.activate.institution}/${id}`).then((data) => {
            this.setState({
                data: data,
            });
        });
    }

    submit = (values: any) => {
        let data = {
            id: this.state.id,
            activeCode: values.activeCode,
            smsCode: values.smsCode,
            user: {
                username: values.phone,
                password: values.password,
            },
        };
        Fetch.postJSON(API.activate.active, data).then((data) => {
            window.location.href = '/';
        });
    };

    sendCode = () => {
        if (this.state.text !== '发送验证码') {
            return;
        }
        if (this.checkPhone()) {
            this.codeChange();
            this.requireCode();
        } else {
            message.error('请输入正确的手机号');
        }
    };

    // 验证手机号是否正确
    checkPhone = () => {

        let reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
        let flag = reg.test(this.state.phone);
        return flag;
    };

    // 发送验证码
    requireCode = () => {
        Fetch.get(`${API.user.sendCode}?phone=${this.state.phone}&templateCode=REGISTERED`).then((data) => {
            message.success('发送成功！');
        });
    };

    // 开启定时器
    codeChange = () => {
        let time = 59;
        this.timer = setInterval(() => {
            if (time === 0) {
                clearInterval(this.timer);
                this.setState({
                    text: '发送验证码',
                });
            } else {
                this.setState({
                    text: time + ' s',
                });
            }

            time--;
        }, 1000);
    };
    inptChange = (e: any) => {

        this.setState({
            phone: e.target.value,
        });
    };

    render() {
        const {text, phone, data} = this.state;

        return (
            <div className={styles.bg}>
                <div className={styles.activate}>
                    <div className={styles.title}>组织账号激活</div>
                    <Form onFinish={this.submit} className={styles.form}>
                        <FormItem
                            className={styles.mylabel}
                            label="组织名称"
                            labelCol={{span: 4}}
                        >
                            <div className={styles.detail}>{data.name}</div>
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            label="组织编号"
                            labelCol={{span: 4}}
                        >
                            <div className={styles.detail}> {data.code}</div>
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            label="手机号"
                            name="phone"
                            labelCol={{span: 4}}
                            rules={[{required: true, message: '手机号不能为空'}]}
                        >
                            <Input

                                placeholder="请输入手机号"
                                type="text"
                                value={phone}
                                onChange={this.inptChange}
                            />
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            label="激活码"
                            name="activeCode"
                            labelCol={{span: 4}}
                            rules={[{required: true, message: '激活码不能为空'}]}
                        >
                            <Input

                                placeholder="请输入激活码"
                                type="text"
                            />
                        </FormItem>

                        <FormItem
                            className={styles.mylabel}
                            name="smsCode"
                            labelCol={{span: 4}}
                            label="验证码"
                            rules={[{required: true, message: '验证码不能为空'}]}
                        >
                            <div className={styles.code_box}>
                                <Input
                                    type="text"

                                    placeholder="请输入验证码"
                                />
                                <span
                                    className={text !== '发送验证码' ? styles.active : ''}
                                    onClick={this.sendCode}
                                >
                  {text}
                </span>
                            </div>
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            name="password"
                            label="密码"
                            labelCol={{span: 4}}
                            rules={[{required: true, message: '密码不能为空'}]}
                        >
                            <Input

                                placeholder="请输入密码"
                                type="password"
                            />
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            label="后台地址"
                            labelCol={{span: 4}}
                        >
                            <a href="https://app.lelingzhiyuan.com">
                                https://app.lelingzhiyuan.com
                            </a>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" className={styles.mybtn}>
                                登录
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }
}
