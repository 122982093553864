import {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Button, Col, message, Modal, Row, Space, Table,} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import type {ColumnsType} from 'antd/es/table';
import Fetch from '../common/FetchIt';
import API from '../common/api';

import App from '../../App';
import createModal from '../common/createModal';
import SupplierFrom from './supplierFrom';

const FormModal = createModal(SupplierFrom);
const {confirm} = Modal;

function Supplier1() {
    const [page, setPage] = useState(0);
    const pageSize = 10;
    const [totalElements, setTotalElements] = useState();
    const [totalPage, setTotalPage] = useState();
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectdata, setSelectdata] = useState({
        id: null,
    });
    const modal = useRef(null);
    useEffect(
        function () {
            loadData();
        },
        [page]
    );
    const Add = function () {
        setSelectdata({
            id: null,
        });
        //@ts-ignore
        modal.current.show();
    };
    const Edit = function (row: any) {
        setSelectdata(row);
        //@ts-ignore
        modal.current.show();
    };
    const DelFn = function (row: any) {
        // setSelectdata(row);
        //  supllerDel

        confirm({
            title: '您确定要删除下面的供应商么?',
            icon: <ExclamationCircleOutlined/>,
            content: row.name,
            onOk() {
                if (row.countProduct > 0) {
                    message.error('该供应商存在关联商品，不允许删除');
                } else {
                    Fetch.put(API.goods.supllerDel, {id: row.id}).then((data) => {
                        // setTotalTotalPages(data.totalPages),
                        message.success('删除成功！');
                        if (totalPage === page + 1 && content.length === 1 && page !== 0) {
                            setPage(page - 1);
                        } else {
                            loadData();
                        }
                    });
                }
            },
            onCancel() {
            },
        });
    };
    const loadData = function () {
        Fetch.get(`${API.goods.supller}?page=${page}&size=${pageSize}`).then(
            (data) => {
                setContent(data.content);
                setTotalElements(data.totalElements);
                setLoading(false);
                setTotalPage(data.totalPages);
                // setTotalTotalPages(data.totalPages),
            }
        );
    };
    const changeCondition = function (condition: any, load = false) {
        setPage(condition.page);
        setLoading(true);
        // loadData();
    };

    //  表头
    interface DataType {
        code: string;
        name: string;
        createdDate: string;
        countProduct: string;
    }

    const columns: ColumnsType<DataType> = [
        {
            title: '供应商名称',
            dataIndex: 'name',
            align: `center`,
        },
        {
            title: '入库商品',
            dataIndex: 'countProduct',
            align: `center`,
        },
        {
            title: '供应商编号',
            dataIndex: 'code',
            align: `center`,
        },
        {
            title: '创建时间',
            dataIndex: 'createdDate',
            align: `center`,
        },
        {
            title: '操作',
            align: `center`,
            render: (text: any, record: any) => {
                return (
                    <Space>
                        <Button
                            onClick={() => {
                                Edit(record);
                            }}
                        >
                            编辑
                        </Button>
                        <Button
                            onClick={() => {
                                DelFn(record);
                            }}
                        >
                            删除
                        </Button>
                    </Space>
                );
            },
        },
    ];
    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>供应商管理</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <Row>
                    <Col span={6}></Col>
                    <Col span={18} style={{textAlign: 'right'}}>
                        <Button type="primary" onClick={Add}>
                            新 建
                        </Button>
                    </Col>
                </Row>

                <Table
                    className="mgTop15"
                    bordered
                    loading={loading}
                    rowKey={(record) => {
                        return record.code;
                    }}
                    columns={columns}
                    dataSource={content}
                    pagination={{
                        current: page + 1,
                        pageSize,
                        total: totalElements,
                        showTotal: (total) => `共${total}条`,
                        showSizeChanger: false,
                    }}
                    onChange={(pagination: any) =>
                        changeCondition(
                            {
                                page: pagination.current - 1,
                                pageSize: pagination.pageSize,
                            },
                            true
                        )
                    }
                />
            </div>

            <FormModal
                title={selectdata.id === null ? '创建供应商' : '编辑供应商'}
                reload={loadData}
                ref={modal}
                data={selectdata}
            />
        </>
    );
}

export default function Supplier() {
    return (
        <App selectedKeys={['supplier']} openKeys="PMallMenu">
            <Supplier1/>
        </App>
    );
}
