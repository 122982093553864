import React, {useEffect, useState} from 'react';
import {Button, Input, Space, Spin, Table} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import {ColumnsType} from "antd/es/table";
import {genderArr} from "../../cooperate/util/AierCommon";
import {BookingStatus, BookingTime, dhStatusColorObj, dhStatusObj} from "../common/DhDictData";

import {addParams, STRING_STR} from "../../cooperate/bill/page/ListCommon";

/**
 * 医院修改预约记录
 * @param props
 * @constructor
 */
// type Scheduling = {
//     hospitalId: number | 0,
//     schedulingDate: string | null,
//     planAmVisitors: number | 0,
//     planPmVisitors: number | 0,
//     actualAmVisitors: number | 0,
//     actualPmVisitors: number | 0
// }
type User = {};

export default function DhPatientBookingModel(props: any) {

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [spinningFlag, setSpinningFlag] = useState<boolean>(true);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [content, setContent] = useState<any>();
    const [isReset, setReset] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.onCancel();
    }

    useEffect(() => {
        loadData()
    }, [props.importResult]);

    useEffect(function () {

        if (isReset) {
            loadData();
        }
    }, [isReset]);

    useEffect(function () {

        loadData();
    }, [page]);

    const loadData = () => {

        if (props.importResult) {
            let url = `${API.dentistry.dh_service_user_booking.dashboard_list}?page=${page}&size=${pageSize}`;
            url = url + addParams("bookingDate", props.importResult, STRING_STR)
            if (!isReset) {
                url = url + addParams("name", name, STRING_STR);
                url = url + addParams("phone", phone, STRING_STR);
            }
            Fetch.get(url).then((data) => {
                setContent(data.content)
                setTotalElements(data.totalElements)
                setTotalPages(data.totalPages)
                setReset(false)
                setSpinningFlag(false)
            });
        }
    };

    const changeCondition = (condition: any) => {

        setPage(condition.page)
        if (condition.name) {
            setName(condition.name)
        }
        if (condition.phone) {
            setPhone(condition.phone)
        }
    };

    const reset = () => {
        setName('')
        setPhone('')
        setReset(true)
    }

    const columns: ColumnsType<User> = [

        {
            title: '姓名',
            dataIndex: 'name',
            align: `center`,
            render: (text) => {
                return (
                    <span style={{wordBreak: 'break-all'}}>{text}</span>
                );
            },
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: `center`,
            render(value) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{genderArr[value]}</span>
                );
            },
        },
        {
            title: '年龄',
            dataIndex: 'age',
            align: `center`,
            render(value) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{value}</span>
                );
            },
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            align: `center`,
            render(value) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{value}</span>
                );
            },
        },
        {
            title: '身份证号码',
            dataIndex: 'idNumber',
            align: `center`,
            render(value) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{value}</span>
                );
            },
        }, {
            title: '关联医院',
            dataIndex: 'hospitalName',
            align: `center`,
            render(value) {
                return (
                    <span style={{wordBreak: 'break-all'}}>{value}</span>
                );
            },
        },
        {
            title: '患者状态',
            dataIndex: 'dhStatus',
            align: `center`,
            render(value) {
                return <>
                    <div>
                        <div
                            style={{
                                wordBreak: 'break-all',
                                color: dhStatusColorObj[value]
                            }}>{dhStatusObj[value]}</div>
                    </div>
                </>;
            },
        },
        {
            title: '预约状态',
            dataIndex: 'bookingStatus',
            align: `center`,
            render(value) {
                return <>
                    <div>
                        <div
                            style={{
                                wordBreak: 'break-all',
                                color: dhStatusColorObj[value]
                            }}>{BookingStatus[value]}</div>
                    </div>
                </>;
            },
        },
        {
            title: '预约时间',
            dataIndex: 'bookingDate',
            align: `center`,
            render(value) {
                return <span style={{wordBreak: 'break-all'}}>{value}</span>;
            },
        },
        {
            title: '上午&下午',
            dataIndex: 'bookingTime',
            align: `center`,
            render(value) {
                return <span style={{wordBreak: 'break-all'}}>{BookingTime[value]}</span>;
            },
        },
    ];

    const pageChange = (condition: any) => {
        setPage(condition.page)
    };

    return <>
        {content && (
            <>
                <Space>
                    <Input
                        placeholder="姓名"
                        value={name}
                        onChange={(e) => {
                            changeCondition({page: 0, name: e.target.value})
                        }
                        }
                    />
                    <Input
                        placeholder="手机号"
                        value={phone}
                        onChange={(e) => {
                            changeCondition({page: 0, phone: e.target.value})
                        }
                        }
                    />

                    <Button onClick={loadData}>
                        搜索
                    </Button>
                    <Button onClick={() => reset()}>重置</Button>
                </Space>
                <Spin tip="加载中..." spinning={spinningFlag}>
                    <Table
                        // loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize: pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            pageChange(
                                {
                                    page: pagination.current - 1
                                }
                            )
                        }
                    />
                </Spin>
            </>
        )}
    </>;
}
