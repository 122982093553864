import React, {Component} from 'react';
import {Button, Card, Form, FormInstance} from 'antd';
import API from '../common/api';
// @ts-ignore
import './RegisteredSuccess.css';
import styles from "./institution.module.css";

const FormItem = Form.Item;

class RegisteredSuccessClass extends Component {
    formRef = React.createRef<FormInstance>();
    cityRef = React.createRef();
    state = {};

    componentDidMount() {

    }

    render() {
        const back = () => {
            window.location.href = '/';
        }
        return (
            <>
                <Card title={
                    <>乐龄志愿组织中心</>
                }
                      styles={{header: {backgroundColor: 'white', color: 'black'}}}
                      bordered={false}
                      className={styles.mycard}
                >
                    <div className="title_img">
                        <img src={API.domain + "/static/user_index/invoice/success.png"}/>
                    </div>
                    <div className="title">
                        已提交注册申请，等待审核
                    </div>
                    <div className="prompt">
                        审核通过后系统会以短信方式通知，请注意查收
                    </div>
                    <div className="goLogin">
                        <Button className="goLoginButton" type="primary" onClick={back}>完成</Button>
                    </div>
                </Card>
            </>
        );
    }
}

export default RegisteredSuccessClass;
