import React from 'react';
import 'dayjs/locale/zh-cn';
import 'moment/locale/zh-cn';
import App from '../../App';

import {BasePageData} from "../cooperate/util/types";
import moment from 'moment';
import dayjs from "dayjs";
import {getQuarterEnd, getQuarterStart, getWeekEnd, getWeekStart} from "../common/DayJsUtil";

class TestDayJsClass extends React.Component<{}, BasePageData> {

    state = {};

    componentDidMount() {

        // subtract
        console.log("-----")
        console.log(moment().subtract(1, 'days').format("YYYY-MM-DD"))
        console.log(dayjs().subtract(1, 'days').format("YYYY-MM-DD"))
        //format
        console.log("-----")
        console.log(moment().format('YYYY-MM-DD'))
        console.log(dayjs().format('YYYY-MM-DD'))
        //date
        console.log("-----")
        console.log(moment().date(10).format('YYYY-MM-DD'))
        console.log(dayjs().date(10).format('YYYY-MM-DD'))
        //str
        console.log("-----")
        console.log(moment("2024-01-01").format('YYYY-MM-DD'))
        console.log(dayjs("2024-01-01").format('YYYY-MM-DD'))
        //startOf("month")
        console.log("-----")
        console.log(moment().startOf("month").format("YYYY-MM-DD"))
        console.log(dayjs().startOf("month").format("YYYY-MM-DD"))
        //endOf("month")
        console.log("-----")
        console.log(moment().endOf("month").format("YYYY-MM-DD"))
        console.log(dayjs().endOf("month").format("YYYY-MM-DD"))

        const year = 2025;
        const week = 12;
        const month = 10;
        const quarter = 1;
        //startOf('week')
        console.log("-----")
        console.log(moment().year(year).week(week).startOf('week').format("YYYY-MM-DD"))
        const firstDayOfYear = dayjs(`${year}-01-01`);
        // 计算给定周数的第一天
        const firstDayOfWeek = firstDayOfYear.add(week - 1, 'week');
        // 获取一周的开始
        const startOfWeek = firstDayOfWeek.startOf('week');
        console.log(startOfWeek.format("YYYY-MM-DD"));
        console.log(getWeekStart(year, week).format("YYYY-MM-DD"))
        //endOf('week')
        console.log("-----")
        console.log(moment().year(year).week(week).endOf('week').format("YYYY-MM-DD"))
        const firstDayOfYear2 = dayjs(`${year}-01-01`);
        // 计算给定周数的第一天
        const firstDayOfWeek2 = firstDayOfYear2.add(week - 1, 'week');
        // 获取一周的开始
        const startOfWeek2 = firstDayOfWeek2.endOf('week');
        console.log(startOfWeek2.format("YYYY-MM-DD"));
        console.log(getWeekEnd(year, week).format("YYYY-MM-DD"))
        console.log("-----")
        console.log(moment().year(year).month(month - 1).startOf('month').format("YYYY-MM-DD"))
        console.log(dayjs().year(year).month(month - 1).startOf('month').format("YYYY-MM-DD"))
        console.log("-----")
        console.log(moment().year(year).month(month - 1).endOf('month').format("YYYY-MM-DD"))
        console.log(dayjs().year(year).month(month - 1).endOf('month').format("YYYY-MM-DD"))
        console.log("-----")
        console.log(moment().year(Number(year)).startOf('year').format("YYYY-MM-DD"));
        console.log(dayjs().year(Number(year)).startOf('year').format("YYYY-MM-DD"));
        console.log("-----")
        console.log(moment().year(Number(year)).endOf('year').format("YYYY-MM-DD"));
        console.log(dayjs().year(Number(year)).endOf('year').format("YYYY-MM-DD"));
        console.log("-----")
        console.log(moment().year(year).quarter(quarter).startOf('quarter').format("YYYY-MM-DD"));
        // const firstDayOfYear = dayjs(`${yearToFind}-01-01`);
        const firstDayOfQuarter = firstDayOfYear.add(3 * (quarter - 1), 'month');
        const startOfQuarter = firstDayOfQuarter.startOf('month');
        console.log(startOfQuarter.format("YYYY-MM-DD"));
        console.log(getQuarterStart(year, quarter).format("YYYY-MM-DD"))
        console.log("-----")
        console.log(moment().year(year).quarter(quarter).endOf('quarter').format("YYYY-MM-DD"));
        // const firstDayOfYear = dayjs(`${yearToFind}-01-01`);
        const endDayOfQuarter = firstDayOfYear.add(3 * (quarter - 1), 'month');
        // 获取季度的结束日期
        const endOfQuarter = endDayOfQuarter.add(3, 'month').subtract(1, 'day');
        console.log(endOfQuarter.format("YYYY-MM-DD"));
        console.log(getQuarterEnd(year, quarter).format("YYYY-MM-DD"))

        console.log("-----")
        console.log(moment('00:00', 'HH:mm').format("YYYY-MM-DD"))
        console.log(dayjs('00:00', 'HH:mm').format("YYYY-MM-DD"))

        console.log("-----")
        console.log(moment("2024-02-01").add(10, 'days').format("YYYY-MM-DD"));
        console.log(moment("2024-02-01").add(10, 'days').format("yyyy-MM-DD"));
        console.log(dayjs("2024-02-01").add(10, 'days').format("YYYY-MM-DD"));
    }

    render() {

        const {} = this.state;
        return (
            <>

            </>
        );
    }

}

export default function TestDayJsList() {

    return (

        <App selectedKeys={
            ['TestDayJs']
        }>
            <TestDayJsClass/>
        </App>
    )
        ;
}
