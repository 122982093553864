import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, message, Select, Space, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import createModal from '../common/createModal';
import AdForm from './AdForm';
import {Ad} from './types';
import styles from './ad.module.css';
import App from '../../App';

type AdState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: Ad[];
    status: string;
    ad: Ad | null;
    loading: boolean;
};

const AdFormModal = createModal(AdForm);

class AdList extends Component<{}, AdState> {
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        status: '',
        ad: null,
        loading: true,
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, status} = this.state;
        Fetch.get(
            `${API.ad.list}?page=${page}&size=${pageSize}&active=${status}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    create = () => {
        this.setState(
            {
                ad: null,
            },
            this.modal.show
        );
    };

    edit = (id: number) => {
        Fetch.get(`${API.ad.get_detail}/${id}`).then((data) => {
            let imgName = data.imgUrl;
            imgName = imgName.split('/');
            imgName = imgName[imgName.length - 1];
            const ad = {
                ...data,
                // active: data.active,
                mediaId: [
                    {
                        id: data.mediaId,
                        uid: data.mediaId,
                        status: 'done',
                        name: imgName,
                        url: API.domain + data.imgUrl,
                    },
                ],
                text: data.detailViewVo.text,
            };
            this.setState(
                {
                    ad,
                },
                this.modal.show
            );
        });
    };

    del = (id: number) => {
        Fetch.del(`${API.ad.del}/${id}`).then(() => {
            message.success('删除成功！');
            this.loadData();
        });
    };

    close = (id: number) => {
        Fetch.put(`${API.ad.status}`, {id}).then(() => {
            message.success('操作成功！');
            this.loadData();
        });
    };

    reset = () => {
        this.setState(
            {
                status: '',
                page: 0,
            },
            this.loadData
        );
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<Ad> = [
            {
                title: '图片',
                dataIndex: 'url',
                render: (text, record) => {
                    return <img src={API.domain + text} width={100}/>;
                },
                align: `center`,
            },
            {
                title: '标题',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'active',
                render: (text, record) => {
                    if (text === 'ENABLE') {
                        return '启用';
                    } else if (text === 'CLOSED') {
                        return '禁用';
                    } else {
                        return '';
                    }
                },
                align: `center`,
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '操作',
                align: `center`,
                render: (text, record: any) => {
                    return (
                        <Space>
                            <Button onClick={() => this.edit(record.id)}>编辑</Button>
                            <Button onClick={() => this.del(record.id)}>删除</Button>
                            <Button onClick={() => this.close(record.id)}>
                                {record.active === 'CLOSED' ? '启用' : '禁用'}
                            </Button>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>轮播管理</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space>
                        状态：
                        <Select
                            placeholder="请选择状态"
                            value={this.state.status}
                            onChange={(e) => this.changeCondition({page: 0, status: e})}
                        >
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="ENABLE">启用</Select.Option>
                            <Select.Option value="CLOSED">禁用</Select.Option>
                        </Select>
                        <Button onClick={this.loadData}>搜索</Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>
                    <div className="mgTop15">
                        <Button
                            onClick={this.create}
                            className={styles.addBtn}
                            type="primary"
                        >
                            新增
                        </Button>
                    </div>
                    <Table
                        loading={this.state.loading}
                        bordered
                        rowKey={(record) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
                <AdFormModal
                    title={this.state.ad === null ? '添加轮播' : '编辑轮播'}
                    reload={this.loadData}
                    ad={this.state.ad}
                    ref={(c) => (this.modal = c)}
                />
            </>
        );
    }
}

export default function AdIndex() {
    return (
        <App selectedKeys={['ad']}>
            <AdList/>
        </App>
    );
}
