import React from 'react';
import {Button, Input, message, Space, Spin, Table} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {PageData} from '../util/types';
import styles from "./ServiceUser.module.css";
import {genderArr, listDefaultData} from "../util/AierCommon";
import common from "../../common/common";
import encryptData, {decryptData} from "../../common/crypto";
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

type User = {};

class ServiceUserRosterListClass extends BaseListPage {
    state: PageData = {
        ...listDefaultData,
        btnQ: {},
        modalTitle: '导入结果'
    };
    private fileInput = document.getElementById('fileInput');

    componentDidMount() {
        super.componentDidMount();
    }


    loadData = () => {
        const id = decryptData(common.getQuery('sId'))
        const {pageSize, searchData} = this.state;
        const url = `${API.monthly_statistics.query_detail_page}?page=${searchData.page}&size=${pageSize}&statisticsId=${id}&name=${searchData.name}&phone=${searchData.phone}&idCard=${searchData.idCard}`;
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };

    reset = () => {
        this.setState(
            {
                searchData: listDefaultData.searchData,
                status: 0,
                page: 0,
            },
            this.loadData
        );
    };

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                window.location.reload()
            }
        );
    }

    onClear = (e: any) => {
    };
    downloadTotal = () => {
        this.setState({
            importLoading: true,
            tip: "数据导出中"
        });
        const {searchData} = this.state;
        const id = decryptData(common.getQuery('sId'));
        let url = `${API.monthly_statistics.do_download_detail}?statisticsId=${id}&name=${searchData.name}&phone=${searchData.phone}&idCard=${searchData.idCard}`;
        Fetch.get(url).then((data) => {
            if (data) {
                common.downloadFile(data.name, API.domain + data.path, (callback: any) => {
                    if (callback === true) {
                        message.success('处理完成');
                    } else {
                        message.error('下载失败，请联系管理员');
                    }
                    this.setState({
                        importLoading: false,
                    });
                });
            } else {
                message.error('下载失败，请联系管理员');
                this.setState({
                    importLoading: false,
                });
            }
        });
    };

    render() {

        const {
            searchData, pageSize, totalElements,
            content, btnQ,
            eyeTypeArrShow
        } = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '姓名',
                width: 100,
                dataIndex: 'name',
                key: 'name',
                align: `center`,
                fixed: 'left',
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '性别',
                width: 70,
                dataIndex: 'gender',
                key: 'age',
                align: `center`,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{genderArr[value]}</span>
                    );
                },
            },
            {
                title: '身份证号码',
                dataIndex: 'idCard',
                key: '3',
                align: `center`,
                width: 180,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                key: '2',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '家庭住址',
                dataIndex: 'addr',
                key: '5',
                align: `center`,
                width: 150,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '入院时间',
                dataIndex: 'timeOfAdmission',
                key: '6',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '眼疾病种',
                dataIndex: 'eyeType',
                key: '6',
                align: `center`,
                width: 100,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>白内障</span>
                    );
                },
            }, {
                title: '眼别',
                dataIndex: 'eyeType',
                key: '6',
                align: `center`,
                width: 70,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{eyeTypeArrShow[value]}</span>
                    );
                },
            }, {
                title: '出院时间',
                dataIndex: 'timeOfDischarge',
                key: '6',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '患者治疗总费用（元）',
                dataIndex: 'totalPrice',
                key: '6',
                align: `center`,
                width: 140,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '医保报销金额（元）',
                dataIndex: 'medicareReimbursement',
                key: '6',
                align: `center`,
                width: 150,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '自费金额（元）',
                dataIndex: 'selfPayPrice',
                key: '6',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '项目资助金额（元）',
                dataIndex: 'subsidy',
                key: '6',
                align: `center`,
                width: 150,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '其他补助额（元）',
                dataIndex: 'otherGrants',
                key: '6',
                align: `center`,
                width: 130,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '资助类型',
                dataIndex: 'economicSituation',
                key: '6',
                align: `center`,
                width: 120,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '备注',
                dataIndex: 'remark',
                key: '6',
                align: `center`,
                width: 150,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (text, record: any) => {
                    return (
                        <Space>
                            {btnQ.ROLE_SERVICE_USERS_ROSTER_DETAIL ? (
                                <Link to={`/serviceUserDetail/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            ) : ('')}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>*/}
                    {/*        <Link to="/monthlyStatistics/list">*/}
                    {/*            花名册*/}
                    {/*        </Link>*/}
                    {/*    </Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>花名册明细</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        {/* status: '', search: '', type: '', */}

                        <Space onKeyDown={this.handleKeyDown}>
                            <Input
                                placeholder="姓名"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="手机号"
                                value={searchData.phone}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, phone: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="身份证号"
                                value={searchData.idCard}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, idCard: e.target.value})
                                }
                                }
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={this.reset}>重置</Button>

                        </Space>
                        {btnQ.ROLE_SERVICE_USERS_DOWNLOAD_D ? (
                            <Button className={styles.downloadBtn} onClick={this.downloadTotal}>
                                导出
                            </Button>
                        ) : ('')}
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            scroll={{x: 1500}}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function ServiceUserRosterList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ServiceUserRosterListClass/>
        </App>
    )
        ;
}
