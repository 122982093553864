import {Card, Descriptions, Space} from 'antd';
import React, {useState} from 'react';
import {DhDetailDataType} from "../common/DhDetailType";
import {dhStatusObj, registerType} from "../common/DhDictData";
import {genderArr} from "../../cooperate/util/AierCommon";
import StepsInfo from "./StepsInfo";
import {FormTitle} from "../common/IdentityData";

type PatientInfoType = {
    userDetail: DhDetailDataType["data"]['userDetail']
    addressDetail: DhDetailDataType["data"]["addressDetail"],
    warVeteran: DhDetailDataType["data"]["warVeteran"]
}
const PatientInfo: React.FC<PatientInfoType> = (props) => {

    const [titleObj, setTitleObj] = useState<FormTitle>();
    // const [warVeteranShowStr, setWarVeteranShowStr] = useState<string>();
    // const [warVeteranShow, setWarVeteranShow] = useState<boolean>(false);
    //
    // useEffect(() => {
    //     if (props.warVeteran?.warVeteran === "1") {
    //         setWarVeteranShowStr(titleObj?.warVeteranShow)
    //     } else if (props.warVeteran?.warVeteran === "2") {
    //         setWarVeteranShowStr("家属")
    //     } else {
    //         setWarVeteranShowStr("未知")
    //     }
    //     if (props.warVeteran && props.warVeteran.serviceIdentity) {
    //         setWarVeteranShow(showFormArray.includes(props.warVeteran.serviceIdentity))
    //     }
    // }, [titleObj])

    // useEffect(() => {
    //     setTitleObj(getTitle(props.warVeteran?.serviceIdentity));
    // }, [props])

    return (
        <>
            {(props && props.userDetail && props.addressDetail) && (
                <Card size="small"
                      title={`个人信息`}
                      extra={<div>
                          患者状态: {dhStatusObj[props.userDetail.dhStatus]}
                      </div>
                      }>
                    <Space direction="vertical">
                        <StepsInfo key={"StepsInfo"} dhStatus={props.userDetail.dhStatus}/>
                        <>
                            <Descriptions>

                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="姓名">
                                    {props.userDetail.name}
                                </Descriptions.Item>
                                <Descriptions.Item label="性别">
                                    {genderArr[props.userDetail.gender]}
                                </Descriptions.Item>
                                <Descriptions.Item label="年龄">
                                    {props.userDetail.age}
                                </Descriptions.Item>
                                <Descriptions.Item label="出生日期">
                                    {props.userDetail.dateBirth}
                                </Descriptions.Item>
                                <Descriptions.Item label="身份证号">
                                    {props.userDetail.idNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label="关联医院">
                                    {props.userDetail.hospitalName}
                                </Descriptions.Item>
                                <Descriptions.Item label="预计缺牙颗数">
                                    {props.userDetail.toothNum}
                                </Descriptions.Item>
                                <Descriptions.Item label="报名来源">
                                    {registerType[props.userDetail.registerType]}
                                </Descriptions.Item>
                                <Descriptions.Item label="省市区">
                                    {props.addressDetail.areaStr}
                                </Descriptions.Item>
                                <Descriptions.Item label="居住地址">
                                    {props.addressDetail.address}
                                </Descriptions.Item>
                                <Descriptions.Item label="联系电话">
                                    {props.userDetail.phone}
                                </Descriptions.Item>
                            </Descriptions>
                            {/*{warVeteranShow === true && (*/}
                            {/*    <Descriptions>*/}
                            {/*        <Descriptions.Item label={titleObj?.warVeteran}>*/}
                            {/*            {warVeteranShowStr}*/}
                            {/*        </Descriptions.Item>*/}
                            {/*    </Descriptions>*/}
                            {/*)}*/}
                        </>
                    </Space>
                </Card>
            )}
        </>
    );
};

export default PatientInfo;
