import React from 'react';
import {Button, Input, message, Modal, Select, Space, Spin, Table} from 'antd';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../App';
import BaseListPage from "../common/listpage/BaseListPage";
import styles from "../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import Fetch from "../common/FetchIt";
import createModal from "../common/createModal";
import BreadcrumbInfo from "../common/breadcrumb/BreadcrumbInfo";
import {AppletsConfigPage} from "../cooperate/util/types";
import {appletsConfigListDefaultData, listDefaultData} from "../cooperate/util/AierCommon";
import WeekRadioModelClass from "./weekRadioModel";
import hospitalTeethFeeIndexListModel from "./hospitalTeethFee/hospitalTeethFeeIndexList";


type User = {};
const {confirm} = Modal;

const WeekRadioModel = createModal(WeekRadioModelClass);
const HospitalTeethFeeIndexList = createModal(hospitalTeethFeeIndexListModel);

class DhHospitalListtClass extends BaseListPage {
    formRef = React.createRef();
    cityRef = React.createRef();
    state: AppletsConfigPage = {
        ...appletsConfigListDefaultData
    };
    private weekRadioModel: any = null;
    private hospitalTeethFeeIndex: any = null;

    componentDidMount() {
        super.componentDidMount();
        this.loadSelectData();
        this.loadHospital(null);
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        const hospitalId = searchData.hospitalId ? searchData.hospitalId : 0;
        const provinceId = searchData.provinceId ? searchData.provinceId : 0;
        const hospitalName = searchData.name ? searchData.name : "";
        let url = `${API.dhHospital.list}?page=${searchData.page}&size=${pageSize}`;
        url = url + `&hospitalId=${hospitalId}&provinceId=${provinceId}&name=${hospitalName}`
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };

    updateParticipate = (id: number, participate: boolean) => {
        const isParticipate = !participate;
        const that = this;
        confirm({
            title: `是否修改医院参与状态？`,
            okText: `提交`,
            onOk() {
                Fetch.putJSON(API.dhHospital.update, {id: id, participate: isParticipate}).then(() => {
                        message.success('提交成功');
                        that.loadData();
                    }
                );
            }
        });
    }

    updateAdvanceMoney = (hospitalId: number, advanceMoney: boolean) => {
        const that = this;
        const title = advanceMoney ? "当前医院已经开启医院垫付是否修改" : "当前医院未开启医院垫付是否开启";
        confirm({
            title: title,
            okText: `提交`,
            onOk() {
                Fetch.postJSON(API.aier_hospital_role.update_advance_money, {hospitalId: hospitalId}).then(() => {
                    message.success('提交成功');
                    that.loadData();
                });
            }
        });
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    editHospitalViewRole = (provinceId: number, hospitalId: number, id: number) => {
        this.setState({
            provinceId: provinceId,
            id: id,
            selectHospitalId: hospitalId,
        }, () => {
            this.weekRadioModel.show()
        })
    }
    viewHospitalTeethFee = (hospitalId: number, id: number) => {
        this.setState({
            id: id,
            selectHospitalId: hospitalId,
        }, () => {
            this.hospitalTeethFeeIndex.show()
        })
    }

    reset = () => {
        super.reset(listDefaultData.searchData);
        this.loadHospital(null);
    };

    loadSelectData = () => {
        let url = `${API.zone.list}?parentId=0&r=1`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
        if (condition.provinceId) {
            this.loadHospital(condition.provinceId)
        }
    };

    loadHospital(provId: any) {
        if (provId === undefined) {
            provId = null
        }
        this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.hospital_area.find_select_data}?provId=${provId}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    setCitystr = (val: any) => {
        this.state.provinceId = val
        this.setState({});
    };

    render() {
        //
        const {
            searchData, pageSize, totalElements,
            content
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '省',
                // width: 80,
                dataIndex: 'provinceName',
                key: 'provinceName',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '市',
                // width: 80,
                dataIndex: 'cityName',
                key: 'cityName',
                align: `center`,
                render: (text: string) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '医院名称',
                // width: 100,
                dataIndex: 'hospitalName',
                key: 'hospitalName',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            // {
            //     title: '医院类型',
            //     dataIndex: 'hospitalType',
            //     key: 'hospitalType',
            //     align: `center`,
            //     // width: 100,
            //     render(value, record: any) {
            //         return (
            //             <span style={{wordBreak: 'break-all'}}>{value === 1 ? '省级' : '执行'}</span>
            //         );
            //     },
            // },
            {
                title: '是否参与',
                dataIndex: 'participate',
                key: 'participate',
                align: `center`,
                // width: 100,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value === true ? '参与' : '不参与'}</span>
                    );
                },
            },
            // {
            //     title: '是否医院垫付',
            //     dataIndex: 'advanceMoney',
            //     key: 'advanceMoney',
            //     align: `center`,
            //     // width: 100,
            //     render(value, record: any) {
            //         return (
            //             <span style={{wordBreak: 'break-all'}}>{value === true ? '是' : '否'}</span>
            //         );
            //     },
            // },
            {
                title: '操作',
                key: 'operation',
                align: `center`,
                width: 100,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <>
                                <Button
                                    onClick={() => this.editHospitalViewRole(record.provinceId, record.hospitalId, record.id)}>
                                    配置时间
                                </Button>
                                <Button
                                    onClick={() => this.viewHospitalTeethFee(record.hospitalId, record.id)}>
                                    配置牙齿数据
                                </Button>
                                <>
                                    <Button
                                        onClick={() => this.updateParticipate(record.id, record.participate)}>
                                        {record.participate ? "不参与" : "参与"}
                                    </Button>
                                </>
                            </>
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>

                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space className={styles.areaHospitalSelect}>
                            <Select
                                showSearch
                                placeholder="选择省份"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, provinceId: e})
                                }}
                                value={searchData.provinceId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 120}}
                                options={this.state.zoneSelect}
                            />
                            <Input
                                placeholder="请输入医院名称"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>
                        </Space>
                        <Table
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            scroll={{x: 1500}}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <WeekRadioModel
                    width={1500}
                    title={'编辑'}
                    provinceId={this.state.provinceId}
                    id={this.state.id}
                    hospitalId={this.state.selectHospitalId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.weekRadioModel = c)}
                />

                <HospitalTeethFeeIndexList
                    width={1500}
                    title={'编辑'}
                    id={this.state.id}
                    hospitalId={this.state.selectHospitalId}
                    ref={(c) => (this.hospitalTeethFeeIndex = c)}
                />
            </>
        );
    }

}

export default function DhHospitalList() {
    return (

        <App selectedKeys={
            ['dhHospital']
        }
             openKeys="ORAL_GUARD">
            <DhHospitalListtClass/>
        </App>
    );
}
