import React, {Component} from 'react';
import {Button, Card, Form, FormInstance, message, Space,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {defaultDhDetailData, DhDetailDataType} from "../common/DhDetailType";
import {decryptData} from "../../common/crypto";
import PatientInfo from "../patientDetail/PatientInfo";
import BookingInfo from "../patientDetail/BookingInfo";
import VisitTaskInfo from "../patientDetail/VisitTaskInfo";
import DhWarVeteranInfo from "../patientDetail/DhWarVeteranInfo";
import DhAccountInfo from "../patientDetail/DhAccountInfo";
import DiagnosisReportInfo from "../patientDetail/DiagnosisReportInfo";
import DhTaskCallLog from "../patientDetail/DhTaskCallLog";
import {dhShowFile} from "../common/edit/DhBackfillDataConfig";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";

type ReturnVisitDispose = {
    cancelLoading: boolean,
    subLoading: boolean
}

class DhReturnVisitDetailClass extends Component<{}, DhDetailDataType & ReturnVisitDispose> {

    formRef = React.createRef<FormInstance>();
    state: DhDetailDataType & ReturnVisitDispose = {
        id: decryptData(common.getQuery('taskId')),
        data: defaultDhDetailData.data,
        cancelLoading: false,
        subLoading: false
    };

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id} = this.state;
        let url = `${API.dentistry.phone_task_batch_detail.do_get_detail_by_id}?id=${id}`;
        Fetch.get(url).then((data) => {
            dhShowFile(data).then(() => {
                this.setState({
                    data: data,
                });
            })
        });
    }

    cancel = () => {

        this.setState({
            cancelLoading: true,
        });
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    submit = (values: any) => {
        //TODO 提示是否
        const {id} = this.state;
        values.taskId = id;
        Fetch.postJSON(API.atl.return_visit.do_handle_return_visits, values).then(() => {
            message.success('处理成功');
            window.history.back()
        });
    }

    render() {
        const {data, cancelLoading, id} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Form
                        onFinish={this.submit}
                        ref={this.formRef}
                    >
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <VisitTaskInfo tasksDetail={data?.tasksDetail} taskDetailId={id} showCallPhone={false}/>
                            <PatientInfo userDetail={data?.userDetail} addressDetail={data?.addressDetail}
                                         warVeteran={data.warVeteran}/>
                            <DhWarVeteranInfo warVeteran={data.warVeteran}/>
                            <DhAccountInfo account={data.account}/>
                            <DiagnosisReportInfo report={data.report}/>
                            <BookingInfo bookingVos={data?.bookingVos}/>
                            <DhTaskCallLog taskId={id}/>
                        </Space>

                        <Card>
                            <Space>
                                {/*<Button*/}
                                {/*    type="primary"*/}
                                {/*    htmlType="submit"*/}
                                {/*    size="large"*/}
                                {/*    loading={subLoading}*/}
                                {/*>*/}
                                {/*    保存*/}
                                {/*</Button>*/}
                                <Button
                                    // type="primary"
                                    size="large"
                                    loading={cancelLoading}
                                    onClick={this.cancel}
                                >
                                    返回
                                </Button>
                            </Space>
                        </Card>
                    </Form>
                </div>
            </div>
        );
    }
}

export default function DhReturnVisitDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <DhReturnVisitDetailClass/>
        </App>
    );
}
