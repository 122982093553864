import {Descriptions} from 'antd';
import {useState} from 'react';
import styles from '../detail.module.css';

export default function Job(props: any) {
    const {datas, dictData} = props;
    const [lineStyle, setlineStyle] = useState<{}>({
        width: '200px',
        color: '#8C8C8C',
        textAlign: 'right',
    });

    return (
        <div>
            <div className={styles.jobTitle}>工作状态</div>
            <Descriptions bordered column={{xs: 1, md: 1, sm: 1}}>
                <Descriptions.Item label="当前状态" labelStyle={lineStyle}>
                    {datas.workStatus === 0
                        ? '在职'
                        : datas.workStatus === 1
                            ? '已退休'
                            : '求学中'}
                </Descriptions.Item>
                <Descriptions.Item label="职业" labelStyle={lineStyle}>
                    {dictData[`profession${datas.professionCode}`]
                        ? dictData[`profession${datas.professionCode}`]
                        : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="当前工作单位" labelStyle={lineStyle}>
                    {datas.workUnit ? datas.workUnit : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="最高学历" labelStyle={lineStyle}>
                    {dictData[`education${datas.educationCode}`]
                        ? dictData[`education${datas.educationCode}`]
                        : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="职务" labelStyle={lineStyle}>
                    {datas.job ? datas.job : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="职称" labelStyle={lineStyle}>
                    {datas.jobTitle ? datas.jobTitle : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="职级" labelStyle={lineStyle}>
                    {datas.jobRank ? datas.jobRank : '--'}
                </Descriptions.Item>
                <Descriptions.Item label="首次参加工作时间" labelStyle={lineStyle}>
                    {datas.firstJoinWorkDate ? datas.firstJoinWorkDate : '--'}
                </Descriptions.Item>
            </Descriptions>
            {datas.workHistoryList.map((item: any) => {
                return (
                    <>
                        <div className={styles.jobTitle} style={{marginTop: '24px'}}>
                            工作经历
                        </div>
                        <Descriptions bordered column={{xs: 1, md: 1, sm: 1}}>
                            <Descriptions.Item label="职业" labelStyle={lineStyle}>
                                {dictData[`profession${item.professionCode}`]
                                    ? dictData[`profession${item.professionCode}`]
                                    : '--'}
                            </Descriptions.Item>
                            <Descriptions.Item label="工作单位" labelStyle={lineStyle}>
                                {item.workUnit ? item.workUnit : '--'}
                            </Descriptions.Item>
                            <Descriptions.Item label="职务" labelStyle={lineStyle}>
                                {item.job ? item.job : '--'}
                            </Descriptions.Item>
                            <Descriptions.Item label="职称" labelStyle={lineStyle}>
                                {item.jobTitle ? item.jobTitle : '--'}
                            </Descriptions.Item>
                            <Descriptions.Item label="职级" labelStyle={lineStyle}>
                                {item.jobRank ? item.jobRank : '--'}
                            </Descriptions.Item>
                            <Descriptions.Item label="工作开始时间" labelStyle={lineStyle}>
                                {item.workBeginTime ? item.workBeginTime : '--'}
                            </Descriptions.Item>
                            <Descriptions.Item label="工作结束时间" labelStyle={lineStyle}>
                                {item.workEndTime ? item.workEndTime : '--'}
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                );
            })}
        </div>
    );
}
