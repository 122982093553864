import React, {useEffect, useState} from "react";
import Fetch from "../common/FetchIt";
import API from "../common/api";
import {Card, Col, Row} from "antd";
import styles from "./gongyiActivity.module.css";
import {Link} from "react-router-dom";
import PATH from "../common/pagePath";

export default function ReviewStatistics(props: { category: string }) {
    const [statistics, setStatistics] = useState({
        draftNum: 0,
        provinceReviewNum: 0,
        finalReviewNum: 0
    });

    useEffect(function () {
        let url = `${API.gongyiActivity.reviewStatistics}`;
        if (props.category === 'donation') {
            url = `${API.donationActivity.reviewStatistics}`;
        } else if (props.category === 'project') {
            url = `${API.project.reviewStatistics}`;
        }
        Fetch.get(url).then(data => {
            setStatistics(data);
        });
    }, []);

    let path = PATH.gongyiActivity.list;
    if (props.category === 'donation') {
        path = PATH.donationActivity.list;
    } else if (props.category === 'project') {
        path = PATH.project.list;
    }

    return (
        <Card className="mgTop15">
            <Row>
                <Col span={8} className={styles.mycol}>
                    <div>草稿箱</div>
                    <div>
                        <div>{statistics.draftNum}</div>
                        <div className={styles.mynum2}><Link to={path}
                                                             className={props.category === 'project' ? styles.mylink3 : styles.mylink2}>已发布列表</Link>
                        </div>
                    </div>
                </Col>
                <Col span={8} className={styles.mycol}>
                    <div>省级审核中</div>
                    <div>
                        <div>{statistics.provinceReviewNum}</div>
                    </div>
                </Col>
                <Col span={8} className={styles.mycol}>
                    <div>老基会审核中</div>
                    <div>
                        <div>{statistics.finalReviewNum}</div>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}
