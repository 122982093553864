import React, {useEffect, useState} from "react";
import Fetch from "../common/FetchIt";
import API from "../common/api";
import {Card, Col, Row} from "antd";
import {Link} from 'react-router-dom';
import styles from "./gongyiActivity.module.css";
import PATH from "../common/pagePath";

export default function Statistics(props: { category: string, key?: number }) {
    const [statistics, setStatistics] = useState({
        reviewNum: 0,
        doingNum: 0,
        impendingNum: 0,
        enabledNum: 0,
    });

    useEffect(function () {
        let url = `${API.gongyiActivity.statistics}`;
        if (props.category === 'donation') {
            url = `${API.donationActivity.statistics}`;
        } else if (props.category === 'project') {
            url = `${API.project.statistics}`;
        }
        Fetch.get(url).then(data => {
            setStatistics(data);
        });
    }, [props.category, props.key]);

    let path = PATH.gongyiActivity.review;
    if (props.category === 'donation') {
        path = PATH.donationActivity.review;
    } else if (props.category === 'project') {
        path = PATH.project.review;
    }

    return (
        <Card className="mgTop15">
            <Row>
                <Col span={props.category !== 'project' ? 8 : 12} className={styles.mycol}>
                    <div>待审核</div>
                    <div>
                        <div className={styles.mynum}>{statistics.reviewNum}</div>
                        <div className={styles.mynum2}><Link to={path}
                                                             className={props.category === 'project' ? styles.mylink3 : styles.mylink}>审核列表</Link>
                        </div>
                    </div>
                </Col>
                {
                    props.category !== 'project' ? (
                        <>
                            <Col span={8} className={styles.mycol}>
                                <div>活动进行中</div>
                                <div>
                                    <div>{statistics.doingNum}</div>
                                </div>
                            </Col>
                            <Col span={8} className={styles.mycol}>
                                <div>即将开始</div>
                                <div>
                                    <div>{statistics.impendingNum}</div>
                                </div>
                            </Col>
                        </>
                    ) : (
                        <Col span={12} className={styles.mycol}>
                            <div>已发布</div>
                            <div>
                                <div>{statistics.enabledNum}</div>
                            </div>
                        </Col>
                    )
                }
            </Row>
        </Card>
    )
}
