import React, {Component} from 'react';
import {Button, DatePicker, Form, FormInstance, Input, InputNumber, message, Space, Switch,} from 'antd';
import Fetch from '../common/FetchIt';
import Editor from '../common/editor/Editor';
import API from '../common/api';
import styles from './donationActivity.module.css';
import {DonationActivity} from './types';
import FileUploader from '../common/uploader';

const FormItem = Form.Item;
const {RangePicker} = DatePicker;

type FormProps = {
    donationActivity: DonationActivity;
    hide: () => void;
    reload: () => void;
};

type FormState = {
    options: [];
    timeLimited: boolean;
};

class DonationActivityForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();
    draft = 'PUBLISHED';
    loading = false;

    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
            timeLimited: props.donationActivity.timeLimited,
        };
    }

    saveDraft = () => {
        this.draft = 'DRAFT';
        this.formRef.current?.submit();
    };

    publish = () => {
        this.draft = 'PUBLISHED';
        this.formRef.current?.submit();
    };

    submit = (values: any) => {
        if (this.loading) {
            return;
        }
        values.coverIds = values.coverIds.map((item: any) => item.id).join(',');
        values.content = values.content.toHTML
            ? values.content.toHTML()
            : values.content;
        if (values.content == '<p></p>') {
            // values.text=''
            message.error('内容不能为空！');
            return;
        }
        if (values.timeLimited) {
            values.startDate = values.date1[0].format('YYYY-MM-DD HH:mm');
            values.endDate = values.date1[1].format('YYYY-MM-DD HH:mm');
        }

        delete values.date1;
        this.loading = true;
        if (values.id) {
            this.update(values);
        } else {
            values.status = this.draft;
            this.create(values);
        }
    };

    create = (values: DonationActivity) => {
        Fetch.postJSON(`${API.donationActivity.zsgc}`, values).then(() => {
            this.loading = false;
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values: DonationActivity) => {
        Fetch.putJSON(`${API.donationActivity.zsgc}/${values.id}`, values).then(
            () => {
                this.loading = false;
                message.success('保存成功');
                this.props.hide();
                this.props.reload();
            }
        );
    };

    onSearch = (searchText: string) => {
        if (searchText && searchText.trim().length > 0) {
            Fetch.get(`${API.location}?keyword=${searchText.trim()}`).then((data) => {
                const result = JSON.parse(data);
                if (result.status === 0) {
                    const options = result.data.map((item: any) => {
                        return {
                            label: item.title + ' ' + item.address,
                            value: item.address,
                            location: item.location,
                        };
                    });
                    this.setState({
                        options,
                    });
                } else {
                    message.error(result.message);
                }
            });
        }
    };

    changeTimeLimit = (value: any) => {
        this.setState({
            timeLimited: value,
        });
    };

    render() {
        const id = this.props.donationActivity && this.props.donationActivity.id;
        return (
            <div className={styles.container}>
                <Form
                    initialValues={{
                        ...this.props.donationActivity,
                    }}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem hidden noStyle name="status">
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="coverIds"
                        valuePropName="fileList"
                        label="封面图"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <FileUploader
                            multiple
                            maxCount={6}
                            listType="picture-card"
                            checkSize={false}
                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸375 * 210; 最多6张"
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="title"
                        label="活动名称"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="address"
                        label="详细地址"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="total"
                        label="募捐金额"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[
                            {required: true, message: '必填'},
                            {min: 0.01, message: '最小金额0.01', type: 'number'},
                        ]}
                    >
                        <InputNumber
                            min={0.01}

                            style={{width: '200px'}}
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="continueWhenFull"
                        label="超过金额是否继续"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                        valuePropName="checked"
                    >
                        <Switch/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="timeLimited"
                        label="是否有时间限制"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                        valuePropName="checked"
                    >
                        <Switch onChange={this.changeTimeLimit}/>
                    </FormItem>
                    {this.state.timeLimited && (
                        <FormItem
                            className={styles.mylabel}
                            name="date1"
                            label="募捐时间"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 19}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <RangePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"

                            />
                        </FormItem>
                    )}
                    <FormItem
                        className={styles.mylabel}
                        name="content"
                        label="捐款文案内容"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 19}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Editor url={API.upload}/>
                    </FormItem>
                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Space>
                            {!id && (
                                <Button onClick={this.saveDraft} className={styles.mybtn}>
                                    存草稿
                                </Button>
                            )}
                            <Button
                                type="primary"
                                onClick={this.publish}
                                className={styles.mybtn}
                            >
                                提交
                            </Button>
                        </Space>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default DonationActivityForm;
