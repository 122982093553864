import React, {Component} from 'react';
import {Badge, BadgeProps, Button, Calendar, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import style from "./HospitalDashboard.module.css"
import createModal from "../../common/createModal";
import HospitalEditModuleImport from "./HospitalEditModule";
import ShowBookingModuleImport from "./ShowBookingModule";
import {SetListStorage} from "../../common/listpage/SessionStorageUtil";
import dayjs, {Dayjs} from "dayjs";
import 'dayjs/locale/zh-cn';

const HospitalEditModule = createModal(HospitalEditModuleImport);
const ShowBookingModule = createModal(ShowBookingModuleImport);

class HospitalDashboardClass extends Component<{}, any> {

    state = {
        scheduling: {},
        startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
        endTime: dayjs().endOf("month").format("YYYY-MM-DD"),
        spinningFlag: true,
        selectId: 0,
        selectTime: ''
    };
    private HospitalEditModuleResult: any = null;
    private ShowBookingModuleResult: any = null;

    componentDidMount() {
        SetListStorage({})
        this.getData()
    };

    getData() {
        const {startTime, endTime} = this.state
        let url = `${API.dentistry.dh_hospital_scheduling.find_hospital_dashboard}?start=${startTime}&end=${endTime}`;
        Fetch.get(url).then((data) => {
            const schedulingData = data.reduce((result: any, item: any) => {
                const {schedulingDate, ...rest} = item;
                result[schedulingDate] = rest;
                return result;
            }, {});
            this.setState({
                scheduling: schedulingData,
                spinningFlag: false
            });
        });
    }

    dateCellRender = (value: Dayjs) => {

        // const myStyle = {
        //     float: 'right',
        //     marginTop: 10,
        // };

        const listData = this.getListData(value);
        const timeStr = value.format("YYYY-MM-DD");
        return (
            <ul className={style.events}>
                {listData.listData.map(item => (
                    <>
                        <li key={item.content}>
                            <Badge status={item.type as BadgeProps['status']} text={item.content}/>
                        </li>
                    </>
                ))}
                {listData.id > 0 && (
                    <>
                        <Space>
                            <Button type="primary"
                                    className={style.editButton}
                                    shape="round"
                                    size="small"
                                    onClick={() => this.goDashboardList(timeStr)}

                            >查看预约</Button>
                            <Button type="primary"
                                    className={style.editButton}
                                    shape="round"
                                    size="small"
                                    onClick={() => this.goEdit(listData.id)}
                            >修改</Button>
                        </Space>
                    </>
                )}
            </ul>
        );
    };

    goEdit = (id: number) => {
        this.setState({
            selectId: id
        }, () => {
            this.HospitalEditModuleResult.show();
        })
    }

    goDashboardList = (time: string) => {
        this.setState({
            selectTime: time
        }, () => {
            this.ShowBookingModuleResult.show();
        })
    }

    getListData = (value: Dayjs) => {

        const {scheduling} = this.state
        let date = value.format("YYYY-MM-DD");
        let schedulingElement = scheduling[date];

        if (schedulingElement) {
            let listData = [
                {
                    type: schedulingElement.actualAmVisitors === schedulingElement.planAmVisitors ? 'error' : 'success',
                    content: '上午' + schedulingElement.planAmVisitors + '/' + schedulingElement.actualAmVisitors + '人'
                },
                {
                    type: schedulingElement.actualPmVisitors === schedulingElement.planPmVisitors ? 'error' : 'success',
                    content: '下午' + schedulingElement.planPmVisitors + '/' + schedulingElement.actualPmVisitors + '人'
                },
            ];
            return {
                id: schedulingElement.id,
                listData: listData || []
            };
        }
        return {
            id: 0,
            listData: []
        };
    };

    onSelect = (newValue: Dayjs) => {

    };
    onPanelChange = (date: Dayjs, mode: string) => {

        if (mode === 'month') {
            this.setState({
                startTime: date.startOf("month").format("YYYY-MM-DD"),
                endTime: date.endOf("month").format("YYYY-MM-DD"),
                spinningFlag: true
            }, () => {
                this.getData();
            })
        }
    }

    closeModal = () => {

        this.getData();
    }

    render() {
        const {spinningFlag, selectId, selectTime} = this.state;
        return (
            <>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip="加载中..." spinning={spinningFlag}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <Calendar cellRender={this.dateCellRender} mode={"month"}
                                      onSelect={this.onSelect} onPanelChange={this.onPanelChange}/>
                        </Space>
                    </Spin>
                </div>
                <HospitalEditModule
                    width={550}
                    title={'修改排期'}
                    importResult={selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.HospitalEditModuleResult = c)}
                />
                <ShowBookingModule
                    width={1300}
                    title={'排期'}
                    importResult={selectTime}
                    onCancel={this.closeModal}
                    ref={(c) => (this.ShowBookingModuleResult = c)}
                />
            </>
        );
    }
}

export default function HospitalDashboard() {


    return (
        <App selectedKeys='HOSPITAL_DASHBOARD'
             openKeys='ORAL_GUARD'>
            <HospitalDashboardClass/>
        </App>
    );
}
