import React, {RefObject} from 'react';
import {Card, Col, FormInstance, Input, Row, Select} from 'antd';
import FormItem from 'antd/lib/form/FormItem';

const Option = Select.Option;

type ZoneSelectProps = {
    form: RefObject<FormInstance>;
};

type Zone = {
    id: number;
    name: string;
};

export default function HandleReturnVisits(props: ZoneSelectProps) {


    return (
        <>
            <Card>
                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            label={"填写"}
                            name="areaStr"
                            labelCol={{span: 4}}
                            wrapperCol={{span: 15}}
                            rules={[{required: true, message: '必填'}]}
                            style={{minWidth: '100px'}}
                        >
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>
            </Card>
        </>
    );
}
