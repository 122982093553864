import API from "../../common/api";
import {TroupeArtistDetailType} from "./TroupeArtistType";

export function buildTroupFileData(data: TroupeArtistDetailType["data"]) {

    if (data.files && data.files.length > 0) {

        data.files.forEach((item: any) => {
            const path = API.domain + item.url;
            const obj = {
                id: item.fileId,
                uid: item.fileId,
                fileId: item.fileId,
                name: item.name,
                url: API.domain + item.url,
                status: 'done',
            };
            if (item.fileType === 1) {
                data.signUrl = obj
            } else if (item.fileType === 2) {
                data.certificate = obj
            }
        });
    }
}

export const RongTitleArr = [
    {
        value: '01',
        label: '荣誉团员',
    },
    {
        value: '02',
        label: '青年之友',
    },
    {
        value: '03',
        label: '艺术指导',
    },
    {
        value: '04',
        label: '顾问委员',
    },
]

export const SignStatusArr = [
    {
        value: 1,
        label: '未签',
    },
    {
        value: 2,
        label: '已签',
    }
]