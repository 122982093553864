import {Component} from 'react';
import {Button, Form, Input, message, Space} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './supplier.module.css';
import {Supplier} from './types';

const FormItem = Form.Item;

type FormProps = {
    data: Supplier;
    hide: () => void;
    reload: () => void;
};

type FormState = {
    provinceId: number | '';
    cityId: number | '';
};

class SupplierFrom extends Component<FormProps, FormState> {
    state: FormState = {
        provinceId: '',
        cityId: '',
    };

    submit = (values: Supplier) => {
        if (values.id) {
            this.update(values);
        } else {
            this.create(values);
        }
    };

    create = (values: Supplier) => {
        Fetch.postJSON(`${API.goods.supllerCreate}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values: Supplier) => {
        Fetch.put(`${API.goods.supllerUpdate}`, values).then(() => {
            message.success('保存成功');
            this.props.hide();
            this.props.reload();
        });
    };
    cancel = () => {
        this.props.hide();
    };

    render() {
        return (
            <div className={styles.container}>
                <Form initialValues={this.props.data} onFinish={this.submit}>
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="name"
                        label="供应商名称"
                        labelCol={{span: 6}}
                        wrapperCol={{span: 14}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input

                            placeholder="在此输入供应商名称"
                        />
                    </FormItem>

                    <FormItem className="center">
                        <Space className={styles.mybtn}>
                            <Button
                                size="large"
                                className={styles.mybtnItem}
                                onClick={this.cancel}
                            >
                                取 消
                            </Button>
                            <Button
                                className={styles.mybtnItem}
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                确 定
                            </Button>
                        </Space>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default SupplierFrom;
