export function getGongyiActivityStatus() {

    return function (activityStatus: number) {
        let status = '';
        switch (activityStatus) {
            case 1:
                status = '未开始';
                break;
            case 2:
                status = '报名中';
                break;
            case 3:
                status = '报名结束';
                break;
            case 4:
                status = '签到中';
                break;
            case 5:
                status = '签到结束';
                break;
            case 6:
                status = '活动中';
                break;

            default:
                status = '活动结束';
                break;
        }
        return status;
    }
}

const gongyiActivityStatus = getGongyiActivityStatus();

export {
    gongyiActivityStatus
}