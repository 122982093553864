import PATH from "../../../common/pagePath";
import {message} from "antd";
import {DhDetailDataType, EditType, FilePushType, FileType} from "../DhDetailType";
import API from "../../../common/api";
import dayjs from "dayjs";

export const buildEditAccount = (value: EditType, source: DhDetailDataType["data"]) => {

    if (source.account) {
        value.paymentMethod = source.account.paymentMethod;
        value.accountName = source.account.name;
        value.accountBank = source.account.accountBank;
        value.accountBackCard = source.account.backCard;
        value.accountIdNumber = source.account.idNumber;
        value.accountPhone = source.account.phone;
        value.accountRelationshipId = source.account.relationship;
        value.accountApplicant = source.account.accountApplicant;
        value.accountPayee = source.account.accountPayee;
        value.accountSignature = source.account.accountSignature;
    }
}

const buildWarVeteran = (value: EditType, source: DhDetailDataType["data"]) => {

    if (source.warVeteran) {
        value.warVeteran = source.warVeteran.warVeteran;
        value.serviceIdentity = source.warVeteran.serviceIdentity;
        value.warVeteranName = source.warVeteran.name;
        value.warVeteranIdNumber = source.warVeteran.idNumber;
        value.warVeteranPhone = source.warVeteran.phone;
        value.warVeteranRelations = source.warVeteran.veteranRelations;
    }
}

export const buildBase = (value: EditType, source: DhDetailDataType["data"]) => {

    if (source.userDetail && source.addressDetail) {
        value.name = source.userDetail.name;
        value.age = source.userDetail.age;
        value.gender = source.userDetail.gender;
        value.phone = source.userDetail.phone;
        value.idNumber = source.userDetail.idNumber;
        if (source.addressDetail.provinceId === 0) {
            value.provinceId = undefined;
            value.cityId = undefined;
            value.districtId = undefined;
        } else {
            value.provinceId = source.addressDetail.provinceId;
            value.cityId = source.addressDetail.cityId;
            value.districtId = source.addressDetail.districtId;
        }
        value.areaStr = source.addressDetail.areaStr;
        value.addressDetail = source.addressDetail.address;
        value.locationAddr = source.addressDetail.locationAddr;
        value.lng = source.addressDetail.lng
        value.lat = source.addressDetail.lat;
        value.toothNum = source.userDetail.toothNum
    }
}

const buildFreeClinicsReport = (value: EditType, source: DhDetailDataType["data"]) => {

    if (source && source.report) {
        value.rSurgery = source.report.surgery;
        value.rNoSurgeryReason = source.report.noSurgeryReason;
        value.rDiagnosticReports = source.report.report;
    }
}

const buildServiceUserFeeData = (value: EditType, source: DhDetailDataType["data"]) => {
    if (source && source.serviceUserFee) {
        value.surgeryStart = dayjs(source.serviceUserFee.surgeryStart);
        value.surgeryEnd = dayjs(source.serviceUserFee.surgeryEnd);
        value.teethNum = source.serviceUserFee.teethNum;
        value.totalPrice = source.serviceUserFee.totalPrice;
        value.remark = source.serviceUserFee.remark;
        value.diagnosticReports = source.serviceUserFee.diagnosticReports;
        value.consentForms = source.serviceUserFee.consentForms;
        value.hospitalizationRecords = source.serviceUserFee.hospitalizationRecords;
        value.invoices = source.serviceUserFee.invoices;
        value.proofIdentity = source.serviceUserFee.proofIdentity;
        value.identityPromise = source.serviceUserFee.identityPromise;
        value.teethType = source.serviceUserFee.teethType;
        value.informed = source.serviceUserFee.informed;
    }
}


export const buildBackFillData = (value: EditType, source: DhDetailDataType["data"], router: any) => {

    if (PATH.dhHospitalPatient.edit === router) {
        buildHospital(value, source);
    } else if (PATH.dhPatientApplication.edit === router) {
        buildAdmin(value, source);
    } else if (PATH.dhPatientApplication.add === router) {
        adminAdd(value, source)
    } else {
        message.error("请返回到列表页重新进入")
    }
};

const buildHospital = (value: EditType, source: DhDetailDataType["data"]) => {

    const dhStatus = source.userDetail?.dhStatus;
    if (dhStatus === 104 || dhStatus === 105) {
        buildEditAccount(value, source);
    } else if (dhStatus === 107) {
        buildEditAccount(value, source);
        buildFreeClinicsReport(value, source);
    } else if (dhStatus === 109 || dhStatus === 111) {
        buildEditAccount(value, source);
        buildServiceUserFeeData(value, source);
    } else if (dhStatus === 113) {
        buildServiceUserFeeData(value, source);
    }
    if (dhStatus === 116 || dhStatus === 117) {
        buildEditAccount(value, source)
    }
}

const buildAdmin = (value: EditType, source: DhDetailDataType["data"]) => {

    const dhStatus = source.userDetail?.dhStatus;
    if (dhStatus === 102 || dhStatus === 101 || dhStatus == 103) {
        buildBase(value, source);
        buildWarVeteran(value, source);
        buildEditAccount(value, source);
    } else if (dhStatus === 104 || dhStatus === 105) {
        buildEditAccount(value, source);
    }
}

/**
 * 管理台修改
 *
 *     101: '待分配',
 *     102: '待审核',
 *     103: '审核退回',
 *     104: '待签到',
 *     105: '已签到',
 *     106: '不需要进行手术',
 *     107: '义诊已完成',
 *
 * @see dhStatusObj
 * @constructor
 */
const adminAdd = (value: EditType, source: DhDetailDataType["data"]) => {

    message.error("请返回到列表页重新进入")
}

export const dhShowFile = async (data: DhDetailDataType["data"]) => {

    if (data && data.files && data.files.length > 0) {
        data.files.forEach((item: FileType) => {
            const path = API.domain + item.url;
            const obj: FilePushType = {
                uid: item.fileId + "",
                name: item.name,
                url: API.domain + item.url,
                status: 'done',
                fileId: item.fileId,
                id: item.fileId
            };
            if (item.type === 2) {
                checkAccount(data).then(() => {
                    if (data.account) {
                        data.account.accountApplicant.push(obj);
                        data.account.applicantShow = path;
                    }
                })
            } else if (item.type === 3) {
                checkAccount(data).then(() => {
                    if (data.account) {
                        data.account.accountPayee.push(obj);
                        data.account.payeeShow = path;
                    }
                })
            } else if (item.type === 4) {
                checkAccount(data).then(() => {
                    if (data.account) {
                        data.account.accountSignature.push(obj);
                        data.account.signatureShow = path;
                    }
                })
            } else if (item.type === 5) {
                checkServiceUserFee(data).then(() => {
                    data.serviceUserFee?.consentForms.push(obj)
                })
            } else if (item.type === 6) {
                checkServiceUserFee(data).then(() => {
                    data.serviceUserFee?.hospitalizationRecords.push(obj)
                })
            } else if (item.type === 7) {
                checkServiceUserFee(data).then(() => {
                    data.serviceUserFee?.invoices.push(obj)
                })
            } else if (item.type === 8) {
                checkServiceUserFee(data).then(() => {
                    data.serviceUserFee?.proofIdentity.push(obj)
                })
            } else if (item.type === 9) {
                checkServiceUserFee(data).then(() => {
                    data.serviceUserFee?.identityPromise.push(obj)
                })
            } else if (item.type === 10) {
                checkServiceUser(data).then(() => {
                    data.userDetail?.voucher.push(obj)
                })
            } else if (item.type === 11) {
                checkServiceUserFee(data).then(() => {
                    data.serviceUserFee?.informed.push(obj)
                })
            }
            if (item.type === 1) {
                checkReport(data).then(() => {
                    data.report?.report.push(obj);
                })
            }
        });
    }
}

const checkAccount = async (data: DhDetailDataType["data"]) => {

    if (data.account) {
        data.account.accountApplicant = [];
        data.account.accountPayee = [];
        data.account.accountSignature = [];
    }
}

const checkReport = async (data: DhDetailDataType["data"]) => {

    if (data.report) {
        data.report.report = []
    }
}
const checkServiceUserFee = async (data: DhDetailDataType["data"]) => {

    if (data.serviceUserFee) {
        data.serviceUserFee.consentForms = [];
        data.serviceUserFee.hospitalizationRecords = [];
        data.serviceUserFee.invoices = [];
        data.serviceUserFee.proofIdentity = [];
        data.serviceUserFee.identityPromise = [];
        data.serviceUserFee.informed = [];
    }
}

const checkServiceUser = async (data: DhDetailDataType["data"]) => {

    if (data.userDetail) {
        data.userDetail.voucher = [];
    }
}