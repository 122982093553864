import React, {RefObject, useEffect, useState} from 'react';
import {Button, Card, Col, FormInstance, Input, Radio, Row} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import createModal from "../../../common/createModal";
import DHospitalBookingTaskModelImport from "./DHospitalBookingTaskModel";
import {DhDetailDataType} from "../../common/DhDetailType";

type NoShowVisitsType = {
    form: RefObject<FormInstance>;
    userDetail: DhDetailDataType["data"]['userDetail']
};

type Zone = {
    id: number;
    name: string;
};

const DhPatientBookingTaskModel = createModal(DHospitalBookingTaskModelImport);

let dhPatientBookingTaskModelResult: any = null;

export default function HandleNoShowVisits(props: NoShowVisitsType) {

    const [participate, setParticipate] = useState<number>(1);

    useEffect(() => {
        props.form.current?.setFieldsValue({
            participate: 1,
        });
    }, []);

    const radioChange = (e: any) => {
        setParticipate(e.target.value)
        // dhPatientBookingTaskModelResult.show()
    }

    const doBookingTime = () => {
        dhPatientBookingTaskModelResult.show()
    }

    const closeModal = (e: any) => {

    }
    const onSaveSuccess = (e: any) => {

    }

    return (
        <>
            <Card>
                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            label={"是否参与"}
                            name="participate"
                            // labelCol={{span: 4}}
                            // wrapperCol={{span: 15}}
                            rules={[{required: true, message: '必填'}]}
                            style={{minWidth: '100px'}}
                        >
                            <Radio.Group onChange={radioChange} value={1}>
                                <Radio value={1}>不参与</Radio>
                                <Radio value={2}>参与</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Col>
                </Row>

                {participate === 2 && (
                    <>
                        <Row gutter={20}>
                            <Col span={10}>
                                <FormItem
                                    label={"预约日期"}
                                    name="bookingDate"
                                    // labelCol={{span: 5}}
                                    wrapperCol={{span: 15}}
                                    rules={[{required: true, message: '必填'}]}
                                    style={{minWidth: '100px'}}
                                >
                                    <Input readOnly={true}/>
                                </FormItem>
                            </Col>
                            <Col span={10}>
                                <FormItem
                                    label={"上午&下午"}
                                    name="bookingTimeStr"
                                    // labelCol={{span: 5}}
                                    wrapperCol={{span: 15}}
                                    rules={[{required: true, message: '必填'}]}
                                    style={{minWidth: '100px'}}
                                >
                                    <Input readOnly={true}/>
                                </FormItem>
                            </Col>
                            <Col span={10}>
                                <FormItem
                                    label={"上午&下午"}
                                    name="bookingTime"
                                    rules={[{required: true, message: '必填'}]}
                                    style={{minWidth: '100px'}}
                                    hidden={true}
                                >
                                    <Input readOnly={true}/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={10}>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={doBookingTime}
                                >
                                    预约时间
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
            <DhPatientBookingTaskModel
                width={1300}
                title={'预约医院'}
                importResult={props.userDetail?.bookingId}
                onCancel={closeModal}
                onSaveSuccess={onSaveSuccess}
                form={props.form}
                ref={(c) => (dhPatientBookingTaskModelResult = c)}
            />
        </>
    );
}
