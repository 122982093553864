let cache = {}; // 全局缓存对象

export const getFromCache = (key: string) => {
    return cache[key];
};

export const setToCache = (key: string, value: any) => {
    cache[key] = value;
};

export const removeFromCache = (key: string) => {
    delete cache[key];
};
