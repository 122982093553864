import React, {Component} from 'react';
import {Button, Card, Descriptions, Space, Spin, Tabs,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {decryptData} from "../../common/crypto";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {QuesType} from "../ExamCommonData";


class ExamUserAnswerDetailClass extends Component<{}> {

    state = {
        id: decryptData(common.getQuery('id')),
        detailData: {
            userAnswerCode: "",
            answerScore: 0.00,
            quesCount: 0,
            correctCount: 0,
            errorCount: 0,
            useTime: 0,
            optionList: [
                {
                    quesId: 0,
                    optionCode: "",
                    isTrue: 0,
                    score: 0.00,
                    fileUrl: "",
                    answers: '',
                    jsonData: "",
                }
            ],
            questions: [{id: 0}],
            options: [{quesId: 0}],
            answers: [{quesId: 0}]
        },
        importLoading: true
    };

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id} = this.state;
        let url = `${API.exam.user_answer.detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            this.setState({
                detailData: data,
                importLoading: false
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const {detailData} = this.state;
        const quesMap = detailData.questions.reduce((map, item) => {
            map.set(item.id, item);
            return map;
        }, new Map());
        const optionMap = detailData.options.reduce((map, item) => {
            if (map.get(item.quesId)) {
                map.get(item.quesId).push(item);
            } else {
                let array = [];
                array.push(item)
                map.set(item.quesId, array);
            }
            return map;
        }, new Map());
        const answerMap = detailData.answers.reduce((map, item) => {
            if (map.get(item.quesId)) {
                map.get(item.quesId).push(item);
            } else {
                let array = [];
                array.push(item)
                map.set(item.quesId, array);
            }
            return map;
        }, new Map());
        const items = detailData.optionList.map((item, i) => {
            const ques = quesMap.get(item.quesId);
            const option = optionMap.get(item.quesId);
            const answer = answerMap.get(item.quesId);
            let answerStr = "";
            console.log(option)
            answer.map((item: { optionCode: string }) => {
                option.map((opItem: { optionCode: string, optionName: string }) => {
                    if (item.optionCode === opItem.optionCode) {
                        answerStr = answerStr + opItem.optionName + " "
                    }
                })
            })
            let userAnswerStr = "";
            option.map((opItem: { optionCode: string, optionName: string }) => {
                if (item.optionCode.includes(opItem.optionCode)) {
                    userAnswerStr = userAnswerStr + opItem.optionName + " "
                }
            })
            const id = String(i + 1);
            const getColor = () => {
                if (item.score === 0) {
                    return "red";
                } else if (item.score === 20) {
                    return "#006AFFFF";
                } else {
                    return "#78AFFCFF";
                }
            }
            return {
                label: <><span style={{color: getColor()}}>第{id}题</span></>,
                key: id,
                children: <>
                    <Descriptions>
                        <Descriptions.Item label="试题">
                            {ques.quesTitle}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="试题类型">
                            {QuesType[ques.quesType]}
                        </Descriptions.Item>
                        <Descriptions.Item label="分数">
                            {ques.quesScore}
                        </Descriptions.Item>
                    </Descriptions>
                    {option.map((child: { optionName: string, optionTitle: string }) => {
                        return (
                            <Descriptions>
                                <Descriptions.Item label={child.optionName}>
                                    {child.optionTitle}
                                </Descriptions.Item>
                            </Descriptions>
                        );
                    })}
                    <Descriptions>
                        <Descriptions.Item label="答案">
                            {answerStr}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="用户选择" contentStyle={{color:"#006AFFFF"}}>
                            {userAnswerStr}
                        </Descriptions.Item>
                        <Descriptions.Item label="用户得分" contentStyle={{color:"#006AFFFF"}}>
                            {item.score}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="解析">
                            {ques.parse}
                        </Descriptions.Item>
                    </Descriptions>
                </>,
            };
        });
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <Card size={"small"}>
                                <Descriptions>
                                    <Descriptions.Item label="答题码">
                                        {detailData.userAnswerCode}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="得分">
                                        {detailData.answerScore}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="用时">
                                        {detailData.useTime}s
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="答题数量">
                                        {detailData.quesCount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="答对题数">
                                        {detailData.correctCount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="答错题数">
                                        {detailData.errorCount}s
                                    </Descriptions.Item>
                                </Descriptions>
                                <Tabs items={items}/>
                            </Card>
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function ExamUserAnswerDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ExamUserAnswerDetailClass/>
        </App>
    );
}
