import imageCompression from 'browser-image-compression';

export function ImgCompression(imageFile: any, maxSizeMB: any) {
    return new Promise((resolve, reject) => {
        if (imageFile) {
            console.log(maxSizeMB)
            // 设置压缩选项
            const options = {
                maxSizeMB: maxSizeMB > 5 ? 5 : maxSizeMB, // 最大文件大小 (MB)
                useWebWorker: true, // 使用 web worker 提升性能
                preserveExif: true
            };
            try {
                imageCompression(imageFile, options).then((res) => {
                    console.log('压缩成功:', res);
                    resolve(res)
                });
            } catch (error) {
                console.error('压缩失败:', error);
                reject(error)
            }
        }
    })
}